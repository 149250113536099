import { FC } from "react";
import styled from "@emotion/styled";

import { Tooltip } from "antd";

import { Dictionary } from "../../../share/types";
import { colorScale, getMapChartNormalizedData } from "../../../helpers";
import { useBreakpoint } from "../../../utils/styling";

interface MultiColorBarProps {
  data: Dictionary<number>;
}

export const MultiColorBar: FC<MultiColorBarProps> = ({ data }) => {
  const [isScreenMoreThan1300] = useBreakpoint(1300, { useBody: true });

  const countriesWithoutNull = { ...data };
  // But in any case, we try to delete it
  try {
    delete countriesWithoutNull.null;
  } catch {
    // Nothing, there was no null to delete
  }

  const normalizedData = getMapChartNormalizedData(data);

  var countriesData: Array<[string, number]> = Object.keys(
    countriesWithoutNull
  ).map(function (key) {
    return [key, countriesWithoutNull[key]];
  });

  // Sort decreasingly by country value
  countriesData.sort(function (first: any, second: any) {
    return second[1] - first[1];
  });

  // Get the total of the countries without null data
  const total = countriesData.reduce((prev, current) => [
    "",
    prev[1] + current[1],
  ])[1];

  const topFive = countriesData.slice(0, 5);
  const others = countriesData.slice(5);
  let displayCountries;
  if (others.length > 0) {
    const otherCountries = others.reduce((previousValue, currentValue) => [
      "others",
      previousValue[1] + currentValue[1],
    ]);
    displayCountries = [...topFive, otherCountries];
  } else {
    displayCountries = [...topFive];
  }

  const MIN_WIDTH = 5;
  const maxWidth = 100 - (displayCountries.length - 1) * MIN_WIDTH;

  return (
    <div style={{ marginTop: isScreenMoreThan1300 ? "-12rem" : "-10rem" }}>
      <SubContainer>
        {displayCountries.map((country) => {
          const countryPercentage = (country[1] * 100) / total;
          const countryScale = normalizedData[country[0]] || 0;
          const countryColor = colorScale(countryScale);
          // Each bar should have at least 5% in order to fit the data and represent everything properly
          // And a max of 100- dataPoints*5, with dataPoints being 6
          return (
            <div
              style={{
                width: countryPercentage + "%",
                minWidth: `${MIN_WIDTH}%`,
                maxWidth: `${maxWidth}%`,
              }}
              key={`${country[0]}-${country[1]}`}
            >
              <CountryData color={countryColor}>
                {Math.round(countryPercentage)}%
              </CountryData>
              <CountryData color={countryColor}>|</CountryData>
              <Tooltip title={`${country[0]} - ${country[1]}`}>
                <CountryBar backgroundColor={countryColor} />
              </Tooltip>
            </div>
          );
        })}
      </SubContainer>
      <LegendContainer>
        {displayCountries.map((country) => {
          const countryScale = normalizedData[country[0]] || 0;
          const countryColor = colorScale(countryScale);
          return (
            <LegendItem key={`${country[1]}-${country[0]}`}>
              <CountryData color={countryColor}>●</CountryData>
              <span>{country[0]}</span>
            </LegendItem>
          );
        })}
      </LegendContainer>
    </div>
  );
};

const SubContainer = styled.div`
  display: flex;
`;

const LegendContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 0.5rem;
`;

const CountryBar = styled.div<{ backgroundColor: string }>`
  height: 3rem;
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  border-right: 0.1rem solid white;
`;

const CountryData = styled.div<{ color: string }>`
  text-align: center;
  color: ${(props) => props.color};
`;

const LegendItem = styled.div`
  display: flex;
`;
