import { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";

import { http } from "../http";
import { SignUpData, SetPasswordData } from "../../auth/store";

const skipAuthRefresh = {
  skipAuthRefresh: true,
} as AxiosAuthRefreshRequestConfig;

export async function forgotPassword(email: string) {
  const { data } = await http.post(
    "/user-management/password-reset",
    {
      email,
    },
    skipAuthRefresh
  );
  return data;
}

export async function login(email: string, password: string) {
  const { data } = await http.post("/token/auth", {
    email,
    password,
  });
  return data;
}

export async function resetPassword(password: string, token: string) {
  const { data } = await http.post(
    "/user-management/password-reset/confirm",
    {
      token,
      password,
    },
    skipAuthRefresh
  );
  return data;
}
export async function setPassword(setPasswordDataFromInvite: SetPasswordData) {
  const { data } = await http.post(
    "/user-management/invitation/confirm",
    setPasswordDataFromInvite,
    skipAuthRefresh
  );
  return data;
}

export async function signUp(signUpData: SignUpData) {
  const { data } = await http.post(
    "/user-management/sign-up",
    signUpData,
    skipAuthRefresh
  );
  return data;
}

export async function validateResetPasswordToken(token: string) {
  const { data } = await http.post(
    "/user-management/password-reset/validate-token",
    {
      token,
    },
    skipAuthRefresh
  );
  return data;
}
