import styled from "@emotion/styled";
import { FC, useState } from "react";

import { CenteredSpinner } from "../../ui-share";
import { Section, SectionHeader, SectionTitle } from "../../ui-share";
import websiteIcon from "../../../../../assets/icons/website.svg";
import defaultSnapshot from "../../../../../assets/states/no-snapshot.svg";

import { addUTMSource, openLinkInNewTabAndTrack } from "../../../../../helpers";
import { TopbarButton } from "../../../../../ui-components/layout/Topbar/components";

import openInNewWindowIcon from "../../../../../assets/icons/open-in-new-window-orange.svg";
import { StylelessButton } from "../../../../../ui-components/StylelessButton";

const WIDTH = 1280;
const HEIGHT = 720;

export const Snapshot: FC<{ url: string | null | undefined }> = ({ url }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const cleanedURL = url?.endsWith("/") ? url.slice(0, -1) : url;
  const urlWithUTM = (cleanedURL && addUTMSource(cleanedURL)) || "";
  const trackOpenLinkEventName = "open-company-website";

  const snapshotURL = `https://images.novable.com/screenshot?url=${cleanedURL}&width=${WIDTH}&height=${HEIGHT}`;

  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Website</SectionTitle>
      </SectionHeader>
      <Website>
          <StylelessButton 
            onClick={() => window.open(urlWithUTM, "_blank")}
            style={{color: "var(--primary-color)"}}
          >
            {cleanedURL} <OpenInNewWindowIcon />
          </StylelessButton>

        <ImageContainer>
          {urlWithUTM ? (
            <a
              href={urlWithUTM}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="website"
              onClick={(event) =>
                url &&
                openLinkInNewTabAndTrack(
                  event,
                  urlWithUTM,
                  trackOpenLinkEventName,
                  {
                    link: url,
                  }
                )
              }
            >
              <AspectRatioContainer>
                <Img
                  style={{ display: imageLoaded ? "block" : "none" }}
                  src={snapshotURL}
                  alt="Snapshot of the Company's website"
                  onError={(e) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = defaultSnapshot;
                  }}
                  onLoad={() => handleImageLoaded()}
                />
                {!imageLoaded && <CenteredSpinner />}
              </AspectRatioContainer>
            </a>
          ) : (
            <DefaultSnapshot />
          )}
        </ImageContainer>
        <div></div>
      </Website>
    </Section>
  );
};

const AspectRatioContainer = styled.div`
  width: 100%;
`;

const Img = styled.img`
  width: 100%;
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  border-width: 0.75rem;
  border-style: solid;
  border-color: var(--background-color-tertiery);
  border-radius: 0.5rem;
  width: 50%;
`;

const DefaultSnapshot = styled.div`
  background: url(${defaultSnapshot});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
`;

const Website = styled.div`
  padding-top: 0;
  margin-bottom: 0rem;
  margin-left: var(--gb-content-section-left-margin);
`;

const OpenInNewWindowIcon = styled.div`
  width: 2rem;
  height: 2rem;
  background: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  background-image: url(${openInNewWindowIcon});
`;