import { useEffect } from "react";

import { Table } from "antd";

import { CenteredErrorMessage } from "../../../ui-components/CenteredErrorMessage";

import { getClientLists } from "../../store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { columns } from "./columns";

export const ListManagementTable = () => {
  const dispatch = useAppDispatch();
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { lists } = useAppSelector((state: State) => state.lists);

  const { loading, error } = useAppSelector(
    (state: State) => state.lists.getClientLists
  );

  // Refresh lists in case another user in the client instance has modified or created lists
  useEffect(() => {
    if (selectedClient) dispatch(getClientLists(selectedClient.uuid));
  }, [dispatch, selectedClient]);

  if (error) {
    return <CenteredErrorMessage>{error}</CenteredErrorMessage>;
  }

  const tableColumns = columns();

  return (
    <Table
      dataSource={lists}
      columns={tableColumns}
      rowKey={(record: any) => (record && record.uuid) || ""}
      loading={loading}
    />
  );
};
