import { FC } from "react";
import styled from "@emotion/styled";

import { CloseIcon } from "../../ui-share";
import { Topic } from "./Topic";

import { highlightTopic } from "../../../../../helpers";

interface ExtraTopicsPopoverProps {
  closePopover: () => void;
  topics: string[];
  highlightTopics?: string[];
}

export const ExtraTopicsPopover: FC<ExtraTopicsPopoverProps> = ({
  closePopover,
  topics,
  highlightTopics,
}) => {
  return (
    <PopoverContent style={{ position: "relative" }}>
      <Close onClick={closePopover}>
        <CloseIcon />{" "}
      </Close>
      <div>
        {topics.map((topic, index) => (
          <Topic
            key={index}
            topic={topic}
            highlightTopic={highlightTopic(topic, highlightTopics)}
          />
        ))}
      </div>
    </PopoverContent>
  );
};

const Close = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
  padding: 0.5rem;
  cursor: pointer;
`;

const PopoverContent = styled.div`
  width: 40rem;
`;
