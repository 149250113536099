import { FC } from "react";

import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { StylelessButton } from "../../../../ui-components/StylelessButton";
import { StyledButtonText } from "../../../../ui-components/Typography";

import { deleteTag } from "../../../store";
import { State } from "../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";

const { confirm } = Modal;

interface DeleteTagButtonProps {
  tagUUID: string;
  tagName: string;
}

export const DeleteTagButton: FC<DeleteTagButtonProps> = ({
  tagUUID,
  tagName,
}) => {
  const dispatch = useAppDispatch();

  const { loading, requestedUUID } = useAppSelector(
    (state: State) => state.tags.deleteTag
  );

  const handleDeleteTag = () => {
    const data = {
      tagUUID: tagUUID,
    };
    dispatch(deleteTag(data));
  };

  const showConfirm = () => {
    confirm({
      title: "Do you want to delete this tag?",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to delete the tag ${tagName}? All the Companies with this tag will have this tag removed`,
      okText: "Confirm",
      onOk() {
        handleDeleteTag();
      },
      okButtonProps: { 
        loading: loading,
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
    });
  };

  const showButtonLoading = loading && requestedUUID === tagUUID;
  return (
    <StylelessButton
      disabled={showButtonLoading}
      loading={showButtonLoading}
      onClick={showConfirm}
    >
      <StyledButtonText>Delete</StyledButtonText>
    </StylelessButton>
  );
};
