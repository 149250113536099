import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { fork, put, takeEvery } from "redux-saga/effects";

import { backend } from "../../utils/http";
import { Dictionary } from "../../share/types";
import { CompanyDetail } from "../../share/company/store";

interface CampaignInsightsPayload {
  data: {
    goldenBasketInsights: GoldenBasketInsights;
    campaignMeta: CampaignMeta;
    dimensionality: Dimensionality;
  };
  message: string;
}

interface GoldenBasketInsights {
  country: Dictionary<number>;
  age: Dictionary<number>;
  employeeRange: Dictionary<number>;
  totalFunding: Dictionary<number>;
  textKeywords: Dictionary<number>;
}

export interface CampaignMeta {
  matches: number;
  misses: number;
  toReview: number;
  iterations: number;
}

export const CompanyStatusMapping = {
  toReview: "To Review",
  matches: "Matches",
  misses: "Misses",
  lookalikes: "Lookalikes",
};

export enum CompanyStatus {
  toReview = "To Review",
  Match = "Matches",
  Miss = "Misses",
  Lookalikes = "Lookalikes",
}

export enum CompanyStatusColors {
  matches = "rgb(33, 174, 255)",
  misses = "rgb(18, 118, 190)",
  toReview = "rgb(2, 62, 125)",
  lookalikes = "#ED944D",
}

export enum CapitalizedCompanyStatusColors {
  Matches = "#27ca69", // "rgb(33, 174, 255)",
  Misses = "#ef3434", //"rgb(18, 118, 190)",
  "To Review" = "rgb(2, 62, 125)",
  Lookalikes = "#ED944D",
}

export type CompanyDimensionality = {
  company: CompanyDetail;
  dimensionality: number[][];
  companyStatus: CompanyStatus;
};
export type Dimensionality = CompanyDimensionality[];

export interface CampaignInsightsState {
  getCampaignInsights: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  goldenBasketInsights: GoldenBasketInsights;
  campaignMeta: CampaignMeta;
  dimensionality: Dimensionality;
}

const initialState = {
  getCampaignInsights: {
    loading: false,
    error: "",
    success: false,
  },
  goldenBasketInsights: {},
  campaignMeta: {},
  dimensionality: {},
} as CampaignInsightsState;

export const campaignInsightsSlice = createSlice({
  name: "edit_campaign",
  initialState: initialState,
  reducers: {
    getCampaignInsights(state, action: PayloadAction<string>) {
      state.getCampaignInsights.loading = true;
      state.getCampaignInsights.success = false;
      state.getCampaignInsights.error = "";
    },
    getCampaignInsightsSuccess(state, action) {
      state.getCampaignInsights.loading = false;
      state.getCampaignInsights.success = true;
      state.getCampaignInsights.error = "";
      state.goldenBasketInsights = action.payload.data.goldenBasketInsights;
      state.campaignMeta = action.payload.data.campaignMeta;
      state.dimensionality = action.payload.data.dimensionality;
    },
    getCampaignInsightsFailure(state, action) {
      state.getCampaignInsights.loading = false;
      state.getCampaignInsights.success = false;
      state.getCampaignInsights.error = action.payload.data.message;
    },
    setInitialState(state) {
      state.getCampaignInsights = initialState.getCampaignInsights;
      state.goldenBasketInsights = initialState.goldenBasketInsights;
    },
  },
});

function* getCampaignInsightsSaga() {
  yield takeEvery(
    campaignInsightsSlice.actions.getCampaignInsights,
    function* (action) {
      try {
        const response: AxiosResponse<CampaignInsightsPayload> =
          yield backend.campaignBackend.getCampaignInsights(action.payload);
        yield put(
          campaignInsightsSlice.actions.getCampaignInsightsSuccess(response)
        );
      } catch (err) {
        yield put(
          campaignInsightsSlice.actions.getCampaignInsightsFailure(err)
        );
      }
    }
  );
}

export function* campaignInsightsSaga() {
  yield fork(getCampaignInsightsSaga);
}

export const { getCampaignInsights, setInitialState } =
  campaignInsightsSlice.actions;
