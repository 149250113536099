import { FC } from "react";
import styled from "@emotion/styled";

import { Section, SectionHeader, SectionTitle } from "../../ui-share";

import { beautifyUrl } from "../../../../../helpers/strings";
import { openLinkInNewTabAndTrack } from "../../../../../helpers";

interface ESGLinksProps {
  links: string[];
}

export const ESGLinks: FC<ESGLinksProps> = ({ links }) => {
  const trackOpenLinkEventName = "open-esg-link";

  return (
    <Section>
      <SectionHeader>
        <SectionTitle>ESG Information</SectionTitle>
      </SectionHeader>
      <LinksContainer>
        {links.map((link, index) => (
          <div key={index}>
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`Link to ESG information with url: ${link}`}
              key={index}
              onClick={(event) =>
                openLinkInNewTabAndTrack(event, link, trackOpenLinkEventName, {
                  link: link,
                })
              }
            >
              &nbsp;{beautifyUrl(link)}
            </a>
          </div>
        ))}
      </LinksContainer>
    </Section>
  );
};

const LinksContainer = styled.div`
  margin-left: var(--gb-content-section-left-margin);
  font-size: var(--gb-content-font-size);
  border-radius: 0.5rem;
`;

