export const getHourDiff = (dateA: Date, dateB: Date) =>
  Math.abs(dateA.getTime() - dateB.getTime()) / (3600 * 1000);

// https://stackoverflow.com/a/3605248
export const dateFormatter = (date: string) => {
  const myDate = new Date(date);
  return (
    ("0" + myDate.getDate()).slice(-2) +
    "/" +
    ("0" + (myDate.getMonth() + 1)).slice(-2) +
    "/" +
    myDate.getFullYear()
  );
};
