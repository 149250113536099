import styled from "@emotion/styled";
import { EditButton as BaseButton } from "./EditButton";

export const StylelessButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  color: var(--font-color-black)
  margin-top: 0rem;
  margin-left: var(--gb-content-section-left-margin);
  font-size: var(--gb-content-font-size);

  &:hover {
    background: none;
  }
  &:focus {
    background: none;
    color: var(--primary-color);
  }

  &:disabled {
    background: none;
    color: var(--background-color-secondary);
  }

  // &:disabled:hover {
  //   background: none;
  // }
`;
