import { FC, useEffect } from "react";

import { Col, Row } from "antd";
import { GeneralInformationWithHighlights } from "../../golden-basket/components/detail-components/body/GeneralInformationWithHighlights";
import { FundingInformationWithShow } from "../../golden-basket/components/detail-components/body/FundingInformationWithShow";
import { Feedback } from "../../golden-basket/components/detail-components/body/Feedback";
import { AgentComment } from "../../golden-basket/components/detail-components/body/AgentComment";
import {
  //  CrunchbaseAttribution,
  News,
  Topics,
} from "../../../share/company/components/company-detail/body";
import { ESGLinks } from "../../../share/company/components/company-detail/body/ESGLinks";
import { ActivityIndexExpanded } from "../../../share/company/components/company-detail/body/ActivityIndexExpanded";
import { CompanyClassification } from "../../../share/company/components/company-detail/body/CompanyClassification";
import { CenteredSpinner } from "../../../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../../../ui-components/CenteredErrorMessage";
import { ShowWithAnimation } from "../../../ui-components/ShowWithAnimation";

import {
  GoldenBasketCompany,
  fetchSelectedCompany,
  setInitialGoldenBasketCompanyState,
} from "../../golden-basket/store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { getCompanyTopics } from "../../../helpers";
import { Segment } from "../../../utils/Segment";

interface ExpandedCompanyRowProps {
  goldenBasketCompany: GoldenBasketCompany;
  highlightTopics: string[];
  visibleRows: string[];
}

export const ExpandedCompanyRow: FC<ExpandedCompanyRowProps> = ({
  goldenBasketCompany,
  highlightTopics,
  visibleRows,
}) => {
  const dispatch = useAppDispatch();

  const { loading, error } = useAppSelector(
    (state: State) => state.goldenBasket.fetchSelectedCompany
  );
  const { selectedCompany } = useAppSelector(
    (state: State) => state.goldenBasket
  );

  useEffect(() => {
    if (
      visibleRows.includes(goldenBasketCompany.uuid) &&
      goldenBasketCompany.company.uuid !== selectedCompany?.uuid
    ) {
      dispatch(fetchSelectedCompany(goldenBasketCompany.uuid));

      Segment.analytics?.track("open company detail", {
        source: "campaign-table-view",
        companyUUID: goldenBasketCompany.company.uuid,
      });
    }
  }, [dispatch, visibleRows, selectedCompany, goldenBasketCompany]);

  useEffect(() => {
    return () => {
      dispatch(setInitialGoldenBasketCompanyState());
    };
  }, [dispatch]);

  if (loading) {
    return <CenteredSpinner />;
  }

  if (error) {
    return <CenteredErrorMessage>{error}</CenteredErrorMessage>;
  }

  if (!selectedCompany) {
    return <CenteredErrorMessage>An error has ocurred</CenteredErrorMessage>;
  }

  const hasFundingDate = selectedCompany.lastFundingDate;
  const hasFundingRounds =
    selectedCompany.fundingRounds || selectedCompany.fundingRounds === 0;
  const hasTotalFunding =
    selectedCompany.totalFunding || selectedCompany.totalFunding === 0;
  const displayFundingInformation =
    hasFundingDate || hasFundingRounds || hasTotalFunding;

  let topics = getCompanyTopics(selectedCompany);

  return (
    <ShowWithAnimation isMounted={true}>
      <Row>
        {selectedCompany.shortDescription && (
          <Col xs={24} sm={24} md={24} lg={24} style={{ padding: "1rem" }}>
            <GeneralInformationWithHighlights
              description={selectedCompany.shortDescription}
              wordsToHighlight={highlightTopics}
            />
          </Col>
        )}
        {displayFundingInformation && (
          <Col xs={8} sm={8} md={8} lg={8} style={{ padding: "1rem" }}>
            <FundingInformationWithShow
              lastFundingDate={selectedCompany.lastFundingDate}
              fundingRounds={selectedCompany.fundingRounds}
              totalFunding={selectedCompany.totalFunding}
              currencyCode={selectedCompany.currency}
              companyUUID={selectedCompany.uuid}
              source="campaign-table-view"
            />
          </Col>
        )}

        {selectedCompany.feedback && (
          <Col xs={12} sm={12} md={12} lg={12} style={{ padding: "1rem" }}>
            <Feedback feedback={selectedCompany.feedback} />
          </Col>
        )}

        {selectedCompany.agentComment && (
          <Col xs={12} sm={12} md={12} lg={12} style={{ padding: "1rem" }}>
            <AgentComment comment={selectedCompany.agentComment} />
          </Col>
        )}

        {selectedCompany.news && selectedCompany.news.length > 0 && (
          <Col xs={12} sm={12} md={12} lg={12} style={{ padding: "1rem" }}>
            <News news={selectedCompany.news} />
          </Col>
        )}

        {selectedCompany.esgLinks && selectedCompany.esgLinks.length > 0 && (
          <Col xs={12} sm={12} md={12} lg={12} style={{ padding: "1rem" }}>
            <ESGLinks links={selectedCompany.esgLinks} />
          </Col>
        )}

        {/* {selectedCompany.activityIndex && (
          <Col xs={8} sm={8} md={6} lg={6} style={{ padding: "1rem" }}>
            <ActivityIndexExpanded
              activityIndex={selectedCompany.activityIndex}
            />
          </Col>
        )} */}

        {selectedCompany.classification?.length > 0 && (
          <Col xs={8} sm={8} md={6} lg={6} style={{ padding: "1rem" }}>
            <CompanyClassification tags={selectedCompany.classification} />
          </Col>
        )}

        {topics.length > 0 && (
          <Col xs={24} sm={24} md={12} lg={12} style={{ padding: "1rem" }}>
            <Topics topics={topics} highlightTopics={highlightTopics} />
          </Col>
        )}

        {/* {selectedCompany.crunchbaseUrl && (
          <Col xs={24} sm={24} md={24} lg={24} style={{ padding: "1rem" }}>
            <CrunchbaseAttribution
              crunchbaseUrl={selectedCompany.crunchbaseUrl}
            />
          </Col>
        )} */}
      </Row>
    </ShowWithAnimation>
  );
};
