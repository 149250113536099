import { FC } from "react";
import styled from "@emotion/styled";

import { MapChartWithCountryList } from "./MapChart/MapChartWithCountryList";
import { Dictionary } from "../../share/types";
import { BarCharts } from "./Firmographics/BarCharts";

interface FirmographicsProps {
  values: {
    countries: Dictionary<number>;
    age: Dictionary<number>;
    employeeRange: Dictionary<number>;
    totalFunding: Dictionary<number>;
    wordCloudKeywords: any;
  };
}

export const Firmographics: FC<FirmographicsProps> = ({ values }) => {
  const { countries } = values;

  return (
    <ChartsContainer>
      <MapChartContainer>
        <MapChartWithCountryList data={countries} />
      </MapChartContainer>
      <BarCharts values={values} />
    </ChartsContainer>
  );
};

const ChartsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const MapChartContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-around;
`;
