import { FC, useCallback } from "react";
import styled from "@emotion/styled";

import { Section, SectionContent as BaseSectionContent } from "../../ui-share";
import { CommentLabel } from ".";

export const AgentComment: FC<{ comment: string }> = ({ comment }) => {
  const getFormattedComment = useCallback(() => {
    const tagRegex = /<\/?(h[1-6]|span|p)(\s[^>]*)?>/gi;
    const styleRegex = /\s*style="[^"]*"/gi;

    let cleanHtml = comment.replace(tagRegex, (match) => {
      if (match.startsWith("</")) {
        return "</div>";
      } else {
        return match.replace(/(h[1-6]|span|p)/i, "div");
      }
    });

    cleanHtml = cleanHtml.replace(styleRegex, "");

    return cleanHtml;
  }, [comment]);

  const applyInlineStyles = (node: HTMLDivElement | null) => {
    if (node) {
      const divElements = node.querySelectorAll("div");
      divElements.forEach((div) => {
        div.style.display = "inline";
      });
    }
  };

  return (
    <Section>
      <CommentLabel>Your Novable Expert says</CommentLabel>

      <SectionContent
        ref={applyInlineStyles}
        dangerouslySetInnerHTML={{ __html: getFormattedComment() }}
      ></SectionContent>
    </Section>
  );
};

const SectionContent = styled(BaseSectionContent)`
  padding: 1.3rem;
  border-radius: var(--border-radius);
  background-color: var(--primary-color-light);
  font-size: var(--gb-content-font-size);
  margin-bottom: 1rem;

  & p {
    margin: 0;
  }
`;
