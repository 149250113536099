import { http } from "../http";

import {
  FollowCompanyData,
  CampaignRequest,
  ShareCompanyData,
  EditCompanyCommentData,
} from "../../campaign/golden-basket/store";

export async function fetchGoldenBasketCompanyDetail(GBCompanyUUID: string) {
  const { data } = await http.get(
    `/api/golden-basket/company/${GBCompanyUUID}`
  );
  return data;
}

export async function followCompany(followCompanyData: FollowCompanyData) {
  const { data } = await http.put(
    "/api/golden-basket/follow",
    followCompanyData
  );
  return data;
}

export async function getGBList(uuid: string) {
  const { data } = await http.get(`/api/golden-basket/${uuid}`);
  return data;
}

export async function shareCompany(requestData: ShareCompanyData) {
  const { data } = await http.post("/api/request/share", requestData);
  return data;
}

export async function submitFeedback(
  isMatch: boolean,
  companyUUID: string,
  campaignUUID: string,
  comment: string | null,
  type: number | null
) {
  const { data } = await http.post("/api/feedback", {
    isMatch,
    companyUUID,
    campaignUUID,
    comment,
    type,
  });
  return data;
}

export async function submitGBCampaignRequest(requestData: CampaignRequest) {
  const { data } = await http.post("/api/request/campaign", requestData);
  return data;
}

export async function editCompanyComment(
  editCompanyCommentData: EditCompanyCommentData
) {
  const { data } = await http.post(
    "/api/golden-basket/company/comment",
    editCompanyCommentData
  );
  return data;
}
