import { notification as baseNotification } from "antd";

export const successNotification = (message: string) => {
  baseNotification.success({
    message: message,
    className: "custom-class",
    top: 100,
    style: {
      width: "auto",
      background: "var(--notification-success-background)",
      border: "var(--notification-success-border)",
      borderRadius: 0,
      fontSize: "1.4rem",
    },
  });
};

export const errorNotification = (message: string) => {
  baseNotification.error({
    message: message,
    className: "custom-class",
    top: 100,
    style: {
      width: "auto",
      background: "var(--notification-error-background)",
      border: "var(notification-error-border)",
      borderRadius: 0,
      fontSize: "1.4rem",
    },
  });
};
