import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import * as AuthBackend from "./backends/authBackend";
import * as CampaignBackend from "./backends/campaignBackend";
import * as ClientsBackend from "./backends/clientsBackend";
import * as CompaniesBackend from "./backends/companiesBackend";
import * as CompaniesSearchBackend from "./backends/companiesSearchBackend";
import * as GoldenBasketBackend from "./backends/goldenBasketBackend";
import * as ListBackend from "./backends/listBackend";
import * as TagBackend from "./backends/tagBackend";
import * as UserBackend from "./backends/userBackend";
import * as UserManagementBackend from "./backends/userManagementBackend";
import * as ThreadsBackend from "./backends/threadsBackend";

import {
  getRefreshToken,
  getAccessToken,
  setAccessToken,
} from "./backends/utils";

export class Backend {
  get authBackend() {
    return AuthBackend;
  }

  get campaignBackend() {
    return CampaignBackend;
  }

  get clientsBackend() {
    return ClientsBackend;
  }

  get companiesBackend() {
    return CompaniesBackend;
  }

  get companiesSearchBackend() {
    return CompaniesSearchBackend;
  }

  get goldenBasketBackend() {
    return GoldenBasketBackend;
  }

  get listBackend() {
    return ListBackend;
  }

  get tagBackend() {
    return TagBackend;
  }

  get userBackend() {
    return UserBackend;
  }

  get userManagementBackend() {
    return UserManagementBackend;
  }

  get threadsBackend() {
    return ThreadsBackend;
  }

  setupInterceptor(performLogout: Function) {
    http.interceptors.request.use((request) => {
      const ignoreURLs = [
        "/user-management/password-reset",
        "/user-management/password-reset/confirm",
        "/user-management/password-reset/validate-token",
      ];
      const requestURL = request.url || "";

      if (!ignoreURLs.includes(requestURL)) {
        request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
      }

      return request;
    });

    const refreshAuthLogic = (failedRequest: any) => {
      const refreshToken = getRefreshToken();
      if (!refreshToken) return Promise.reject();

      return http
        .post("/token/refresh", {
          refresh: refreshToken,
        })
        .then((tokenRefreshResponse) => {
          setAccessToken(tokenRefreshResponse.data.access);
          failedRequest.response.config.headers["Authorization"] =
            "Bearer " + tokenRefreshResponse.data.access;
          return Promise.resolve();
        })
        .catch(async () => {
          await performLogout();
          return Promise.reject();
        });
    };

    createAuthRefreshInterceptor(http, refreshAuthLogic, {
      pauseInstanceWhileRefreshing: true,
    });

    // Error catching
    http.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        let error_response = error.response;
        if (error.code === "ECONNABORTED") {
          error_response = {
            data: {
              message:
                "Your request is taking too long. It could be our servers are saturated. If the problem persists, please contact us at support@novable.com",
            },
            statusText: "Unavailable",
            request: {},
            code: "ECONNABORTED",
          };
        } else if (!error_response) {
          error_response = {
            data: {
              message:
                "Oops, something went wrong. Our systems may be down, if the problem persists, please contact us at support@novable.com",
            },
            statusText: "Unauthorized",
            request: {},
            code: error.code,
          };
        } else if (error_response.data?.message?.nonFieldErrors?.length > 0) {
          error_response = {
            data: {
              message: error_response.data.message.nonFieldErrors[0],
            },
            statusText: error.statusText,
            request: {},
            code: error.code,
          };
        }
        return Promise.reject(error_response);
      }
    );
  }
}

export const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const httpScott = axios.create({
  baseURL: process.env.REACT_APP_SCOTT_BACKEND_URL,
  headers: {
    "Authorization": `Bearer ${process.env.REACT_APP_LANGSERVE_AUTH_TOKEN}`,
  },
});

export const backend = new Backend();
export { LocalStorageKeys } from "./backends/utils";
