import { http } from "../http";

import {
  CreateTagData,
  DeleteTagData,
  UpdateTagData,
  AddCompanyToTagData,
  RemoveCompanyFromTagData,
} from "../../tags/store";

export async function getClientTags(clientUUID: string) {
  const { data } = await http.get(`/api/client/${clientUUID}/tags`);
  return data;
}

export async function createTag(requestData: CreateTagData) {
  const { data } = await http.post(`/api/tag`, requestData);
  return data;
}

export async function deleteTag(requestData: DeleteTagData) {
  const config = {
    data: requestData,
  };
  const { data } = await http.delete(`/api/tag`, config);
  return data;
}

export async function updateTag(requestData: UpdateTagData) {
  const { data } = await http.put(`/api/tag`, requestData);
  return data;
}

export async function removeCompanyFromTag(
  requestData: RemoveCompanyFromTagData
) {
  const config = {
    data: requestData,
  };
  const { data } = await http.delete(`/api/tag/company`, config);
  return data;
}

export async function addCompanyToTag(requestData: AddCompanyToTagData) {
  const { data } = await http.post(`/api/tag/company`, requestData);
  return data;
}
