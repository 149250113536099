import styled from "@emotion/styled";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import { NoResult } from "../../ui-components/states";
import { CenteredSpinner } from "../../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../../ui-components/CenteredErrorMessage";
import { ListOfCompanies } from "./components/ListOfCompanies";
import { ListCompanyDetail } from "./components/ListCompanyDetail";
import { UnkownError } from "../../ui-components/states/UnknownError";

import { setSelectedCompanyInitialState } from "../../share/company/store";
import { getListCompanies, selectedListSlugSelector } from "../store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import { Segment } from "../../utils/Segment";

export const ListView = () => {
  const dispatch = useAppDispatch();

  const {
    loading: loadingCompany,
    error: errorFetchingCompany,
    success: successFetchingCompany,
  } = useAppSelector((state: State) => state.company.fetchCompany);
  const { loading: loadingListCompany, error: errorFetchingListCompanies } =
    useAppSelector((state: State) => state.lists.getListCompanies);

  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const { listSlug } = useParams<{ listSlug: string }>();
  const list = useAppSelector(selectedListSlugSelector(listSlug));

  const { fetchedCompany } = useAppSelector((state: State) => state.company);

  useEffect(() => {
    if (listSlug && list && selectedClient) {
      dispatch(
        getListCompanies({
          listUUID: list.uuid,
          clientUUID: selectedClient?.uuid,
        })
      );
      Segment.analytics?.track(`open list`, {
        listUUID: list.uuid,
        listName: list.name,
      });
    }
  }, [dispatch, listSlug, list, selectedClient]);

  // Since golden basket view, list view and favourite view all use the
  // state.company.selectedCompany variable, it's necessary to reset it after
  // this component unmounts to avoid unwanted effects
  useEffect(() => {
    return () => {
      dispatch(setSelectedCompanyInitialState());
    };
  }, [dispatch]);

  if (!listSlug || !list) {
    return <Navigate to={`/${selectedClient?.slug}/dashboard`} />;
  }

  const listCompanyFetchedWithResults =
    !loadingCompany &&
    !loadingListCompany &&
    !errorFetchingCompany &&
    !errorFetchingListCompanies &&
    successFetchingCompany &&
    fetchedCompany;

  const listCompanyFetchedWithoutResults =
    !loadingCompany &&
    !loadingListCompany &&
    !errorFetchingCompany &&
    !errorFetchingListCompanies &&
    successFetchingCompany &&
    !fetchedCompany;

  return (
    <Container>
      <ListOfCompanies listName={list.name} />

      {/* Error Fetching company flow */}
      {errorFetchingCompany ? (
        <CenteredErrorMessage>
          {" "}
          There was an error loading the selected Company.{" "}
          {errorFetchingCompany}{" "}
        </CenteredErrorMessage>
      ) : errorFetchingListCompanies ? (
        <CenteredErrorMessage>
          {" "}
          There was an error loading the list. {errorFetchingListCompanies}{" "}
        </CenteredErrorMessage>
      ) : /* Loading List flow */
      loadingCompany || loadingListCompany ? (
        <CenteredSpinner size="large" />
      ) : /* List and company fetched succesfully */
      listCompanyFetchedWithResults ? (
        <ListCompanyDetail company={fetchedCompany} />
      ) : listCompanyFetchedWithoutResults ? (
        <NoResult />
      ) : (
        <UnkownError />
      )}
    </Container>
  );
};

const Container = styled.div`
  height: calc(100vh - 8rem);
  display: grid;
  grid-template-columns: 32rem 1fr;
  grid-gap: 0rem;
`;
