import { FC, useEffect } from "react";

import { Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  successNotification,
  errorNotification,
} from "../../../../../../ui-components/Notification";
import { StylelessButton } from "../../../../../../ui-components/StylelessButton";
import { StyledButtonText } from "../../../../../../ui-components/Typography";

import {
  alterUserRole,
  AlterRoleRequestType,
  isNumberOfAdminsInUserListLessThanTwo,
  filteredUserInList,
} from "../../../../../store";
import { State } from "../../../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../../../utils/hooks";

const { confirm } = Modal;

interface DisableUserButtonProps {
  userUUID: string;
  clientUUID: string | undefined;
  isEnabled: boolean;
}

/**
 *  Based on the business logic, this is how the delete-user works
 *
 *  - Only admins can do it
 *  - If the user is the only admin, they cannot disable themselves
 */
export const DisableUserButton: FC<DisableUserButtonProps> = ({
  userUUID,
  clientUUID,
  isEnabled,
}) => {
  const dispatch = useAppDispatch();

  const { success, loading, error, uuidRequested, requestType } =
    useAppSelector((state: State) => state.userManagement.alterUserRole);
  const { user } = useAppSelector((state: State) => state.auth);
  const userInList = useAppSelector(filteredUserInList(userUUID));
  const isNumberOfAdminsLessThanTwo = useAppSelector(
    isNumberOfAdminsInUserListLessThanTwo()
  );

  const enableRequestForUser =
    uuidRequested === userUUID && requestType === AlterRoleRequestType.Enable;

  // Do not add isEnabled as a dependency
  useEffect(() => {
    if (enableRequestForUser) {
      if (success) {
        successNotification(
          `User has been ${isEnabled ? "disabled" : "enabled"} successfully`
        );
      } else if (success === false) {
        errorNotification(error);
      }
    }
  }, [success, loading, error, uuidRequested, enableRequestForUser]); // eslint-disable-line

  // Handles the different logic for "enable vs disable" a user
  const handleOnClick = (value: boolean) => {
    if (value) showConfirm();
    else handleAlterUserRole();
  };

  const handleAlterUserRole = () => {
    if (!clientUUID) return;

    const data = {
      userUUID: userUUID,
      clientUUID: clientUUID,
      isEnabled: !isEnabled,
    };
    dispatch(alterUserRole(data));
  };

  const showConfirm = () => {
    confirm({
      title: `Do you want to disable ${userInList?.email}?`,
      icon: <ExclamationCircleOutlined />,
      content:
        "Disabling this user will remove their access to the platform, are you sure you want to continue?",
      okText: "Confirm",
      okButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      onOk() {
        handleAlterUserRole();
      },
    });
  };

  let displayTooltip = false;
  let tooltipMessage;

  if (user?.uuid === userUUID && isNumberOfAdminsLessThanTwo) {
    displayTooltip = true;
    tooltipMessage =
      "You cannot disable yourself since you are the only active admin of this client";
  }

  const isProcessingRequest = loading && enableRequestForUser;
  const isDisabled = isProcessingRequest || displayTooltip;

  return (
    <>
      {displayTooltip ? (
        <Tooltip title={tooltipMessage}>
          <span style={{ cursor: "not-allowed" }}>
            <StylelessButton disabled={true} style={{ pointerEvents: "none" }}>
              {" "}
              <StyledButtonText disabled={true}>
                {isEnabled ? "Disable" : "Enable"}
              </StyledButtonText>
            </StylelessButton>
          </span>
        </Tooltip>
      ) : (
        <StylelessButton
          onClick={() => handleOnClick(isEnabled)}
          loading={isProcessingRequest}
          disabled={isDisabled}
        >
          <StyledButtonText disabled={isDisabled}>
            {isEnabled ? "Disable" : "Enable"}
          </StyledButtonText>
        </StylelessButton>
      )}
    </>
  );
};
