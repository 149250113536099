import { http } from "../http";

import {
  FavouriteCompanyData,
  FetchCompanyData,
  RequestEnrichedCompanyProfileData,
} from "../../share/company/store";
import { FetchFavouriteListData } from "../../favourite-page/store";

export async function fetchCompany(fetchCompanyData: FetchCompanyData) {
  const { data } = await http.get(
    `/api/client/${fetchCompanyData.clientUUID}/company/${fetchCompanyData.companyUUID}`
  );
  return data;
}

export async function favouriteCompany(
  favouriteCompanyData: FavouriteCompanyData
) {
  const { data } = await http.post(
    "/api/company/favourite",
    favouriteCompanyData
  );
  return data;
}

export async function unfavouriteCompany(
  favouriteCompanyData: FavouriteCompanyData
) {
  const config = {
    data: favouriteCompanyData,
  };
  const { data } = await http.delete("/api/company/favourite", config);
  return data;
}

export async function fetchFavouritedCompanies(
  favouriteCompanyData: FetchFavouriteListData
) {
  const { page, pageSize, clientUUID } = favouriteCompanyData;
  // Example of URL
  // http://admin.novable.com/api/client/2d7bc588-5958-4d58-9995-213df6ace15f/favourites?page=1&page_size=10
  const url = `/api/client/${clientUUID}/favourites?page=${page}&page_size=${pageSize}`;
  const { data } = await http.get(url.toString());
  return data;
}

export async function requestEnrichedCompanyProfile(
  requestEnrichedCompanyProfileData: RequestEnrichedCompanyProfileData
) {
  const { data } = await http.post(
    `/api/company/enriched-profile-request`,
    requestEnrichedCompanyProfileData
  );
  return data;
}
