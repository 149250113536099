import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Modal } from "antd";
import { NextButton } from "../../../share/campaigns/components/share-ui";
import { ButtonCancel } from "../../../ui-components/ButtonCancel";
import { CenteredSpinner } from "../../../ui-components/CenteredSpinner";
import { ToggleTag } from "../../../ui-components/ToggleTag";
import { ButtonText, ModalTitle } from "../../../ui-components/Typography";

import { fetchSuggestedKeywords, suggestedKeywordsSelector } from "../../store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { cleanKeyword } from "../../../helpers";

interface ExtraTopicsModalProps {
  visible: boolean;
  hideModal: () => void;
  values: { [field: string]: any };
  addTopics: (topics: string[]) => void;
}

const KEYWORD_LIMIT = 25;

export const ExtraTopicsModal: FC<ExtraTopicsModalProps> = ({
  visible,
  hideModal,
  values,
  addTopics,
}) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(
    (state: State) => state.newCampaign.fetchSuggestedKeywords
  );
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const suggestedKeywords = useAppSelector(suggestedKeywordsSelector);

  const [currentKeywords, setCurrentKeywords] = useState<string[]>([]);

  useEffect(() => {
    if (visible) {
      if (!selectedClient) return;

      const {
        strategyPlainText: strategy,
        countries,
        keywords,
        lookalikes,
      } = values;

      let data = {
        strategy: strategy,
        countries: countries,
        keywords: keywords,
        lookalikes: [],
      };

      // As of V5 (21/04/2022) only non-beta testers will use
      // lookalikes in their extra keywords request
      if (!selectedClient.isBetaTester) data.lookalikes = lookalikes;

      dispatch(fetchSuggestedKeywords(data));
    } else {
      setCurrentKeywords([]);
    }
  }, [dispatch, visible, values, selectedClient]);

  const selectKeyword = (keyword: string) => {
    setCurrentKeywords([...currentKeywords, keyword]);
  };

  const deselectKeyword = (keyword: string) => {
    setCurrentKeywords(currentKeywords.filter((k) => k !== keyword));
  };

  const addTopicsAndClose = () => {
    addTopics(currentKeywords.map((keyword: any) => cleanKeyword(keyword[0])));
    hideModal();
  };

  return (
    <Modal
      visible={visible}
      onCancel={hideModal}
      footer={
        <Footer>
          <SelectedContainer>
            <SelectedIndicator>
              Selected: {currentKeywords.length}
            </SelectedIndicator>
          </SelectedContainer>
          <div>
            <ButtonCancel onClick={hideModal}>
              <ButtonText>Cancel</ButtonText>
            </ButtonCancel>
            <NextButton onClick={addTopicsAndClose}>Add topics</NextButton>
          </div>
        </Footer>
      }
      title={<ModalTitle>Add proposed topics</ModalTitle>}
    >
      {loading ? (
        <CenteredSpinner />
      ) : (
        <div>
          {suggestedKeywords.slice(0, KEYWORD_LIMIT).map((keyword, index) => (
            <ToggleTag
              key={keyword}
              addTag={() => selectKeyword(keyword)}
              removeTag={() => deselectKeyword(keyword)}
              initialActive={false}
            >
              {cleanKeyword(keyword[0])}
            </ToggleTag>
          ))}
        </div>
      )}
    </Modal>
  );
};

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectedContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SelectedIndicator = styled.p`
  margin: 0;
`;
