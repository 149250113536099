import { FC } from "react";

import {
  BarChart as BaseBarChart,
  Bar,
  ResponsiveContainer,
  LabelList,
  XAxis,
} from "recharts";
import { EmptyChartResult } from "../../ui-components/charts/EmptyChartResult";

import { Dictionary } from "../../share/types";
import { formatLabel } from "../../helpers";

interface BarChartProps {
  data: Dictionary<number>;
}

export const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`} style={{ marginTop: "0.3rem" }}>
      <text
        x={0}
        y={-2}
        dx={5}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-30)"
        fontSize={12}
      >
        {payload.value}
      </text>
    </g>
  );
};

const NULL_LABEL = "null";

export const BarChart: FC<BarChartProps> = ({ data }) => {
  let dataLabels = Object.keys(data).filter((label) => label !== NULL_LABEL);

  if (dataLabels.length < 2) return <EmptyChartResult />;

  // Order: First all sections in the form "<n>-<m>"
  // Lastly "+<n>"
  dataLabels.sort(function (a, b) {
    if (a.startsWith("+")) return 1;
    else if (b.startsWith("+")) return -1;
    else return a.localeCompare(b, "en", { numeric: true });
  });

  let formattedLabels = dataLabels.map((label) => {
    const newLabel = formatLabel(label);
    return { [newLabel]: data[label] };
  });
  const formattedData: {
    [x: string]: number;
  } = formattedLabels.reduce((a, b) => Object.assign({}, a, b));

  let transformedData: { value: number; name: string }[] = [];
  for (let label in formattedData) {
    if (label === NULL_LABEL) continue;
    transformedData.push({ value: formattedData[label], name: label });
  }

  const renderLabel = (dataEntry: any) => {
    return dataEntry.name;
  };

  const getValue = (dataLabel: any) => {
    return transformedData[dataLabel];
  };
  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <BaseBarChart
        width={150}
        height={150}
        data={transformedData}
        margin={{ top: 15, bottom: 45 }}
      >
        <XAxis dataKey="name" interval={0} tick={CustomizedAxisTick} />
        <Bar dataKey="value" fill="var(--chart-light-blue)" label={renderLabel}>
          <LabelList
            dataKey="value"
            position="top"
            style={{ fill: "var(--font-color-default)" }}
            valueAccessor={getValue}
          />
        </Bar>
      </BaseBarChart>
    </ResponsiveContainer>
  );
};
