import { FC } from "react";

import { Progress } from "antd";
import {
  GOOD_ACTIVITY_INDEX_THRESHOLD,
  MEDIUM_ACTIVITY_INDEX_THRESHOLD,
} from "../../../../helpers/constants";

interface ActivityIndexProgressCircleProps {
  activityIndex: number;
  width?: number | undefined;
}

const ACTIVITY_INDEX_UPPER_LIMIT = 5;
export const PROGRESS_CIRCLE_DEFAULT_WIDTH = 40;

export const ActivityIndexProgressCircle: FC<ActivityIndexProgressCircleProps> =
  ({ activityIndex, width }) => {
    if (!activityIndex || activityIndex === 0) return <></>;

    const progressCircleWidth = width ? width : PROGRESS_CIRCLE_DEFAULT_WIDTH;

    const percentageValue = (activityIndex / ACTIVITY_INDEX_UPPER_LIMIT) * 100;
    const formattedActivityIndex = activityIndex.toFixed(1);

    let strokeColor;
    if (percentageValue > GOOD_ACTIVITY_INDEX_THRESHOLD) {
      strokeColor = "green";
    } else if (percentageValue > MEDIUM_ACTIVITY_INDEX_THRESHOLD) {
      strokeColor = "yellow";
    } else {
      strokeColor = "red";
    }

    return (
      <Progress
        type="circle"
        percent={percentageValue}
        width={progressCircleWidth}
        strokeColor={strokeColor}
        format={() => {
          return formattedActivityIndex;
        }}
      />
    );
  };
