import { FC } from "react";

import defaultCompanyLogo from "../../../assets/icons/default-company-logo.png";
import { SocialMedia } from "../../../share/company/components/ui-share";
import {
  Contact,
  EmployeesYearFoundedAndStatusSection,
  FavouriteButton,
  HeaderActions,
  Header,
  HeaderInformation,
  Image,
  MetaData,
  Title,
  Section,
  SubSection,
  CompanyListManagementButton,
} from "../../../share/company/components/company-detail/header";
import {
  CompanyHeaderDetailsText,
  Location,
} from "../../../ui-components/CompanyCardStyles";

import { unfavouriteCompany } from "../../store";
import { ListCompanySource } from "../../../list/store";
import { CompanyDetail } from "../../../share/company/store";

import { getLocation } from "../../../helpers";

export const CompanyDetailHeader: FC<{ company: CompanyDetail }> = ({
  company,
}) => {
  const location = getLocation(company.city, company.country);

  return (
    <Header>
      <HeaderInformation>
        <Image
          alt="Company Logo"
          src={company.logo || defaultCompanyLogo}
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = defaultCompanyLogo;
          }}
        />
        <MetaData>
          <Title companyName={company.name} />
          <EmployeesYearFoundedAndStatusSection company={company} />
          <Section>
            <SubSection>
              <SocialMedia company={company} isCompanyDetail={true} />
            </SubSection>
            {company.email && <Contact email={company.email} />}
            <SubSection>
              {company.country && (
                <CompanyHeaderDetailsText>
                  <Location /> {location}
                </CompanyHeaderDetailsText>
              )}
            </SubSection>
          </Section>
        </MetaData>
      </HeaderInformation>
      <HeaderActions>
        <CompanyListManagementButton
          companyName={company.name}
          companyLists={company.lists}
          companyUUID={company.uuid}
          listCompanySource={ListCompanySource.CAMPAIGN}
        />
        <FavouriteButton
          companyUUID={company.uuid}
          isFavourite={true}
          toggleFavouriteFunction={unfavouriteCompany}
        />
      </HeaderActions>
    </Header>
  );
};
