import { FC } from "react";
import styled from "@emotion/styled";

import { ActionButton } from "../../ui-components/ButtonAction";
import { ButtonActionText } from "../../ui-components/Typography";

interface CampaignDetailsProps {
  onClick: () => void;
}

export const EditButton: FC<CampaignDetailsProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <ButtonActionText>EDIT</ButtonActionText>
    </Button>
  );
};

const Button = styled(ActionButton)`
  padding: 0 3rem;
`;
