import { FC, useState } from "react";

import { Popover } from "antd";
import { ListWidgetContent } from "./ListWidgetContent";
import { ListCompanySource } from "../../../../list/store";
import { Segment } from "../../../../utils/Segment";
import { TooltipPlacement } from "antd/es/tooltip";

export interface ListWidgetProps {
  companyLists: string[];
  companyUUID: string;
  companyName: string;
  listCompanySource: ListCompanySource;
  popoverPlacement?: TooltipPlacement;
  children?: React.ReactNode;
}

const defaultPopoverPlacement = "bottom" as "bottom";
export const ListWidget: FC<ListWidgetProps> = ({
  companyLists,
  companyUUID,
  companyName,
  listCompanySource,
  popoverPlacement,
  children,
}) => {
  // Controlled at this level of components to be able to empty
  const [filter, setFilter] = useState("");
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const popoverContent = ListWidgetContent(
    companyLists,
    companyUUID,
    companyName,
    listCompanySource,
    filter,
    setFilter
  );

  const handleOnOpenChange = (open: boolean) => {
    setTooltipIsOpen(open);

    if (!open) {
      // The timeout is needed to not have a wonky effect when setting the filter to empty value
      // Otherwise, the filter is reset, and entire list is displayed again too quickly
      // due to the "fase-out" behaviour of the tooltip
      setTimeout(() => setFilter(""), 200);
    } else {
      Segment.analytics?.track("open-list-tooltip", {
        openedFrom: listCompanySource,
      });
    }
  };

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      placement={popoverPlacement || defaultPopoverPlacement}
      onOpenChange={handleOnOpenChange}
      open={tooltipIsOpen}
    >
      {children}
    </Popover>
  );
};
