import styled from "@emotion/styled";
import { GhostButton } from "../../../ui-components/GhostButton";
import { Button } from "../../../ui-components/Button";

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const CancelButton = styled(GhostButton)`
  width: 10rem;
  margin-right: 1.6rem;
  margin-left: auto;
`;

export const SaveButton = styled(Button)`
  width: 8.7rem;
`;
