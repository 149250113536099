import { Collapse as BaseCollapse } from "antd";
import styled from "@emotion/styled";

import { DownOutlined } from "@ant-design/icons";
import { Slider } from "../../../ui-components/Slider";
import { Tag } from "../../../ui-components/Tag";

import { Cluster, Keyword } from "../../../share/campaigns/store";
import { cleanKeyword, normalizeClusterWeight } from "../../../helpers";

interface ClusterCollapseProps {
  clusters: Cluster[];
}

export const ClusterCollapse = ({ clusters }: ClusterCollapseProps) => {
  const normalizedClusters = normalizeClusterWeight(clusters);

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={"0"}
      expandIcon={({ isActive }) => (
        <DownOutlined rotate={isActive ? 180 : 0} />
      )}
      accordion
    >
      {normalizedClusters.map((cluster: Cluster, clusterIndex: number) => (
        <Panel
          key={clusterIndex}
          header={cleanKeyword(cluster.label)}
          extra={
            <Slider
              disabled
              value={cluster.keywords.length === 0 ? 0 : cluster.weight}
              onChange={() => {}}
            />
          }
        >
          {cluster.keywords.length > 0 ? (
            cluster.keywords.map((keyword: Keyword) => (
              <Tag key={keyword.keyword}>{cleanKeyword(keyword.keyword)}</Tag>
            ))
          ) : (
            <p>No topics</p>
          )}
        </Panel>
      ))}
    </Collapse>
  );
};

const Collapse = styled(BaseCollapse)`
  background-color: var(--white);
  width: 100%;

  .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: var(--font-weight);
  }

  .ant-slider-handle {
    border: var(--button-border);
  }

  .ant-collapse-extra {
    margin-left: auto;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 1.2rem 0 1.6rem 0 !important;
    margin: 0 1.6rem 0 1.6rem;
    border-top: var(--campaign-card-border);
  }
`;

const Panel = styled(BaseCollapse.Panel)`
  margin-bottom: 1.6rem;
  background-color: var(--white);
  border: var(--campaign-card-border);
  border-radius: var(--border-radius);
`;
