import { FC, MouseEvent } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router";

import starIcon from "../assets/icons/star.svg";
import backlogIcon from "../assets/icons/backlog.svg";
import eyeIcon from "../assets/icons/eye.svg";
import statsIcon from "../assets/icons/stats.svg";

import { Filter } from "../campaign/golden-basket/store";

interface CampaignStatsProps {
  clientSlug: string | undefined;
  campaignSlug: string;
  favourites: number;
  followed: number;
  matches: number;
  toReview: number;
}

export const CampaignStats: FC<CampaignStatsProps> = ({
  clientSlug,
  campaignSlug,
  favourites,
  followed,
  toReview,
  matches,
}) => {
  let navigate = useNavigate();

  const handleRedirectClick = (e: MouseEvent, page: string) => {
    navigate(
      `/${clientSlug}/campaign/golden-basket/${campaignSlug}?filter=${page}`
    );
    e.preventDefault();
  };

  return (
    <CampaignStatsContainer>
      <CamapignStatsItem
        onClick={(e) => handleRedirectClick(e, Filter.FAVOURITE)}
      >
        <StarIcon />
        Favourites: {favourites}
      </CamapignStatsItem>
      <CamapignStatsItem>
        <EyeIcon />
        Followed: {followed}
      </CamapignStatsItem>
      <CamapignStatsItem
        onClick={(e) => handleRedirectClick(e, Filter.TOREVIEW)}
      >
        <BacklogIcon />
        To review: {toReview}
      </CamapignStatsItem>
      <CamapignStatsItem onClick={(e) => handleRedirectClick(e, Filter.MATCH)}>
        <StatsIcon />
        Matches: {matches}
      </CamapignStatsItem>
    </CampaignStatsContainer>
  );
};

const CampaignStatsContainer = styled.ul`
  height: 4.8rem;
  width: 50rem;
  margin: 0;
  padding: 0 2rem;
  background: var(--campaign-stats-background);
  border: var(--campaign-card-border);
  border-radius: var(--border-radius);
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--font-color-campaign-stats);

  li:last-child {
    border-right: none;
    padding-right: 0;
  }
`;

const CamapignStatsItem = styled.li`
  padding-right: 1.8rem;
  border-right: var(--campaign-stats-border-right);
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StarIcon = styled.div`
  background: url(${starIcon});
  width: 1.667rem;
  height: 1.8rem;
  margin-right: 1rem;
`;

const EyeIcon = styled.div`
  background: url(${eyeIcon});
  width: 1.833rem;
  height: 1.4rem;
  margin-right: 1rem;
`;

const BacklogIcon = styled.div`
  background: url(${backlogIcon});
  width: 1.563rem;
  height: 1.5rem;
  margin-right: 1rem;
`;

const StatsIcon = styled.div`
  background: url(${statsIcon});
  width: 1.583rem;
  height: 1.583rem;
  margin-right: 1rem;
`;
