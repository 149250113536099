import { FC, useEffect, useRef } from "react";
import { Formik, FormikProps } from "formik";

import { Spin } from "antd";
import { Button } from "../../../ui-components/Button";
import { FormikInput } from "../../../ui-components/FormikInput";
import { Modal } from "../../../ui-components/Modal";

import { updateTag, setInitialUpdateTagState } from "../../store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { EditTagSchema } from "./validation";

interface UpdateTagModalProps {
  visible: boolean;
  handleModalClose: () => void;
  tagUUID: string;
  tagName: string;
}

export const UpdateTagModal: FC<UpdateTagModalProps> = ({
  visible,
  handleModalClose,
  tagUUID,
  tagName,
}) => {
  const dispatch = useAppDispatch();
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { loading, success } = useAppSelector(
    (state: State) => state.tags.updateTag
  );

  const formikRef = useRef<FormikProps<Record<string, string>>>(null);

  useEffect(() => {
    return () => {
      dispatch(setInitialUpdateTagState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      handleModalClose();
      formikRef?.current?.resetForm();
    }
  }, [success, handleModalClose]);

  return (
    <Formik
      initialValues={{
        name: tagName,
      }}
      onSubmit={(values, { resetForm }) => {
        const uuid = selectedClient?.uuid;
        if (!uuid) return;
        const data = {
          ...values,
          clientUUID: uuid,
          tagUUID: tagUUID,
        };
        dispatch(updateTag(data));
      }}
      validationSchema={EditTagSchema}
    >
      {({ handleSubmit, values, errors }) => (
        <form>
          <Modal
            title="Edit tag name"
            visible={visible}
            handleSubmit={handleSubmit}
            onCancel={handleModalClose}
            afterClose={() => dispatch(setInitialUpdateTagState())}
            footer={[
              <Button key="cancel" onClick={handleModalClose}>
                Cancel
              </Button>,
              <Button
                key="confirm"
                htmlType="submit"
                onClick={() => handleSubmit()}
              >
                {loading ? (
                  <>
                    Sending &nbsp; <Spin />
                  </>
                ) : (
                  "Confirm"
                )}
              </Button>,
            ]}
          >
            <FormikInput
              name="name"
              type="name"
              label="Name"
              ariaLabel="name"
              placeholder=""
              isRequired={true}
              value={values.name}
              error={errors.name}
            />
          </Modal>
        </form>
      )}
    </Formik>
  );
};
