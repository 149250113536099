import { FC, useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";

import { Avatar as BaseAvatar, Image } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { GhostButton } from "../../ui-components/GhostButton";
import {
  successNotification,
  errorNotification,
} from "../../ui-components/Notification";
import { ButtonText } from "../../ui-components/Typography";

import DeleteIcon from "../../assets/icons/delete.svg";

import { uploadAvatar, deleteAvatar } from "../store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

interface AvatarSectionProps {
  editProfile: boolean;

  setEditableProfile: (editProfile: boolean) => void;
}

export const AvatarSection: FC<AvatarSectionProps> = ({
  editProfile,

  setEditableProfile,
}) => {
  const [previewUrl, setPreviewURL] = useState("");
  const [avatar, setAvatar] = useState<Blob | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const { success: uploadAvatarSuccess, message: uploadAvatarMessage } =
    useAppSelector((state: State) => state.editProfile.uploadAvatar);
  const { success: deleteAvatarSuccess, message: deleteAvatarMessage } =
    useAppSelector((state: State) => state.editProfile.deleteAvatar);
  const userAvatar = useAppSelector(
    (state: State) => state.layout.currentUser?.photoURL
  );

  // set preview url for Avatar component
  useEffect(() => {
    if (userAvatar) {
      setPreviewURL(userAvatar);
    } else if (!avatar && !userAvatar) {
      setPreviewURL("");
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (typeof fileReader.result === "string") {
        setPreviewURL(fileReader.result);
      }
    };
    if (avatar) {
      fileReader.readAsDataURL(avatar);
    }
  }, [avatar, userAvatar]);

  useEffect(() => {
    if (uploadAvatarSuccess === false) {
      const displayMessage =
        uploadAvatarMessage ||
        "There was an error with the upload of the avatar image";
      errorNotification(displayMessage);
    }
  }, [uploadAvatarSuccess, uploadAvatarMessage]);

  // success msg and error notification
  useEffect(() => {
    if (uploadAvatarSuccess === true) {
      successNotification("Avatar has been saved.");
      setEditableProfile(false);
    } else if (uploadAvatarSuccess === false) {
      errorNotification(uploadAvatarMessage);
      setEditableProfile(false);
    }
  }, [setEditableProfile, uploadAvatarSuccess, uploadAvatarMessage]);

  useEffect(() => {
    if (deleteAvatarSuccess === true) {
      successNotification("Avatar has been deleted.");
      setEditableProfile(false);
    } else if (deleteAvatarSuccess === false) {
      errorNotification(deleteAvatarMessage);
      setEditableProfile(false);
    }
  }, [setEditableProfile, deleteAvatarSuccess, deleteAvatarMessage]);

  // avatar handlers
  const handlePickImageClick = () => {
    inputRef.current!.click();
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let pickedFile;
    if (e.target.files || e.target.files === 1) {
      pickedFile = e.target.files[0];
      setAvatar(pickedFile);
      dispatch(uploadAvatar(pickedFile));
    }
    inputRef.current!.value = null as any;
  };

  const handleDeleteAvatar = () => {
    dispatch(deleteAvatar());
    setAvatar(null);
  };

  return (
    <AvatarSectionContainer>
      {editProfile && previewUrl ? (
        <DeleteAvatarButton type="button" onClick={handleDeleteAvatar} />
      ) : null}
      {previewUrl ? (
        <Avatar size={145} src={<Image src={previewUrl} />} />
      ) : (
        <Avatar size={145} icon={<UserOutlined />} />
      )}
      {editProfile && (
        <div>
          <UploadAvatarButton onClick={handlePickImageClick}>
            <ButtonText>Upload a photo</ButtonText>
          </UploadAvatarButton>
          <input
            hidden
            ref={inputRef}
            type="file"
            onChange={handleAvatarChange}
            accept=".jpg, .jpeg, .png"
          />
        </div>
      )}
    </AvatarSectionContainer>
  );
};

const UploadAvatarButton = styled(GhostButton)`
  width: 15.1rem;
`;

const AvatarSectionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Avatar = styled(BaseAvatar)`
  margin-right: 2.6rem;
  margin-bottom: 2.3rem;
  min-width: 14.5rem;
  align-self: flex-start;
`;

const DeleteAvatarButton = styled.button`
  position: absolute;
  top: 0;
  right: 4rem;
  width: 3rem;
  height: 3rem;
  z-index: 999;
  background-color: var(--delete-avatar-btn-background);
  background-image: url(${DeleteIcon});
  background-size: 60% 60%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: var(--button-border);
  border-radius: 50%;
  cursor: pointer;
`;
