import { FC } from "react";

import { ButtonsContainer } from "./ButtonsContainer";
import { UpdateTagButton } from "./UpdateTagButton";
import { DeleteTagButton } from "./DeleteTagButton";

interface TagManagementActionsProps {
  tagName: string;
  tagUUID: string;
}

export const TagManagementActions: FC<TagManagementActionsProps> = ({
  tagName,
  tagUUID,
}) => {
  return (
    <ButtonsContainer>
      <UpdateTagButton tagUUID={tagUUID} tagName={tagName} />
      <DeleteTagButton tagUUID={tagUUID} tagName={tagName} />
    </ButtonsContainer>
  );
};
