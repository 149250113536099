import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../auth/store";

export interface LayoutState {
  currentUser: User | null;
  isRightbarOpen: boolean;
  currentStep: number;
}

const initialState = {
  isRightbarOpen: false,
  currentUser: null,
} as LayoutState;

export const layoutSlice = createSlice({
  name: "layout",
  initialState: initialState,
  reducers: {
    setRightbarOpen(state, action) {
      state.isRightbarOpen = action.payload;
    },
    setCurrentStepInStore(state, action) {
      state.currentStep = action.payload;
      state.isRightbarOpen = false;
    },
    setInitialLayoutState(state) {
      state.currentStep = initialState.currentStep;
      state.isRightbarOpen = initialState.isRightbarOpen;
    },
  },
});

export function* layoutSaga() {}

export const { setRightbarOpen, setCurrentStepInStore, setInitialLayoutState } =
  layoutSlice.actions;
