import { FC, useEffect } from "react";
import { Formik } from "formik";

import { Spin, Select } from "antd";
import { Button } from "../../../ui-components/Button";
import { FormikInput } from "../../../ui-components/FormikInput";
import { FormikSelectWithValues } from "../../../ui-components/FormikSelectWithValues";
import { Modal } from "../../../ui-components/Modal";

import { sendInvitation, setInitialSendInvitationState } from "../../store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { InviteUserSchema } from "../../validation";

const { Option } = Select;

interface InviteUserModalProps {
  visible: boolean;
  handleModalClose: () => void;
  loading: boolean;
}

export const InviteUserModal: FC<InviteUserModalProps> = ({
  visible,
  handleModalClose,
  loading,
}) => {
  const dispatch = useAppDispatch();
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  useEffect(() => {
    return () => {
      dispatch(setInitialSendInvitationState());
    };
  }, [dispatch]);

  return (
    <Formik
      initialValues={{
        email: "",
        role: "admin",
      }}
      onSubmit={(values, { resetForm }) => {
        const uuid = selectedClient?.uuid;
        if (!uuid) return;
        const data = {
          ...values,
          clientUUID: uuid,
        };
        dispatch(sendInvitation(data));
        resetForm();
      }}
      validationSchema={InviteUserSchema}
    >
      {({ handleSubmit }) => (
        <form>
          <Modal
            title="Invite a user"
            visible={visible}
            handleSubmit={handleSubmit}
            onCancel={handleModalClose}
            afterClose={() => dispatch(setInitialSendInvitationState())}
            footer={[
              <Button key="cancel" onClick={handleModalClose}>
                Cancel
              </Button>,
              <Button
                key="confirm"
                htmlType="submit"
                onClick={() => handleSubmit()}
              >
                {loading ? (
                  <>
                    Sending &nbsp; <Spin />
                  </>
                ) : (
                  "Confirm"
                )}
              </Button>,
            ]}
          >
            <div>
              <FormikInput
                name="email"
                type="email"
                label="Email"
                ariaLabel="email"
                placeholder="email@example.com"
                isRequired={true}
              />
              <FormikSelectWithValues
                name="role"
                ariaLabel="role"
                label="User Role"
              >
                <Option value="admin">Admin</Option>
                <Option value="contributor">Contributor</Option>
              </FormikSelectWithValues>
            </div>
          </Modal>
        </form>
      )}
    </Formik>
  );
};
