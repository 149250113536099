import styled from "@emotion/styled";

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: var(--font-weight-bold-strong);
  color: var(--font-color-white);
`;

export const Paragraph = styled.p`
  font-size: 1.4rem;
`;

export const SmallParagraph = styled.p`
    font-size: var(--font-size-base);
`;

export const AuthTitle = styled.p`
  font-size: 3.4rem;
  font-weight: var(--font-weight-bold-strong);
  line-height: 3.2rem;
  margin: 0 0 2.5rem 0;
`;

export const ButtonText = styled.p`
  margin: 0;
  padding: 0;
  font-weight: var(--font-weight-bold);
  font-size: 1.4rem;
  color: var(--font-color-button);
`;

export const ButtonActionText = styled(ButtonText)`
  color: var(--primary-color);
  &:hover: {
    color: var(--white);
  }
`;

export const AuthSubtitle = styled.p`
  font-size: 2rem;
  line-height: 2.8rem;
  margin: 0 0 3rem 0;
`;

export const AuthFormTitle = styled.h1`
  font-size: 4.8rem;
  line-height: 6.2rem;
  font-weight: var(--font-weight-bold-strong);
  color: var(--font-color-white);
`;

export const AuthFormSubtitle = styled.h2`
  font-size: 3rem;
  line-height: 4rem;
  color: var(--font-color-white);
  margin-top: 8rem;
`;

export const ContactTitle = styled.h2`
  line-height: 2.5rem;
  font-size: 1.6rem;
  font-weight: var(--font-weight-bold); ;
`;

export const ContactSubtitle = styled.h2`
  line-height: 1.6rem;
  font-size: 1.4rem;
  font-weight: var(--font-weight-bold); ;
`;

export const CardHeadTitle = styled.h2`
  line-height: 2.8rem;
  font-size: 2rem;
  font-weight: var(--font-weight-bold); ;
`;

export const CardDescription = styled.p`
  font-size: 1.2rem;
  font-weight: var(--font-weight-bold);
  margin: 0;
  hyphens: auto;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const PoweredParagraph = styled.p`
  font-size: 1rem;
  line-height: 1.4rem;
  margin-bottom: 0.4rem;
  margin: 0;
  padding: 0;
  opacity: 0.6;
  moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const UpgradePlanParagraph = styled.p`
  color: var(--font-color-upgrade-plan);
  padding: 0;
  margin: 0;
`;

export const Date = styled.p`
  padding: 0;
  margin: 0;
  color: var(--font-color-campaign-stats);
  font-weight: normal;
`;

export const CheckboxButtonTitle = styled.p`
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  padding: 0;
  margin: 0;
`;

export const CheckboxButtonContent = styled.p`
  color: var(--font-color-upgrade-plan);
  font-size: 1.2rem;
  line-height: 1.8rem;
  padding: 0;
`;

export const NewCampaignSubtitle = styled.p`
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: var(--font-weight-bold);
  color: var(--label-color);
`;

export const NewCampaignSectionExplanation = styled.p`
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: var(--font-color-campaign-stats);
`;

export const SectionExplanation = styled.p`
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: var(--font-color-campaign-stats);
  margin-bottom: 0;
`;

export const Label = styled.label`
  color: var(--label-color);
  line-height: 2rem;
`;

export const RequiredLabel = styled(Label)`
  &:after {
    content: " *";
    color: red;
  }
`;

export const ResponsiveLabel = styled.label`
  color: var(--label-color);
  line-height: 2rem;

  @media (max-width: 1199px) {
    color: var(--white);
  }
`;

export const ResponsiveRequiredLabel = styled(Label)`
  &:after {
    content: " *";
    color: red;
  }

  @media (max-width: 1199px) {
    color: var(--white);
  }
`;

export const ActivateInfo = styled.p`
  font-size: 1.4rem;
  color: var(--label-color);
  margin: 0 0 0 1.2rem;
  padding: 0;
`;

export const Error = styled.p`
  color: var(--font-color-error);
`;

export const ModalSubtitle = styled.p`
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: var(--font-weight-bold-strong);
`;

export const ModalTitle = styled.p`
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: var(--font-weight-bold-strong);
  margin: 0;
  padding: 0;
  display: flex;
  gap: 0.5rem;
`;

export const PanelHeadTitle = styled.h2`
  margin-bottom: 0;
  line-height: 2.8rem;
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
`;

export const SignUpInformationParagraph = styled.p`
  margin: 0;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: var(--font-weight-bold-strong);

  @media (max-width: 1199px) {
    color: var(--white);
  }
`;

export const StyledButtonText = styled(ButtonText)<{
  disabled?: boolean;
}>`
  color: ${props =>
    props.disabled
      ? "var(--font-color-campaign-stats)"
      : "var(--primary-color)"};
  font-weight: var(--font-weight-bold);
`;

export const DashboardSectionTitle = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.5715;
`;

export const CompanyPageFieldName = styled.span`
  color: var(--font-color-campaign-stats);
  font-weight: var(--font-weight-bold);
  margin-right: 5px;
`;
