import styled from "@emotion/styled";

import { CompanyDetailHeader } from "./company-detail-components/CompanyDetailHeader";
import { CompanyDetailBody } from "./company-detail-components/CompanyDetailBody";
import { CompanyDetail } from "../../share/company/store";

export const FavouriteCompanyDetail = ({
  company,
}: {
  company: CompanyDetail;
}) => {
  return (
    <Container>
      <CompanyDetailHeader company={company} />
      <CompanyDetailBody company={company} />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 5rem 5rem 0.5rem 5rem;
`;
