/**
 *
 * THIS IS A TEMPORARY COMPONENT. THE "GOOD" ONE IS FundingInformation, BUT THERE'S A NEED
 * (AGAIN, TEMPORARILY) TO HIDE THE INFORMATION BEHIND A BUTTON AND MEASURE THE CLICK
 * BUT ONLY ON THE GOLDEN BASKET, AND NOT ON THE FAVOURITE PAGE
 *
 * TODO: This file is to be removed once the Enriched Profile feature is implemented
 *
 */

import styled from "@emotion/styled";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";

import {
  ActionButton,
  Section,
  SectionHeader,
  SectionTitle as BaseSectionTitle,
  SectionContent as BaseSectionContent,
} from "../../../../../share/company/components/ui-share";
import {
  ButtonText,
  CompanyPageFieldName,
} from "../../../../../ui-components/Typography";
import fundingIcon from "../../../../../assets/icons/funding.svg";

import { filteredGBCompanySelector } from "../../../store";
import { selectedCampaignSlugSelector } from "../../../../../share/campaigns/store";
import { State } from "../../../../../utils/store";
import { useAppSelector } from "../../../../../utils/hooks";

import { Segment } from "../../../../../utils/Segment";
import { toNumberWithSeparators } from "../../../../../helpers";

type currencySymbolsType = {
  [key: string]: string;
};

const getLocale = () => "fr";

const currencySymbols: currencySymbolsType = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};

const expandedCompanyUUIDsLocalStorageKey = "expandedCompanyUUIDs";

interface FundingInformationWithShowProps {
  lastFundingDate: Date | null;
  fundingRounds: number | null;
  totalFunding: number | null;
  currencyCode: string | null;
  companyUUID: string;
  source?: string;
}

export const FundingInformationWithShow: FC<FundingInformationWithShowProps> =
  ({
    lastFundingDate,
    fundingRounds,
    totalFunding,
    currencyCode,
    companyUUID,
    source,
  }) => {
    const { selectedClient } = useAppSelector((state: State) => state.clients);
    const GBCompany = useAppSelector(filteredGBCompanySelector);
    const { campaignSlug } = useParams<{ campaignSlug: string }>();
    const campaign = useAppSelector(selectedCampaignSlugSelector(campaignSlug));

    let fundingInformationDisplayedAt = source || "campaign";

    let currency =
      currencyCode && Object.keys(currencySymbols).includes(currencyCode)
        ? currencySymbols[currencyCode]
        : currencyCode;

    let formatedLastFundingDate;
    if (lastFundingDate) {
      let d = new Date(lastFundingDate);
      formatedLastFundingDate = d.toLocaleDateString(getLocale());
    }

    const expandedCompanyUUIDs = localStorage.getItem(
      expandedCompanyUUIDsLocalStorageKey
    );
    const [displayFundingInformation, setDisplayFundingInformation] = useState(
      expandedCompanyUUIDs?.includes(companyUUID) || true
    );

    const handleShowCompany = () => {
      const expandedCompanyUUIDs = localStorage.getItem(
        expandedCompanyUUIDsLocalStorageKey
      );
      if (expandedCompanyUUIDs) {
        const expandedCompanyUUIDsList = expandedCompanyUUIDs.split(",");
        expandedCompanyUUIDsList.push(companyUUID);
        localStorage.setItem(
          expandedCompanyUUIDsLocalStorageKey,
          expandedCompanyUUIDsList.toString()
        );
      } else {
        localStorage.setItem(
          expandedCompanyUUIDsLocalStorageKey,
          [companyUUID].toString()
        );
      }
      setDisplayFundingInformation(true);
      Segment.analytics?.track("show-funding-information", {
        companyUUID: companyUUID,
        companyName: GBCompany?.company.name,
        campaignCompanyUUID: GBCompany?.uuid,
        campaignUUID: campaign?.uuid,
        clientUUID: selectedClient?.uuid,
        clientName: selectedClient?.name,
        source: fundingInformationDisplayedAt,
      });
    };

    return (
      <Section>
        <SectionHeader>
          <SectionTitle>
            Funding information{" "}
            {!displayFundingInformation && (
              <ActionButton onClick={handleShowCompany}>
                <ButtonText>Show</ButtonText>
              </ActionButton>
            )}
          </SectionTitle>
        </SectionHeader>
        {displayFundingInformation && (
          <SectionContent>
            {formatedLastFundingDate && (
              <FundingInfo>
                <CompanyPageFieldName>
                  Last funding date on:
                </CompanyPageFieldName>
                {formatedLastFundingDate}
              </FundingInfo>
            )}
            {(fundingRounds === 0 || fundingRounds) && (
              <FundingInfo>
                <CompanyPageFieldName>Funding rounds:</CompanyPageFieldName>
                {fundingRounds}
              </FundingInfo>
            )}

            {(totalFunding === 0 || totalFunding) && (
              <FundingInfo>
                <CompanyPageFieldName>Total funding:</CompanyPageFieldName>
                {toNumberWithSeparators(totalFunding)}
                {currency && currency}
              </FundingInfo>
            )}
          </SectionContent>
        )}
      </Section>
    );
  };

const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: var(--gb-content-section-left-margin);
`;

const FundingIcon = styled.div`
  background: url(${fundingIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1.333rem;
  height: 1.333rem;
  margin-right: 0.933rem;
`;

const FundingInfo = styled(BaseSectionContent)`
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
  flex-align: left;
  justify-content: center;
  align-items: center;
  margin-left: 0rem;
  font-size: var(--gb-content-font-size);
`;

const SectionTitle = styled(BaseSectionTitle)`
  display: flex;
  align-items: center;
  // gap: 2rem;
  padding: 0;
  margin: 0;
`;
