import { useEffect, FC } from "react";
import { Formik } from "formik";
import styled from "@emotion/styled";

import { ButtonSection, CancelButton, SaveButton } from "./share-ui";
import { FormikInput } from "../../ui-components/FormikInput";
import { ButtonText } from "../../ui-components/Typography";
import {
  successNotification,
  errorNotification,
} from "../../ui-components/Notification";

import { changeProfileInfo } from "../store";
import { User } from "../../auth/store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import { EditProfileSchema } from "../validation";

interface ProfileInfoFormProps {
  editProfile: boolean;
  currentUser: User;
  handleSetEditable: () => void;
  setEditableProfile: (editProfile: boolean) => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

export const ProfileInfoForm: FC<ProfileInfoFormProps> = ({
  editProfile,
  currentUser,
  handleSetEditable,
  setEditableProfile,
}) => {
  const dispatch = useAppDispatch();
  const { success, message } = useAppSelector(
    (state: State) => state.editProfile.changeProfileInfo
  );

  useEffect(() => {
    if (success === true) {
      successNotification("Changes has been saved.");
      setEditableProfile(false);
    } else if (success === false) {
      errorNotification(message);
      setEditableProfile(false);
    }
  }, [success, message, setEditableProfile]);

  return (
    <Formik
      initialValues={{
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
      }}
      onSubmit={(values: FormValues, { resetForm }) => {
        dispatch(changeProfileInfo(values));
        resetForm();
      }}
      validationSchema={EditProfileSchema}
      enableReinitialize
    >
      {({ handleSubmit, errors }) => (
        <Form onSubmit={handleSubmit}>
          <FormContent>
            <InputSection>
              <FormikInput
                name="firstName"
                type="text"
                label="First name"
                disabled={!editProfile}
                error={errors.firstName}
              />
              <FormikInput
                name="lastName"
                type="text"
                label="Last name"
                disabled={!editProfile}
                error={errors.lastName}
              />
              <FormikInput
                name="email"
                type="email"
                label="Email"
                disabled={!editProfile}
                error={errors.email}
              />
            </InputSection>
          </FormContent>
          {editProfile && (
            <ButtonSection>
              <CancelButton onClick={handleSetEditable}>
                <ButtonText>Cancel</ButtonText>
              </CancelButton>
              <SaveButton htmlType="submit">Save</SaveButton>
            </ButtonSection>
          )}
        </Form>
      )}
    </Formik>
  );
};

const Form = styled.form`
  width: 34.2rem;
`;

const InputSection = styled.div`
  width: 100%;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
