import { FC, ReactNode } from "react";
import styled from "@emotion/styled";
import { Tag as BaseTag } from "antd";

export interface TagProps {
  onClick?: () => void;
  color?: string;
  children?: ReactNode;
}

export const Tag: FC<TagProps> = ({ onClick, color, children, ...rest }) => {
  return (
    <StyledTag onClick={onClick} color={color} {...rest}>
      {children}
    </StyledTag>
  );
};

const StyledTag = styled(BaseTag)`
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  position: relative;
`;
