import { FC, useEffect } from "react";
import styled from "@emotion/styled";

import { Modal as BaseModal, Tooltip } from "antd";
import { CompanyCard } from "./firmographics/CompanyCard";
import { Firmographics } from "../../../ui-components/charts/Firmographics";
import { LongLoaderWithMessage } from "../../../ui-components/LongLoaderWithMessage";
import { ModalSubtitle, ModalTitle } from "../../../ui-components/Typography";

import informationIcon from "../../../assets/icons/information.svg";

import { fetchFirmographics, CompanyInformation } from "../../store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import {
  denormalizeClusterWeight,
  filterActiveKeywordsInClusters,
  getWordCloudKeywordsNormalized,
} from "../../../helpers";

interface FirmographicsModalProps {
  visible: boolean;
  hideModal: () => void;
  values: { [field: string]: any };
}

export const FirmographicsModal: FC<FirmographicsModalProps> = ({
  visible,
  hideModal,
  values,
}) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(
    (state: State) => state.newCampaign.fetchFirmographics
  );
  const { clusters } = useAppSelector((state: State) => state.newCampaign);
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const { age, country, employeeRange, textKeywords, totalFunding, companies } =
    useAppSelector((state: State) => state.newCampaign.firmographics);

  const wordCloudKeywords = getWordCloudKeywordsNormalized(textKeywords);

  // Important: Do not add clusters nor values to the dependencies list
  useEffect(() => {
    if (visible && selectedClient) {
      const {
        strategyPlainText: strategy,
        countries,
        keywords,
        lookalikes,
      } = values;
      const clustersWithProperKeywords =
        filterActiveKeywordsInClusters(clusters);
      const denormalizedClusters = denormalizeClusterWeight(
        clustersWithProperKeywords
      );

      let data: any = {
        strategy: strategy,
        countries: countries,
        keywords: keywords,
        lookalikes: lookalikes,
        clusters: denormalizedClusters,
        clientUUID: selectedClient.uuid,
      };

      dispatch(fetchFirmographics(data));
    }
  }, [dispatch, visible, selectedClient]); // eslint-disable-line

  const firmographicsValues = {
    countries: country,
    age: age,
    employeeRange: employeeRange,
    totalFunding: totalFunding,
    wordCloudKeywords: wordCloudKeywords,
  };

  // companies.length === 0 means that there were no results in the firmographics request
  return (
    <Modal
      visible={visible}
      onCancel={hideModal}
      width={"95%"}
      title={
        <ModalTitle>
          Results Preview{" "}
          <Tooltip
            title="Charts provided from a limited subset of data"
            placement="right"
          >
            <span>
              <InfoIcon />
            </span>
          </Tooltip>
        </ModalTitle>
      }
      destroyOnClose={true}
      bodyStyle={{
        top: "1rem",
        maxHeight: "85vh",
        overflow: "scroll",
        padding: "2rem",
      }}
      style={{ top: "5%", maxHeight: "90vh" }}
      footer={null}
    >
      {loading && (
        <LongLoaderWithMessage message="Your request takes a lot of time to process, you can edit your briefing with more content or choose less restrictive geographical filter" />
      )}
      {!loading && companies.length === 0 && (
        <span>Your briefing did not provide any results.</span>
      )}
      {!loading && companies.length !== 0 && (
        <>
          <Firmographics values={firmographicsValues} />
          <ModalSubtitle>Examples of matching Companies</ModalSubtitle>
          <CompanyContainer>
            {companies.map((company: CompanyInformation, index: number) => {
              return <CompanyCard company={company} key={index} />;
            })}
          </CompanyContainer>
        </>
      )}
    </Modal>
  );
};

const Modal = styled(BaseModal)`
  height: &.ant-modal-header {
    padding: 1rem 1.8rem;
  }
  &.ant-modal-body {
    padding: 1.8rem;
  }
`;

const CompanyContainer = styled.div`
  display: flex;
  overflow-x: auto;
  justify-content: space-around;
`;

const InfoIcon = styled.div`
  background: url(${informationIcon}) no-repeat;
  width: 1.433rem;
  height: 1.433rem;
  margin-right: 0.933rem;
`;
