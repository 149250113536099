import { FC, MouseEvent } from "react";
import styled from "@emotion/styled";

import { Alert as BaseAlert } from "antd";
import { ContainerOutlined } from "@ant-design/icons";

import { EditButton } from "../../ui-components/EditButton";
import {
  Card as BaseCard,
  CardHeader as BaseCardHeader,
  HeadTitle as BaseHeadTitle,
} from "../../ui-components/Card";

import { Campaign, restoreCampaign } from "../../share/campaigns/store";
import { useAppDispatch } from "../../utils/hooks";

interface ArchivedCampaignCardProps {
  campaign: Campaign;
}

export const ArchivedCampaignCard: FC<ArchivedCampaignCardProps> = ({
  campaign,
}) => {
  const dispatch = useAppDispatch();

  const handleRestoreClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(restoreCampaign({ campaignUUID: campaign.uuid }));
  };

  return (
    <Card>
      <CardHeader>
        <HeaderItemContainer>
          <HeadTitle>{campaign.name}</HeadTitle>
          {campaign.statusPending && (
            <Alert type="warning" message="Status pending" />
          )}
        </HeaderItemContainer>
      </CardHeader>
      <ArchivedCardContent>
        <span>{campaign.companiesDelivered} companies delivered</span>
        <EditButton
          icon={<ContainerOutlined />}
          onClick={handleRestoreClick}
          className="archive-campaign-button"
        >
          Restore
        </EditButton>
      </ArchivedCardContent>
    </Card>
  );
};

const Card = styled(BaseCard)`
  width: 100%;
  margin-bottom: 0;
`;

const CardHeader = styled(BaseCardHeader)`
  height: 3rem;
  padding-bottom: 0.5rem;
`;

const ArchivedCardContent = styled.div`
  width: 100%;
  padding-top: 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

const HeaderItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeadTitle = styled(BaseHeadTitle)`
  line-height: 2rem;
  font-size: 1.7rem;
`;

const Alert = styled(BaseAlert)`
  margin-left: 1rem;
  padding: 0.2rem 0.8rem;
  font-size: 1.2rem;
`;
