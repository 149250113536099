import { cleanKeyword } from "./strings";

import { Campaign } from "../share/campaigns/store";
import { BaseCompany } from "../share/company/store";

import { countries } from "../utils/countries";

export const getLocation = (
  city: string,
  countryCode: string
): string | null => {
  if (city && countryCode) {
    return `${city} (${countryCode})`;
  } else if (city) {
    return `${city}`;
  } else if (countryCode) {
    return `${countryCode}`;
  } else {
    return null;
  }
};

export const filterEmptyStrings = (list: string[]) => {
  return list.filter((el: string) => el);
};

export const getCampaignWithEmptyArray = (campaign: Campaign) => {
  return {
    ...campaign,
    keywords: campaign.keywords.filter(Boolean),
    countries: campaign.countries.filter(Boolean),
  };
};

export const checkCompanyDetailsLength = (
  location: string | null,
  eRangeStart: number | null,
  eRangeEnd: number | null
) => {
  const locationLength = location && location.length > 14;
  const allLength = locationLength || eRangeStart! > 999 || eRangeEnd! > 999;
  return allLength;
};

export const scrollToErrors = (errors: { [field: string]: any }) => {
  const errorKeys = Object.keys(errors);
  if (errorKeys.length > 0) {
    const firstError = document.querySelector(`.${errorKeys[0]}`);

    if (firstError) {
      const topbarOffset = 80;
      const marginOffset = 5;
      const y =
        firstError.getBoundingClientRect().top - topbarOffset - marginOffset;
      window.scrollBy({ top: y, behavior: "smooth" });
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
};

export const checkCampaignExist = (
  campaigns: Campaign[],
  value: string | undefined,
  campaignUUID?: string
) => {
  const campaignsList =
    (campaignUUID &&
      campaigns.filter(
        (campaing: Campaign) => campaing.uuid !== campaignUUID
      )) ||
    campaigns;

  const isValid = campaignsList.find(
    (campaign: Campaign) => campaign.name.toLowerCase() === value?.toLowerCase()
  );

  return !isValid;
};

export const checkCampaignName = (campaignName: string | undefined) => {
  const minNameCharacters = 3;
  const cleanCampaignName = campaignName?.replace(/\s+/g, "");
  return (
    (cleanCampaignName && cleanCampaignName?.length >= minNameCharacters) ||
    false
  );
};

export const addUTMSource = (url: string) => {
  /* 
    Intended to use only for companies' websites, so that they know 
    their traffic is coming from Novable 
  */
  return url + "?utm_source=NovableDotCom&utm_campaign=StartupScouting";
};

export const normalizeData = (
  x: number,
  min: number,
  max: number,
  lowerTargetScaling?: number,
  upperTargetScaling?: number
) => {
  const _lowerTargetScaling = lowerTargetScaling || 0;
  const _upperTargetScaling = upperTargetScaling || 1;
  return (
    ((x - min) / (max - min)) * (_upperTargetScaling - _lowerTargetScaling) +
    _lowerTargetScaling
  );
};

export const highlightTopic = (
  topic: string,
  highlightTopics: string[] | undefined
) => {
  if (!highlightTopics || highlightTopics.length === 0) return false;
  return highlightTopics.includes(topic);
};

export const MIN_CLUSTER_WEIGHT_TO_COUNT_FOR_KEYWORDS = 2;
export const getHighlightTopicsFromCampaign = (
  campaign: Campaign | undefined
) => {
  if (!campaign) return [];

  let highlightTopics: string[] = [];
  if (campaign?.keywords) {
    highlightTopics = campaign.keywords.map((x) => cleanKeyword(x));
  }

  if (campaign?.clusters) {
    const clusterKeywords = campaign.clusters
      .filter(
        (cluster) => cluster.weight > MIN_CLUSTER_WEIGHT_TO_COUNT_FOR_KEYWORDS
      )
      .map((cluster) => {
        return cluster.keywords.map((keyword) => keyword.keyword);
      });
    const flatClusterKeywords = clusterKeywords.flat(1);
    highlightTopics = highlightTopics.concat(
      flatClusterKeywords.map((x) => cleanKeyword(x))
    );
  }

  return highlightTopics;
};

const getCompanyTopicsCache = new Map();
export const getCompanyTopics = (company: BaseCompany) => {
  let topics: string[] = [];
  // if (company.tags) {
  //   topics = [...company.tags];
  // }
  // if (company.textKeywords) {
  //   topics = [...topics, ...company.textKeywords];
  // }
  // return topics;
  if (getCompanyTopicsCache.has(company.uuid)) {
    topics = getCompanyTopicsCache.get(company.uuid);
  } else {
    if (company.tags) {
      topics = [...company.tags];
    }
    if (company.textKeywords) {
      topics = [...topics, ...company.textKeywords];
    }

    getCompanyTopicsCache.set(company.uuid, topics);
  }
  return topics;
};

export const getCountryByISO3Code = (countryISO3Code: string) => {
  for (let continent of countries) {
    const countryIndex = continent.children.findIndex(
      (x) => x.value === countryISO3Code
    );
    if (countryIndex !== -1) return continent.children[countryIndex].title;
  }
  return null;
};

const DISPLAY_TABLE_VIEW_KEY = "DISPLAY_TABLE_VIEW";

export const shouldDisplayTableView = (campaignUUID: string) => {
  const currentPreferences = localStorage.getItem(DISPLAY_TABLE_VIEW_KEY);
  return (
    (currentPreferences && currentPreferences.includes(campaignUUID)) || false
  );
};

export const handleDisplayTableViewPreference = (
  campaignUUID: string,
  shouldDisplay: boolean
) => {
  shouldDisplay
    ? addCampaignToDisplayTableViewList(campaignUUID)
    : deleteCampaignFromDisplayTableViewList(campaignUUID);
};

const deleteCampaignFromDisplayTableViewList = (campaignUUID: string) => {
  const currentPreferences = localStorage.getItem(DISPLAY_TABLE_VIEW_KEY);
  if (!currentPreferences) {
    localStorage.setItem(DISPLAY_TABLE_VIEW_KEY, "");
  } else {
    const newCampaignUUIdsList = currentPreferences
      .split(",")
      .filter((UUID) => UUID !== campaignUUID)
      .join(",");
    localStorage.setItem(DISPLAY_TABLE_VIEW_KEY, newCampaignUUIdsList);
  }
};

const addCampaignToDisplayTableViewList = (campaignUUID: string) => {
  const currentPreferences = localStorage.getItem(DISPLAY_TABLE_VIEW_KEY);
  if (!currentPreferences) {
    localStorage.setItem(DISPLAY_TABLE_VIEW_KEY, campaignUUID);
  } else {
    const newCampaignUUIdsList = currentPreferences + "," + campaignUUID;
    localStorage.setItem(DISPLAY_TABLE_VIEW_KEY, newCampaignUUIdsList);
  }
};

export {
  getMapChartNormalizedData,
  getWordCloudKeywordsNormalized,
} from "./charts";

export {
  changeClusterWeightFromTo,
  normalizeClusterWeight,
  denormalizeClusterWeight,
  filterActiveKeywordsInClusters,
  addIsActiveToClustersKeywords,
} from "./clusters";

export { getHourDiff, dateFormatter } from "./date";

export { colorScale } from "./d3";

export { useDelayUnmount } from "./hooks";

export {
  camelCaseToTitleCase,
  capitalizeWord,
  cleanKeyword,
  cleanUrl,
  formatLabel,
  isEllipsisActive,
  reverseString,
  toNumberWithSeparators,
} from "./strings";

export { isClientPremium } from "./client";
export { openLinkInNewTabAndTrack } from "./trackers";
