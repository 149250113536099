import { FC, useEffect } from "react";
import styled from "@emotion/styled";
import { Navigate, useParams, useLocation } from "react-router";

import { Layout } from "antd";
import { Sidebar } from "./Sidebar/Sidebar";
import { CampaignTopBar } from "./Topbar/CampaignTopBar";
import { Topbar } from "./Topbar/Topbar";
import { TopBarNewCampaign } from "./Topbar/TopBarNewCampaign";
import { CenteredSpinner } from "../CenteredSpinner";

import { getClientLists, selectedListSlugSelector } from "../../list/store";
import {
  getCampaigns,
  selectedCampaignSlugSelector,
} from "../../share/campaigns/store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { getClientTags } from "../../tags/store";

interface UserLayoutProps {
  children: React.ReactNode;
  title?: string | undefined;
  withNav?: boolean;
  activeNewCampaign?: boolean;
  activeNewChat?: boolean;
  isNewCampaign?: boolean;
}

export const UserLayout: FC<UserLayoutProps> = ({
  children,
  withNav,
  title,
  activeNewCampaign,
  activeNewChat,
  isNewCampaign,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const selectedClient = useAppSelector(
    (state: State) => state.clients.selectedClient
  );
  const { success: getCampaignsSuccess } = useAppSelector(
    (state: State) => state.campaigns.getCampaigns
  );
  const { campaignSlug, clientSlug, listSlug } =
    useParams<{ campaignSlug: string; clientSlug: string; listSlug: string }>();
  const campaign = useAppSelector(selectedCampaignSlugSelector(campaignSlug));

  const list = useAppSelector(selectedListSlugSelector(listSlug));

  // Fetch campaigns if they haven't been fetched yet
  // 'campaigns' variable starts as a null object, if the length
  // is equal to 0, it means that they have not been fetched yet
  const { campaigns } = useAppSelector((state: State) => state.campaigns);
  useEffect(() => {
    if (selectedClient && campaigns.length === 0) {
      dispatch(getCampaigns(selectedClient));
    }
  }, [dispatch, selectedClient, campaigns.length]);

  // Fetch lists if they haven't been fetched yet
  const { lists } = useAppSelector((state: State) => state.lists);
  useEffect(() => {
    if (selectedClient && lists.length === 0) {
      dispatch(getClientLists(selectedClient.uuid));
    }
  }, [dispatch, selectedClient, lists.length]);

  // Fetch tags if they haven't been fetched yet
  const { tags } = useAppSelector((state: State) => state.tags);
  useEffect(() => {
    if (selectedClient && tags.length === 0) {
      dispatch(getClientTags(selectedClient.uuid));
    }
  }, [dispatch, selectedClient, tags.length]);

  // Two possible cases:
  // 1. A client has not been selected yet
  // 2. The url includes a slug that is not the same as the selected client.
  //    This happens when someone, after having selected a client (logged in),
  //    opens a link to a different client
  if (
    !selectedClient ||
    (selectedClient && selectedClient.slug !== clientSlug)
  ) {
    // The url /clients renders ClientManagerView, which in turn decides whether to render
    // ClientView or ClientsView (depending on the existence of slug in the params or
    // location state)
    return (
      <Navigate
        to={"/clients"}
        state={{ slug: clientSlug, from: location.pathname }}
      />
    );
  }

  // Show full-screen loader only when loading campaigns on any page that is not the
  // dashboard
  // Edge case: there is a campaign with "dashboard", hence it is checked if both "campaign" and "dashboard"
  // in the location
  const campaignWithDashboardInSlug =
    location.pathname.includes("campaign") &&
    location.pathname.includes("dashboard");
  const userIsNotInDashboard =
    !location.pathname.includes("dashboard") && !campaignWithDashboardInSlug;
  if (!getCampaignsSuccess && userIsNotInDashboard) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <CenteredSpinner />;
      </Layout>
    );
  }

  let topBarComponent;
  if (activeNewChat) topBarComponent = <Topbar title={title} activeNewChat={activeNewChat} />;
  else if (isNewCampaign) topBarComponent = <TopBarNewCampaign />;
  else if (withNav && campaign)
    topBarComponent = <CampaignTopBar campaign={campaign} />;
  else if (list) topBarComponent = <Topbar title={list.name} />;
  else
    topBarComponent = (
      <Topbar title={title} activeNewCampaign={activeNewCampaign} />
    );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout style={{ background: "var(--background-color-base)" }}>
        {topBarComponent}
        {children}
      </Layout>
    </Layout>
  );
};

export const ContentSection = styled.section`
  padding: 2.4rem;
  grid-area: main;
`;
