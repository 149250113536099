import { FC, useEffect } from "react";

import { errorNotification, successNotification } from "../../../Notification";
import { Button } from "../../../../ui-components/Button";
import { Modal } from "../../../../ui-components/Modal";
import { Paragraph } from "../../../../ui-components/Typography";

import {
  submitGBExportRequest,
  setGBExportInitialState,
  CampaignRequestType,
} from "../../../../campaign/golden-basket/store";
import { State } from "../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";

interface ExportGoldenBasketModalProps {
  visible: boolean;
  handleModalClose: () => void;
  campaignUUID?: string;
  clientUUID?: string;
  clientHasAutomaticExport: boolean | undefined;
}

const clientWithoutAutomaticExportMessage =
  "You are requesting an export of this Golden Basket. The exported file will be checked by your Novable Expert and will be sent to your email soon.";
const clientWithAutomaticExportMessage =
  "You are requesting an export of this Golden Basket. The exported file will be sent to your email.";

export const ExportGoldenBasketModal: FC<ExportGoldenBasketModalProps> = ({
  handleModalClose,
  visible,
  campaignUUID,
  clientUUID,
  clientHasAutomaticExport,
}) => {
  const dispatch = useAppDispatch();
  const { success, loading, message } = useAppSelector(
    (state: State) => state.goldenBasket.submitGBExportRequest
  );
  const modalMessage = clientHasAutomaticExport
    ? clientWithAutomaticExportMessage
    : clientWithoutAutomaticExportMessage;

  useEffect(() => {
    if (success) {
      handleModalClose();
      successNotification("Request submitted successfully");
      dispatch(setGBExportInitialState());
    } else if (success === false) {
      errorNotification(message || "There was an error submitting the request");
    }
  }, [dispatch, success, message, handleModalClose]);

  const handleSubmit = () => {
    if (!clientUUID || !campaignUUID) return;

    const data = {
      clientUUID: clientUUID,
      campaignUUID: campaignUUID,
      requestType: CampaignRequestType.Export,
    };
    dispatch(submitGBExportRequest(data));
  };
  return (
    <Modal
      title="Request an export"
      visible={visible}
      handleSubmit={handleSubmit}
      onCancel={handleModalClose}
      footer={[
        <Button key="cancel" onClick={handleModalClose}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          htmlType="submit"
          loading={loading}
          disabled={loading}
          onClick={() => handleSubmit()}
        >
          {loading ? "Submitting" : "Submit"}
        </Button>,
      ]}
    >
      <Paragraph>{modalMessage}</Paragraph>
    </Modal>
  );
};
