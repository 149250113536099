import styled from "@emotion/styled";
import { Button as DefaultButton } from "../../../../ui-components/Button";
import { HeadTitle as BaseHeadTitle } from "../../../../ui-components/Card";
import { GhostButton } from "../../../../ui-components/GhostButton";
import ArrowIcon from "../../../../assets/icons/arrow.svg";

export const HeadTitle = styled(BaseHeadTitle)`
  &::after {
    position: absolute;
    left: 0;
    bottom: -2px;
    top: 4.3rem;
    content: "";
    width: 100%;
    height: 2px;
    background: var(--primary-color);
  }
`;

export const PreviousButton = styled(GhostButton)`
  width: 10rem;
  margin-right: 1.6rem;
  margin-left: auto;
`;

export const NextButton = styled(DefaultButton)``;

export const BaseArrow = styled.span`
  width: 0.6rem;
  height: 1.2rem;
  display: inline-block;
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
`;

export const NextArrow = styled(BaseArrow)`
  filter: brightness(0) invert(1);
  margin-left: 1.3rem;
`;

export const PreviousArrow = styled(BaseArrow)`
  transform: rotate(180deg);
  margin-right: 1rem;
`;

export const SubmitContainer = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 1.6rem;
  height: 6.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
`;
