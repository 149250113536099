import { FC, useState } from "react";
import styled from "@emotion/styled";
import { FieldArray } from "formik";

import {
  NewCampaignSubtitle,
} from "../../ui-components/Typography";
import { EditButton as BaseButton } from "../../ui-components/EditButton";
import { FormikTreeSelect } from "../../ui-components/FormikTreeSelect";
import { FormikInput } from "../../ui-components/FormikInput";
import { ExamplesOfCompaniesRender } from "../../share/campaigns/components/ExamplesOfCompaniesRender";
import { ExtraTopicsModal } from "./modals/ExtraTopicsModal";

import { Segment } from "../../utils/Segment";
import { countries } from "../../utils/countries";
import { FreeSoloTopicsSelect } from "./form/FreeSoloTopicsSelect";
import { NegativeKeywordSelect } from "./form/NegativeKeywordSelect";

interface CampaignDetailsProps {
  values: { [field: string]: any };
  errors: { [field: string]: any };
  clustersHaveBeenRequested: boolean;
  hasLoadedDraftWithClusters: boolean;
  onCancelEdit: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const CampaignDetails: FC<CampaignDetailsProps> = ({
  values,
  errors,
  clustersHaveBeenRequested,
  hasLoadedDraftWithClusters,
  onCancelEdit,
  setFieldValue,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const addSuggestedTopics = (topics: string[]) => {
    setFieldValue("keywords", [...values.keywords, ...topics]);
  };

  const openExtraTopicsModalAndTrack = () => {
    Segment.analytics?.track("extra topics");
    setModalVisible(true);
  };

  const disabledExtraTopicsButton = values.strategyPlainText.length === 0;
  return (
    <ContentCol>
      <Briefing>
        {/* Strategy */}
        <RequiredNewCampaignSubtitle className={"strategyPlainText"}>
          Describe the type of company, product, or service you are looking for
        </RequiredNewCampaignSubtitle>
        <EditorContainer>
          <FormikInput
            as="textarea"
            type="text"
            name="strategyPlainText"
            placeholder="I'm looking for ..."
            data-intercom-target="strategy-field"
            spellcheck="true"
          />
        </EditorContainer>
      </Briefing>

      <RequiredNewCampaignSubtitle>Add Topics</RequiredNewCampaignSubtitle>
      <KeywordsContainer>
        <SelectContainer>
          <FreeSoloTopicsSelect
            name="keywords"
            mode="tags"
            placeholder="AI, self-driving cars, ..."
            sectionExplanation=""
            disabledExtraTopicsButton={disabledExtraTopicsButton}
            openExtraTopicsModalAndTrack={openExtraTopicsModalAndTrack}
            data-intercom-target="topics-field"
          />
        </SelectContainer>
      </KeywordsContainer>

      <ExamplesOfCompaniesForm>
        <NewCampaignSubtitle className={"lookalikes"}>
          Add similar company URLs:
        </NewCampaignSubtitle>
        <FieldArray
          name="lookalikes"
          render={(arrayHelpers) => (
            <ExamplesOfCompaniesRender
              values={values}
              arrayHelpers={arrayHelpers}
            />
          )}
        />
      </ExamplesOfCompaniesForm>

      <GeographiesContainer>
        <NewCampaignSubtitle>Countries or Regions: </NewCampaignSubtitle>
        <SelectContainer>
          <FormikTreeSelect
            name="countries"
            treeData={countries}
            placeholder="Enter countries or regions of interest (or leave empty for the entire world)"
          />
        </SelectContainer>
      </GeographiesContainer>

      <OptionalNewCampaignSubtitle>Exclude Keywords</OptionalNewCampaignSubtitle>
      <KeywordsContainer>
        <SelectContainer>
          <NegativeKeywordSelect
            name="keywordsExcluded"
            mode="tags"
            placeholder="List keywords you want to exclude from the search"
            sectionExplanation=""
            data-intercom-target="keywords-excluded-field"
          />
        </SelectContainer>
      </KeywordsContainer>

      <ExtraTopicsModal
        visible={modalVisible}
        hideModal={() => setModalVisible(false)}
        values={values}
        addTopics={addSuggestedTopics}
      />
    </ContentCol>
  );
};

const Briefing = styled.div`
  width: 100%;
`;

const EditorContainer = styled.div`
  border-radius: var(--border-radius);
  width: 100%;
`;

const ExamplesOfCompaniesForm = styled.div`
  width: 40%;
  margin-top: 2.5rem;
  // display: flex;
  // flex-direction: column;
`;

const KeywordsContainer = styled.div`
  width: 100%;
`;

const GeographiesContainer = styled.div`
  width: 100%;
  margin-top: 2.5rem;
`;

const ContentCol = styled.div`
  grid-area: content;
`;

const SelectContainer = styled.div`
  width: 100%;
  margin: 0;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CancelButton = styled(BaseButton)`
  border: var(--button-border);
  margin-left: 2rem;
`;

const RequiredNewCampaignSubtitle = styled(NewCampaignSubtitle)`
  margin-top: 2.5rem;
  &:after {
    content: " *";
    color: var(--font-color-black);
  }
`;

const OptionalNewCampaignSubtitle = styled(NewCampaignSubtitle)`
  margin-top: 2.5rem;
`;
