import { PersonaOptions } from '@nlux/react';
import { Avatar as BaseAvatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import scottAvatar from '../../assets/novable-agents/Scott.png';

const botStyle = {
    background: 'var(--background-color-primary)',
    fontSize: '2.3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
};

const avatarStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
};

const Avatar = styled(BaseAvatar)`
  flex: 0 0 4rem;
  background: var(--background-campaign-avatar);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
  }
`;

export const personas: PersonaOptions = {
    assistant: {
        name: 'Scott',
        avatar: <Avatar style={botStyle} src={scottAvatar} />,
        tagline: 'Your AI scouting assistant',
    },
    user: {
        name: 'Novable User',
        avatar: <Avatar size="default" icon={<UserOutlined />} style={avatarStyle} />,
    }
};