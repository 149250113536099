import { scaleLinear } from "d3-scale";
import antCssVars from "../styles/antCssVars";

// Color scale's range function cannot access css variables
// It has been decided thus to set them up by hand, and leave here as comments
// The css variable names, in case it's ever decided to be changed, to easily
// look for them
// var(--primary-color) (--primary-color)
// var(--chart-light-blue) --chart-light-blue
const PRIMARY_COLOR = antCssVars["primary-color"];
const CHART_LIGHT_BLUE = "rgba(250, 130, 19, 0.25)";
export const colorScale = scaleLinear<string>()
  .domain([0, 1])
  .range([CHART_LIGHT_BLUE, PRIMARY_COLOR]);
