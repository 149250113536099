import { FC } from "react";
import { Form, Checkbox } from "antd";
import { useField } from "formik";
import { Dictionary } from "../share/types";

export interface FormikCheckboxProps {
  name: string;
  formItemProps?: Dictionary<any>;
  [x: string]: any;
}

export const FormikCheckbox: FC<FormikCheckboxProps> = ({
  name,
  formItemProps,
  children,
  ...rest
}: any) => {
  const [field, meta, { setValue, setTouched }] = useField({ name, ...rest });
  const invalid = meta.touched && meta.error; // Show error message & red border
  return (
    <Form.Item
      help={invalid && meta.error ? meta.error : null}
      validateStatus={invalid ? "error" : "success"}
      {...formItemProps}
    >
      <Checkbox
        checked={field.value}
        onChange={(e) => setValue(e.target.checked)}
        onBlur={() => setTouched(true)}
        {...rest}
      >
        {children}
      </Checkbox>
    </Form.Item>
  );
};
