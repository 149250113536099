import { Cluster } from "../share/campaigns/store";

const CLUSTER_NORMALIZED_WEIGHT = 7;
const CLUSTER_DENORMALIZED_WEIGHT = 10;

export const normalizeClusterWeight = (clusters: Cluster[]) => {
  return changeClusterWeightFromTo(
    clusters,
    CLUSTER_DENORMALIZED_WEIGHT,
    CLUSTER_NORMALIZED_WEIGHT
  );
};

export const denormalizeClusterWeight = (clusters: Cluster[]) => {
  return changeClusterWeightFromTo(
    clusters,
    CLUSTER_NORMALIZED_WEIGHT,
    CLUSTER_DENORMALIZED_WEIGHT
  );
};

export const changeClusterWeightFromTo = (
  clusters: Cluster[],
  initialWeight = CLUSTER_DENORMALIZED_WEIGHT,
  targetWeight: number
) => {
  if (targetWeight < initialWeight) {
    return clusters.map((cluster: Cluster) => {
      return {
        ...cluster,
        weight: Math.round((cluster.weight * targetWeight) / initialWeight),
      };
    });
  } else {
    return clusters.map((cluster: Cluster) => {
      return {
        ...cluster,
        weight: Math.round((cluster.weight / initialWeight) * targetWeight),
      };
    });
  }
};

export const filterActiveKeywordsInClusters = (clusters: Cluster[]) => {
  return clusters.map((cluster) => ({
    ...cluster,
    keywords: cluster.keywords
      .filter((keyword) => keyword.isActive)
      .map((keyword) => ({
        keyword: keyword.keyword,
        weight: keyword.weight,
      })),
  }));
};

export const addIsActiveToClustersKeywords = (clusters: Cluster[]) => {
  const clustersCopy = [...clusters];

  clustersCopy.forEach((cluster) =>
    cluster.keywords.forEach((keyword) => (keyword.isActive = true))
  );
  return clustersCopy;
};
