import { useState, useEffect } from "react";
import { Navigate } from "react-router";
import styled from "@emotion/styled";
import { Formik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";

import { Collapse as BaseCollapse, Tooltip } from "antd";
import { AutoSaveDraft } from "./components/AutoSaveDraft";
import { CampaignDetails } from "./components/CampaignDetails";
import { CampaignDetailsPanelHeader } from "./components/CampaignDetailsPanelHeader";
import { CampaignSubmitted } from "./components/CampaignSubmitted";
import { ClustersEvaluation } from "./components/ClustersEvaluation";
import { FirmographicsModal } from "./components/modals/FirmographicsModal";
import { DeleteDraftButton } from "./components/DeleteDraftButton";
import { PublishCampaignModal } from "./components/modals/PublishCampaignModal";
import {
  SubmitContainer as BaseSubmitContainer,
  NextButton as BaseButton,
} from "../share/campaigns/components/share-ui";
import { CenteredSpinner } from "../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../ui-components/CenteredErrorMessage";
import { errorNotification } from "../ui-components/Notification";
import {
  ButtonText as BaseButtonText,
  PanelHeadTitle,
} from "../ui-components/Typography";

import {
  createNewCampaign,
  fetchDraftCampaign,
  setClusters,
  setInitialState,
} from "./store";
import { Client } from "../clients/store";
import { Cluster } from "../share/campaigns/store";
import { setInitialLayoutState } from "../share/layout/store";
import { State } from "../utils/store";
import { useAppDispatch, useAppSelector } from "../utils/hooks";

import {
  ExploreCampaignSchema,
  ClustersSchema,
  ConfirmCampaignSchema,
  EmptySchema,
} from "../share/campaigns/validation";
import {
  denormalizeClusterWeight,
  normalizeClusterWeight,
  filterActiveKeywordsInClusters,
} from "../helpers";
import { CancelButton } from "./components/CampaignDetails";
import { NextButton } from "../share/campaigns/components/share-ui";
import { scrollToErrors } from "../helpers";

export enum Step {
  First = 0, // Briefing input
  Second = 1, // Clusters input
  Third = 2, // Publish campaign modal
  Fourth = 3, // Success page
}

export enum PanelKeys {
  briefing = "briefing",
  clusters = "clusters",
}

const validationSchema = [
  ExploreCampaignSchema,
  ClustersSchema,
  ConfirmCampaignSchema,
  EmptySchema,
];

/*
 * Draft Mechanics --> A draft may be loaded into a new campaign view. The way to
 * load a draf is to redirect to 'new-campaign/<uuid>' instead of just 'new-campaign/'
 *
 * Loading a draft have some consequeces, namely, the initialFormik values have to be
 * properly set up. The name is handled together with the TopBarNewCampaign, which holds
 * the input field. The name is then set into the store.
 *
 *
 * The NewCampaignView contains a form in 3 steps
 * Step 1: Campaign View
 * Step 2: Clusters evaluation
 * Step 3: Publish campaign
 *
 * Steps 1 and 2 are Panels inside a Collapse. Step 3 is a modal
 *
 * - There are some "rules" to the steps
 * - Only after step 1 has been "submitted", the preview can be opened
 * - When Step 1 has been submitted, the user needs to press on Edit to open it again
 * - The user may cancel the editing through the discard changes button, in which case
 *   the panel for the briefing is closed, and the one for the clusters is opened, showing
 *   the same data that was displayed already
 * - If the user modifies the values (and saves the changes), the clusters have to be updated
 *
 *
 * Others:
 * Step 2 may be just for show (no need to interact with it)
 * Preview and publish are only available on "step 2" (when clusters panel is open)
 *
 *
 */

// Used for form innerRef
export type FormValues = {
  name: string;
  description: string;
  strategyPlainText: string;
  lookalikes: String[];
  keywords: String[];
  keywordsExcluded: String[];
  countries: String[];
  clusters: Cluster[];
  uuid: string;
};

export const initialValues: FormValues = {
  name: "",
  description: "",
  strategyPlainText: "",
  lookalikes: [""],
  keywords: [],
  keywordsExcluded: [],
  countries: [],
  clusters: [],
  uuid: "",
};

export const getReadyCampaignData = (
  values: any,
  client: Client,
  name: string,
  clusters: Cluster[]
) => {
  const clustersWithProperKeywords = filterActiveKeywordsInClusters(clusters);

  const readyData = {
    ...values,
    name: name,
    clientUUID: client.uuid,
    keywords: values.keywords,
    clusters: denormalizeClusterWeight(clustersWithProperKeywords),
  };

  return readyData;
};

export const NewCampaignView = () => {
  const dispatch = useAppDispatch();

  const [uuid, setUuid] = useState("");
  // Read uuid from url, otherwise, create it
  let { uuid: uuidParam } = useParams<{ uuid: string }>();
  const {
    success: fetchDraftSuccess,
    loading: fetchDraftLoading,
    message: fetchDraftMessage,
  } = useAppSelector((state: State) => state.newCampaign.fetchDraftCampaign);

  const draftCampaign = useAppSelector(
    (state: State) => state.newCampaign.draftCampaign
  );
  let [loadedDraftWithClusters, setLoadedDraftWithClusters] = useState(false);
  let [initialFormikValues, setInitialFormikValues] = useState({
    ...initialValues,
  });

  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const {
    success: createNewCampaignSuccess,
    message,
    newCampaignSlug,
  } = useAppSelector((state: State) => state.newCampaign.createNewCampaign);

  const { clusters } = useAppSelector((state: State) => state.newCampaign);

  // current steps keeps track of the Step (Briefing)
  const [currentStep, setCurrentStep] = useState(Step.First);

  // We get campaigns for validation
  const { campaigns } = useAppSelector((state: State) => state.campaigns);

  // Keeps track of which panel is open
  const [activePanel, setActivePanel] = useState(PanelKeys.briefing);

  // Needed to display the Edit and Discard Changes button in CampaignDetails
  const [clustersHaveBeenRequested, setClustersHaveBeenRequested] =
    useState(false);

  // To know if the user requested an update on the clusters
  // There can be several cases in which this field is needed
  // 1. On an empty new draft, it has to be set to true
  // 2. On a loaded draft, with clusters, it may happen
  //   a) The user wants to keep the clusters, then it's set to false
  //   b) The user wants to refresh the clusters, then it'll be set to (remain, actually) true
  // 3. On either case, after checking the clusters once already, and going back
  //    to the Briefing pannel, the clusters will be requested again.
  const [updateClusters, setUpdateClusters] = useState(true);

  // Modal visibility handling
  const [publishModalIsVisible, setPublishModalIsVisible] = useState(false);
  const [firmographicsModalIsVisible, setFirmographicsModalIsVisible] =
    useState(false);

  const [autoSaveDisabled, setAutoSaveDisabled] = useState(false);

  const explanationText = "Complete the required fields to preview the results or publish a campaign"

  useEffect(() => {
    if (fetchDraftSuccess) {
      const {
        name: draftName,
        description,
        strategyPlainText,
        lookalikes,
        keywords,
        keywordsExcluded,
        countries,
        clusters,
        uuid,
      } = draftCampaign;

      setInitialFormikValues({
        name: draftName,
        description: description,
        strategyPlainText: strategyPlainText,
        lookalikes: lookalikes.length !== 0 ? lookalikes : [""],
        keywords: keywords,
        keywordsExcluded: keywordsExcluded,
        countries: countries,
        clusters: clusters,
        uuid: uuid,
      });
    }
    // Do not add draft campaign as a dependency, we don't want to change
  }, [fetchDraftSuccess, uuid]); // eslint-disable-line

  useEffect(() => {
    if (uuidParam) {
      setUuid(uuidParam);
      dispatch(fetchDraftCampaign(uuidParam));
    } else setUuid(uuidv4());
    // When changing between drafts, reset the status of the draft
    setActivePanel(PanelKeys.briefing);
    setCurrentStep(Step.First);

    return () => {
      // Reset store if a change in uuidParam is detected, but the component is still mounted
      dispatch(setInitialState());
      dispatch(setInitialLayoutState());
    };
  }, [uuidParam, dispatch]);

  // Reset formik values for new draft campaign
  useEffect(() => {
    if (!draftCampaign?.uuid && !fetchDraftLoading && !fetchDraftSuccess) {
      setInitialFormikValues(initialValues);
    };
  }, [draftCampaign?.uuid]);

  // On first load of the draft campaign, load clusters to store
  useEffect(() => {
    if (draftCampaign && draftCampaign.clusters.length !== 0) {
      setLoadedDraftWithClusters(true);
      setUpdateClusters(true);
      const normalizedClusters = normalizeClusterWeight(draftCampaign.clusters);
      dispatch(setClusters(normalizedClusters));
    }
  }, [draftCampaign?.uuid]); // eslint-disable-line

  // Cleanup of new campaign store
  useEffect(() => {
    return () => {
      dispatch(setInitialState());
      dispatch(setInitialLayoutState());
    };
  }, [dispatch]);

  // Fourth step is a Result view
  useEffect(() => {
    if (createNewCampaignSuccess === true) {
      setCurrentStep(Step.Fourth);
    }
    if (createNewCampaignSuccess === false) {
      errorNotification(message);
    }
  }, [createNewCampaignSuccess, message]);

  useEffect(() => {
    if (currentStep === Step.Fourth) {
      setAutoSaveDisabled(true);
    } else {
      setAutoSaveDisabled(false);
    }
  }, [currentStep]);

  const cancelEditCampaign = () => {
    setUpdateClusters(false);
    openClustersPanel();
  };

  const openBriefingPanel = () => {
    setUpdateClusters(false);
    disableOpenClustersButton();
    setCurrentStep(Step.First);
    setActivePanel(PanelKeys.briefing);
  };

  const openClustersPanel = () => {
    setCurrentStep(Step.Second);
    setActivePanel(PanelKeys.clusters);
    setClustersHaveBeenRequested(true);
  };

  const displayPublishModal = () => {
    setCurrentStep(Step.Third);
    setPublishModalIsVisible(true);
  };

  const hidePublishModal = () => {
    setCurrentStep(Step.Second);
    setPublishModalIsVisible(false);
  };

  const displayFirmographicsModal = () => {
    setFirmographicsModalIsVisible(true);
  };

  const hideFirmographicsModal = () => {
    setFirmographicsModalIsVisible(false);
  };

  const disableOpenClustersButton = () => {
    // Reset LoadedDraftWithClusters, to not show the button anymore
    setLoadedDraftWithClusters(false);
  };

  if (fetchDraftLoading) {
    return <CenteredSpinner />;
  }
  if (fetchDraftMessage && !fetchDraftSuccess) {
    return <CenteredErrorMessage>{fetchDraftMessage}</CenteredErrorMessage>;
  }

  // Last saved by user_name on save_date at save_time.
  let lastUpdatedDate: any;
  let lastUpdatedTime: any;
  if (draftCampaign) {
    let lastUpdatedAt = new Date(draftCampaign.updatedAt);
    lastUpdatedDate = lastUpdatedAt.toLocaleDateString("fr-BE");
    lastUpdatedTime = lastUpdatedAt.toLocaleTimeString("fr-BE");
  }

  return (
    <NewCampaignContainer>
      {draftCampaign && draftCampaign.lastEditedBy && (
        <EditorContainer>
          <LastEditedText>
            {`✅ Last autosave: ${draftCampaign.lastEditedBy} on ${lastUpdatedDate} at ${lastUpdatedTime}`}
          </LastEditedText>
        </EditorContainer>
      )}
      <Formik
        initialValues={{ ...initialFormikValues, uuid: uuid }}
        onSubmit={(values, { resetForm }) => {
          // Change from Briefing information to clusters, it will allow preview and submit buttons
          if (currentStep === Step.First) {
            disableOpenClustersButton();
            setUpdateClusters(true);
            openClustersPanel();
            setClustersHaveBeenRequested(true);
          } else {
            // Current step === Step.third, submit with all info and reset
            if (selectedClient) {
              const readyData = getReadyCampaignData(
                values,
                selectedClient,
                values.name,
                clusters
              );
              dispatch(createNewCampaign(readyData));
              if (createNewCampaignSuccess) {
                resetForm();
              }
            }
          }
        }}
        validationSchema={validationSchema[currentStep](campaigns)}
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={true}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => (
          <>
            <Form onSubmit={handleSubmit}>
              {currentStep === Step.Fourth ? (
                selectedClient?.isBetaTester ? (
                  <Navigate
                    to={`/${selectedClient.slug}/campaign/golden-basket/${newCampaignSlug}`}
                  />
                ) : (
                  <CampaignSubmitted />
                )
              ) : (
                <>
                  <Collapse activeKey={activePanel}>
                    <Panel
                      showArrow={false}
                      header={
                        <CampaignDetailsPanelHeader
                          displayEditButton={
                            (clustersHaveBeenRequested ||
                              loadedDraftWithClusters) &&
                            activePanel !== PanelKeys.briefing
                          }
                          onEdit={openBriefingPanel}
                        />
                      }
                      key={PanelKeys.briefing}
                    >
                      <CampaignDetails
                        values={values}
                        errors={errors}
                        hasLoadedDraftWithClusters={loadedDraftWithClusters}
                        clustersHaveBeenRequested={clustersHaveBeenRequested}
                        onCancelEdit={cancelEditCampaign}
                        setFieldValue={setFieldValue}
                      />
                    </Panel>
                    <Panel
                      showArrow={false}
                      header={
                        <PanelHeaderContainer>
                          <PanelHeadTitle style={{ cursor: "default" }}>
                            Advanced Settings
                          </PanelHeadTitle>

                          <div>
                          { currentStep == Step.First && (<SecondaryButton
                            style={{ marginLeft: '5rem' }}
                            htmlType="submit" 
                            onClick={() => scrollToErrors(errors)}
                            data-intercom-target="generate-clusters-btn"
                          >
                            {clustersHaveBeenRequested
                              ? "Update your clusters"
                              : "Generate clusters"}
                          </SecondaryButton>)}
                          </div>

                        </PanelHeaderContainer>
                      }
                      key={PanelKeys.clusters}
                    >
                      <ClustersEvaluation
                        values={values}
                        shouldUpdateClusters={updateClusters}
                        resetShouldUpdateClusters={() =>
                          setUpdateClusters(false)
                        }
                        setFieldValue={setFieldValue}
                      />
                    </Panel>
                  </Collapse>
                  <FirmographicsModal
                    visible={firmographicsModalIsVisible}
                    hideModal={hideFirmographicsModal}
                    values={values}
                  />
                  <PublishCampaignModal
                    visible={publishModalIsVisible}
                    submit={handleSubmit}
                    hideModal={hidePublishModal}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                  />
                </>
              )}
            </Form>
            {currentStep !== Step.Fourth && (
              <SubmitContainer>
                <>
                  {<AutoSaveDraft disableAutoSave={autoSaveDisabled} />}
                  <DeleteDraftButton />
                  {(values?.strategyPlainText?.length < 100 || values?.keywords.length === 0) ? (
                    <>
                      <Tooltip title={explanationText} placement="top">
                        <span style={{ cursor: "not-allowed" }}>
                          <PreviewButton
                            disabled={true}
                            style={{ pointerEvents: "none" }}
                          >
                            <ButtonText>Preview results</ButtonText>
                          </PreviewButton>
                        </span>
                      </Tooltip>
                      <Tooltip title={explanationText} placement="top">
                        <span style={{ cursor: "not-allowed" }}>
                          <Button
                            disabled={true}
                            style={{ pointerEvents: "none" }}
                          >
                            <ButtonText>Publish campaign</ButtonText>
                          </Button>
                        </span>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <PreviewButton onClick={displayFirmographicsModal}>
                        Preview results
                      </PreviewButton>
                      <Button
                        onClick={displayPublishModal}
                        data-intercom-target="open-publish-modal-btn"
                      >
                        <ButtonText>Publish campaign</ButtonText>
                      </Button>
                    </>
                  )}
                </>
              </SubmitContainer>
            )}
          </>
        )}
      </Formik>
    </NewCampaignContainer>
  );
};

const EditorContainer = styled.div`
  width: 88%;
  margin: 0rem auto 0.2rem auto;
  display: flex;
  justify-content: end;
`;

const LastEditedText = styled.span``;

const Form = styled.form`
  // Entire screen - topbar - footer - padding from parent
  min-height: calc(100vh - 8rem - 6.8rem - 2.4rem);
`;

const NewCampaignContainer = styled.div`
  margin-top: 2rem;
  background: var(--background-color-secondary);
`;

const Collapse = styled(BaseCollapse)`
  width: 90%;
  margin: 0 auto 2rem auto;
  cursor: default;
`;

// Change padding for margin to not have cursor:pointer on the padding section
const Panel = styled(BaseCollapse.Panel)`
  margin-bottom: 1.6rem;
  background-color: var(--background-color-primary);
  border: var(--campaign-card-border);
  border-radius: var(--border-radius);
  cursor: default;

  .ant-collapse-header {
    padding: 0 !important;
    margin: 12px 16px;

    .ant-collapse-header-text {
      width: 100%;
    }
  }
`;

const SubmitContainer = styled(BaseSubmitContainer)`
  display: flex;
  justify-content: center;
  background-color: var(--background-color-secondary);
`;

export const Button = styled(BaseButton)`
  height: 3.6rem;
  margin-right: 2rem;
  background-color: var(--primary-color);
  &:disabled {
    background-color: var(--background-color-tertiery);
  }

  &:disabled:hover {
    background-color: var(--background-color-tertiery);
  }
`;

const PreviewButton = styled(Button)`
border: 1px solid var(--primary-color);
background-color: var(--background-color-primary);
color: var(--primary-color);

&:hover {
  border: 1px solid var(--primary-color);
  background-color: var(--background-color-primary);
  color: var(--primary-color);
}

&:focus {
  border: 1px solid var(--primary-color);
  background-color: var(--background-color-primary);
  color: var(--primary-color);
}

&:disabled {
  background-color: var(--background-color-tertiery);
}

&:disabled:hover {
  background-color: var(--background-color-tertiery);
}
`;

export const ButtonText = styled(BaseButtonText)`
  color: var(--white);
  font-size: 1.6rem;
`;

const PanelHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
`;

export const SecondaryButton = styled(NextButton)`
  height: 3.6rem;
  margin-left: 5rem;
  margin-right: 2rem;
  border: 1px solid var(--primary-color);
  background-color: var(--background-color-primary);
  color: var(--primary-color);

  &:hover {
    border: 1px solid var(--primary-color);
    background-color: var(--background-color-primary);
    color: var(--primary-color);
  }

  &:focus {
    border: 1px solid var(--primary-color);
    background-color: var(--background-color-primary);
    color: var(--primary-color);
  }

  &:disabled {
    background-color: var(--background-color-tertiery);
  }

  &:disabled:hover {
    background-color: var(--background-color-tertiery);
  }
`;
