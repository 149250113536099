import styled from "@emotion/styled";

import { TagTwoTone } from "@ant-design/icons";
import { TableFollowButton } from "./TableFollowButton";
import { ActionButton } from "../../../share/company/components/ui-share";
import { ListWidget } from "../../../share/company/components/list-widget/ListWidget";
import { ActivityIndex } from "../../../share/company/components/company-list/ActivityIndex";
import { DisplayTags } from "../../../share/company/components/company-detail/body/DisplayTags";
import { DisplayTopics } from "../../../share/company/components/company-detail/body/DisplayTopics";
import { Team } from "../../../ui-components/CompanyCardStyles";
import { ListIconBlue } from "../../../ui-components";
import { TagWidget } from "../../../share/company/components/tag-widget/TagWidget";
import { FavouriteButton } from "../../../share/company/components/company-detail/header/FavouriteButton";

import defaultLogo from "../../../assets/defaults/default-logo.png";
import { LikeFilled, DislikeFilled } from '@ant-design/icons';
import openInNewWindowIcon from "../../../assets/icons/open-in-new-window.svg";

import {
  GoldenBasketCompany,
  favouriteGBCompany,
  iterationsFilter,
  topicsFilter,
} from "../../golden-basket/store";
import { countriesTableFilter } from "../../../utils/countriesTableFilter";
import { State } from "../../../utils/store";
import { ListCompanySource } from "../../../list/store";
import { TagCompanySource, Tag as TagType } from "../../../tags/store";
import {
  cleanKeyword,
  getCompanyTopics,
  getCountryByISO3Code,
  openLinkInNewTabAndTrack,
} from "../../../helpers";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

// TODO: Manage to make the searchFilter work, could not do it
// interface CountryFilterObject {
//   title?: string;
//   key?: string;
//   expanded?: boolean;
//   selected?: boolean;
//   checked?: boolean;
//   loaded?: boolean;
//   loading?: boolean;
//   halfChecked?: boolean;
//   dragOver?: boolean;
//   dragOverGapTop?: boolean;
//   dragOverGapBottom?: boolean;
//   pos?: string;
//   active?: boolean;
// }

const employeesColumnFilters = [
  { text: "1 - 10", value: "1 - 10" },
  { text: "11 - 50", value: "11 - 50" },
  { text: "51 - 100", value: "51 - 100" },
  { text: "101 - 250", value: "101 - 250" },
  { text: "250 - 500", value: "250 - 500" },
  { text: "500+", value: "500+" },
];

const listCompanySource = ListCompanySource.TABLE_VIEW;
const tagCompanySource = TagCompanySource.TABLE_VIEW;

export const Columns = () => {
  const dispatch = useAppDispatch();
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const { tags } = useAppSelector((state: State) => state.tags);
  const tagsColumnFilters = tags.map((x: TagType) => {
    return { text: x.name, value: x.name };
  });

  const allTopicsFilter = useAppSelector(topicsFilter);
  allTopicsFilter.sort();
  const topicsColumnFilters = allTopicsFilter.map((x: string) => {
    return { text: cleanKeyword(x), value: x };
  });

  const allIterationsFilter = useAppSelector(iterationsFilter);
  allIterationsFilter.sort();
  const allIterationsFilterColumn = allIterationsFilter.map((x: number) => {
    return { text: x, value: x };
  });

  const handleFavourite = (e: any, companyUUID: string) => {
    if (!selectedClient) return;
    e.stopPropagation();
    let payload = {
      companyUUID: companyUUID,
      clientUUID: selectedClient.uuid,
    };
    dispatch(favouriteGBCompany(payload));
  };

  return [
    {
      title: "Name",
      dataIndex: "company.name",
      key: "company.name",
      width: 150,
      fixed: "left" as "left", // typescript
      sorter: (goldenBasketCompanyA: any, goldenBasketCompanyB: any) => {
        const companyNameA = goldenBasketCompanyA.company?.name;
        const companyNameB = goldenBasketCompanyB.company?.name;
        if (companyNameA < companyNameB) {
          return -1;
        }
        if (companyNameA > companyNameB) {
          return 1;
        }
        return 0;
      },
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        return (
          <FlexDiv>
            {goldenBasketCompany.company.logo && (
              <TableLogo
                src={goldenBasketCompany.company.logo || defaultLogo}
                alt="Snapshot of the Company's website"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = defaultLogo;
                }}
              />
            )}
            {goldenBasketCompany.company?.name}{" "}
            {/* <Tooltip title="Open Company's website in a new tab"> */}
            <ClickableCompanyName
              title="Open the Company's website in a new tab"
              onClick={(event) =>
                openLinkInNewTabAndTrack(
                  event,
                  goldenBasketCompany.company?.url || "",
                  "open-company-website",
                  { source: "table-view", tableSource: "golden-basket" }
                )
              }
              href={goldenBasketCompany.company?.url || ""}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="website"
            >
              <OpenInNewWindowIcon />
            </ClickableCompanyName>
            {/* </Tooltip> */}
          </FlexDiv>
        );
      },
    },
    {
      title: "Feedback",
      dataIndex: "isMatch",
      key: "isMatch",
      width: 105,
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        switch (goldenBasketCompany.isMatch) {
          case true:
            return (
              <FlexDiv>
                <GreenBackgroundLikeFilled>
                <LikeFilled />
                </GreenBackgroundLikeFilled>
              </FlexDiv>
            );
          case false:
            return (
              <FlexDiv>
                <RedBackgroundDislikeFilled>
                <DislikeFilled />
                </RedBackgroundDislikeFilled>
              </FlexDiv>
            );
          default:
            return "To Review";
        }
      },
      filters: [
        { text: "Match", value: true },
        { text: "Miss", value: false },
        { text: "To Review", value: "" },
      ],
      onFilter: (
        value: string | number | boolean,
        goldenBasketCompany: GoldenBasketCompany
      ) => {
        switch (value) {
          case true:
            return goldenBasketCompany.isMatch === true;
          case false:
            return goldenBasketCompany.isMatch === false;
          default:
            return goldenBasketCompany.isMatch === null;
        }
      },
    },
    {
      title: "Tags",
      dataIndex: "tag",
      key: "tag",
      width: 90,
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        return (
          <DisplayTags
            tags={goldenBasketCompany.company.companyTags}
            tagsLimit={3}
          />
        );
      },
      filters: tagsColumnFilters,
      onFilter: (value: any, goldenBasketCompany: GoldenBasketCompany) => {
        return goldenBasketCompany.company.companyTags.includes(value);
      },
    },
    {
      title: "Delivery",
      dataIndex: "company.iteration",
      key: "company.iteration",
      width: 120,
      filters: allIterationsFilterColumn,
      filterSearch: true,
      onFilter: (
        value: string | number | boolean,
        goldenBasketCompany: GoldenBasketCompany
      ) => {
        return value === goldenBasketCompany.iteration;
      },
      sorter: (goldenBasketCompanyA: any, goldenBasketCompanyB: any) => {
        const iterationA = goldenBasketCompanyA.iteration;
        const iterationB = goldenBasketCompanyB.iteration;
        if (iterationA < iterationB) {
          return -1;
        }
        if (iterationA > iterationB) {
          return 1;
        }
        return 0;
      },
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        return goldenBasketCompany.iteration;
      },
    },
    {
      title: "Activity index",
      dataIndex: "company.activiyIndex",
      key: "company.activiyIndex",
      width: 120,
      sorter: (
        goldenBasketCompanyA: GoldenBasketCompany,
        goldenBasketCompanyB: GoldenBasketCompany
      ) => {
        if (!goldenBasketCompanyA.company.activityIndex?.activityIndex) return -1;
        if (!goldenBasketCompanyB.company.activityIndex?.activityIndex) return 1;
        if (
          goldenBasketCompanyA.company.activityIndex.activityIndex <
          goldenBasketCompanyB.company.activityIndex.activityIndex
        ) {
          return -1;
        }
        return 1;
      },
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        return goldenBasketCompany.company.activityIndex?.activityIndex ? (
          <ActivityIndex
            activityIndex={
              goldenBasketCompany.company.activityIndex?.activityIndex
            }
          ></ActivityIndex>
        ) : (
          ""
        );
      },
    },
    {
      title: "Favourite",
      dataIndex: "favourite",
      key: "favourite",
      width: 120,
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        return (
          <FavouriteButton
            companyUUID={goldenBasketCompany.company.uuid}
            isFavourite={goldenBasketCompany.company.favourite}
            toggleFavouriteFunction={favouriteGBCompany}
            favouriteSource={"campaign"}
          />
        );
      },
      filters: [
        { text: "Favourited", value: true },
        { text: "Not Favourited", value: false },
      ],
      onFilter: (
        value: string | number | boolean,
        goldenBasketCompany: GoldenBasketCompany
      ) => {
        return goldenBasketCompany.company.favourite === value;
      },
    },
    {
      title: "Year founded",
      dataIndex: "company.yearFounded",
      key: "company.yearFounded",
      width: 120,
      sorter: (goldenBasketCompanyA: any, goldenBasketCompanyB: any) => {
        if (
          goldenBasketCompanyA.company.yearFounded <
          goldenBasketCompanyB.company.yearFounded
        ) {
          return -1;
        }
        if (
          goldenBasketCompanyA.company.yearFounded >
          goldenBasketCompanyB.company.yearFounded
        ) {
          return 1;
        }
        return 0;
      },
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        return goldenBasketCompany.company.yearFounded;
      },
    },
    {
      title: "Internal comment",
      dataIndex: "clientComment",
      key: "clientComment",
      width: 200,
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        return goldenBasketCompany.clientComment ? (
          <span> {goldenBasketCompany.clientComment}</span>
        ) : (
          ""
        );
      },
      filters: [
        { text: "Has internal comment", value: true },
        { text: "Does not have internal comment", value: false },
      ],
      onFilter: (
        value: string | number | boolean,
        goldenBasketCompany: GoldenBasketCompany
      ) => {
        if (typeof value === "boolean") {
          return value === Boolean(goldenBasketCompany.clientComment);
        }
        return false;
      },
    },
    {
      title: "Agent Comment",
      dataIndex: "comment",
      key: "comment",
      width: 200,
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        return goldenBasketCompany.agentComment ? (
          <span
            dangerouslySetInnerHTML={{
              __html: goldenBasketCompany.agentComment,
            }}
          />
        ) : (
          ""
        );
      },
      filters: [
        { text: "Has agent comment", value: true },
        { text: "Does not have agent comment", value: false },
      ],
      onFilter: (
        value: string | number | boolean,
        goldenBasketCompany: GoldenBasketCompany
      ) => {
        if (typeof value === "boolean") {
          return value === Boolean(goldenBasketCompany.agentComment);
        }
        return false;
      },
    },
    {
      title: "Topics",
      dataIndex: "company.textKeywords",
      key: "company.textKeywords",
      width: 250,
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        let { company } = goldenBasketCompany;
        let topics = getCompanyTopics(company);
        return <DisplayTopics topics={topics} topicsLimit={3} />;
      },
      filters: topicsColumnFilters,
      filterSearch: true,
      onFilter: (
        value: string | number | boolean,
        goldenBasketCompany: GoldenBasketCompany
      ) => {
        if (typeof value === "string") {
          let { company } = goldenBasketCompany;
          const topics = getCompanyTopics(company);
          const lowercaseTopics = topics.map((x) => x.toLowerCase());
          return lowercaseTopics.includes(value.toLowerCase());
        }
        return false;
      },
    },
    {
      title: "Country",
      dataIndex: "company.country",
      key: "company.country",
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        const companyCountry =
          getCountryByISO3Code(goldenBasketCompany.company.country) ||
          goldenBasketCompany.company.country;
        return companyCountry;
      },
      filterMode: "tree" as "tree", //Typescript
      filters: countriesTableFilter,
      // TODO: Manage to make it work
      // filterSearch: (input: string, goldenBasketCompany: CountryFilterObject) => {
      //   console.log(goldenBasketCompany?.title?.toLowerCase().includes(input.toLowerCase()));
      //   return (
      //     goldenBasketCompany?.title?.toLowerCase().includes(input.toLowerCase()) || false
      //   );
      // },
      onFilter: (
        value: string | number | boolean,
        goldenBasketCompany: GoldenBasketCompany
      ) => {
        if (typeof value === "string") {
          let { company } = goldenBasketCompany;
          return company.country === value;
        }
        return false;
      },
    },
    {
      title: "Employees",
      dataIndex: "employees",
      key: "employees",
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        if (
          goldenBasketCompany.company.employeesRangeStart &&
          goldenBasketCompany.company.employeesRangeEnd
        ) {
          return (
            <FlexDiv>
              <Team />
              {goldenBasketCompany.company.employeesRangeStart} -{" "}
              {goldenBasketCompany.company.employeesRangeEnd}
            </FlexDiv>
          );
        }
      },
      filters: employeesColumnFilters,
      onFilter: (
        value: string | number | boolean,
        goldenBasketCompany: GoldenBasketCompany
      ) => {
        if (typeof value === "string") {
          let { company } = goldenBasketCompany;
          if (value === "500+") {
            return (
              (company.employeesRangeStart &&
                company.employeesRangeStart >= 500) ||
              false
            );
          } else {
            const filterValues = value.split(" - ").map((x) => Number(x));
            if (filterValues.length < 2) return false;
            const [lowerEmployeeThreshold, upperEmployeeThreshold] =
              filterValues;

            return (
              (company.employeesRangeStart &&
                company.employeesRangeEnd &&
                company.employeesRangeStart >= lowerEmployeeThreshold &&
                company.employeesRangeEnd <= upperEmployeeThreshold) ||
              false
            );
          }
        }
        return false;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 250,
      render: (value: string, goldenBasketCompany: GoldenBasketCompany) => {
        const { company } = goldenBasketCompany;
        return (
          <FlexDiv>
            <div title="Manage the tags for this Company">
              <TagWidget
                companyTags={company.companyTags}
                companyUUID={company.uuid}
                companyName={company.name}
                tagCompanySource={tagCompanySource}
              >
                <ActionButton
                  style={{ justifyContent: "self-end" }}
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <TagTwoTone color="--var(primary-color)" />
                </ActionButton>
              </TagWidget>
            </div>
            <div title="Manage to which lists this Company belongs to">
              <ListWidget
                companyLists={company.lists}
                companyUUID={company.uuid}
                companyName={company.name}
                listCompanySource={listCompanySource}
              >
                <ActionButton
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ListIconBlue />
                </ActionButton>
              </ListWidget>
            </div>
            <div
              title={
                "By following a Company, you will be notified each time Novable detects new information, contextual data or changes about that Company"
              }
            >
              <TableFollowButton
                GBCompanyUUID={goldenBasketCompany.uuid}
                isFollowed={goldenBasketCompany.followed}
              />
            </div>
            {/* Commented due to unmanaged notfications, needs fix.
              TODO: Change notification so that there's not a notification for each button
            */}
            {/* <>
              <Tooltip
                placement="left"
                title="Share per email a Company and its information"
                trigger={"hover"}
              >
                <TableShareButton
                  goldenBasketCompanyUUID={goldenBasketCompany.uuid}
                />
              </Tooltip>
            </> */}
          </FlexDiv>
        );
      },
    },
  ];
};

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TableLogo = styled.img`
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 1rem;
`;

const ClickableCompanyName = styled.a`
  text-decoration: none;
  text-decoration: inherit;
  color: inherit;

  &:hover {
    text-decoration: none;
    text-decoration: inherit;
    color: inherit;
  }

  &:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }
`;

const OpenInNewWindowIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  background-image: url(${openInNewWindowIcon});
`;

const GreenBackgroundLikeFilled = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #5DB75D;
  border-radius: 60%; // Makes the background rounded, adjust as necessary
  padding: 5px;

  svg {
    color: white;
  }
`;

const RedBackgroundDislikeFilled = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ff4d4f;
  border-radius: 60%; // Makes the background rounded, adjust as necessary
  padding: 5px;

  svg {
    color: white;
  }
`;
