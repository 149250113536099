import { FC } from "react";
import styled from "@emotion/styled";

import { TagOutlined } from "@ant-design/icons";
import { Section, SectionHeader, SectionTitle } from "../../ui-share";
import { DisplayTags } from "./DisplayTags";

interface TagsProps {
  tags: string[];
  tagsLimit?: number;
}

export const Tags: FC<TagsProps> = ({ tags, tagsLimit }) => {
  const nonEmptyTags = tags.filter((x) => Boolean(x.trim()));
  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Tags</SectionTitle>
      </SectionHeader>
      {nonEmptyTags.length > 0 ? (
        <DisplayTags tags={nonEmptyTags} tagsLimit={tagsLimit} />
      ) : (
        <></>
      )}
    </Section>
  );
};
