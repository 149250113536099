import { FC } from "react";

import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { EmptyChartResult } from "../EmptyChartResult";

import { Dictionary } from "../../../share/types";
import { colorScale, getMapChartNormalizedData } from "../../../helpers";

import WorldMapWithoutAntartica from "../../../assets/maps/worldMapWithoutAntartica.json";

interface MapChartProps {
  data: Dictionary<number>;
  setTooltipContent: (value: string) => void;
}

export const MapChart: FC<MapChartProps> = ({ data, setTooltipContent }) => {
  if (Object.keys(data).length < 1) {
    return <EmptyChartResult />;
  }

  const normalizedData = getMapChartNormalizedData(data);

  return (
    <>
      <ComposableMap
        data-tip=""
        projectionConfig={{ scale: 100, rotate: [0, 0, 0] }}
        projection="geoMercator"
      >
        <Geographies geography={WorldMapWithoutAntartica}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const d = normalizedData[geo.properties.ISO_A3];
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d ? colorScale(d) : "#d3d4dc"}
                  onMouseEnter={() => {
                    const { NAME, ISO_A3 } = geo.properties;
                    const tooltipContent = data[ISO_A3]
                      ? `${NAME} — ${data[ISO_A3]}`
                      : "";
                    setTooltipContent(tooltipContent);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </>
  );
};
