import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";

// User identification happens only when they have selected a client

/**
 * Singleton class for Segment analytics
 *
 * Class needs to be instantiated first in index.html by calling initialize
 *
 * Example:
 * Segment.initialize();
 *
 */

// To reduce "noise" in test trackers environments, Segment is only
// allowed in PROD and LOCAL,
const SEGMENT_ENABLED_ENVIRONMENTS = ["PRODUCTION", "LOCAL"];

class _Segment {
  private static _instance: _Segment;
  private _analytics: Analytics | undefined;

  private constructor() {
    this._analytics = undefined;
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public get analytics() {
    return this._analytics;
  }

  initialize = () => {
    // To reduce "noise" in test trackers environments, Segment is only
    // allowed in PROD and LOCAL,
    const environment = process.env.REACT_APP_ENV || "";
    if (!SEGMENT_ENABLED_ENVIRONMENTS.includes(environment)) {
      console.warn("Segment won't be created in this Environment");
      return;
    }

    const writeKey = process.env.REACT_APP_SEGMENT_ANALYTICS_KEY;
    if (!writeKey) {
      console.warn("No segment key configured.");
      return;
    }

    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({ writeKey });
      this._analytics = response;
    };

    loadAnalytics();
  };
}

export const Segment = _Segment.Instance;
export const initializeSegment = Segment.initialize;
