import { useState, FC } from "react";
import { Input } from "antd";
import styled from "@emotion/styled";

import { State } from "../../../../../utils/store";
import {
  CardHeadTitle,
  SmallParagraph,
} from "../../../../../ui-components/Typography";
import {
  CloseIcon,
  DecissionButton,
  DecissionButtonsContainer,
} from "../../ui-share";

import {
  submitFeedback,
  filteredGBCompanySelector,
  FEEDBACK_COMMENT_MAX_NUMBER_CHARACTERS,
} from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import { ListCompanySource } from "../../../../../list/store";
import { AddToListDecissionButton } from "./AddToListDecissionButton";

const { TextArea } = Input;

interface MatchPopOverProps {
  hidePopover: () => void;
  campaignUUID: string;
}
export const MatchPopover: FC<MatchPopOverProps> = ({
  hidePopover,
  campaignUUID,
}) => {
  const dispatch = useAppDispatch();
  const [comment, setComment] = useState("");
  const { loading } = useAppSelector(
    (state: State) => state.goldenBasket.submitFeedback
  );
  const GBCompany = useAppSelector(filteredGBCompanySelector);

  let company;
  if (GBCompany) company = GBCompany.company;

  function handleSetComment(e: any) {
    setComment(e.target.value);
  }

  function submitPositiveFeedback() {
    if (GBCompany && campaignUUID) {
      const feedbackData = {
        isMatch: true,
        companyUUID: GBCompany.uuid,
        campaignUUID: campaignUUID,
        comment: comment,
        type: null,
      };
      dispatch(submitFeedback(feedbackData));
    }
  }

  return (
    <PopoverContent style={{ position: "relative" }}>
      <Close onClick={hidePopover}>
        <CloseIcon />
      </Close>
      <PopperTitle>
        <CardHeadTitle>It fits the briefing</CardHeadTitle>
      </PopperTitle>
      <CommentContainer>
        <SmallParagraph>
          Would you like to add a comment to your review? It's not mandatory
        </SmallParagraph>

        <TextArea
          showCount
          maxLength={FEEDBACK_COMMENT_MAX_NUMBER_CHARACTERS}
          id="outlined-multiline-static"
          rows={3}
          style={{ resize: "none" }}
          value={comment}
          onChange={handleSetComment}
          placeholder="Start typing here..."
        />
      </CommentContainer>
      <DecissionButtonsContainer>
        {company && (
          <AddToListDecissionButton
            companyName={company.name}
            companyLists={company.lists}
            companyUUID={company.uuid}
            listCompanySource={ListCompanySource.MATCH}
          />
        )}
        <DecissionButton disabled={loading} onClick={submitPositiveFeedback}>
          Confirm
        </DecissionButton>
      </DecissionButtonsContainer>
    </PopoverContent>
  );
};

const Close = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
  padding: 0.5rem;
  cursor: pointer;
`;

const PopoverContent = styled.div`
  width: 40rem;
`;

const PopperTitle = styled.div`
  width: 100%;
  padding: 1rem;
`;

const CommentContainer = styled.div`
  padding: 1rem;
`;
