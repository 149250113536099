import { useEffect } from "react";
import styled from "@emotion/styled";
import { Input } from "antd";

import { ListSwitch } from "./ListSwitch";
import { errorNotification } from "../../../../ui-components/Notification";
import {
  createList,
  ListCompanySource,
  listsOfCompanies,
} from "../../../../list/store";
import { State } from "../../../../utils/store";
import { Segment } from "../../../../utils/Segment";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { Button } from "../../../../ui-components/Button";

export const ListWidgetContent = (
  companyLists: string[],
  companyUUID: string,
  companyName: string,
  listCompanySource: ListCompanySource,
  filter: string,
  setFilter: (value: string) => void
) => {
  const dispatch = useAppDispatch();
  const clientLists = useAppSelector(listsOfCompanies);
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const { loading, error } = useAppSelector(
    (state: State) => state.lists.createList
  );

  let filteredLists = [...clientLists];

  if (filter) {
    const lowerCaseFilter = filter.toLowerCase();
    filteredLists = filteredLists.filter((x: any) =>
      x.name.toLowerCase().includes(lowerCaseFilter)
    );
  }

  const handleCreateList = (listName: string) => {
    if (!selectedClient) return;
    dispatch(createList({ clientUUID: selectedClient.uuid, name: listName }));
    Segment.analytics?.track("create-list", {
      client: selectedClient.name,
      clientUUID: selectedClient.uuid,
      listName: listName,
      source: "list-widget",
    });
  };

  useEffect(() => {
    if (error) errorNotification(error);
  }, [error]);

  // Stop propagation to prevent the company detail page loading on any click
  return (
    <WidgetContainer onClick={(e) => e.stopPropagation()}>
      <WidgetListSearchContainer>
        <Input
          allowClear
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          autoFocus={true}
        ></Input>
      </WidgetListSearchContainer>
      <WidgetListContainer>
        {filteredLists.length > 0 ? (
          filteredLists.map((list) => {
            return (
              <ListItem key={list.uuid + list.order + list.updatedAt}>
                <ListName>{list.name}</ListName>
                <ListSwitch
                  switchInitialState={companyLists.indexOf(list.uuid) !== -1}
                  listUUID={list.uuid}
                  companyUUID={companyUUID}
                  companyName={companyName}
                  listCompanySource={listCompanySource}
                />
              </ListItem>
            );
          })
        ) : (
          <Button
            onClick={() => handleCreateList(filter)}
            loading={loading}
            disabled={loading}
          >
            Create {filter}
          </Button>
        )}
      </WidgetListContainer>
    </WidgetContainer>
  );
};

const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const WidgetListSearchContainer = styled.div``;

const WidgetListContainer = styled.div`
  max-height: 30vh;
  max-width: 25vw;
  min-width: 120px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;

const ListName = styled.div`
  max-width: 79%;
`;
