import styled from "@emotion/styled";
import { AiChat, useAsStreamAdapter, ComposerOptions } from '@nlux/react';
import { useState, useEffect } from "react";
import '@nlux/themes/nova.css';
import { Dict } from 'mixpanel-browser';
import { List } from "../../list/store";

import { sendFactory } from './send';
import { personas } from './personas';

import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { State } from "../../utils/store";


export const ChatBot = (config: Dict) => {
  const adapter = useAsStreamAdapter(sendFactory(config), [config]);
  const dispatch = useAppDispatch();

  const selectedUser = useAppSelector(
    (state: State) => state.auth.user
  );

  const selectedThread = useAppSelector(
    (state: State) => state.scottThreadList.selectedThread
  );

  const composerOptions: ComposerOptions = {
    autoFocus: true,
    disableSubmitButton: false,
    placeholder: "Ask Scott anything about innovative companies you are searching for...",
  };

  const initialConversation: any[] = selectedThread?.initial_conversation || [];
  const sortedInitialConversation: any[] = sortAndRemoveTimestamp(initialConversation);

  return (
    <Wrapper>
      <Container>
      {sortedInitialConversation.length === 0 ? (
          <AiChat
            key={selectedThread?.thread_uuid}
            adapter={adapter}
            personaOptions={personas}
            displayOptions={{ 
              themeId: 'custom-nova',
            }}
            composerOptions={composerOptions}
          />
        ) : (
          <AiChat
            key={selectedThread?.thread_uuid}
            adapter={adapter}
            personaOptions={personas}
            displayOptions={{ 
              themeId: 'custom-nova',
            }}
            composerOptions={composerOptions}
            initialConversation={sortedInitialConversation}
          />
      )}

      </Container>
      <p style={{
            textAlign: 'center', 
            color: 'var(--content-color-tertiary)', 
            marginTop: '10px',
          }}> 
          Keep in mind that Scott can make mistakes. To include Novable Experts, please create a Campaign.
        </p>
            {/* <p>
            What now? If you want more results, you can{" "}
            <strong>request a new Delivery</strong>. You can also{" "}
            <a href={`/${selectedClient?.slug}/new-campaign`}>
              start a new campaign
            </a>{" "}
            if your needs are different.
          </p> */}
    </Wrapper>
  );
};

type MessageDict = {
  role: string;
  message: string;
  timestamp: string;
};

function sortAndRemoveTimestamp(data: MessageDict[]): Omit<MessageDict, 'timestamp'>[] {
  try {
    const dataCopy = [...data];

    dataCopy.sort((a, b) => {
        const dateA = new Date(a.timestamp);
        const dateB = new Date(b.timestamp);
        return dateA.getTime() - dateB.getTime();
    });

    return dataCopy.map(({ timestamp, ...rest }) => rest);
  } catch (error) {
    console.error(error);
    return data;
  }
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
//   background: var(--background-color-secondary);
  overflow: hidden;
  padding: 5rem 5rem 1rem 5rem;
  nlux-PromptInput--BackgroundColor: green;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  background: var(--background-color-secondary);
  flex-direction: column;
  overflow: hidden;
  padding: 0 0 0 0;
`;

const paragraphStyle: React.CSSProperties = {
  textAlign: 'center',
  color: 'blue',
};