import { useEffect } from "react";

import { Table } from "antd";

import { CenteredErrorMessage } from "../../../ui-components/CenteredErrorMessage";

import { getUserList } from "../../store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { columns } from "./columns/columns";

export const UserManagementTable = () => {
  const dispatch = useAppDispatch();

  const { userList } = useAppSelector((state: State) => state.userManagement);
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const { loading, error } = useAppSelector(
    (state: State) => state.userManagement.getUserList
  );

  useEffect(() => {
    if (selectedClient) dispatch(getUserList(selectedClient.uuid));
  }, [dispatch, selectedClient]);

  if (error) {
    return <CenteredErrorMessage>{error}</CenteredErrorMessage>;
  }

  const tableColumns = columns(selectedClient?.uuid);

  return (
    <Table
      dataSource={userList}
      columns={tableColumns}
      rowKey={(record: any) => (record && record.uuid) || ""}
      loading={loading}
    />
  );
};
