import { FC } from "react";
import styled from "@emotion/styled";

import { StylelessButton } from "../../ui-components/StylelessButton";
import { DashboardSectionTitle } from "../../ui-components/Typography";

interface ArchivePanelHeaderProps {
  onclick: () => void;
  activePanelKey: string;
}

export const ArchivePanelHeader: FC<ArchivePanelHeaderProps> = ({
  onclick,
  activePanelKey,
}) => {
  return (
    <Container>
      <DashboardSectionTitle>Archived campaigns</DashboardSectionTitle>
      <StylelessButton onClick={onclick}>
        {activePanelKey ? "Hide" : "Show"}
      </StylelessButton>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
