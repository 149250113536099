import { useEffect } from "react";

import { EditPasswordCard } from "./components/EditPasswordCard";
import { EditProfileCard } from "./components/EditProfileCard";
import { ContentSection } from "../ui-components/layout/UserLayout";

import { setInitialState } from "./store";
import { State } from "../utils/store";
import { useAppDispatch, useAppSelector } from "../utils/hooks";

export const ProfileView = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: State) => state.auth);
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, [dispatch]);

  return (
    <ContentSection>
      {user && (
        <EditProfileCard currentUser={user} clientUUID={selectedClient?.uuid} />
      )}
      <EditPasswordCard />
    </ContentSection>
  );
};
