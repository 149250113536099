import { useNavigate } from "react-router";
import styled from "@emotion/styled";

import { Tooltip } from "antd";
import { PlusCircleOutlined as BasePlusCircleOutlined } from "@ant-design/icons";

import { setCreateListModalVisibility } from "../../../list/store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { Segment } from "../../../utils/Segment";

export const CreateListIcon = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  function onClick(event: any) {
    // To avoid opening/closing the Campaigns section
    event.stopPropagation();
    Segment.analytics?.track("open-create-list", { source: "sidebar" });
    dispatch(setCreateListModalVisibility(true));
    navigate(`/${selectedClient?.slug}/list-management`);
  }

  return (
    <Tooltip title="Create a new list">
      <PlusCircleOutlined onClick={(e) => onClick(e)} />
    </Tooltip>
  );
};

const PlusCircleOutlined = styled(BasePlusCircleOutlined)`
  margin-left: 1rem;
`;
