import { FC, useEffect, useRef } from "react";
import { Formik } from "formik";

import { Spin } from "antd";
import { Button } from "../../../ui-components/Button";
import { FormikInput } from "../../../ui-components/FormikInput";
import { Modal } from "../../../ui-components/Modal";

import { createTag, setInitialCreateTagState } from "../../store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { EditTagSchema } from "./validation";
import { Segment } from "../../../utils/Segment";

interface CreateTagModalProps {
  visible: boolean;
  handleModalClose: () => void;
}

export const CreateTagModal: FC<CreateTagModalProps> = ({
  visible,
  handleModalClose,
}) => {
  const dispatch = useAppDispatch();
  const formikRef = useRef<any>();
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { loading, success } = useAppSelector(
    (state: State) => state.tags.createTag
  );

  useEffect(() => {
    return () => {
      dispatch(setInitialCreateTagState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      handleModalClose();
      formikRef.current.resetForm();
    }
  }, [success, handleModalClose]);

  return (
    <Formik
      initialValues={{
        name: "",
      }}
      onSubmit={(values, { resetForm }) => {
        const uuid = selectedClient?.uuid;
        if (!uuid) return;
        const data = {
          ...values,
          clientUUID: uuid,
        };
        dispatch(createTag(data));
        Segment.analytics?.track("create-tag", {
          source: "tag-management-view",
        });
      }}
      validationSchema={EditTagSchema}
      innerRef={(p) => (formikRef.current = p)}
    >
      {({ handleSubmit }) => (
        <form>
          <Modal
            title="Create tag"
            visible={visible}
            handleSubmit={handleSubmit}
            onCancel={handleModalClose}
            afterClose={() => dispatch(setInitialCreateTagState())}
            footer={[
              <Button key="cancel" onClick={handleModalClose}>
                Cancel
              </Button>,
              <Button
                key="confirm"
                htmlType="submit"
                onClick={() => handleSubmit()}
              >
                {loading ? (
                  <>
                    Sending &nbsp; <Spin />
                  </>
                ) : (
                  "Confirm"
                )}
              </Button>,
            ]}
          >
            <FormikInput
              name="name"
              type="name"
              label="Name"
              ariaLabel="name"
              placeholder=""
              isRequired={true}
            />
          </Modal>
        </form>
      )}
    </Formik>
  );
};
