import { FC } from "react";
import { NavLink as BaseNavLink } from "react-router-dom";
import styled from "@emotion/styled";

import { Header, SectionTitle, NovableLogo, Logo } from "./components";
import { ExportGoldenBasketButton } from "./components/ExportGoldenBasketButton";
import { PoweredParagraph } from "../../Typography";

import { Campaign } from "../../../share/campaigns/store";
import { State } from "../../../utils/store";
import { useAppSelector } from "../../../utils/hooks";

export interface CampaignTopBarProps {
  campaign: Campaign;
}

export const CampaignTopBar: FC<CampaignTopBarProps> = ({ campaign }) => {
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  return (
    <Header style={{ paddingLeft: 0 }}>
      <CampaignInformation>
        <StyledSectionTitle>{campaign.name}</StyledSectionTitle>
      </CampaignInformation>
      <Tabs>
        <NavLink to={`/${selectedClient?.slug}/campaign/home/${campaign.slug}`}>
          Home
        </NavLink>
        <NavLink
          to={`/${selectedClient?.slug}/campaign/golden-basket/${campaign.slug}`}
        >
          Golden Basket
        </NavLink>
        <NavLink
          to={`/${selectedClient?.slug}/campaign/insights/${campaign.slug}`}
        >
          Insights
        </NavLink>
      </Tabs>

      <ExportGoldenBasketButton />

      <NovableLogo>
        <Logo />
      </NovableLogo>
    </Header>
  );
};

// Temporary
const CampaignInformation = styled.div`
  display: flex;
  height: 100%;
`;

const StyledSectionTitle = styled(SectionTitle)`
  line-height: 2.8rem;
  margin-top: 1.4rem;
  align-self: flex-start;
  margin-left: 1.6rem;
`;

const Tabs = styled.nav`
  height: 2rem;
  position: absolute;
  top: 2.6rem;
  padding: 0;
  margin: 0;
`;

const NavLink = styled(BaseNavLink)`
  padding: 0.6rem 1.2rem;
  font-size: 1.5rem;

  &.active {
    background: white;
    border: none;
    border-radius: var(--rightbar-button-border-radius);
    color: var(--secondary-color);
    font-weight: var(--font-weight-bold);
    border-bottom: 2px solid
  }
`;
