import { useParams, useLocation } from "react-router";

import { ClientsView } from "./ClientsView";
import { ClientView } from "./ClientView";

// This manager solves the dashboard redirection issue. This issue was an old implementation
// that caused a by-default redirection from the ClientsView (once a client was selected)
// to /dashboard. To eliminate that, client slug was added to the URL, however, the need
// for a manager between Client and ClientS views arose.
export const ClientManagerView = () => {
  const { clientSlug } = useParams<{ clientSlug: string }>();
  const location = useLocation();
  const locationState: any = location?.state;
  const locationSlug = locationState?.slug;

  if (clientSlug || locationSlug) {
    return <ClientView />;
  } else {
    return <ClientsView />;
  }
};
