import styled from "@emotion/styled";
import { Button, Spin, Tag as BaseTag } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { Button as BaseButton } from "../../../../ui-components/Button";
import foundedIcon from "../../../../assets/icons/founded.svg";
import twitterIcon from "../../../../assets/icons/twitter.svg";

export const FoundedIcon = styled.div`
  background: url(${foundedIcon});
  width: 1.533rem;
  height: 1.333rem;
  margin-right: 0.8rem;
`;

export const TwitterIcon = styled.div`
  background: url(${twitterIcon});
  width: 1.533rem;
  height: 1.333rem;
  margin-right: 0.8rem;
  background-repeat: no-repeat;
`;

export const Section = styled.div`
  border-bottom: var(--separator-border);
`;

export const SectionHeader = styled.div`
  margin-bottom: var(--gb-header-bottom-margin);
  padding: 0;
  display: flex;
  align-items: center;
`;

export const SectionContent = styled.div`
  font-size: 1.2rem;
  line-height: 2rem;
`;

export const SectionTitle = styled.h6`
  font-color: var(--font-color-black);
  font-size: var(--gb-content-titles-font-size);
  line-height: 3rem;
  font-weight: var(--font-weight-bold-strong);
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`;

export const SubSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.2rem;
  border: var(--separator-border);
  border-radius: 0.5rem;
`;

export const CenteredSpinner = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionButton = styled(Button)`
  background-color: var(--white);
  border: var(--campaign-card-border);

  &:hover,
  &:active,
  &:focus {
    background-color: var(--primary-color-hover);
    border: var(--button-border);
  }
`;

export const Tag = styled(BaseTag)`
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
`;

export const CloseIcon = styled(PlusOutlined)`
  transform: rotate(45deg);
`;

export const DecissionButtonsContainer = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  padding: 1rem;
`;

export const DecissionButton = styled(BaseButton)`
  width: 100%;
`;
