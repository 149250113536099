import { TagManagementActions } from "./actions/TagManagementTableActions";

export const columns = () => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Creator",
    dataIndex: "createdBy",
    key: "createdBy",
    render: (value: string, record: any) => {
      return record.createdBy || "";
    },
    ellipsis: true,
  },
  {
    title: "Actions",
    key: "actions",
    render: (value: string, record: any) => {
      return (
        <TagManagementActions tagUUID={record.uuid} tagName={record.name} />
      );
    },
  },
];
