import { FC } from "react";
import styled from "@emotion/styled";

import { NewsArticle } from "../../../store";

import { Section, SectionHeader, SectionTitle } from "../../ui-share";

import newsIcon from "../../../../../assets/icons/news.svg";

import { cleanUrl, openLinkInNewTabAndTrack } from "../../../../../helpers";

interface NewsProps {
  news: NewsArticle[];
}

const MAX_ARTICLES = 5;

export const News: FC<NewsProps> = ({ news }) => {
  const trackNewsClickEvent: string = "click-news-article";

  const parsedNews = news.map(article => ({
    ...article,
    date: new Date(article.date)
  }));

  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Company news</SectionTitle>
      </SectionHeader>
      <NewsContainer>
        {parsedNews.slice(0, MAX_ARTICLES).map((newsArticle, index) => (
          <Card key={index}>
            <ArticleHeader>
              <ArticleDate>{newsArticle.date.toLocaleDateString()}: </ArticleDate>

              <ArticleTitle
                href={newsArticle.articleUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(event) =>
                  openLinkInNewTabAndTrack(
                    event,
                    newsArticle.articleUrl,
                    trackNewsClickEvent
                  )
                }
                aria-label={`Link to article with url: ${newsArticle.articleUrl}`}
                key={index}
              >
                &nbsp;{newsArticle.title}
              </ArticleTitle>
            </ArticleHeader>
            <MetadataItem>{cleanUrl(newsArticle.sourceUrl)}</MetadataItem>
          </Card>
        ))}
      </NewsContainer>
    </Section>
  );
};

const Card = styled.div`
  margin-left: var(--gb-content-section-left-margin);
`;

const ArticleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
`;

const MetadataItem = styled.p`
  font-size: var(--gb-content-font-size);
  font-weight: var(--font-weight-bold);
  line-height: 1.8rem;
  letter-spacing: 0em;
  margin: 0;
  text-align: left;
  color: var(--font-color-campaign-stats);
`;

const ArticleTitle = styled.a`
  font-size: var(--gb-content-font-size);
  cursor: pointer;
  align-items: normal;
  border-color: var(--primary-color);
  border-style: none;
  color: var(--primary-color);
  cursor: pointer;
  display: inline;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  -webkit-logical-height: auto; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */

  /* Mozilla uses a pseudo-element to show focus on buttons, */
  /* but anchors are highlighted via the focus pseudo-class. */
`;

const ArticleDate = styled.div`
  font-size: var(--gb-content-font-size);
`;

const NewsContainer = styled.div`
  border-radius: 0.5rem;
`;

