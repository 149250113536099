import { FC, useEffect } from "react";

import { Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  successNotification,
  errorNotification,
} from "../../../../../../ui-components/Notification";
import { StylelessButton } from "../../../../../../ui-components/StylelessButton";
import { StyledButtonText } from "../../../../../../ui-components/Typography";

import {
  deleteUser,
  filteredUserInList,
  isNumberOfAdminsInUserListLessThanTwo,
} from "../../../../../store";
import { State } from "../../../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../../../utils/hooks";

const { confirm } = Modal;

interface DeleteUserButtonProps {
  userUUID: string;
  clientUUID: string | undefined;
}

/**
 *  Based on the business logic, this is how the delete-user works
 *
 *  - Only admins can do it
 *  - If the user is the only admin, they cannot request to delete themselves
 *  - If the user has already been requested to be deleted, it cannot be done again
 */
export const DeleteUserButton: FC<DeleteUserButtonProps> = ({
  userUUID,
  clientUUID,
}) => {
  const dispatch = useAppDispatch();

  const { success, loading, error, uuidRequested } = useAppSelector(
    (state: State) => state.userManagement.deleteUser
  );
  const { user } = useAppSelector((state: State) => state.auth);

  const userInList = useAppSelector(filteredUserInList(userUUID));
  const isNumberOfAdminsLessThanTwo = useAppSelector(
    isNumberOfAdminsInUserListLessThanTwo()
  );

  const deleteRequestForUser = userUUID === uuidRequested;

  const handleDelete = () => {
    if (!clientUUID) return;
    const data = {
      userUUID: userUUID,
      clientUUID: clientUUID,
    };
    dispatch(deleteUser(data));
  };

  const showConfirm = () => {
    confirm({
      title: "Do you want to delete this user?",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to delete the account for ${userInList?.email}?`,
      okText: "Confirm",
      okButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      onOk() {
        handleDelete();
      },
    });
  };

  useEffect(() => {
    if (!deleteRequestForUser) return;

    if (success) {
      successNotification(`User deletion request submitted successfully`);
    } else if (success === false && error) {
      errorNotification(error);
    }
  }, [deleteRequestForUser, success, error]);

  const isLoading = loading && deleteRequestForUser;

  let displayTooltip = false;
  let tooltipMessage;

  if (userInList?.requestedToBeDeleted) {
    displayTooltip = true;
    tooltipMessage = "This user has been requested to be deleted already";
  } else if (user?.uuid === userUUID && isNumberOfAdminsLessThanTwo) {
    displayTooltip = true;
    tooltipMessage =
      "You cannot delete yourself since you are the only active admin of your platform";
  }

  const isDisabled = isLoading || displayTooltip;

  return (
    <>
      {displayTooltip ? (
        <Tooltip title={tooltipMessage}>
          <span style={{ cursor: "not-allowed" }}>
            <StylelessButton disabled={true} style={{ pointerEvents: "none" }}>
              {" "}
              <StyledButtonText disabled={true}>Delete</StyledButtonText>
            </StylelessButton>
          </span>
        </Tooltip>
      ) : (
        <StylelessButton
          disabled={isDisabled}
          loading={isLoading}
          onClick={showConfirm}
        >
          <StyledButtonText>Delete</StyledButtonText>
        </StylelessButton>
      )}
    </>
  );
};
