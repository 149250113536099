import { useEffect, useCallback, useRef } from "react";
import { useFormikContext } from "formik";
import { debounce, isEqual } from "lodash";

import { getReadyCampaignData } from "../NewCampaignView";
import { saveDraftCampaign } from "../store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import { initialValues, FormValues } from "../NewCampaignView";

interface AutoSaveDraftProps {
  disableAutoSave?: boolean;
}

export const AutoSaveDraft: React.FC<AutoSaveDraftProps> = ({ 
  disableAutoSave = false,
}) => {
  const dispatch = useAppDispatch();
  const formik = useFormikContext<FormValues>();
  const lastSavedNameRef = useRef("");
  const lastSavedValuesRef: any = useRef(initialValues);

  const isDefaultName: RegExp = /^Campaign Title(\s\d+)?$/
  
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { name } = useAppSelector((state: State) => state.newCampaign.draftCampaign);
  const { clusters } = useAppSelector((state: State) => state.newCampaign);

  useEffect(() => {
    lastSavedNameRef.current = name;
    lastSavedValuesRef.current = formik.values;
  }
  , [formik?.values?.uuid]);

  const debouncedSaveDraft = useRef(
    debounce((data) => dispatch(saveDraftCampaign(data)), 500)
  ).current;

  const autoSaveDraft = useCallback(
    () => {

      if (disableAutoSave || !selectedClient || !name) return;

      // Prevents autosave at New Campaign creation:
      if (isDefaultName.test(name) && !formik.dirty) {
        return;
      }
      
      // Prevents autosave when swithing between campaigns:
      if (name === lastSavedNameRef.current && !formik.dirty) {
        return;
      }

      // Note: name is separate Input, not part of the formik values
      if (isEqual(formik.values, lastSavedValuesRef.current) && name === lastSavedNameRef.current) {
        return;
      }
      
      const readyData = getReadyCampaignData(formik.values, selectedClient, name, clusters);
      debouncedSaveDraft(readyData);
      lastSavedNameRef.current = name;
      lastSavedValuesRef.current = formik.values;
    },
    [formik.values, name, selectedClient, clusters, dispatch]
  );

  useEffect(() => {
    autoSaveDraft();
  }, [autoSaveDraft]);

  return (
    <>
    </>
  );
};
