import { Modal } from "antd";

export function warningNoMoreCampaignsAvailable() {
  Modal.warning({
    title: "No more campaigns available",
    content:
      "You have reached the maximum available number of campaigns. If you would like to create more, please contact us ( support@novable.com ).",
  });
}

export function warningNoMoreDraftsAvailable() {
  Modal.warning({
    title: "Publish your drafts to get your Golden Basket",
    content:
      "You have reached the limit of 20 drafts. Publish or delete your campaigns to continue",
  });
}
