import { StreamSend, StreamingAdapterObserver, ChatAdapterExtras } from '@nlux/react';
import { Dict } from 'mixpanel-browser';
import { RemoteRunnable } from "@langchain/core/runnables/remote";
import { Segment } from '../../utils/Segment';

const scottLangServeUrl: string | undefined = process.env.REACT_APP_SCOTT_BACKEND_URL || '';

export const sendFactory = (config: Dict): StreamSend => {
    const agent = new RemoteRunnable({
        url: scottLangServeUrl + '/scott',
        options: {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_LANGSERVE_AUTH_TOKEN}`,
            },
        },
    });

    return async (
        prompt: string,
        observer: StreamingAdapterObserver,
        extras: ChatAdapterExtras,
    ) => {
        try {
            if (config?.config?.limitScott > 0 || config?.config?.limitScott === null) {
                const eventStream = await agent.streamEvents(
                    [{"role": "human", "content": prompt}],
                    {
                        version: "v1",
                        configurable: {
                            'thread_id': config?.config?.thread_id,
                        },
                        metadata: {
                            'client_id': config?.config?.client_uuid,
                            'user_id': config?.config?.user_uuid,
                        }

                    }
                );
                
                let canStream = false;
                let graphStep = '';
                for await (const event of eventStream) {
                    try {
                        let kind = event.event;

                        if (event.name === "input_validator_agent" ) {
                            canStream = false;
                            graphStep = 'input_validator_agent';
                        } else if (event.name === "output_validator_agent") {
                            canStream = false;
                            graphStep = 'output_validator_agent';
                        } else if (event.name === "planning_agent") {
                            canStream = true;
                            graphStep = 'planning_agent';
                        } else if (event.name === "tools") {
                            canStream = false;
                            graphStep = 'tools';
                        } else if (event.name === "add_feedback") {
                            canStream = false;
                            graphStep = 'add_feedback';
                        }

                        if (kind === "on_chat_model_stream" && event.data.chunk.content) {
                            let next_chunk = event.data.chunk.content;
                            if (canStream) {
                                observer.next(next_chunk);
                            }
                        } else if (kind === "on_chain_stream" && event.data.chunk.hasOwnProperty("input_validator_agent") && event.data.chunk.input_validator_agent.content) {
                            const content = event.data.chunk.input_validator_agent.content;
                            observer.next(content);  
                        }
                    } catch (error) {
                        console.error('Error in event processing:', error);
                    }
                }

                Segment.analytics?.track('Scott message sent', {
                    'client_uuid': config?.config?.client_uuid,
                });
            } else {
                observer.next('To get access to Scott, please contact support@novable.com');

                Segment.analytics?.track('Scott: message without access', {
                    'client_uuid': config?.config?.client_uuid,
                });
            }
        } catch (error) {
            console.error('eventStream loop error:', error);
        } finally {
            observer.complete();
        }
    };
};
