export const countries = [
  {
    title: "Europe",
    value: 0,
    children: [
      {
        title: "Åland Islands",
        continent: "Europe",
        value: "ALA",
      },
      {
        title: "Albania",
        continent: "Europe",
        value: "ALB",
      },
      {
        title: "Andorra",
        continent: "Europe",
        value: "AND",
      },
      {
        title: "Austria",
        continent: "Europe",
        value: "AUT",
      },
      {
        title: "Belgium",
        continent: "Europe",
        value: "BEL",
      },
      {
        title: "Bulgaria",
        continent: "Europe",
        value: "BGR",
      },
      {
        title: "Bosnia and Herzegovina",
        continent: "Europe",
        value: "BIH",
      },
      {
        title: "Belarus",
        continent: "Europe",
        value: "BLR",
      },
      {
        title: "Switzerland",
        continent: "Europe",
        value: "CHE",
      },
      {
        title: "Cyprus",
        continent: "Europe",
        value: "CYP",
      },
      {
        title: "Czechia",
        continent: "Europe",
        value: "CZE",
      },
      {
        title: "Germany",
        continent: "Europe",
        value: "DEU",
      },
      {
        title: "Denmark",
        continent: "Europe",
        value: "DNK",
      },
      {
        title: "Spain",
        continent: "Europe",
        value: "ESP",
      },
      {
        title: "Estonia",
        continent: "Europe",
        value: "EST",
      },
      {
        title: "Finland",
        continent: "Europe",
        value: "FIN",
      },
      {
        title: "France",
        continent: "Europe",
        value: "FRA",
      },
      {
        title: "Faroe Islands",
        continent: "Europe",
        value: "FRO",
      },
      {
        title: "United Kingdom",
        continent: "Europe",
        value: "GBR",
      },
      {
        title: "Guernsey",
        continent: "Europe",
        value: "GGY",
      },
      {
        title: "Gibraltar",
        continent: "Europe",
        value: "GIB",
      },
      {
        title: "Greece",
        continent: "Europe",
        value: "GRC",
      },
      {
        title: "Croatia",
        continent: "Europe",
        value: "HRV",
      },
      {
        title: "Hungary",
        continent: "Europe",
        value: "HUN",
      },
      {
        title: "Isle of Man",
        continent: "Europe",
        value: "IMN",
      },
      {
        title: "Ireland",
        continent: "Europe",
        value: "IRL",
      },
      {
        title: "Iceland",
        continent: "Europe",
        value: "ISL",
      },
      {
        title: "Italy",
        continent: "Europe",
        value: "ITA",
      },
      {
        title: "Jersey",
        continent: "Europe",
        value: "JEY",
      },
      {
        title: "Kosovo",
        continent: "Europe",
        value: "UNK",
      },
      {
        title: "Liechtenstein",
        continent: "Europe",
        value: "LIE",
      },
      {
        title: "Lithuania",
        continent: "Europe",
        value: "LTU",
      },
      {
        title: "Luxembourg",
        continent: "Europe",
        value: "LUX",
      },
      {
        title: "Latvia",
        continent: "Europe",
        value: "LVA",
      },
      {
        title: "Monaco",
        continent: "Europe",
        value: "MCO",
      },
      {
        title: "Moldova",
        continent: "Europe",
        value: "MDA",
      },
      {
        title: "North Macedonia",
        continent: "Europe",
        value: "MKD",
      },
      {
        title: "Malta",
        continent: "Europe",
        value: "MLT",
      },
      {
        title: "Montenegro",
        continent: "Europe",
        value: "MNE",
      },
      {
        title: "Netherlands",
        continent: "Europe",
        value: "NLD",
      },
      {
        title: "Norway",
        continent: "Europe",
        value: "NOR",
      },
      {
        title: "Poland",
        continent: "Europe",
        value: "POL",
      },
      {
        title: "Portugal",
        continent: "Europe",
        value: "PRT",
      },
      {
        title: "Romania",
        continent: "Europe",
        value: "ROU",
      },
      {
        title: "Russia",
        continent: "Europe",
        value: "RUS",
      },
      {
        title: "Svalbard and Jan Mayen",
        continent: "Europe",
        value: "SJM",
      },
      {
        title: "San Marino",
        continent: "Europe",
        value: "SMR",
      },
      {
        title: "Serbia",
        continent: "Europe",
        value: "SRB",
      },
      {
        title: "Slovakia",
        continent: "Europe",
        value: "SVK",
      },
      {
        title: "Slovenia",
        continent: "Europe",
        value: "SVN",
      },
      {
        title: "Sweden",
        continent: "Europe",
        value: "SWE",
      },
      {
        title: "Ukraine",
        continent: "Europe",
        value: "UKR",
      },
      {
        title: "Vatican City",
        continent: "Europe",
        value: "VAT",
      },
    ],
  },
  {
    title: "North America",
    value: 1,
    children: [
      {
        title: "Aruba",
        continent: "North America",
        value: "ABW",
      },
      {
        title: "Anguilla",
        continent: "North America",
        value: "AIA",
      },
      {
        title: "Antigua and Barbuda",
        continent: "North America",
        value: "ATG",
      },
      {
        title: "Bahamas",
        continent: "North America",
        value: "BHS",
      },
      {
        title: "Barbados",
        continent: "North America",
        value: "BRB",
      },
      {
        title: "Belize",
        continent: "North America",
        value: "BLZ",
      },
      {
        title: "Bermuda",
        continent: "North America",
        value: "BMU",
      },
      {
        title: "British Virgin Islands",
        continent: "North America",
        value: "VGB",
      },
      {
        title: "Canada",
        continent: "North America",
        value: "CAN",
      },
      {
        title: "Costa Rica",
        continent: "North America",
        value: "CRI",
      },
      {
        title: "Cuba",
        continent: "North America",
        value: "CUB",
      },
      {
        title: "Cayman Islands",
        continent: "North America",
        value: "CYM",
      },
      {
        title: "Dominica",
        continent: "North America",
        value: "DMA",
      },
      {
        title: "Dominican Republic",
        continent: "North America",
        value: "DOM",
      },
      {
        title: "El Salvador",
        continent: "North America",
        value: "SLV",
      },
      {
        title: "Guadeloupe",
        continent: "North America",
        value: "GLP",
      },
      {
        title: "Guatemala",
        continent: "North America",
        value: "GTM",
      },
      {
        title: "Greenland",
        continent: "North America",
        value: "GRL",
      },
      {
        title: "Honduras",
        continent: "North America",
        value: "HND",
      },
      {
        title: "Haiti",
        continent: "North America",
        value: "HTI",
      },
      {
        title: "Jamaica",
        continent: "North America",
        value: "JAM",
      },
      {
        title: "Mexico",
        continent: "North America",
        value: "MEX",
      },
      {
        title: "Montserrat",
        continent: "North America",
        value: "MSR",
      },
      {
        title: "Martinique",
        continent: "North America",
        value: "MTQ",
      },
      {
        title: "Nicaragua",
        continent: "North America",
        value: "NIC",
      },
      {
        title: "Panama",
        continent: "North America",
        value: "PAN",
      },
      {
        title: "Puerto Rico",
        continent: "North America",
        value: "PRI",
      },
      {
        title: "Saint Kitts and Nevis",
        continent: "North America",
        value: "KNA",
      },
      {
        title: "Saint Lucia",
        continent: "North America",
        value: "LCA",
      },

      {
        title: "Turks and Caicos Islands",
        continent: "North America",
        value: "TCA",
      },
      {
        title: "Trinidad and Tobago",
        continent: "North America",
        value: "TTO",
      },
      {
        title: "United States Minor Outlying Islands",
        continent: "North America",
        value: "UMI",
      },

      {
        title: "United States",
        continent: "North America",
        value: "USA",
      },
      {
        title: "United States Virgin Islands",
        continent: "North America",
        value: "VIR",
      },
    ],
  },
  {
    title: "South America",
    value: 2,
    children: [
      {
        title: "Argentina",
        continent: "South America",
        value: "ARG",
      },
      {
        title: "Bolivia",
        continent: "South America",
        value: "BOL",
      },
      {
        title: "Brazil",
        continent: "South America",
        value: "BRA",
      },
      {
        title: "Chile",
        continent: "South America",
        value: "CHL",
      },
      {
        title: "Colombia",
        continent: "South America",
        value: "COL",
      },

      {
        title: "Ecuador",
        continent: "South America",
        value: "ECU",
      },
      {
        title: "Falkland Islands",
        continent: "South America",
        value: "FLK",
      },
      {
        title: "French Guiana",
        continent: "South America",
        value: "GUF",
      },
      {
        title: "Guyana",
        continent: "South America",
        value: "GUY",
      },
      {
        title: "Paraguay",
        continent: "South America",
        value: "PRY",
      },
      {
        title: "Peru",
        continent: "South America",
        value: "PER",
      },
      {
        title: "Uruguay",
        continent: "South America",
        value: "URY",
      },
      {
        title: "Venezuela",
        continent: "South America",
        value: "VEN",
      },
      {
        title: "Caribbean Netherlands",
        continent: "South America",
        value: "BES",
      },
      {
        title: "Curaçao",
        continent: "South America",
        value: "CUW",
      },
      {
        title: "Grenada",
        continent: "South America",
        value: "GRD",
      },
      {
        title: "Saint Barthélemy",
        continent: "South America",
        value: "BLM",
      },
      {
        title: "Saint Martin",
        continent: "South America",
        value: "MAF",
      },
      {
        title: "Saint Pierre and Miquelon",
        continent: "South America",
        value: "SPM",
      },
      {
        title: "Suriname",
        continent: "South America",
        value: "SUR",
      },
      {
        title: "Sint Maarten",
        continent: "South America",
        value: "SXM",
      },

      {
        title: "Saint Vincent and the Grenadines",
        continent: "South America",
        value: "VCT",
      },
    ],
  },
  {
    title: "Asia",
    value: 3,
    children: [
      {
        title: "Afghanistan",
        continent: "Asia",
        value: "AFG",
      },
      {
        title: "United Arab Emirates",
        continent: "Asia",
        value: "ARE",
      },
      {
        title: "Armenia",
        continent: "Asia",
        value: "ARM",
      },
      {
        title: "Azerbaijan",
        continent: "Asia",
        value: "AZE",
      },
      {
        title: "Bangladesh",
        continent: "Asia",
        value: "BGD",
      },
      {
        title: "Bahrain",
        continent: "Asia",
        value: "BHR",
      },
      {
        title: "Brunei",
        continent: "Asia",
        value: "BRN",
      },
      {
        title: "Bhutan",
        continent: "Asia",
        value: "BTN",
      },
      {
        title: "China",
        continent: "Asia",
        value: "CHN",
      },
      {
        title: "Georgia",
        continent: "Asia",
        value: "GEO",
      },
      {
        title: "Hong Kong",
        continent: "Asia",
        value: "HKG",
      },
      {
        title: "Indonesia",
        continent: "Asia",
        value: "IDN",
      },
      {
        title: "India",
        continent: "Asia",
        value: "IND",
      },
      {
        title: "Iran",
        continent: "Asia",
        value: "IRN",
      },
      {
        title: "Iraq",
        continent: "Asia",
        value: "IRQ",
      },
      {
        title: "Israel",
        continent: "Asia",
        value: "ISR",
      },
      {
        title: "Jordan",
        continent: "Asia",
        value: "JOR",
      },
      {
        title: "Japan",
        continent: "Asia",
        value: "JPN",
      },
      {
        title: "Kazakhstan",
        continent: "Asia",
        value: "KAZ",
      },
      {
        title: "Kyrgyzstan",
        continent: "Asia",
        value: "KGZ",
      },
      {
        title: "Cambodia",
        continent: "Asia",
        value: "KHM",
      },
      {
        title: "South Korea",
        continent: "Asia",
        value: "KOR",
      },
      {
        title: "Kuwait",
        continent: "Asia",
        value: "KWT",
      },
      {
        title: "Laos",
        continent: "Asia",
        value: "LAO",
      },
      {
        title: "Lebanon",
        continent: "Asia",
        value: "LBN",
      },
      {
        title: "Sri Lanka",
        continent: "Asia",
        value: "LKA",
      },
      {
        title: "Macau",
        continent: "Asia",
        value: "MAC",
      },
      {
        title: "Maldives",
        continent: "Asia",
        value: "MDV",
      },
      {
        title: "Myanmar",
        continent: "Asia",
        value: "MMR",
      },
      {
        title: "Mongolia",
        continent: "Asia",
        value: "MNG",
      },
      {
        title: "Malaysia",
        continent: "Asia",
        value: "MYS",
      },
      {
        title: "Nepal",
        continent: "Asia",
        value: "NPL",
      },
      {
        title: "Oman",
        continent: "Asia",
        value: "OMN",
      },
      {
        title: "Pakistan",
        continent: "Asia",
        value: "PAK",
      },
      {
        title: "Philippines",
        continent: "Asia",
        value: "PHL",
      },
      {
        title: "North Korea",
        continent: "Asia",
        value: "PRK",
      },
      {
        title: "Palestine",
        continent: "Asia",
        value: "PSE",
      },
      {
        title: "Qatar",
        continent: "Asia",
        value: "QAT",
      },
      {
        title: "Saudi Arabia",
        continent: "Asia",
        value: "SAU",
      },
      {
        title: "Singapore",
        continent: "Asia",
        value: "SGP",
      },
      {
        title: "Syria",
        continent: "Asia",
        value: "SYR",
      },
      {
        title: "Thailand",
        continent: "Asia",
        value: "THA",
      },
      {
        title: "Tajikistan",
        continent: "Asia",
        value: "TJK",
      },
      {
        title: "Turkmenistan",
        continent: "Asia",
        value: "TKM",
      },
      {
        title: "Timor-Leste",
        continent: "Asia",
        value: "TLS",
      },
      {
        title: "Turkey",
        continent: "Asia",
        value: "TUR",
      },
      {
        title: "Taiwan",
        continent: "Asia",
        value: "TWN",
      },
      {
        title: "Uzbekistan",
        continent: "Asia",
        value: "UZB",
      },
      {
        title: "Vietnam",
        continent: "Asia",
        value: "VNM",
      },
      {
        title: "Yemen",
        continent: "Asia",
        value: "YEM",
      },
    ],
  },
  {
    title: "Oceania",
    value: 4,
    children: [
      {
        title: "American Samoa",
        continent: "Oceania",
        value: "ASM",
      },
      {
        title: "Australia",
        continent: "Oceania",
        value: "AUS",
      },
      {
        title: "Cocos (Keeling) Islands",
        continent: "Oceania",
        value: "CCK",
      },
      {
        title: "Cook Islands",
        continent: "Oceania",
        value: "COK",
      },
      {
        title: "Christmas Island",
        continent: "Oceania",
        value: "CXR",
      },
      {
        title: "Fiji",
        continent: "Oceania",
        value: "FJI",
      },
      {
        title: "Micronesia",
        continent: "Oceania",
        value: "FSM",
      },
      {
        title: "Guam",
        continent: "Oceania",
        value: "GUM",
      },
      {
        title: "Kiribati",
        continent: "Oceania",
        value: "KIR",
      },
      {
        title: "Marshall Islands",
        continent: "Oceania",
        value: "MHL",
      },
      {
        title: "Northern Mariana Islands",
        continent: "Oceania",
        value: "MNP",
      },
      {
        title: "New Caledonia",
        continent: "Oceania",
        value: "NCL",
      },
      {
        title: "Norfolk Island",
        continent: "Oceania",
        value: "NFK",
      },
      {
        title: "Niue",
        continent: "Oceania",
        value: "NIU",
      },
      {
        title: "Nauru",
        continent: "Oceania",
        value: "NRU",
      },
      {
        title: "New Zealand",
        continent: "Oceania",
        value: "NZL",
      },
      {
        title: "Pitcairn Islands",
        continent: "Oceania",
        value: "PCN",
      },
      {
        title: "Palau",
        continent: "Oceania",
        value: "PLW",
      },
      {
        title: "Papua New Guinea",
        continent: "Oceania",
        value: "PNG",
      },
      {
        title: "French Polynesia",
        continent: "Oceania",
        value: "PYF",
      },
      {
        title: "Solomon Islands",
        continent: "Oceania",
        value: "SLB",
      },
      {
        title: "Tokelau",
        continent: "Oceania",
        value: "TKL",
      },
      {
        title: "Tonga",
        continent: "Oceania",
        value: "TON",
      },
      {
        title: "Tuvalu",
        continent: "Oceania",
        value: "TUV",
      },
      {
        title: "Vanuatu",
        continent: "Oceania",
        value: "VUT",
      },
      {
        title: "Wallis and Futuna",
        continent: "Oceania",
        value: "WLF",
      },
      {
        title: "Samoa",
        continent: "Oceania",
        value: "WSM",
      },
    ],
  },
  {
    title: "Africa",
    value: 5,
    children: [
      {
        title: "Angola",
        continent: "Africa",
        value: "AGO",
      },
      {
        title: "Burundi",
        continent: "Africa",
        value: "BDI",
      },
      {
        title: "Benin",
        continent: "Africa",
        value: "BEN",
      },
      {
        title: "Burkina Faso",
        continent: "Africa",
        value: "BFA",
      },
      {
        title: "Saint Helena, Ascension and Tristan da Cunha",
        continent: "Africa",
        value: "SHN",
      },
      {
        title: "Botswana",
        continent: "Africa",
        value: "BWA",
      },
      {
        title: "Central African Republic",
        continent: "Africa",
        value: "CAF",
      },
      {
        title: "Ivory Coast",
        continent: "Africa",
        value: "CIV",
      },
      {
        title: "Cameroon",
        continent: "Africa",
        value: "CMR",
      },
      {
        title: "DR Congo",
        continent: "Africa",
        value: "COD",
      },
      {
        title: "Republic of the Congo",
        continent: "Africa",
        value: "COG",
      },
      {
        title: "Comoros",
        continent: "Africa",
        value: "COM",
      },
      {
        title: "Cape Verde",
        continent: "Africa",
        value: "CPV",
      },
      {
        title: "Djibouti",
        continent: "Africa",
        value: "DJI",
      },
      {
        title: "Algeria",
        continent: "Africa",
        value: "DZA",
      },
      {
        title: "Egypt",
        continent: "Africa",
        value: "EGY",
      },
      {
        title: "Eritrea",
        continent: "Africa",
        value: "ERI",
      },
      {
        title: "Western Sahara",
        continent: "Africa",
        value: "ESH",
      },
      {
        title: "Ethiopia",
        continent: "Africa",
        value: "ETH",
      },
      {
        title: "Gabon",
        continent: "Africa",
        value: "GAB",
      },
      {
        title: "Ghana",
        continent: "Africa",
        value: "GHA",
      },
      {
        title: "Guinea",
        continent: "Africa",
        value: "GIN",
      },
      {
        title: "Gambia",
        continent: "Africa",
        value: "GMB",
      },
      {
        title: "Guinea-Bissau",
        continent: "Africa",
        value: "GNB",
      },
      {
        title: "Equatorial Guinea",
        continent: "Africa",
        value: "GNQ",
      },
      {
        title: "British Indian Ocean Territory",
        continent: "Africa",
        value: "IOT",
      },
      {
        title: "Kenya",
        continent: "Africa",
        value: "KEN",
      },
      {
        title: "Liberia",
        continent: "Africa",
        value: "LBR",
      },
      {
        title: "Libya",
        continent: "Africa",
        value: "LBY",
      },
      {
        title: "Lesotho",
        continent: "Africa",
        value: "LSO",
      },
      {
        title: "Morocco",
        continent: "Africa",
        value: "MAR",
      },
      {
        title: "Madagascar",
        continent: "Africa",
        value: "MDG",
      },
      {
        title: "Mali",
        continent: "Africa",
        value: "MLI",
      },
      {
        title: "Mozambique",
        continent: "Africa",
        value: "MOZ",
      },
      {
        title: "Mauritania",
        continent: "Africa",
        value: "MRT",
      },
      {
        title: "Mauritius",
        continent: "Africa",
        value: "MUS",
      },
      {
        title: "Malawi",
        continent: "Africa",
        value: "MWI",
      },
      {
        title: "Mayotte",
        continent: "Africa",
        value: "MYT",
      },
      {
        title: "Namibia",
        continent: "Africa",
        value: "NAM",
      },
      {
        title: "Niger",
        continent: "Africa",
        value: "NER",
      },
      {
        title: "Nigeria",
        continent: "Africa",
        value: "NGA",
      },
      {
        title: "Réunion",
        continent: "Africa",
        value: "REU",
      },
      {
        title: "Rwanda",
        continent: "Africa",
        value: "RWA",
      },
      {
        title: "Sudan",
        continent: "Africa",
        value: "SDN",
      },
      {
        title: "Senegal",
        continent: "Africa",
        value: "SEN",
      },
      {
        title: "Sierra Leone",
        continent: "Africa",
        value: "SLE",
      },
      {
        title: "Somalia",
        continent: "Africa",
        value: "SOM",
      },
      {
        title: "South Sudan",
        continent: "Africa",
        value: "SSD",
      },
      {
        title: "São Tomé and Príncipe",
        continent: "Africa",
        value: "STP",
      },
      {
        title: "Eswatini",
        continent: "Africa",
        value: "SWZ",
      },
      {
        title: "Seychelles",
        continent: "Africa",
        value: "SYC",
      },
      {
        title: "Chad",
        continent: "Africa",
        value: "TCD",
      },
      {
        title: "Togo",
        continent: "Africa",
        value: "TGO",
      },
      {
        title: "Tunisia",
        continent: "Africa",
        value: "TUN",
      },
      {
        title: "Tanzania",
        continent: "Africa",
        value: "TZA",
      },
      {
        title: "Uganda",
        continent: "Africa",
        value: "UGA",
      },
      {
        title: "South Africa",
        continent: "Africa",
        value: "ZAF",
      },
      {
        title: "Zambia",
        continent: "Africa",
        value: "ZMB",
      },
      {
        title: "Zimbabwe",
        continent: "Africa",
        value: "ZWE",
      },
    ],
  },
  {
    title: "Antarctic",
    value: 6,
    children: [
      {
        title: "Antarctica",
        continent: "Antarctic",
        value: "ATA",
      },
      {
        title: "French Southern and Antarctic Lands",
        continent: "Antarctic",
        value: "ATF",
      },
      {
        title: "Bouvet Island",
        continent: "Antarctic",
        value: "BVT",
      },
      {
        title: "Heard Island and McDonald Islands",
        continent: "Antarctic",
        value: "HMD",
      },
      {
        title: "South Georgia",
        continent: "Antarctic",
        value: "SGS",
      },
    ],
  },
];
