import { FC } from "react";
import styled from "@emotion/styled";

import { SectionTitle } from "../../../ui-share";
import { StylelessButton } from "../../../../../../ui-components/StylelessButton";

interface EnrichedCompanyProfilePanelHeaderProps {
  onclick: () => void;
  titleText: string;
  showContent: boolean;
}

export const EnrichedCompanyProfilePanelHeader: FC<EnrichedCompanyProfilePanelHeaderProps> =
  ({ onclick, titleText, showContent }) => {
    return (
      <Container onClick={onclick}>
        <SectionTitle>{titleText}</SectionTitle>
        {/* <StylelessButton>{showContent ? "Hide" : "Show"}</StylelessButton> */}
      </Container>
    );
  };

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
