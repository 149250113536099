import * as Yup from "yup";

// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$/;

export const PasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("The current password is required."),
  newPassword: Yup.string()
    .min(8, "Too short. Password must be at least 8 characters.")
    .matches(
      passwordRegex,
      "Password must have one uppercase letter, one lowercase letter, one number, and one special character."
    )
    .required("The password is required."),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], `Password doesn't match.`)
    .required("You need to confirm your password."),
});
