import * as Yup from "yup";
import { Campaign } from "../../share/campaigns/store";
import { checkCampaignExist } from "../../helpers";

const CAMPAIGN_NAME_FIELD_LENGTH = 500;
const CAMPAIGN_DESCRIPTION_FIELD_LENGTH = 2000;

const urlRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

// TODO: Change lookalikes validation
export const ExploreCampaignSchema = (campaigns: Campaign[]) => {
  return Yup.object().shape({
    strategyPlainText: Yup.string()
      .required("Strategy is required")
      .min(100, "Please give us more information"),
    lookalikes: Yup.array().of(
      Yup.string().matches(urlRegex, "That doesn't look like a correct URL")
    ),
    keywords: Yup.array()
      .min(1, "Topics are required for the algorithm")
      .of(Yup.string()),
    countries: Yup.array().of(Yup.string()),
    activate: Yup.boolean(),
  });
};

export const ClustersSchema = (campaigns: Campaign[], isBetaTester?: boolean) =>
  Yup.object().shape({});

export const ConfirmCampaignSchema = (campaigns: Campaign[]) =>
  Yup.object().shape({
    name: Yup.string()
      .required("Campaign name is required.")
      .max(
        CAMPAIGN_NAME_FIELD_LENGTH,
        `Campaign description cannot exceed ${CAMPAIGN_NAME_FIELD_LENGTH} characters`
      )
      .test("exist", "A campaign with that name already exists.", (value) =>
        checkCampaignExist(campaigns, value)
      ),
    description: Yup.string(),
  });

export const EditCampaignSchema = (
  campaigns: Campaign[],
  campaignUUID: string
) =>
  Yup.object().shape({
    name: Yup.string()
      .required("Campaign name is required.")
      .max(
        CAMPAIGN_NAME_FIELD_LENGTH,
        "Campaign name cannot exceed 500 characters"
      )
      .test("exist", "A campaign with that name already exists", (value) =>
        checkCampaignExist(campaigns, value, campaignUUID)
      ),
    description: Yup.string().max(
      CAMPAIGN_DESCRIPTION_FIELD_LENGTH,
      `Campaign description cannot exceed ${CAMPAIGN_DESCRIPTION_FIELD_LENGTH} characters`
    ),
  });

// Used for New campaign Step 4 "validation"
export const EmptySchema = () => Yup.object().shape({});
