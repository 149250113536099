import { AxiosResponse } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { channel } from "redux-saga";
import { fork, put, delay, takeEvery } from "redux-saga/effects";
import { Segment } from "../utils/Segment";

import { backend, LocalStorageKeys } from "../utils/http";

import { SELECTED_CLIENT_LOCAL_STORAGE_KEY } from "../clients/store";

export interface User {
  email: string;
  uuid: string;
  firstName: string;
  lastName: string;
  displayName: string;
  photoURL: string | null;
  passwordLastUpdated: string;
  isStaff: boolean;
  hasMultipleClients: boolean;
}

export interface AuthState {
  login: {
    error: string | null;
    loading: boolean;
    success: boolean;
  };
  loadUser: {
    error: string | null;
    loading: boolean;
  };
  forgotPassword: {
    message: string | null;
    loading: boolean;
    success: boolean;
  };
  resetPassword: {
    message: string | null;
    loading: boolean;
    success: boolean;
  };
  signUp: {
    message: string | null;
    loading: boolean;
    success: boolean;
  };
  user?: User;
}

export interface LoginResponse {
  refresh: string;
  access: string;
}

interface UserAuthData {
  email: string;
  password: string;
}

interface ForgotPasswordData {
  email: string;
}

interface ResetPasswordData {
  password: string;
  token: string;
}

export interface SetPasswordData {
  firstName: string;
  lastName: string;
  password: string;
  invite: string;
  acceptedTermsAndConditions: boolean;
}

export interface SignUpData {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  acceptedTermsAndConditions: boolean;
  companyName: string;
  role: string | undefined;
  useCase: string;
  useCaseText: string;
  phoneNumber: string;
}

const initialState = {
  login: {
    error: "",
    loading: false,
    success: false,
  },
  loadUser: {
    error: "",
    loading: false,
  },
  forgotPassword: {
    message: "",
    loading: false,
    success: false,
  },
  resetPassword: {
    message: "",
    loading: false,
    success: false,
  },
  setPassword: {
    message: "",
    loading: false,
    success: false,
  },
  signUp: {
    message: "",
    loading: false,
    success: false,
  },
} as AuthState;

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    loadUser(state) {
      state.loadUser.loading = true;
    },
    loadUserSuccess(state, action) {
      state.loadUser.loading = false;
      state.user = action.payload;
    },
    loadUserFailure(state) {
      state.loadUser.loading = false;
      state.loadUser.error = "Something goes wrong, try again later";
    },
    login(state, action: PayloadAction<UserAuthData>) {
      state.login.loading = true;
      state.login.error = "";
      state.login.success = false;
    },
    loginSuccess(state, action) {
      state.login.loading = false;
      state.login.success = true;
      state.login.error = "";
      state.user = action.payload;
    },
    loginFailure(state, action) {
      state.login.loading = false;
      state.login.success = false;
      state.login.error = action.payload;
    },
    logout(state) {},
    forgotPassword(state, action: PayloadAction<ForgotPasswordData>) {
      state.forgotPassword.loading = true;
      state.forgotPassword.message = "";
    },
    forgotPasswordSuccess(state, action) {
      state.forgotPassword.loading = false;
      state.forgotPassword.success = true;
      state.forgotPassword.message = action.payload.message;
    },
    forgotPasswordFailure(state, action) {
      state.forgotPassword.loading = false;
      state.forgotPassword.message = action.payload;
      state.forgotPassword.success = false;
    },
    resetPassword(state, action: PayloadAction<ResetPasswordData>) {
      state.resetPassword.loading = true;
      state.resetPassword.message = "";
    },
    resetPasswordSuccess(state, action) {
      state.resetPassword.loading = false;
      state.resetPassword.success = true;
      state.resetPassword.message = action.payload.message;
    },
    resetPasswordFailure(state, action) {
      state.resetPassword.loading = false;
      state.resetPassword.message = action.payload;
      state.resetPassword.success = false;
    },
    setPassword(state, action: PayloadAction<SetPasswordData>) {
      state.resetPassword.loading = true;
      state.resetPassword.message = "";
    },
    setPasswordSuccess(state, action) {
      state.resetPassword.loading = false;
      state.resetPassword.success = true;
      state.resetPassword.message = action.payload.message;
    },
    setPasswordFailure(state, action) {
      state.resetPassword.loading = false;
      state.resetPassword.message = action.payload;
      state.resetPassword.success = false;
    },
    signUp(state, action: PayloadAction<SignUpData>) {
      state.signUp.loading = true;
      state.signUp.message = "";
    },
    signUpSuccess(state, action) {
      state.signUp.loading = false;
      state.signUp.success = true;
      state.signUp.message = action.payload.message;
    },
    signUpFailure(state, action) {
      state.signUp.loading = false;
      state.signUp.message = action.payload;
      state.signUp.success = false;
    },
    setInitialSignUpState(state) {
      state.signUp = initialState.signUp;
    },
    updateUserData(state, action) {
      state.user = action.payload;
    },
  },
});

function* loadUserSaga() {
  yield put(authSlice.actions.loadUser());
  try {
    const user: AxiosResponse<User> =
      yield backend.userBackend.getCurrentUser();
    yield delay(1000);
    yield put(authSlice.actions.loadUserSuccess(user));
  } catch {
    yield put(authSlice.actions.loadUserFailure());
  }
}

function* loginSaga() {
  yield takeEvery(authSlice.actions.login, function* (action) {
    try {
      const response: LoginResponse = yield backend.authBackend.login(
        action.payload.email,
        action.payload.password
      );
      yield localStorage.setItem(LocalStorageKeys.access, response.access);
      yield localStorage.setItem(LocalStorageKeys.refresh, response.refresh);
      const currentUser: AxiosResponse<User> =
        yield backend.userBackend.getCurrentUser();
      yield Segment.analytics?.track("login success", {
        email: action.payload.email,
      });

      yield put(authSlice.actions.loginSuccess(currentUser));
    } catch (error: any) {
      let errorMessage =
        error?.data?.message || error?.data?.detail || "Could not log in";

      yield Segment.analytics?.track("login failed", {
        email: action.payload.email,
      });

      yield put(authSlice.actions.loginFailure(errorMessage));
    }
  });
}

function* logoutSaga() {
  yield takeEvery(authSlice.actions.logout, function* (action) {
    yield localStorage.removeItem(LocalStorageKeys.access);
    yield localStorage.removeItem(LocalStorageKeys.refresh);
    yield localStorage.removeItem(LocalStorageKeys.selectedClient);
    yield localStorage.removeItem(SELECTED_CLIENT_LOCAL_STORAGE_KEY);
    window.location.reload();
  });
}

function* forgotPasswordSaga() {
  yield takeEvery(authSlice.actions.forgotPassword, function* (action) {
    try {
      const response: AxiosResponse = yield backend.authBackend.forgotPassword(
        action.payload.email
      );

      yield Segment.analytics?.track("password request", {
        email: action.payload.email,
      });

      yield put(authSlice.actions.forgotPasswordSuccess(response));
    } catch (error: any) {
      let errorMessage;
      if (error.data?.email) {
        errorMessage = error.data.email[0];
      } else {
        errorMessage = "There was an error with your request, please try again";
      }

      yield put(authSlice.actions.forgotPasswordFailure(errorMessage));
    }
  });
}

/* 
  Used for reset password, AND for password creation when a user has been created through admin
*/
function* resetPasswordSaga() {
  yield takeEvery(authSlice.actions.resetPassword, function* (action) {
    try {
      /*
        Reset password token must be validated. if the confirm request is not valid, it will
        throw an error directly before requesting the resetPassword
      */
      yield backend.authBackend.validateResetPasswordToken(
        action.payload.token
      );

      const response: AxiosResponse = yield backend.authBackend.resetPassword(
        action.payload.password,
        action.payload.token
      );

      yield Segment.analytics?.track("password reset");

      yield put(authSlice.actions.resetPasswordSuccess(response));
    } catch (error: any) {
      let errorMessage;
      if (error?.status === 404) {
        errorMessage =
          "Token has expired or has already been used, please request a new one";
      } else if (error.data?.password) {
        errorMessage = error.data.password.join(" ");
      } else {
        errorMessage = "Password not valid, try again";
      }
      yield put(authSlice.actions.resetPasswordFailure(errorMessage));
    }
  });
}

/* 
  Used for reset password, AND for password creation when a user has been created through admin
*/
function* setPasswordSaga() {
  yield takeEvery(authSlice.actions.setPassword, function* (action) {
    try {
      const response: AxiosResponse = yield backend.authBackend.setPassword(
        action.payload
      );

      yield Segment.analytics?.track("invite password set");

      yield put(authSlice.actions.setPasswordSuccess(response));
    } catch (error: any) {
      let errorMessage = error.data?.error || "Password not valid, try again";
      yield put(authSlice.actions.setPasswordFailure(errorMessage));
    }
  });
}

function* signUpSaga() {
  yield takeEvery(authSlice.actions.signUp, function* (action) {
    try {
      const response: AxiosResponse = yield backend.authBackend.signUp(
        action.payload
      );

      yield put(authSlice.actions.signUpSuccess(response));
    } catch (error: any) {
      let errorMessage =
        error.message ||
        "An error ocurred with your request, please try again later";

      yield put(authSlice.actions.signUpFailure(errorMessage));
    }
  });
}

function* interceptorSetup() {
  const performLogout = channel<any>();
  backend.setupInterceptor(() => performLogout.put({}));
  yield takeEvery(performLogout, function* () {
    yield put(logout());
  });
}

export function* authSaga() {
  yield fork(interceptorSetup);
  yield fork(loadUserSaga);
  yield fork(loginSaga);
  yield fork(logoutSaga);
  yield fork(forgotPasswordSaga);
  yield fork(resetPasswordSaga);
  yield fork(setPasswordSaga);
  yield fork(signUpSaga);
}

export const {
  login,
  logout,
  forgotPassword,
  resetPassword,
  setPassword,
  signUp,
  setInitialSignUpState,
  updateUserData,
} = authSlice.actions;
