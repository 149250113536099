import { FC, useEffect, useState, useCallback } from "react";
import { v4 as uuid4 } from "uuid";

import { Switch } from "antd";
import { errorNotification } from "../../../../ui-components/Notification";

import {
  ListCompanySource,
  addCompanyToList,
  removeCompanyFromList,
} from "../../../../list/store";
import { State } from "../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { Segment } from "../../../../utils/Segment";

interface ListSwitchProps {
  switchInitialState: boolean;
  listUUID: string;
  companyUUID: string;
  companyName: string;
  listCompanySource: ListCompanySource;
}

enum RequesType {
  AddCompanyToList = "AddCompanyToList",
  RemoveCompanyFromList = "RemoveCompanyFromList",
}

/**
 * This component is quite constribed, for the sole reason of having an architecture that
 * doesn't allow easy integration with RTK Query.
 *
 * Problem --> Each individual addCompanyToList/removeCompanyFromList request has to be
 *             properly controlled, and state-checked to revert, show error... in case
 *             there's a problem, but that's not possible with the current Redux-Saga
 *             implementation, since Redux-Saga + RTK work on "request type", not on
 *             individual requests. If several requests are fired, they will override the
 *             loading/success/error values, and it's not possible to know which of the
 *             requests yielded an error, which one was successfull...
 *
 * Solution --> Use three different state-controlling measures
 *
 * 1. The loading is controlled locally (useState), rather than from Store (useSelect -> State)
 * 2. Each request has an identifier, called idempotency key, that will be returned by the API,
 *    which is then store on the state on Success/Error state-update operations. With this key,
 *    it's easy to check if the incoming state-update operation belongs to one particular Switch
 *    action.
 * 3. This solution so far has one problem, the IdempotencyKey remains in the state, unless the same
 *    request has been fired again, but <addToList>, and <removeFromList> are two different requests.
 *    Meaning, if one Switch triggers an <addToListSuccess>, the values are stored in the store. Next
 *    time the same (or any other) switch triggers a <removeFromList>, it could trigger the useEffect
 *    designated for the Success case.
 *    In order to not overly complicate the reducer for the ListState, a third locally controleld variable
 *    (useState) is used, requestType. With this one, we can see which one was the operation last triggered
 *    by the user on the Switch
 *
 * ALL of this will be much simplified with two different approaches: either using RTK-Query, or by using
 * a different structure on the state
 * Proposal for next version:
 * (TODO) State goes from
 * {loading:..., error:..., sucess:...}
 * to
 * {
 *  <someSwitchId>: {loading:..., error:..., sucess:...},
 *  <someOtherSwitchId>: {loading:..., error:..., sucess:...},
 *  ...
 * }
 *
 * This will allow the same granularity control over the request state as introducing a whole new paradigm
 * and architectural change into the application. (Only drawback is slightly more complex state management on
 * reducer, plus creating the "initial" entry for each id)
 */
export const ListSwitch: FC<ListSwitchProps> = ({
  switchInitialState,
  listUUID,
  companyUUID,
  companyName,
  listCompanySource,
}) => {
  const dispatch = useAppDispatch();
  const [switchValue, setSwitchValue] = useState(switchInitialState);
  const [loading, setLoading] = useState(false);
  const [requestIdempotencyKey, setRequestIdempotencyKey] = useState("");
  const [requestType, setRequestType] = useState<RequesType | null>(null);

  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const {
    success: addCompanyToListSuccess,
    error: addCompanyToListError,
    responseIdempotencyKey: addCompanyToListResponseIdempotencyKey,
  } = useAppSelector((state: State) => state.lists.addCompanyToList);
  const {
    success: removeCompanyFromListSuccess,
    error: removeCompanyFromListError,
    responseIdempotencyKey: removeCompanyFromListResponseIdempotencyKey,
  } = useAppSelector((state: State) => state.lists.removeCompanyFromList);

  const resetRequestData = useCallback(() => {
    setRequestType(null);
    setLoading(false);
    setRequestIdempotencyKey("");
  }, []);

  useEffect(() => {
    if (
      addCompanyToListError &&
      addCompanyToListResponseIdempotencyKey === requestIdempotencyKey &&
      requestType === RequesType.AddCompanyToList
    ) {
      errorNotification(addCompanyToListError);
      resetRequestData();
    }
  }, [
    addCompanyToListError,
    addCompanyToListResponseIdempotencyKey,
    requestIdempotencyKey,
    requestType,
    resetRequestData,
  ]);

  useEffect(() => {
    if (
      removeCompanyFromListError &&
      removeCompanyFromListResponseIdempotencyKey === requestIdempotencyKey &&
      requestType === RequesType.RemoveCompanyFromList
    ) {
      errorNotification(removeCompanyFromListError);
      resetRequestData();
    }
  }, [
    removeCompanyFromListError,
    removeCompanyFromListResponseIdempotencyKey,
    requestIdempotencyKey,
    requestType,
    resetRequestData,
  ]);

  useEffect(() => {
    if (
      addCompanyToListSuccess &&
      addCompanyToListResponseIdempotencyKey === requestIdempotencyKey &&
      requestType === RequesType.AddCompanyToList
    ) {
      setSwitchValue(true);
      resetRequestData();
    }
  }, [
    addCompanyToListSuccess,
    addCompanyToListResponseIdempotencyKey,
    requestIdempotencyKey,
    requestType,
    setSwitchValue,
    switchValue,
    resetRequestData,
  ]);

  useEffect(() => {
    if (
      removeCompanyFromListSuccess &&
      removeCompanyFromListResponseIdempotencyKey === requestIdempotencyKey &&
      requestType === RequesType.RemoveCompanyFromList
    ) {
      setSwitchValue(false);
      resetRequestData();
    }
  }, [
    removeCompanyFromListSuccess,
    removeCompanyFromListResponseIdempotencyKey,
    requestIdempotencyKey,
    requestType,
    setSwitchValue,
    switchValue,
    resetRequestData,
  ]);

  const toggleCompanyInList = (value: boolean) => {
    if (!selectedClient) return;

    setLoading(true);
    let idempotencyKey = uuid4();
    setRequestIdempotencyKey(idempotencyKey);

    let dispatchData = {
      clientUUID: selectedClient.uuid,
      companyUUID: companyUUID,
      listUUID: listUUID,
      idempotencyKey: idempotencyKey,
    };

    if (value) {
      setRequestType(RequesType.AddCompanyToList);
      let addCompanyToListData = {
        ...dispatchData,
        companyName: companyName,
        addedFrom: listCompanySource,
      };
      dispatch(addCompanyToList(addCompanyToListData));
    } else {
      setRequestType(RequesType.RemoveCompanyFromList);
      dispatch(removeCompanyFromList(dispatchData));
    }

    const eventProperties = {
      listUUID: listUUID,
      companyUUID: companyUUID,
      companyName: companyName,
      client: selectedClient.name,
      clientUUID: selectedClient.uuid,
      source: listCompanySource,
    };
    const eventName = value
      ? "add-company-to-list"
      : "remove-company-from-list";
    Segment.analytics?.track(eventName, eventProperties);
  };

  return (
    <Switch
      style={{ backgroundColor: switchValue ? "var(--primary-color)" : "gray" }}
      loading={loading}
      checked={switchValue}
      onChange={(value) => toggleCompanyInList(value)}
      disabled={loading}
    ></Switch>
  );
};
