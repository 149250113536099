import styled from "@emotion/styled";
import { Tooltip } from "antd";
import { FC } from "react";
import Highlighter from "react-highlight-words";

import {
  InfoIcon,
  TAG_HIGHLIGHT_COLOR,
} from "../../../../../share/company/components/company-detail/body";
import { Section, SectionHeader, SectionTitle } from "../../ui-share";

interface GeneralInformationWithHighlightsProps {
  description: string;
  wordsToHighlight?: string[];
}

export const GeneralInformationWithHighlights: FC<GeneralInformationWithHighlightsProps> =
  ({ description, wordsToHighlight }) => {
    /**
     * Highlighter does not identify standalone terms, it simply does an unhinged regex
     * search. In order to fix this issue, we have two solutions, first create our own
     * findChunks function, which we would have to design properly to identify all
     * the chunks with each length (chunks are defined as an array of [startIndex, endIndex])
     * OR, with a simple regex search we can pre-filter which results should
     * __actually__ be highlighted, and only pass those.
     *
     */

    // Create regex in the form /\b(term1|term2|...)\b/g
    let regex = "\\b(";
    wordsToHighlight?.forEach((word, index) => {
      const escapedWord = word.toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      regex += escapedWord;
      if (index + 1 !== wordsToHighlight.length) regex += "|";
    });
    regex += ")\\b";
    const regEx = new RegExp(regex, "g");

    const foundWords = Array.from(description.toLowerCase().matchAll(regEx));
    const cleanedFoundWords = foundWords.map((x) => x[0]);

    return (
      <Section>
        <HighlighterWrapper>
          <Highlighter
            searchWords={cleanedFoundWords || []}
            textToHighlight={description}
            highlightTag={HighlightTag}
          />
        </HighlighterWrapper>
      </Section>
    );
  };

const HighlightTag: FC<{ children: any }> = ({ children }) => {
  return (
    <Tooltip title="This term is highlighted because it's one of the topics that match your briefing">
      <Mark>{children}</Mark>
    </Tooltip>
  );
};

const Mark = styled.mark`
  color: var(--highlighted-text-color);
  background-color: ${TAG_HIGHLIGHT_COLOR};
`;

const HighlighterWrapper = styled.div`
  margin-left: var(--gb-content-section-left-margin);
`;