import { FC } from "react";
import styled from "@emotion/styled";
import { SectionHeader } from "../../../ui-share";

interface EnrichedSectionProps {
  title: string;
  children?: React.ReactNode;
}

export const EnrichedSection: FC<EnrichedSectionProps> = ({
  title,
  children,
}) => {
  return (
    <div>
      <SectionHeader>{title}</SectionHeader>
      <Container>{children}</Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  margin-bottom: 1rem;

  border-bottom: var(--campaign-card-border);

  &:last-of-type {
    border-bottom: none;
  }
`;
