import { FC, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Formik, FormikProps } from "formik";

import { Modal } from "../../../../../ui-components/Modal";
import { FormikInput } from "../../../../../ui-components/FormikInput";
import { Paragraph } from "../../../../../ui-components/Typography";
import { Button } from "../../../../../ui-components/Button";
import {
  errorNotification,
  successNotification,
} from "../../../../../ui-components/Notification";

import { EmailSchema } from "../../../validation";
import {
  shareCompany,
  GoldenBasketCompanyRequestType,
  setInitialShareCompanyState,
} from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import { State } from "../../../../../utils/store";

interface ShareModalProps {
  modalVisible: boolean;
  handleModalClose: () => void;
  companyUUID: string;
}

export const ShareModal: FC<ShareModalProps> = ({
  modalVisible,
  handleModalClose,
  companyUUID,
}) => {
  const dispatch = useAppDispatch();
  const { success, message, loading } = useAppSelector(
    (state: State) => state.goldenBasket.shareCompany
  );

  const formikRef = useRef<FormikProps<Record<string, string>>>(null);
  useEffect(() => {
    if (success === true && loading === false) {
      successNotification("Email has been sent successfully");
      handleModalClose();
      formikRef?.current?.resetForm();
      dispatch(setInitialShareCompanyState());
    } else if (success === false) {
      errorNotification(message);
    }
  }, [dispatch, success, message, loading, handleModalClose]);

  useEffect(() => {
    return () => {};
  });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ destinationEmail: "" }}
      onSubmit={(values, { resetForm }) => {
        const data = {
          destinationEmail: values.destinationEmail,
          companyUUID: companyUUID,
          requestType: GoldenBasketCompanyRequestType.Share,
        };
        dispatch(shareCompany(data));
      }}
      validationSchema={EmailSchema}
    >
      {({ handleSubmit, values, errors }) => (
        <form>
          <Modal
            title="Share Company"
            visible={modalVisible}
            handleSubmit={handleSubmit}
            onCancel={handleModalClose}
            footer={[
              <Button key="cancel" onClick={handleModalClose}>
                Close
              </Button>,
              <Button
                key="send"
                htmlType="submit"
                onClick={() => handleSubmit()}
                loading={loading}
                disabled={loading}
              >
                {loading ? "Sending" : "Send"}
              </Button>,
            ]}
          >
            <div>
              <Info>
                Enter the email of the person you would like to share the
                Company to:
              </Info>

              <FormikInput
                name="destinationEmail"
                type="email"
                label="Email"
                value={values.destinationEmail}
                error={errors.destinationEmail}
              />
            </div>
          </Modal>
        </form>
      )}
    </Formik>
  );
};

const Info = styled(Paragraph)`
  margin-bottom: 1.6rem;
`;
