import { Result } from "antd";
import styled from "@emotion/styled";
import Icon from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Button as BaseButton } from "../../../ui-components/Button";

import { ReactComponent as EmailSentIcon } from "../../../assets/icons/emailsent.svg";

export const ResultForgotPassword = () => {
  return (
    <Result
      icon={
        <Icon
          component={EmailSentIcon}
          style={{ color: "var(--font-color-white)", margin: "0 1px 1px 0" }}
        />
      }
      title="Email has been sent!"
      subTitle="Please check your inbox and click in the received link to reset password"
      extra={
        <Link to="login">
          <Button type="primary">Back to login</Button>
        </Link>
      }
    />
  );
};

const Button = styled(BaseButton)`
  width: 100%;
  font-weight: var(--font-weight-bold);
  margin-bottom: 1rem;
`;
