import { http } from "../http";

import {
  CreateListData,
  DeleteListData,
  UpdateListData,
  AddCompanyToListData,
  RemoveCompanyFromListData,
} from "../../list/store";

export async function getListCompanies(listUUID: string) {
  const { data } = await http.get(`/api/list/${listUUID}`);
  return data;
}

export async function getClientLists(clientUUID: string) {
  const { data } = await http.get(`/api/client/${clientUUID}/lists`);
  return data;
}

export async function createList(requestData: CreateListData) {
  const { data } = await http.post(`/api/list`, requestData);
  return data;
}

export async function deleteList(requestData: DeleteListData) {
  const config = {
    data: requestData,
  };
  const { data } = await http.delete(`/api/list`, config);
  return data;
}

export async function updateList(requestData: UpdateListData) {
  const { data } = await http.put(`/api/list`, requestData);
  return data;
}

export async function removeCompanyFromList(
  requestData: RemoveCompanyFromListData
) {
  const config = {
    data: requestData,
  };
  const { data } = await http.delete(`/api/list/company`, config);
  return data;
}

export async function addCompanyToList(requestData: AddCompanyToListData) {
  const { data } = await http.post(`/api/list/company`, requestData);
  return data;
}
