import styled from "@emotion/styled";

import { FormikCheckbox } from "../../ui-components/FormikCheckbox";
import { Span } from "../SignUpView";

import { FC } from "react";
import { Dictionary } from "../../share/types";

interface TermsAndConditionsProps {
  errors: { [field: string]: any };
  formItemProps?: Dictionary<any>;
}

const termsOfServiceURL =
  "https://novable.com/terms-and-conditions?utm_source=signup&utm_medium=prod&utm_campaign=";
const privacyPolicyURL =
  "https://novable.com/privacy-policy?utm_source=signup&utm_medium=prod&utm_campaign=";

export const TermsAndConditions: FC<TermsAndConditionsProps> = ({
  errors,
  formItemProps,
}) => {
  return (
    <FormikCheckbox
      name="acceptedTermsAndConditions"
      error={errors.acceptedTermsAndConditions}
      formItemProps={formItemProps}
    >
      <Span>
        By checking this box you confirm that you’ve read and accepted our{" "}
        <A href={termsOfServiceURL} target="_blank" rel="noreferrer">
          Terms of Service
        </A>{" "}
        and{" "}
        <A href={privacyPolicyURL} target="_blank" rel="noreferrer">
          Privacy Policy
        </A>
      </Span>
    </FormikCheckbox>
  );
};

const A = styled.a`
  @media (max-width: 1199px) {
    color: var(--white);
    text-decoration: underline;
  }
`;
