import { useEffect } from "react";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import novableLogoBlack from "../assets/logos/novable-new-black-large.png";

import { Space as BaseSpace, Alert, Spin } from "antd";
import { Formik } from "formik";
import { FormikInput } from "../ui-components/FormikInput";
import { Button as BaseButton } from "../ui-components/Button";
import { AuthTitle, AuthSubtitle } from "../ui-components/Typography";

import { login } from "./store";
import { State } from "../utils/store";
import { useAppDispatch, useAppSelector } from "../utils/hooks";

import { LoginSchema } from "./validation";

interface FormValues {
  email: string;
  password: string;
}

export const LoginView = () => {
  const { loading, error, success } = useAppSelector(
    (state: State) => state.auth.login
  );
  const { user } = useAppSelector((state: State) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState: any = location?.state;
  const locationStateFrom = locationState?.from;

  useEffect(() => {
    if (user) navigate("/clients");
  }, [user, navigate]);

  useEffect(() => {
    const redirectTo = locationStateFrom ? locationStateFrom : "/clients";
    if (success) navigate(redirectTo);
  }, [success, locationStateFrom, navigate]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={(values: FormValues) => {
        dispatch(login(values));
      }}
      validationSchema={LoginSchema}
    >
      {({ handleSubmit, values, errors }) => (
        <Form onSubmit={handleSubmit} className="login-form" id="loginForm">
          <Logo />
          <AuthTitle>Hello!</AuthTitle>
          <AuthSubtitle>Log in to your account.</AuthSubtitle>
          <Space direction="vertical" size="small">
            <FormikInput
              name="email"
              type="email"
              label="Email"
              value={values.email}
              error={errors.email}
              responsiveLabel={true}
            />
            <FormikInput
              name="password"
              type="password"
              label="Password"
              value={values.password}
              error={errors.password}
              responsiveLabel={true}
            />
            <ForgotPasswordLinkWrapper>
              <Link to="/forgot-password">Forgot password</Link>
            </ForgotPasswordLinkWrapper>
            <ButtonWrapper>
              <Button htmlType="submit">
                {loading ? (
                  <>
                    Logging in &nbsp; <Spin />{" "}
                  </>
                ) : (
                  <> Log in </>
                )}
              </Button>
              {error && <Alert type="error" message={error} />}
            </ButtonWrapper>
            <SignUpWrapper>
            <span>Don't have an account? </span>
            <a href="https://novable.com/discoverycall/" target="_blank" rel="noopener noreferrer">Book a call with a Novable expert</a>
            </SignUpWrapper>
          </Space>
        </Form>
      )}
    </Formik>
  );
};

const ForgotPasswordLinkWrapper = styled.div`
  width: 100%;
  margin-top: -1rem;
  text-align: right;
  margin-bottom: 1rem; // Adjust as necessary to align with the form
  font-size: 1.4rem;
`;

const SignUpWrapper = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 0rem;
`;

const Button = styled(BaseButton)`
  width: 100%;
  font-weight: var(--font-weight-bold);
  margin-bottom: 1rem;
`;

const Space = styled(BaseSpace)`
  width: 100%;
`;

const Form = styled.form`
  max-width: 47.2rem;
  width: 100%;

  @media (max-width: 576px) {
    width: 90%;
  }
`;

const Logo = styled.div`
  height: 3.1rem;
  width: 15.3rem;
  left: 6rem;
  top: 8rem;
  background-image: url(${novableLogoBlack});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 6rem;
`;
