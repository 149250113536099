import styled from "@emotion/styled";
import { Card as BaseCard } from "antd";
import { CardHeadTitle } from "./Typography";

export const Card = styled(BaseCard)`
  padding: 1.6rem;
  border-radius: var(--border-radius);
  margin-bottom: 1.6rem;
  box-shadow: var(--card-box-shadow);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  -webkit-animation: scale-up-center 0.3s cubic-bezier(0.26, 0.86, 0.36, 1) both;
  animation: scale-up-center 0.3s cubic-bezier(0.26, 0.86, 0.36, 1) both;
`;

export const CardHeader = styled.div`
  height: 5rem;
  border-bottom: var(--campaign-card-border);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CardContent = styled.div`
  padding-top: 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const HeadTitle = styled(CardHeadTitle)`
  position: relative;
  display: inline-block;
  margin-bottom: 0;
`;
