import { FC } from "react";

import { ButtonsContainer } from "../ButtonsContainer";
import { DisableUserButton } from "./DisableUserButton";
import { DeleteUserButton } from "./DeleteUserButton";

interface UserActionsProps {
  userUUID: string;
  clientUUID: string | undefined;
  isEnabled: boolean;
}

export const UserActions: FC<UserActionsProps> = ({
  userUUID,
  clientUUID,
  isEnabled,
}) => {
  return (
    <ButtonsContainer>
      <DisableUserButton
        userUUID={userUUID}
        clientUUID={clientUUID}
        isEnabled={isEnabled}
      />
      <DeleteUserButton userUUID={userUUID} clientUUID={clientUUID} />
    </ButtonsContainer>
  );
};
