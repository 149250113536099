import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { ClusterCollapse } from "./data-components/ClusterCollapse";
import { RadarChart } from "./data-components/RadarChart";
import { CardContent } from "../../ui-components/Card";
import { CenteredErrorMessage } from "../../ui-components/CenteredErrorMessage";
import { LongLoaderWithMessage } from "../../ui-components/LongLoaderWithMessage";
import { NewCampaignSubtitle } from "../../ui-components/Typography";
// import { WordCloud } from "../../ui-components/charts/WordCloud";

import { fetchClusters, setClusters, setInitialState } from "../store";
import { Cluster, Keyword } from "../../share/campaigns/store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import { cleanKeyword } from "../../helpers";

interface ClustersEvaluationProps {
  values: { [field: string]: any };
  shouldUpdateClusters: boolean;
  resetShouldUpdateClusters: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const minimumClustersLength = 2;

export const ClustersEvaluation: FC<ClustersEvaluationProps> = ({
  values,
  shouldUpdateClusters,
  resetShouldUpdateClusters,
  setFieldValue,
}) => {
  const dispatch = useAppDispatch();
  const [activePanel, setActivePanel] = useState<string>("0");

  const { clusters } = useAppSelector((state: State) => state.newCampaign);
  const { loading, success, message } = useAppSelector(
    (state: State) => state.newCampaign.fetchClusters
  );

  const gatherDataAndFetchClusters = () => {
    const {
      strategyPlainText: strategy,
      countries,
      keywords,
      lookalikes,
    } = values;

    dispatch(
      fetchClusters({
        strategy,
        countries,
        keywords: keywords,
        lookalikes,
      })
    );
  };

  // Cleanup of state
  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, [dispatch]);

  // Needed to keep track of the clusters in the AutoSaveDraft component, since it looks at the formik fields
  useEffect(() => {
    setFieldValue("clusters", [...clusters]);
  }, [clusters, setFieldValue]);

  useEffect(() => {
    if (shouldUpdateClusters) {
      gatherDataAndFetchClusters();
      resetShouldUpdateClusters();
    }
  }, [shouldUpdateClusters]); // eslint-disable-line

  // we take cluster values to separate array to not rerender chart
  const clusterValues = clusters.map((cluster: Cluster) => cluster.weight);

  const handleSliderChange = (value: number, clusterLabel: string) => {
    const updatedClusters = clusters.map((cluster) =>
      clusterLabel === cluster.label ? { ...cluster, weight: value } : cluster
    );

    dispatch(setClusters(updatedClusters));
  };

  const addTag = (clusterLabel: string, newKeyword: Keyword) => {
    let selectedCluster = clusters.find(
      (cluster) => clusterLabel === cluster.label
    );

    if (!selectedCluster) return;

    const updatedCluster = {
      ...selectedCluster,
      keywords: selectedCluster.keywords.map((keyword: Keyword) =>
        keyword.keyword === newKeyword.keyword
          ? { ...keyword, isActive: true }
          : keyword
      ),
    };

    const updatedClusters = clusters.map((cluster) =>
      clusterLabel === cluster.label ? updatedCluster : cluster
    );

    dispatch(setClusters(updatedClusters));
  };

  const removeTag = (clusterLabel: string, deletedKeyword: Keyword) => {
    const selectedCluster = clusters.find(
      (cluster) => clusterLabel === cluster.label
    );

    if (!selectedCluster) return;

    const updatedCluster = {
      ...selectedCluster,
      keywords: selectedCluster.keywords.map((keyword: Keyword) =>
        keyword.keyword === deletedKeyword.keyword
          ? { ...keyword, isActive: false }
          : keyword
      ),
    };

    const numberActiveTags = updatedCluster.keywords.filter(
      (keyword: Keyword) => keyword.isActive
    );

    // Business decission: If there are no keywords, weight should change to 0 automatically
    // and be disabled until another word is added
    if (numberActiveTags.length === 0) updatedCluster.weight = 0;

    const updatedClusters = clusters.map((cluster) =>
      clusterLabel === cluster.label ? updatedCluster : cluster
    );

    dispatch(setClusters(updatedClusters));
  };

  if (loading) {
    return (
      <LongLoaderWithMessage message="Your request takes a lot of time to process, you can edit your briefing with more content or choose less restrictive geographical filter" />
    );
  }
  if (!success && message)
    return <CenteredErrorMessage>{message}</CenteredErrorMessage>;

  return (
    <>
      {clusters.length < minimumClustersLength ? (
        <p>
          It looks like your briefing did not provide enough information to
          group the topics we detected into clusters. We recommend that you go
          back to the previous steps to further detail your briefing.
          Alternatively, you can go on with the process and submit your campaign
          as such.
        </p>
      ) : (
        <>
          <p>Rate the clusters. Deactivate any topics that don't match.</p>
          <Content>
            <LeftColumn>
              <ClustersContainer>
                <ClusterCollapse
                  clusters={clusters}
                  handleSliderChange={handleSliderChange}
                  addTag={addTag}
                  removeTag={removeTag}
                  setActivePanel={setActivePanel}
                />
              </ClustersContainer>
            </LeftColumn>
            <RightColumn>
              {clusters.length > 2 && (
                <ChartContainer>
                  <RadarChart
                    clustersData={clusters}
                    clusterValues={clusterValues}
                  />
                </ChartContainer>
              )}
              { // Temporarily disable because react-wordcloud incompatible with React 18
              /* <WordCloudContainer>
                <WordCloudTitle>
                  {clusters.length > 0 &&
                    cleanKeyword(clusters[Number(activePanel)]?.label)}{" "}
                  wordcloud
                </WordCloudTitle>
                <WordCloud keywords={clusters[Number(activePanel)]?.keywords} />
              </WordCloudContainer> */}
            </RightColumn>
          </Content>
        </>
      )}
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1500px) {
    flex-direction: column;
  }
`;

const ClustersContainer = styled.div`
  width: 100%;
  margin-right: 2.4rem;

  @media (max-width: 1500px) {
    margin-right: 0;
  }
`;

const LeftColumn = styled(CardContent)`
  align-items: flex-start;
  width: 55%;

  @media (max-width: 1500px) {
    width: 100%;
  }
`;

const RightColumn = styled(CardContent)`
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  /* max-height: 50rem; */

  @media (max-width: 1500px) {
    width: 100%;
    justify-content: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

// for chart and wordcloud
const DataContainers = styled.div`
  background: transparent;
  border: var(--campaign-card-border);
  border-radius: var(--border-radius);
`;

const ChartContainer = styled(DataContainers)`
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.6rem;

  @media (max-width: 1500px) {
    width: 50%;
    padding-top: 0;
  }
`;

const WordCloudContainer = styled(DataContainers)`
  padding: 1.2rem;
  width: 100%;
  height: 38.2rem;

  @media (max-width: 1500px) {
    width: 50%;
  }
`;

const WordCloudTitle = styled(NewCampaignSubtitle)`
  margin: 0;
  padding: 0;
`;
