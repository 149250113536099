import { FC } from "react";
import styled from "@emotion/styled";

import { CompanyCard } from "../../../share/company/components/company-list/CompanyCard";
import { ResultCount } from "../../../share/company/components/company-list/ResultCount";
import { CenteredSpinner } from "../../../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../../../ui-components/CenteredErrorMessage";
import { StatusMessage } from "../../../ui-components/states";

import { toggleCompanyFavourite } from "../../store";
import { fetchCompany } from "../../../share/company/store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { Segment } from "../../../utils/Segment";

interface ListOfCompaniesProps {
  listName: string;
}

export const ListOfCompanies: FC<ListOfCompaniesProps> = ({ listName }) => {
  const dispatch = useAppDispatch();
  const { selectedListCompanies } = useAppSelector(
    (state: State) => state.lists
  );

  const {
    loading: loadingList,
    error: errorList,
    success: successList,
  } = useAppSelector((state: State) => state.lists.getListCompanies);
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const handleFetchSelectedCompanyDetails = (uuid: string) => {
    if (!selectedClient) return;
    const data = {
      clientUUID: selectedClient?.uuid,
      companyUUID: uuid,
    };
    dispatch(fetchCompany(data));
    Segment.analytics?.track("open company detail", {
      source: "list-view",
      client: selectedClient.name,
      listName: listName,
    });
  };

  const successGetList = !loadingList && !errorList && successList;
  const successWithResults =
    successGetList &&
    selectedListCompanies !== null &&
    selectedListCompanies.length > 0;
  const successWithoutResults =
    successGetList &&
    selectedListCompanies !== null &&
    selectedListCompanies.length === 0;

  const handleFavourite = (e: any, companyUUID: string) => {
    if (!selectedClient) return;
    e.stopPropagation();
    let payload = {
      companyUUID: companyUUID,
      clientUUID: selectedClient.uuid,
    };
    dispatch(toggleCompanyFavourite(payload));
  };

  return (
    <List>
      <ResultCount>
        {selectedListCompanies !== null ? selectedListCompanies.length : "-"}
      </ResultCount>
      <CompaniesListContainer>
        {errorList ? (
          <CenteredErrorMessage>
            <StatusMessage>
              There was an error fetching the list of Companies. {errorList}{" "}
            </StatusMessage>
          </CenteredErrorMessage>
        ) : loadingList ? (
          <CenteredSpinner size="large" />
        ) : successWithResults ? (
          selectedListCompanies.map((company) => (
            <CompanyCard
              key={company.uuid}
              companyMinified={company}
              onClick={handleFetchSelectedCompanyDetails}
              handleFavourite={handleFavourite}
            />
          ))
        ) : (
          successWithoutResults && (
            <CenteredErrorMessage>
              <StatusMessage>
                This list does not have any Companies yet.
              </StatusMessage>
            </CenteredErrorMessage>
          )
        )}
      </CompaniesListContainer>
    </List>
  );
};

const List = styled.div`
  background: var(--campaign-stats-background);
  box-shadow: var(--gb-list-container-shadow);
  position: sticky;
  top: 8rem;
  height: calc(100vh - 8rem);
`;

const CompaniesListContainer = styled.div`
  // Total height minus results header
  height: calc(100% - 5.2rem);
  overflow-y: scroll;
`;
