import { useNavigate } from "react-router";
import styled from "@emotion/styled";

import { Result, Button } from "antd";

// Redirecting to clients will select the only existing client of the user
export const ClientErrorView = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Result
        status="error"
        title="You don’t have access to this page"
        subTitle="If you think this is an error, contact our support team at support@novable.com."
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => navigate("/clients")}
          >
            Go to dashboard
          </Button>,
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
