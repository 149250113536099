import { FC } from "react";
import styled from "@emotion/styled";

import { FieldArray } from "formik";
import { ClusterCollapse } from "./ClusterCollapse";
import {
  HeadTitle,
  SubmitContainer,
  NextArrow,
  NextButton,
} from "../../../share/campaigns/components/share-ui";
import { ExamplesOfCompaniesRender } from "../../../share/campaigns/components/ExamplesOfCompaniesRender";
import { Card, CardContent, CardHeader } from "../../../ui-components/Card";
import { FormikInput } from "../../../ui-components/FormikInput";
import { FormikTreeSelect } from "../../../ui-components/FormikTreeSelect";
import { FormikSelect } from "../../../ui-components/FormikSelect";
import { NewCampaignSubtitle } from "../../../ui-components/Typography";

import { countries } from "../../../utils/countries";
import { scrollToErrors } from "../../../helpers";

interface CampaignDetailsProps {
  values: { [field: string]: any };
  errors: { [field: string]: any };
  touched: { [field: string]: any };
}

export const CampaignDetails: FC<CampaignDetailsProps> = ({
  values,
  errors,
  touched,
}) => {
  return (
    <ContentCol>
      <CardsContainer>
        <Card>
          <CardHeader>
            <HeadTitle>Campaign Data</HeadTitle>
          </CardHeader>
          <CardContent>
            <CampaignFormContent>
              <NewCampaignSubtitle>Campaign Name</NewCampaignSubtitle>
              <FormikInput
                name="name"
                type="text"
                ariaLabel="campaign name"
                placeholder="Campaign name"
                error={errors.name}
              />
              <NewCampaignSubtitle>Campaign Description</NewCampaignSubtitle>
              <FormikInput
                name="description"
                type="text"
                as="textarea"
                ariaLabel="campaign description"
                placeholder="Campaign description"
                error={errors.description}
              />
            </CampaignFormContent>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <HeadTitle>Campaign Briefing</HeadTitle>
          </CardHeader>
          <CardContent>

            <EditorContainer>
              <Strategy>{values.strategyPlainText}</Strategy>
            </EditorContainer>
            {values.lookalikes?.length > 0 && (
              <ExamplesOfCompaniesForm>
                <NewCampaignSubtitle>Add similar company URLs:</NewCampaignSubtitle>
                <FieldArray
                  name="lookalikes"
                  render={(arrayHelpers) => (
                    <ExamplesOfCompaniesRender
                      values={values}
                      arrayHelpers={arrayHelpers}
                      disabled={true}
                    />
                  )}
                />
              </ExamplesOfCompaniesForm>
            )}
            {values.keywords?.length > 0 && (
              <>
                <NewCampaignSubtitle>Topics</NewCampaignSubtitle>
                <KeywordsContainer>
                  <SelectContainer>
                    <FormikSelect name="keywords" disabled />
                  </SelectContainer>
                </KeywordsContainer>
              </>
            )}
            {values.keywordsExcluded?.length > 0 && (
              <>
                <NewCampaignSubtitle>Exclude Keywords</NewCampaignSubtitle>
                <KeywordsContainer>
                  <SelectContainer>
                    <FormikSelect name="keywordsExcluded" disabled />
                  </SelectContainer>
                </KeywordsContainer>
              </>
            )}
            {values.countries?.length > 0 && (
              <GeographiesContainer>
                <NewCampaignSubtitle>Geographies</NewCampaignSubtitle>
                <SelectContainer>
                  <FormikTreeSelect
                    name="countries"
                    treeData={countries}
                    placeholder="Please select countries of interest, or leave empty to limit the matching"
                    disabled
                  />
                </SelectContainer>
              </GeographiesContainer>
            )}
          </CardContent>
        </Card>
        {values.clusters.length !== 0 ? (
          <Card>
            <CardHeader>
              <HeadTitle>Clusters</HeadTitle>
            </CardHeader>
            <CardContent>
              <ClusterCollapse clusters={values.clusters} />
            </CardContent>
          </Card>
        ) : null}
      </CardsContainer>
      <SubmitContainer>
        <NextButton htmlType="submit" onClick={() => scrollToErrors(errors)}>
          Save <NextArrow />
        </NextButton>
      </SubmitContainer>
    </ContentCol>
  );
};

const EditorContainer = styled.div`
  border-radius: var(--border-radius);
  width: 100%;
`;

const ExamplesOfCompaniesForm = styled.div`
  width: 100%;
  margin-bottom: 2.8rem;
  display: flex;
  flex-direction: column;
`;

const KeywordsContainer = styled.div`
  width: 100%;
`;

const GeographiesContainer = styled.div`
  width: 100%;
`;

const ContentCol = styled.div`
  grid-area: content;
  padding: 2.4rem 2.4rem 0 0;
`;

const CardsContainer = styled.div`
  padding-left: 2.4rem;
`;

const CampaignFormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SelectContainer = styled.div`
  width: 100%;
  margin: 0;
`;

const Strategy = styled.p`
  color: var(--font-color-disabled-input) !important;
  cursor: text !important;
  padding: 4px 11px;
`;
