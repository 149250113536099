import { FC, useState } from "react";

import { StylelessButton } from "../../../../ui-components/StylelessButton";
import { StyledButtonText } from "../../../../ui-components/Typography";

import { UpdateTagModal } from "../UpdateTagModal";

interface UpdateTagButtonProps {
  tagUUID: string;
  tagName: string;
}

/**
 *  Based on the business logic, this is how the delete-user works
 *
 *  - Only admins can do it
 *  - If the user is the only admin, they cannot request to delete themselves
 *  - If the user has already been requested to be deleted, it cannot be done again
 */
export const UpdateTagButton: FC<UpdateTagButtonProps> = ({
  tagUUID,
  tagName,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <UpdateTagModal
        visible={modalVisible}
        handleModalClose={() => setModalVisible(false)}
        tagName={tagName}
        tagUUID={tagUUID}
      />
      <StylelessButton onClick={() => setModalVisible(true)}>
        <StyledButtonText>Edit</StyledButtonText>
      </StylelessButton>
    </>
  );
};
