import { FC, useState } from "react";
import styled from "@emotion/styled";

import { Col, Tooltip } from "antd";
import { ShareModal } from "./ShareModal";
import { ActionButton } from "../../../../../share/company/components/ui-share";
import { ShareAltOutlined } from "@ant-design/icons";

export const ShareButton: FC<{ GBCompanyUUID?: string }> = ({
  GBCompanyUUID,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const toggleShareModalVisibility = () => {
    setModalVisible((prevState) => !prevState);
  };

  return (
    <Col
      className="gutter-row"
      // xs={23}
      // sm={11}
      // md={11}
      // lg={11}
      // xl={11}
      // xxl={11}
    >
      <Tooltip
        placement="top"
        title="Share per email a Company and its information"
      >
        <ActionButton onClick={toggleShareModalVisibility}>
          <ShareAltOutlined />
          {/* <ButtonText>Share</ButtonText> */}
        </ActionButton>
      </Tooltip>
      {GBCompanyUUID && (
        <ShareModal
          modalVisible={modalVisible}
          handleModalClose={handleModalClose}
          companyUUID={GBCompanyUUID}
        />
      )}
    </Col>
  );
};
