import { FC } from "react";
import styled from "@emotion/styled";

import { PanelHeadTitle } from "../../ui-components/Typography";
import { EditButton } from "./EditButton";

interface CampaignDetailsPanelHeaderProps {
  displayEditButton: boolean;
  onEdit: () => void;
}

export const CampaignDetailsPanelHeader: FC<
  CampaignDetailsPanelHeaderProps
> = ({ displayEditButton, onEdit }) => {
  return (
    <Header>
      <PanelHeadTitle>Campaign Briefing</PanelHeadTitle>
      {displayEditButton && <EditButton onClick={onEdit} />}
    </Header>
  );
};

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: default;
`;
