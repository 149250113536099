import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import styled from "@emotion/styled";

import { CenteredSpinner } from "../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../ui-components/CenteredErrorMessage";

import { getClients, setSelectedClient, Client } from "./store";
import { logout } from "../auth/store";
import { State } from "../utils/store";
import { useAppDispatch, useAppSelector } from "../utils/hooks";

import { Segment } from "../utils/Segment";
import { isClientPremium } from "../helpers";

export const ClientsView = () => {
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const navigate = useNavigate();

  const [filter, setFilter] = useState("");

  const user = useAppSelector((state: State) => state.auth.user);
  const clients = useAppSelector((state: State) => state.clients.clients);
  const selectedClient = useAppSelector(
    (state: State) => state.clients.selectedClient
  );
  const { loading, success, error } = useAppSelector(
    (state: State) => state.clients.getClients
  );

  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);

  if (error) {
    return <CenteredErrorMessage>{error}</CenteredErrorMessage>;
  }

  if (loading || !success) {
    return <CenteredSpinner />;
  }

  if (success && clients.length === 0) {
    return (
      <>
        <h1>No clients</h1>
        <button onClick={() => dispatch(logout())}>logout</button>
      </>
    );
  }

  if (selectedClient) {
    // Redirect To options:
    // 1. There's a previous path name
    // 2. It comes from a "legacy" url, i.e. app.novable.com/dashboard, it has to be
    //    redirected to app.novable.com/<clientSlug>/dashboard
    let redirectTo;
    if (location?.state?.from) {
      redirectTo = location?.state?.addClientSlug
        ? `/${selectedClient.slug}/${location?.state?.from}`
        : location?.state?.from;
    } else redirectTo = `/${selectedClient.slug}/dashboard`;

    user &&
      Segment.analytics?.identify(user.uuid, {
        ...user,
        isPaidUser: isClientPremium(selectedClient),
        clientContractType: selectedClient.contract.contractName,
        clientID: selectedClient.uuid,
        clientName: selectedClient.name,
      });

    try {
      user &&
        FS.identify(user.uuid, {
          ...user,
          isPaidUser: isClientPremium(selectedClient),
          clientContractType: selectedClient.contract.contractName,
          clientID: selectedClient.uuid,
          clientName: selectedClient.name,
        });
    } catch (error) {
      console.error(error);
      // Expected output: ReferenceError: nonExistentFunction is not defined
      // (Note: the exact output may be browser-dependent)
    }

    navigate(redirectTo);
  }

  const handleClick = (client: Client) => {
    dispatch(setSelectedClient(client));
  };

  const filteredClients = clients.filter((client) =>
    client.name.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <>
      <h1>Clients View</h1>
      <SearchWrapper>
        <input
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
        ></input>
      </SearchWrapper>
      <ul>
        {filteredClients.map((client: Client) => (
          <li key={client.uuid}>
            {client.name}{" "}
            <button onClick={() => handleClick(client)}>Select</button>
          </li>
        ))}
        <button onClick={() => dispatch(logout())}>logout</button>
      </ul>
    </>
  );
};

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 3rem;
`;
