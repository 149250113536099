import styled from "@emotion/styled";
import { FC, useEffect, useState } from "react";
import { Popover } from "antd";

import { ExtraTopicsPopover } from "./ExtraTopicsPopover";
import { Tag } from "./Tag";

import { Segment } from "../../../../../utils/Segment";

const TAGS_LIMIT = 12;

interface DisplayTagsProps {
  tags: string[];
  tagsLimit?: number;
}

export const DisplayTags: FC<DisplayTagsProps> = ({ tags, tagsLimit }) => {
  const showTagsLimit = tagsLimit || TAGS_LIMIT;
  let [open, setOpen] = useState(false);
  let [uniqueTags, setUniqueTags] = useState<string[]>([]);
  let [showMoreTags, setShowMoreTags] = useState<string[]>([]);

  useEffect(() => {
    // First TAGS_LIMIT items will be displayed, and the rest will be hidden in a popover
    // that will display on click on "Show more"
    setShowMoreTags(tags.slice(showTagsLimit));
    setUniqueTags(tags.slice(0, showTagsLimit));
  }, [showTagsLimit, tags]);

  const eventName = "show-more-tags";
  const onVisibleChangePopover = (visible: boolean) => {
    setOpen(visible);

    visible && Segment.analytics?.track(eventName);
  };

  const closePopover = () => {
    setOpen(false);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {uniqueTags.map((tag) => (
        <Tag key={tag} tag={tag} />
      ))}
      {showMoreTags.length > 0 && (
        <Popover
          open={open}
          content={
            <ExtraTopicsPopover
              closePopover={closePopover}
              topics={showMoreTags}
            />
          }
          trigger="click"
          onOpenChange={onVisibleChangePopover}
          placement="bottomLeft"
        >
          <ShowMoreButton
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Show more
          </ShowMoreButton>
        </Popover>
      )}
    </div>
  );
};

const ShowMoreButton = styled.button`
  cursor: pointer;
  align-items: normal;
  background-color: var(--background-color-base);
  border-color: var(--primary-color);
  border-style: none;
  box-sizing: content-box;
  color: var(--primary-color);
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  -webkit-logical-height: 1rem; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */

  /* Mozilla uses a pseudo-element to show focus on buttons, */
  /* but anchors are highlighted via the focus pseudo-class. */
`;
