import { createSlice, createSelector } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { fork, put, takeEvery } from "redux-saga/effects";

import { backend } from "../utils/http";
import { State } from "../utils/store";

export const SELECTED_CLIENT_LOCAL_STORAGE_KEY = "selectedClient";

export enum UserRole {
  ADMIN = "admin",
  CONTRIBUTOR = "contributor",
}

interface ClientEnrichedCompanyProfilePreferences {
  hasBusinessCategory: boolean;
  hasContactCategory: boolean;
  hasPatentCategory: boolean;
}

export enum ContractType {
  DEMO = "DEMO",
  STARTER = "STARTER",
  PRO = "PRO",
  PARTNER = "PARTNER",
  CUSTOM = "CUSTOM",
  EXPLORER = "EXPLORER",
  NAVIGATOR = "NAVIGATOR",
}

export interface Contract {
  uuid: string;
  contractName: ContractType;
  limitSearches: number | null;
  limitScott: number | null;
  limitIterations: number | null;
  limitCampaigns: number | null;
  limitProServiceHours: number;
  consumedProServiceHours: number;
  startDate: string;
  endDate: string;
  hasAutomaticExport: boolean;
}

export interface Client {
  uuid: string;
  name: string;
  clientLogo: string | null;
  limitDrafts: number;
  availableSearches: number | null;
  isBetaTester: boolean;
  userRole: string | null;
  slug: string;
  enrichedCompanyProfileRequests: number;
  enrichedCompanyProfilePreferences: ClientEnrichedCompanyProfilePreferences;
  contract: Contract;
}

export interface ClientsState {
  getClients: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  getClient: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  clients: Client[];
  selectedClient: Client | null;
}

const initialState = {
  getClients: {
    loading: false,
    error: "",
    success: false,
  },
  getClient: {
    loading: false,
    error: "",
    success: false,
  },
  clients: [],
  selectedClient: null,
} as ClientsState;

export const clientsSlice = createSlice({
  name: "clients",
  initialState: initialState,
  reducers: {
    decreaseSelectedClientEnrichedProfileRequestLimit(state) {
      if (
        state.selectedClient &&
        state.selectedClient.enrichedCompanyProfileRequests
      ) {
        state.selectedClient.enrichedCompanyProfileRequests =
          state.selectedClient.enrichedCompanyProfileRequests - 1;
      }
    },
    getClients(state) {
      state.getClients.loading = true;
    },
    getClientsSuccess(state, action) {
      state.getClients.loading = false;
      state.getClients.success = true;
      state.clients = action.payload.data;
    },
    getClientsFailure(state, action) {
      state.getClients.loading = false;
      state.getClients.success = false;
      state.getClients.error = action.payload.message;
    },
    getClient(state, action) {
      state.getClient.loading = true;
    },
    getClientSuccess(state, action) {
      state.getClient.loading = false;
      state.getClient.success = true;
      state.selectedClient = action.payload.data;
    },
    getClientFailure(state, action) {
      state.getClient.loading = false;
      state.getClient.success = false;
      state.getClient.error = action.payload.message;
    },
    setSelectedClient(state, action) {
      state.selectedClient = action.payload;
    },
    setInitialSelectedClientState(state) {
      state.selectedClient = initialState.selectedClient;
      localStorage.removeItem(SELECTED_CLIENT_LOCAL_STORAGE_KEY);
    },
    decreaseAvailableSearches(state) {
      if (
        state.selectedClient &&
        state.selectedClient.availableSearches &&
        state.selectedClient.availableSearches !== 0
      )
        state.selectedClient.availableSearches =
          state.selectedClient.availableSearches - 1;
    },
  },
});

const selectedClient = (state: State) => state.clients.selectedClient;
export const selectedClientHasAutomaticExport = () =>
  createSelector(selectedClient, (client) => {
    if (!client) return;
    return client.contract.hasAutomaticExport;
  });

function* getClientSaga() {
  yield takeEvery(clientsSlice.actions.getClient, function* (action) {
    try {
      const response: AxiosResponse = yield backend.clientsBackend.getClient(
        action.payload
      );
      yield put(clientsSlice.actions.getClientSuccess(response));
    } catch (err: any) {
      if (err?.status === 404) {
        yield put(
          clientsSlice.actions.getClientFailure({
            message: "Error accessing the client",
          })
        );
      } else yield put(clientsSlice.actions.getClientFailure(err));
    }
  });
}

function* getClientsSaga() {
  yield takeEvery(clientsSlice.actions.getClients, function* (action) {
    try {
      const response: AxiosResponse<Client[]> =
        yield backend.clientsBackend.getClients();
      const localClientUUID = localStorage.getItem(
        SELECTED_CLIENT_LOCAL_STORAGE_KEY
      );

      if (response.data.length === 1) {
        yield put(clientsSlice.actions.setSelectedClient(response.data[0]));
      } else if (localClientUUID) {
        const selectedClient = response.data.find(
          (client: any) => client.uuid === localClientUUID
        );
        yield put(clientsSlice.actions.setSelectedClient(selectedClient));
      }

      yield put(clientsSlice.actions.getClientsSuccess(response));
    } catch (err) {
      yield put(clientsSlice.actions.getClientsFailure(err));
    }
  });
}

function* setInitialSelectedClientSaga() {
  yield takeEvery(
    clientsSlice.actions.setInitialSelectedClientState,
    function* () {
      yield localStorage.removeItem(SELECTED_CLIENT_LOCAL_STORAGE_KEY);
    }
  );
}

function* setSelectedClientSaga() {
  yield takeEvery(clientsSlice.actions.setSelectedClient, function* (action) {
    if (action.payload) {
      yield localStorage.setItem(
        SELECTED_CLIENT_LOCAL_STORAGE_KEY,
        action.payload.uuid
      );
    }
  });
}
export function* clientsSaga() {
  yield fork(getClientSaga);
  yield fork(getClientsSaga);
  yield fork(setInitialSelectedClientSaga);
  yield fork(setSelectedClientSaga);
}

export const {
  getClient,
  getClients,
  setSelectedClient,
  setInitialSelectedClientState,
} = clientsSlice.actions;
