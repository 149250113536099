import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { CenteredSpinner } from "./CenteredSpinner";

interface LongLoaderWithMessageProps {
  message: string;
  interval?: number;
}

export const LongLoaderWithMessage: FC<LongLoaderWithMessageProps> = ({
  message,
  interval,
}) => {
  const [displayLongLoadingMessage, setDisplayLongLoadingMessage] =
    useState(false);

  useEffect(() => {
    let timerInterval = interval || 10;
    let timer = setTimeout(() => {
      setDisplayLongLoadingMessage(true);
    }, timerInterval * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [interval]);

  return (
    <LoadingWrapper>
      <CenteredSpinner />
      {displayLongLoadingMessage && (
        <span style={{ marginTop: "1rem" }}>{message}</span>
      )}
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
