import { FC } from "react";

import { AgentComment } from "./detail-components/body/AgentComment";
import { ClientComment } from "./detail-components/body/ClientComment";
import { CommentSection } from "./detail-components/body/CommentSection";
import { Feedback } from "./detail-components/body/Feedback";
import { FundingInformationWithShow } from "./detail-components/body/FundingInformationWithShow";
import { GeneralInformationWithHighlights } from "./detail-components/body/GeneralInformationWithHighlights";
import {
  ClearbitAttribution,
  Content,
  CrunchbaseAttribution,
  News,
  Snapshot,
  Topics,
} from "../../../share/company/components/company-detail/body";
import { CompanyClassification } from "../../../share/company/components/company-detail/body/CompanyClassification";
import { ESGLinks } from "../../../share/company/components/company-detail/body/ESGLinks";
import { EnrichedCompanyProfile } from "../../../share/company/components/company-detail/body/EnrichedCompanyProfile";
import { ActivityIndexExpanded } from "../../../share/company/components/company-detail/body/ActivityIndexExpanded";
import { Tags } from "../../../share/company/components/company-detail/body/Tags";
import { ShowWithAnimation } from "../../../ui-components/ShowWithAnimation";

import { GBCompanyDetail } from "../store";
import { selectedCampaignSelector } from "../../../share/campaigns/store";
import { State } from "../../../utils/store";
import { useAppSelector } from "../../../utils/hooks";

import {
  getHighlightTopicsFromCampaign,
  getCompanyTopics,
} from "../../../helpers";

interface GoldenBasketDetailContentProps {
  company: GBCompanyDetail;
  campaignUUID: string;
}

export const GoldenBasketDetailContent: FC<GoldenBasketDetailContentProps> = ({
  company,
  campaignUUID,
}) => {
  const { logoStatus } = useAppSelector((state: State) => state.goldenBasket);
  const campaign = useAppSelector(selectedCampaignSelector(campaignUUID));

  const displayClearbitAttribution = !logoStatus.loading && !logoStatus.error;

  let topics = getCompanyTopics(company);

  let highlightTopics: string[] = getHighlightTopicsFromCampaign(campaign);

  const hasFundingDate = company.lastFundingDate;
  const hasFundingRounds = company.fundingRounds || company.fundingRounds === 0;
  const hasTotalFunding = company.totalFunding || company.totalFunding === 0;
  const displayFundingInformation =
    hasFundingDate || hasFundingRounds || hasTotalFunding;

  return (
    <Content>
          {company.feedback && (
            <ShowWithAnimation isMounted={"feedback" in company}>
              <Feedback feedback={company.feedback} />
            </ShowWithAnimation>
          )}

          {company.shortDescription && (
            <GeneralInformationWithHighlights
              description={company.shortDescription}
              wordsToHighlight={highlightTopics}
            />
          )}
          
          <CommentSection>
            {company.agentComment && (
              <AgentComment comment={company.agentComment} />
            )}
            <ClientComment
              comment={company.clientComment}
              author={company.clientCommentAuthor}
              commentDate={company.clientCommentCommentedAt}
            />
          </CommentSection>

          {displayFundingInformation && (
            <FundingInformationWithShow
              lastFundingDate={company.lastFundingDate}
              fundingRounds={company.fundingRounds}
              totalFunding={company.totalFunding}
              currencyCode={company.currency}
              companyUUID={company.uuid}
            />
          )}

          {company.url && <Snapshot url={company.url} />}

          {company.news && company.news.length > 0 && (
            <News news={company.news} />
          )}

          {company.esgLinks && company.esgLinks.length > 0 && (
            <ESGLinks links={company.esgLinks} />
          )}

          {(company.activityIndex || company.activityIndex === 0) && (
            <ActivityIndexExpanded activityIndex={company.activityIndex} />
          )}

          {company.classification?.length > 0 && (
            <CompanyClassification tags={company.classification} />
          )}

          {company.companyTags && company.companyTags.length > 0 && (
            <Tags tags={company.companyTags} />
          )}

          {topics.length > 0 && (
            <Topics topics={topics} highlightTopics={highlightTopics} />
          )}
          {/* {company.twitter && (
            <TwitterFeed name={company.name} url={company.twitter} />
          )} */}

          <EnrichedCompanyProfile
            company={company}
            campaignUUID={campaignUUID}
          />

          {company.crunchbaseUrl && (
            <CrunchbaseAttribution crunchbaseUrl={company.crunchbaseUrl} />
          )}
          
          {displayClearbitAttribution && <ClearbitAttribution />}

    </Content>
  );
};
