import { FC } from "react";
import { Scatter } from "@ant-design/plots";

import {
  Dimensionality,
  CompanyDimensionality,
  CapitalizedCompanyStatusColors,
} from "../store";

import { locationIconSVG, teamIconSVG } from "./Icons";
import { getLocation } from "../../../helpers";

const dimensionalityTooltipCard = (data: any) => {
  /**
   * Data is expected to come in the format
   * [
   *  {
   *    title: ...,
   *    name: "x",
   *    value:...,
   *    data:{
   *      dimensionality:[p1, p2],
   *      company:{...}
   *    }
   *  }
   * ]
   *
   */
  if (!data || data.length === 0) return "";
  const { company } = data[0]?.data;

  if (!company) return "";

  const location = getLocation(company.city, company.countryCode);

  return `
          <div class="ant-card" style="padding: 1rem; max-width:500px">
            <div class="ant-card-body">
              <div style="display:flex; align-items:center">
                <object data="${company.logo}" 
                        type="image/png"
                        width="75"
                        height="75"
                >
                  <img
                    alt="Default Company logo"
                    src="${
                      process.env.REACT_APP_FRONTEND_URL
                    }/static/media/default-logo.114450ad.png"
                    width="75"
                    height="75"
                  />
                </object>
                
                <div style="padding-top:1rem; margin-left: 1rem;">
                  <p style="margin:0;">${company.name}</p>
                  <div style="display:flex; align-items:center; gap:2.5rem; padding-top:1rem;">
                  ${
                    location
                      ? `<div style="display:flex; align-items:center; gap:0.5rem;max-width:50%;">
                          <div style="margin-left:0.5rem">${locationIconSVG}</div>
                          <span>${location}</span>
                        </div>`
                      : ""
                  }
                    ${
                      company.employeesRangeStart && company.employeesRangeEnd
                        ? `<div style="display:flex; align-items:center; gap:0.5rem;max-width:50%;">
                            <div style="margin-left:0.5rem">${teamIconSVG}</div>
                            ${company.employeesRangeStart} - ${company.employeesRangeEnd}
                          </div>`
                        : ""
                    }
                  </div>
                </div>
              </div>
              <div >
                <p>
                  ${company.shortDescription}
                </p>
              </div>
              <div style="height: 4.4rem;margin-top: 1.6rem;border-top: var(--campaign-card-border);display: flex;align-items: center;">
                <a
                  href="${
                    company.url
                  }?utm_source=NovableDotCom&amp;utm_campaign=StartupScouting"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="website"
                >
                  ${company.url}
                </a>
              </div>
            </div>
          </div>
            `;
};

interface dimensionalityPieChartProps {
  dimensionality: Dimensionality;
}

export const CampaignDimensionalityScatterPlot: FC<dimensionalityPieChartProps> =
  ({ dimensionality }) => {
    const scatterPlotData = dimensionality.map(
      (dimensionalityPoint: CompanyDimensionality) => {
        return {
          x: dimensionalityPoint.dimensionality[0],
          y: dimensionalityPoint.dimensionality[1],
          title: dimensionalityPoint.company.name || "",
          ...dimensionalityPoint,
        };
      }
    );

    // Height is adjusted to match the Total Companies height
    const config = {
      appendPadding: 20,
      height: 416,
      data: scatterPlotData,
      xField: "x",
      yField: "y",
      colorField: "companyStatus",
      color: (datum: any) => {
        const companyStatusKey =
          datum.companyStatus as keyof typeof CapitalizedCompanyStatusColors;
        return CapitalizedCompanyStatusColors[companyStatusKey];
      },
      size: 5,
      shape: "circle",
      pointStyle: {
        fillOpacity: 1,
      },
      yAxis: {
        grid: null,
        line: null,
        label: {
          formatter: (text: string, item: any, index: any) => {
            return "";
          },
        },
      },
      xAxis: {
        tickLine: null,
        grid: null,
        line: null,
        label: {
          formatter: (text: string, item: any, index: any) => {
            return "";
          },
        },
      },
      tooltip: {
        enterable: true,
        customContent: (title: string, data: any) => {
          return dimensionalityTooltipCard(data);
        },
      },
    };

    return <Scatter {...config} />;
  };
