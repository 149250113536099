import styled from "@emotion/styled";
import { FC } from "react";

interface FirmographicsCellProps {
  children: React.ReactNode;
  label: React.ReactNode;
  height?: string;
  width?: string;
}

export const FirmographicsCell: FC<FirmographicsCellProps> = ({
  children,
  label,
  height,
  width,
}) => {
  return (
    <Cell height={height} width={width}>
      <div>{label}</div>
      {children}
    </Cell>
  );
};

// 5 Cells per row max
const Cell = styled.div<{
  height: string | undefined;
  width: string | undefined;
}>`
  display: inline-block;
  width: ${(props) => (props.width ? props.width : "48%")};
  height: ${(props) => (props.height ? props.height : "20rem")};
  padding: 1rem;
  border: var(--firmographics-cell-border);
  border-radius: var(--firmographics-cell-border-radius);
`;
