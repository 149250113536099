import styled from "@emotion/styled";
import { useState, useEffect } from "react";

import { ListOfThreads } from "./components/ListofThreads";
import { ChatBot } from "./components/ChatBot";
import { v4 as uuidv4 } from 'uuid';
import { Segment } from "../utils/Segment";

import { getThreadsByUser, setInitialState, setSelectedThread } from "./store";

import { useAppDispatch, useAppSelector } from "../utils/hooks";
import { State } from "../utils/store";

export const ScottView = () => {
  const dispatch = useAppDispatch();

  const selectedClient = useAppSelector(
    (state: State) => state.clients.selectedClient
  );
  const selectedUser = useAppSelector(
    (state: State) => state.auth.user
  );
  const limitScott = useAppSelector(
    (state: State) => state.clients.selectedClient?.contract?.limitScott
  );
  const selectedThread = useAppSelector(
    (state: State) => state.scottThreadList.selectedThread
  );

  const threadList = useAppSelector(
    (state: State) => state.scottThreadList.threadList
  );
  
  const threadUuid = selectedThread?.thread_uuid;

  const { success: ThreadListSuccess, loading: ThreadListLoading } = useAppSelector(
    (state: State) => state.scottThreadList.getThreadsByUser
  );

  const clientUuid = selectedClient?.uuid;
  const userUuid = selectedUser?.uuid;

  useEffect(() => {
    if (userUuid && clientUuid) {
      dispatch(setInitialState());
      dispatch(getThreadsByUser(userUuid));
      Segment.analytics?.track(`Scott: open scott`, {
        userUuid: userUuid,
      });
    }
  }, [dispatch, clientUuid]); // eslint-disable-line

  return (
    <Container>
      <ListOfThreads userUuid={userUuid} />
      <ChatBot config={{ 
        thread_id: threadUuid, 
        client_uuid: clientUuid, 
        user_uuid: userUuid,
        limitScott: limitScott
        }} />
    </Container>
  );
};

const Container = styled.div`
  height: calc(100vh - 8rem);
  display: grid;
  grid-template-columns: 32rem 1fr;
  grid-gap: 0rem;
`;
