import styled from "@emotion/styled";
import { Button } from "./Button";

export const EditButton = styled(Button)`
  background: none;
  font-weight: 500;
  box-shadow: none;
  color: var(--primary-color);

  &:hover {
    background: none;
    box-shadow: none;
    color: var(--primary-color);
  }
`;
