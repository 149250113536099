import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { fork, put, takeEvery } from "redux-saga/effects";

import { Campaign } from "../../share/campaigns/store";
import { campaignsSlice } from "../../share/campaigns/store";

import { backend } from "../../utils/http";
import { getCampaignWithEmptyArray } from "../../helpers";

export interface EditCampaignData {
  uuid: string;
  clientUUID: string;
  name: string;
  description: string;
}

export interface EditCampaignState {
  getCampaign: {
    loading: boolean;
    message: string;
  };
  updateCampaign: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
  campaign: Campaign | null;
}

const initialState = {
  getCampaign: {
    loading: false,
    message: "",
  },
  updateCampaign: {
    loading: false,
    message: "",
    success: null,
  },
  campaign: null,
} as EditCampaignState;

export const editCampaignSlice = createSlice({
  name: "edit_campaign",
  initialState: initialState,
  reducers: {
    getCampaign(state, action: PayloadAction<string>) {
      state.getCampaign.loading = true;
    },
    getCampaignSuccess(state, action) {
      state.getCampaign.loading = false;
      state.getCampaign.message = "";
      state.campaign = action.payload;
    },
    getCampaignFailure(state, action) {
      state.getCampaign.loading = false;
      state.getCampaign.message = action.payload.data.message;
    },
    updateCampaign(state, action: PayloadAction<EditCampaignData>) {
      state.updateCampaign.loading = true;
      state.updateCampaign.success = null;
      state.updateCampaign.message = "";
    },
    updateCampaignSuccess(state, action) {
      state.updateCampaign.loading = false;
      state.updateCampaign.success = true;
      state.updateCampaign.message = action.payload.message;
      if (state.campaign) state.campaign.name = action.payload.data.name;
    },
    updateCampaignFailure(state, action) {
      state.updateCampaign.loading = false;
      state.updateCampaign.message = action.payload.data.message;
      state.updateCampaign.success = false;
    },
    setInitialState(state) {
      state.getCampaign = initialState.getCampaign;
      state.updateCampaign = initialState.updateCampaign;
      state.campaign = null;
    },
  },
});

function* getCampaignSaga() {
  yield takeEvery(editCampaignSlice.actions.getCampaign, function* (action) {
    try {
      const response: AxiosResponse<Campaign> =
        yield backend.campaignBackend.getCampaign(action.payload);
      const readyResponse = getCampaignWithEmptyArray(response.data);

      yield put(editCampaignSlice.actions.getCampaignSuccess(readyResponse));
    } catch (err) {
      yield put(editCampaignSlice.actions.getCampaignFailure(err));
    }
  });
}

function* updateCampaignSaga() {
  yield takeEvery(editCampaignSlice.actions.updateCampaign, function* (action) {
    try {
      const response: AxiosResponse<string> =
        yield backend.campaignBackend.updateCampaign(action.payload);

      yield put(editCampaignSlice.actions.updateCampaignSuccess(response));
      yield put(campaignsSlice.actions.updateCampaign(response.data));
    } catch (err) {
      yield put(editCampaignSlice.actions.updateCampaignFailure(err));
    }
  });
}

export function* editCampaignSaga() {
  yield fork(getCampaignSaga);
  yield fork(updateCampaignSaga);
}

export const { getCampaign, updateCampaign, setInitialState } =
  editCampaignSlice.actions;
