import { FC } from "react";
import styled from "@emotion/styled";

import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  ActivityIndexIcon as BaseActivityIndexIcon,
  Section as BaseSection,
  SectionHeader as BaseSectionHeader,
  SectionTitle,
} from "../../ui-share";
import { ActivityIndexProgressCircle } from "../../ui-share/ActivityIndexProgressCircle";
import { ActivityIndexTimeSeries } from "../../ui-share/ActivityIndexTimeSeries";

import { ActivityIndex } from "../../../store";

interface ActivityIndexExpandedProps {
  activityIndex: ActivityIndex;
}

const MIN_POINTS_TO_SHOW = 3;

export const ActivityIndexExpanded: FC<ActivityIndexExpandedProps> = ({
  activityIndex,
}) => {
  if (!activityIndex) {
    return <></>;
  }
  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Activity index</SectionTitle>
        &nbsp;
        {/* <Tooltip title="Activity index of the Company. It's a Novable-calculated index for this Company">
          <QuestionCircleOutlined />
        </Tooltip> */}
        <ActivityIndexProgressCircleWrapper>
          <ActivityIndexProgressCircle
            activityIndex={activityIndex.activityIndex}
          />
        </ActivityIndexProgressCircleWrapper>
      </SectionHeader>
      {/* {activityIndex.timeSeries &&
        activityIndex.timeSeries.length > MIN_POINTS_TO_SHOW && (
          <ActivityIndexTimeSeriesWrapper>
            <ActivityIndexTimeSeries
              activityIndexTimeSeries={activityIndex.timeSeries}
            />
          </ActivityIndexTimeSeriesWrapper>
        )} */}
    </Section>
  );
};

// The fact that Section is relative, and SectionHeader and ActivityIndexTimeSeriesWrapper
// are absolute is due to the two following factors:
//
// 1. The SVG from the LineChart inside ActivityIndexTimeSeriesWrapper positions "above" the SectionHeader
//    which makes it so that the (?) icon can not be hoverable (and thus info is not shown)
// 2. The SVG is very high, but the chart inside is not, in order to not reduce the size of the chart
//    a solution is to just "pull" it higher by modifying the position and top attributes.
const Section = styled(BaseSection)`
  position: relative;
  // height: 4rem;
  padding-bottom: 4rem;
`;

const SectionHeader = styled(BaseSectionHeader)`
  position: absolute;
  top: 0;
`;

const ActivityIndexIcon = styled(BaseActivityIndexIcon)`
  margin-right: 0.933rem;
`;

const ActivityIndexProgressCircleWrapper = styled.div`
  margin-left: 1rem;
`;

const ActivityIndexTimeSeriesWrapper = styled.div`
  position: absolute;
  top: 3rem;
  height: 20rem;
  width: 50%;

`;
