import styled from "@emotion/styled";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Navigate } from "react-router";

import { GoldenBasketTable } from "./GoldenBasketTable";
import { CenteredSpinner } from "../../ui-components/CenteredSpinner";

import { selectedCampaignSlugSelector } from "../../share/campaigns/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { State } from "../../utils/store";

import { Segment } from "../../utils/Segment";
import { CenteredErrorMessage } from "../../ui-components/CenteredErrorMessage";
import { ResultCount } from "../golden-basket/components/ui-share/ResultCount";

interface GoldenBasketTableViewProps {
  handleDisplayTableView: (shouldDisplay: boolean) => void;
}

export const GoldenBasketTableView: FC<GoldenBasketTableViewProps> = ({
  handleDisplayTableView,
}) => {
  const dispatch = useAppDispatch();
  const { GBList } = useAppSelector((state: State) => state.goldenBasket);

  const { campaignSlug } = useParams<{ campaignSlug: string }>();
  const campaign = useAppSelector(selectedCampaignSlugSelector(campaignSlug));

  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const {
    success: GBListSuccess,
    error: GBListError,
    loading: GBListLoading,
  } = useAppSelector((state: State) => state.goldenBasket.getGBList);

  const [totalResults, setTotalResults] = useState<number>(0);

  useEffect(() => {
    if (campaignSlug && campaign) {
      // dispatch(getGBList(campaign.uuid));
      Segment.analytics?.track(`open-campaign-table`, {
        campaignSlug: campaignSlug,
      });
    }
  }, [dispatch, campaignSlug, campaign]);

  useEffect(() => {
    if (GBListSuccess) {
      setTotalResults(GBList.length);
    }
  }, [GBList, GBListSuccess]);

  if (!campaignSlug || !campaign) {
    return <Navigate to={`/${selectedClient?.slug}/dashboard`} />;
  }

  if (GBListError) {
    return (
      <CenteredErrorMessage>
        Could not load list of Companies
      </CenteredErrorMessage>
    );
  }

  return (
    <Container>
      <div>
        <ResultCount
          handleDisplayTableView={handleDisplayTableView}
          campaignUUID={campaign.uuid}
        >
          {totalResults}
        </ResultCount>
      </div>
      {GBListError ? (
        <CenteredErrorMessage>
          Could not load list of Companies
        </CenteredErrorMessage>
      ) : GBListLoading || !GBListSuccess ? (
        <CenteredSpinner size="large" />
      ) : (
        <GoldenBasketTable
          campaign={campaign}
          setTotalResults={setTotalResults}
        />
      )}
    </Container>
  );
};

// 100% minus the header (8rem) minus the search bar (7rem)
const Container = styled.div`
  height: calc(100vh - 8rem - 7rem);
`;
