import { useEffect } from "react";
import styled from "@emotion/styled";

import { ListManagementTable } from "./components/ListManagementTable";
import { Button } from "../../ui-components/Button";
import {
  errorNotification,
  successNotification,
} from "../../ui-components/Notification";
import { CreateListModal } from "./components/CreateListModal";
import { Title as BaseTitle } from "../../ui-components/Typography";
import plusIcon from "../../assets/icons/plus.svg";

import { setCreateListModalVisibility } from "../store";
import { State } from "../../utils/store";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import { Segment } from "../../utils/Segment";

export const ListManagementView = () => {
  const dispatch = useAppDispatch();

  const { createListModalVisible } = useAppSelector(
    (state: State) => state.lists
  );
  const setCreateModalVisible = (visibility: boolean) => {
    Segment.analytics?.track("open-create-list", {
      source: "list-management-view",
    });
    dispatch(setCreateListModalVisibility(visibility));
  };

  const { error: updateListError, success: updateListSuccess } = useAppSelector(
    (state: State) => state.lists.updateList
  );
  const { error: deleteListError, success: deleteListSuccess } = useAppSelector(
    (state: State) => state.lists.deleteList
  );
  const { error: createListError, success: createListSuccess } = useAppSelector(
    (state: State) => state.lists.createList
  );

  useEffect(() => {
    if (updateListSuccess) {
      successNotification("List updated successfully");
    } else if (updateListError) {
      errorNotification(updateListError);
    }
  }, [updateListSuccess, updateListError]);

  useEffect(() => {
    if (deleteListSuccess) {
      successNotification("List deleted successfully");
    } else if (deleteListError) {
      errorNotification(deleteListError);
    }
  }, [deleteListSuccess, deleteListError]);

  useEffect(() => {
    if (createListSuccess) {
      successNotification("List created successfully");
    } else if (createListError) {
      errorNotification(createListError);
    }
  }, [createListSuccess, createListError]);

  return (
    <Content>
      <SectionHeader>
        <Title>List of lists</Title>
        <Button onClick={() => setCreateModalVisible(true)}>
          <ButtonContent>
            <PlusIcon /> Create list
          </ButtonContent>
        </Button>
      </SectionHeader>

      <ListManagementTable />
      <CreateListModal
        handleModalClose={() => setCreateModalVisible(false)}
        visible={createListModalVisible}
      />
    </Content>
  );
};

const Content = styled.div`
  padding: 2.4rem;
  background-color: var(--white);
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Title = styled(BaseTitle)`
  color: var(--152734);
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled.div`
  width: 1.667rem;
  height: 1.667rem;
  margin-right: 0.3rem;
  background: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  background-image: url(${plusIcon});
`;
