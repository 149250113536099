import { useLocation } from "react-router-dom";
import { Navigate } from "react-router";

import { SetPasswordFormFromToken } from "./components/SetPasswordFormFromToken";
import { SetPasswordFormFromInvite } from "./components/SetPasswordFormFromInvite";

import { State } from "../utils/store";
import { useAppSelector } from "../utils/hooks";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const NewUserView = () => {
  const { loading, success, message } = useAppSelector(
    (state: State) => state.auth.resetPassword
  );

  // Create password can be either from a created user (through admin tool)
  // or from an invitation from an admin.
  let token = useQuery().get("token");
  let invite = useQuery().get("invite");

  if ((!token && !invite) || success) {
    return <Navigate to="/login" />;
  }

  return invite ? (
    <SetPasswordFormFromInvite
      loading={loading}
      message={message}
      invite={invite}
    />
  ) : (
    <SetPasswordFormFromToken
      loading={loading}
      message={message}
      token={token}
    />
  );
};
