import { FC } from "react";
import styled from "@emotion/styled";

import { LinkedinOutlined } from "@ant-design/icons";
import { EnrichedProfileContact } from "../../../../store";

interface ContactSectionProps {
  contact: EnrichedProfileContact;
}

export const ContactSection: FC<ContactSectionProps> = ({ contact }) => {
  return (
    <Container>
      {contact.name && contact.linkedinUrl && (
        <Item>
          <Anchor href={contact.linkedinUrl} target={"_blank"} rel="noreferrer">
            <LinkedinOutlined /> <span>{contact.name}</span>
          </Anchor>
        </Item>
      )}
      {contact.name && !contact.linkedinUrl && (
        <Item>
          <span>{contact.name}</span>
        </Item>
      )}
      {contact.email && <Item>{contact.email} </Item>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.7rem 0.5rem;
  width: min-content;
  height: fit-content;
  padding: 1rem;
  border: var(--card-border);
  border-radius: 1rem;
`;

const Item = styled.span``;

const Anchor = styled.a`
  display: flex;
  align-items: baseline;
  column-gap: 0.3rem;
`;
