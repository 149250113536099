import styled from "@emotion/styled";
import pendingStatus from "../../assets/states/pending-status.svg";

export const StatusPendingImage = styled.div`
  height: 32rem;
  width: 40rem;
  background-image: url(${pendingStatus});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;
