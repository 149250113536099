import styled from "@emotion/styled";
import { FC } from "react";

import {
  Section,
  SectionHeader,
  SectionTitle,
  SectionContent as BaseSectionContent,
} from "../../ui-share";
import { CompanyPageFieldName } from "../../../../../ui-components/Typography";

import fundingIcon from "../../../../../assets/icons/funding.svg";

import { toNumberWithSeparators } from "../../../../../helpers";

type currencySymbolsType = {
  [key: string]: string;
};

const getLocale = () => "fr";

const currencySymbols: currencySymbolsType = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};

export const FundingInformation: FC<{
  lastFundingDate: Date | null;
  fundingRounds: number | null;
  totalFunding: number | null;
  currencyCode: string | null;
}> = ({ lastFundingDate, fundingRounds, totalFunding, currencyCode }) => {
  let currency =
    currencyCode && Object.keys(currencySymbols).includes(currencyCode)
      ? currencySymbols[currencyCode]
      : currencyCode;

  let formatedLastFundingDate;
  if (lastFundingDate) {
    let d = new Date(lastFundingDate);
    formatedLastFundingDate = d.toLocaleDateString(getLocale());
  }

  return (
    <Section>
      <SectionHeader>
        <FundingIcon />
        <SectionTitle>Funding information</SectionTitle>
      </SectionHeader>
      <SectionContent>
        {formatedLastFundingDate && (
          <FundingInfo>
            <CompanyPageFieldName>Last funding date on:</CompanyPageFieldName>
            {formatedLastFundingDate}
          </FundingInfo>
        )}
        {(fundingRounds === 0 || fundingRounds) && (
          <FundingInfo>
            <CompanyPageFieldName>Funding rounds:</CompanyPageFieldName>
            {fundingRounds}
          </FundingInfo>
        )}

        {(totalFunding === 0 || totalFunding) && (
          <FundingInfo>
            <CompanyPageFieldName>Total funding:</CompanyPageFieldName>
            {toNumberWithSeparators(totalFunding)}
            {currency && currency}
          </FundingInfo>
        )}
      </SectionContent>
    </Section>
  );
};

const SectionContent = styled.div`
margin-left: var(--gb-content-section-left-margin);
display: flex;
  flex-wrap: wrap;
`;

const FundingIcon = styled.div`
  background: url(${fundingIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1.333rem;
  height: 1.333rem;
  margin-right: 0.933rem;
`;

const FundingInfo = styled(BaseSectionContent)`
  display: flex;
  flex-align: left;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
`;
