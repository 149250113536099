import { useEffect } from "react";

import { Table as BaseTable } from "antd";

import { CenteredErrorMessage } from "../../../ui-components/CenteredErrorMessage";

import { getClientTags } from "../../store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { columns } from "./columns";
import styled from "@emotion/styled";

export const TagManagementTable = () => {
  const dispatch = useAppDispatch();
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { tags } = useAppSelector((state: State) => state.tags);

  const { loading, error } = useAppSelector(
    (state: State) => state.tags.getClientTags
  );

  // Refresh tags in case another user in the client instance has modified or created tags
  useEffect(() => {
    if (selectedClient) dispatch(getClientTags(selectedClient.uuid));
  }, [dispatch, selectedClient]);

  if (error) {
    return <CenteredErrorMessage>{error}</CenteredErrorMessage>;
  }

  const tableColumns = columns();

  return (
    <Table
      dataSource={tags}
      columns={tableColumns}
      rowKey={(record: any) => (record && record.uuid) || ""}
      loading={loading}
    />
  );
};

const Table = styled(BaseTable)`
  .ant-table-cell {
    padding: 0.8rem 1.6rem;
  }
`;
