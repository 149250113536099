import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { Input as BaseInput, Skeleton, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Header, NovableLogo, Logo } from "./components";
import { PoweredParagraph } from "../../Typography";

import {
  setDraftName,
  generateDraftName,
  campaignAndDraftNames,
} from "../../../new-campaign/store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

export const TopBarNewCampaign = () => {
  const dispatch = useAppDispatch();
  const allCampaignAndDraftNames = useAppSelector(campaignAndDraftNames());
  const autoGeneratedDraftName = useAppSelector(generateDraftName());
  const { name: campaignName } = useAppSelector(
    (state: State) => state.newCampaign.draftCampaign
  );
  const { loading } = useAppSelector(
    (state: State) => state.newCampaign.fetchDraftCampaign
  );

  let { uuid: uuidParam } = useParams<{ uuid: string }>();

  const [error, setError] = useState("");

  // A name is valid if there is no other campaign/draft with the same name,
  // if the one that has the same name, is itself
  // if the name is the initial draft name
  // or if the name is the current one
  const isInvalidDraftName = (name: string) => {
    const filteredCampaignsList = allCampaignAndDraftNames.find(
      (x) => x.name === name
    );
    if (!filteredCampaignsList) return false;
    return (
      autoGeneratedDraftName !== name &&
      name !== campaignName &&
      filteredCampaignsList.uuid !== uuidParam
    );
  };

  useEffect(() => {
    // If uuid in url, a draft was loaded. NewCampaignView will handle the setting of draft name
    if (uuidParam) return;
    dispatch(setDraftName(autoGeneratedDraftName));
    // do not add autoGeneratedDraftName to list of dependencies. On each save of setDraftName
    // it will change
  }, [dispatch, uuidParam]); // eslint-disable-line

  const handleOnChange = (event: any) => {
    const newName = event.target.value;
    if (isInvalidDraftName(newName)) {
      setError("This name already exists in a draft or campaign");
    } else {
      dispatch(setDraftName(newName));
      setError("");
    }
    if (!newName) setError("Name cannot be empty");
  };

  const highlightIfInitialDraftName = (event: any) => {
    if (autoGeneratedDraftName === campaignName) {
      event.target.select();
    }
  };

  // Cleanup of error
  useEffect(() => {
    if (uuidParam) setError("");
  }, [uuidParam]);

  return (
    <Header>
        {loading ? (
          <Skeleton.Input
            style={{ width: 200, margin: "50% 0" }}
            active={true}
            size={"default"}
          />
        ) : (
          <Input
            size="large"
            onChange={handleOnChange}
            value={campaignName}
            type="text"
            suffix={<EditOutlined />}
            onFocus={highlightIfInitialDraftName}
            isvalid={error ? 1 : 0}
          />
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      <NovableLogo>
        <Logo />
      </NovableLogo>
    </Header>
  );
};

const Input = styled(BaseInput)<{ isvalid: number }>`
  width: 30rem;
  background-color: var(--background-color-primary);
  color: black;
  border: none;
  outline: none;
  height: 3rem;

  box-shadow: ${(props) =>
    props.isvalid === 0
      ? "var(--campaign-name-input-box-shadow)"
      : "var(--campaign-name-input-box-shadow-error)"};

  & input {
    background-color: rgba(0, 0, 0, 0);
    color: var(--font-color-default);
    border: none;
    outline: none;
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: var(--campaign-name-input-box-shadow-active);
  }
`;

const ErrorMessage = styled.span`
  padding: 0.6rem 1.1rem;
  font-size: 1.6rem;
  color: var(--font-color-error);
`;
