import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fork, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { backend } from "../utils/http";
import {
  BaseCompanyMinified,
  CompanyDetail,
  EnrichedProfileStatus,
} from "../share/company/store";

import { companySlice, FavouriteCompanyData } from "../share/company/store";

export interface FetchFavouriteListData {
  clientUUID: string;
  page: number;
  pageSize: number;
}

interface FetchFavouriteListReturnData {
  results: CompanyDetail[];
  previousPage: number;
  nextPage: string;
  totalResults: number;
  currentPage: number | null;
  lastPage: number;
}

export interface FavouriteState {
  fetchFavouriteList: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  unfavouriteCompanySuccess: boolean;
  favouriteList: BaseCompanyMinified[];
  totalResults: number;
  currentPage: number | null;
  lastPage: number;
}

const initialState = {
  fetchFavouriteList: {
    loading: false,
    error: "",
    success: false,
  },
  unfavouriteCompanySuccess: false,
  favouriteList: [],
  totalResults: -1,
  // To make initial comparison of page <= lastPage, it's necessary that this
  // value is set to negative, since page is set to 1 as initial value
  lastPage: -1,
  currentPage: 0,
} as FavouriteState;

export const favouriteSlice = createSlice({
  name: "favourite_page",
  initialState: initialState,
  reducers: {
    setInitialState(state) {
      state.fetchFavouriteList = initialState.fetchFavouriteList;
      state.favouriteList = initialState.favouriteList;
      state.unfavouriteCompanySuccess = initialState.unfavouriteCompanySuccess;
      state.totalResults = initialState.totalResults;
      state.lastPage = initialState.lastPage;
      state.currentPage = initialState.currentPage;
    },
    fetchFavouriteList(state, action: PayloadAction<FetchFavouriteListData>) {
      state.fetchFavouriteList.loading = true;
      state.fetchFavouriteList.success = false;
      state.fetchFavouriteList.error = "";
    },
    fetchFavouriteListSuccess(state, action) {
      state.fetchFavouriteList.loading = false;
      state.fetchFavouriteList.success = true;
      state.fetchFavouriteList.error = "";
      const { data } = action.payload;
      state.totalResults = data.totalResults;
      const concatenatedCompanies: BaseCompanyMinified[] = [
        ...state.favouriteList,
        ...data.results,
      ];

      const result = [];
      const map = new Map();
      for (const company of concatenatedCompanies) {
        if (!map.has(company.companyUuid)) {
          map.set(company.companyUuid, true); // set any value to Map
          result.push(company);
        }
      }

      state.favouriteList = Array.from(result);
      state.lastPage = data.lastPage;
      state.currentPage = data.currentPage;
    },
    fetchFavouriteListFailure(state, action) {
      state.fetchFavouriteList.loading = false;
      state.fetchFavouriteList.error = action.payload.data?.message || "";
      state.fetchFavouriteList.success = false;
    },
    setFavEnrichedStatusToPending(state, action) {
      let position = state.favouriteList.findIndex(
        (company) => company.companyUuid === action.payload.uuid
      );
      if (position !== -1) {
        state.favouriteList[position].enrichedStatus =
          EnrichedProfileStatus.PENDING;
      }
    },
    // Needed to Call unfavouriteCompany from the specific store of favourite-page
    // since there are different possibilities or side-effects to take into account
    // from different types of unfavouriting
    unfavouriteCompany(state, action: PayloadAction<FavouriteCompanyData>) {},
    unfavouriteCompanySuccess(
      state,
      action: PayloadAction<FavouriteCompanyData>
    ) {
      state.favouriteList = state.favouriteList.filter(
        (company) => company.companyUuid !== action.payload.companyUUID
      );
      state.unfavouriteCompanySuccess = true;
      const newCount = state.totalResults - 1;
      state.totalResults = newCount < 0 ? 0 : newCount;
    },
  },
});

// Call the company slice to perform unfavourite request
// and "listen" to the success signal to perform the action
// of deleting the element from the list
function* unfavouriteCompanySaga() {
  yield takeEvery(
    favouriteSlice.actions.unfavouriteCompany,
    function* (action) {
      yield put(companySlice.actions.unfavouriteCompany(action.payload));
      yield put(companySlice.actions.setCompany(null));
    }
  );
  yield takeEvery(
    companySlice.actions.unfavouriteCompanySuccess,
    function* (action) {
      yield put(
        favouriteSlice.actions.unfavouriteCompanySuccess(action.payload)
      );
    }
  );
}

function* fetchFavouriteListSaga() {
  yield takeEvery(
    favouriteSlice.actions.fetchFavouriteList,
    function* (action) {
      try {
        const response: AxiosResponse<FetchFavouriteListReturnData> =
          yield backend.companiesBackend.fetchFavouritedCompanies(
            action.payload
          );

        yield put(favouriteSlice.actions.fetchFavouriteListSuccess(response));
      } catch (err) {
        yield put(favouriteSlice.actions.fetchFavouriteListFailure(err));
      }
    }
  );
}

export function* favouriteSaga() {
  yield fork(unfavouriteCompanySaga);
  yield fork(fetchFavouriteListSaga);
}

export const { unfavouriteCompany, fetchFavouriteList, setInitialState } =
  favouriteSlice.actions;
