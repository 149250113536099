import { Row, Col } from "antd";
import { FC } from "react";

import {
  ClearbitAttribution,
  Content,
  CrunchbaseAttribution,
  GeneralInformation,
  News,
  Snapshot,
  Topics,
  TwitterFeed,
} from "../../../share/company/components/company-detail/body";
import { FundingInformationWithShow } from "../../../campaign/golden-basket/components/detail-components/body/FundingInformationWithShow";
import { ActivityIndexExpanded } from "../../../share/company/components/company-detail/body/ActivityIndexExpanded";
import { CompanyClassification } from "../../../share/company/components/company-detail/body/CompanyClassification";
import { EnrichedCompanyProfile } from "../../../share/company/components/company-detail/body/EnrichedCompanyProfile";

import { CompanyDetail } from "../../../share/company/store";

export const ListCompanyDetailBody: FC<{ company: CompanyDetail }> = ({
  company,
}) => {
  let topics: string[] = [];
  if (company.tags) topics = [...company.tags];
  if (company.textKeywords) topics = [...topics, ...company.textKeywords];

  return (
    <Content>
        {company.shortDescription && (
          <GeneralInformation description={company.shortDescription} />
        )}

        {(company.lastFundingDate ||
          company.fundingRounds ||
          company.totalFunding) && (
            <FundingInformationWithShow
              lastFundingDate={company.lastFundingDate}
              fundingRounds={company.fundingRounds}
              totalFunding={company.totalFunding}
              currencyCode={company.currency}
              companyUUID={company.uuid}
            />
          )}

        {company.url && <Snapshot url={company.url} />}
        {company.news && company.news.length > 0 && (
          <News news={company.news} />
        )}

        {company.activityIndex && (
          <ActivityIndexExpanded activityIndex={company.activityIndex} />
        )}

        {company.classification?.length > 0 && (
          <CompanyClassification tags={company.classification} />
        )}
        {topics.length > 0 && <Topics topics={topics} />}

        <EnrichedCompanyProfile company={company} campaignUUID={null} />

        {/* {company.twitter && (
          <TwitterFeed name={company.name} url={company.twitter} />
        )} */}

        {company.crunchbaseUrl && (
          <CrunchbaseAttribution crunchbaseUrl={company.crunchbaseUrl} />
        )}

        <ClearbitAttribution />
    </Content>
  );
};
