import { FC } from "react";
import { useNavigate } from "react-router";
import { 
  PlusOutlined,
  EditOutlined
} from "@ant-design/icons";
import {
  Header,
  SectionTitle,
  TopbarButton,
  NovableLogo,
  Logo,
} from "./components";

import { canCreateMoreDrafts } from "../../../share/campaigns/store";

import { warningNoMoreDraftsAvailable } from "../share";
import { getThreadsByUser, setInitialState } from "../../../scott/store";
import { Segment } from "../../../utils/Segment";
import { State } from "../../../utils/store";
import { useAppSelector, useAppDispatch } from "../../../utils/hooks";

export interface TopbarProps {
  title: string | undefined;
  activeNewCampaign?: boolean;
  activeNewChat?: boolean;
}

export const Topbar: FC<TopbarProps> = ({ title, activeNewCampaign, activeNewChat }) => {
  const dispatch = useAppDispatch();
  const userUuid = useAppSelector((state: State) => state.auth.user?.uuid);

  const navigate = useNavigate();
  const _canCreateMoreDrafts = useAppSelector(canCreateMoreDrafts());
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  function onClick(event: any) {
    event.stopPropagation();
    if (!_canCreateMoreDrafts) {
      warningNoMoreDraftsAvailable();
    } else {
      Segment.analytics?.track("create-campaign", { source: "topbar" });
      navigate(`/${selectedClient?.slug}/new-campaign`);
    }
  }

  function onClickNewChat(event: any) {
    event.stopPropagation();
    Segment.analytics?.track("Scott: new chat", { 
      userUuid: userUuid,
    });
    if (userUuid) {
      dispatch(setInitialState());
      dispatch(getThreadsByUser(userUuid));
    }
  }

  return (
    <Header>
      <SectionTitle>{title ? title : "Section"}</SectionTitle>
      {activeNewCampaign && (
        <TopbarButton
          onClick={onClick}
          data-intercom-target="new-campaign-topbar-btn"
        >
          <PlusOutlined /> New campaign
        </TopbarButton>
      )}
      {activeNewChat && (
        <TopbarButton
          onClick={onClickNewChat}
          data-intercom-target="new-chat-topbar-btn"
        >
          <EditOutlined /> New Chat
        </TopbarButton>
      )}
      <NovableLogo>
        <Logo />
      </NovableLogo>
    </Header>
  );
};
