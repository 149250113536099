import styled from "@emotion/styled";

import noResults from "../../assets/states/no-results.svg";
import computerServer from "../../assets/states/computer-server.svg";

export { NoResult } from "./NoResult";

export const GoldenBasketStatusWrapper = styled.div`
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StatusMessage = styled.p`
  font-size: 1.2rem;
  line-height: 1.7rem;
  width: 60%;
  margin: 1rem auto;
  text-align: center;
`;

export const NoResultImage = styled.div`
  height: 32rem;
  width: 40rem;
  background-image: url(${noResults});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

export const UnkownErrorImage = styled.div`
  height: 32rem;
  width: 40rem;
  background-image: url(${computerServer});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;
