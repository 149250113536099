import { FC, useState } from "react";
import styled from "@emotion/styled";

import { Tooltip } from "antd";
import { RequestNewIterationModal } from "./RequestNewIterationModal";
import { Button as BaseButton } from "../../../../ui-components/Button";
import {
  upgradeYourPlan,
  UpgradeYourPlanTypes,
} from "../../../../ui-components/UpgradeYourPlan";

import { Campaign } from "../../../../share/campaigns/store";
import { State } from "../../../../utils/store";
import { useAppSelector } from "../../../../utils/hooks";
import { isClientPremium } from "../../../../helpers";

export interface RequestNewIterationButtonProps {
  campaign: Campaign;
}

export const RequestNewIterationButton: FC<RequestNewIterationButtonProps> = ({
  campaign,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { GBList } = useAppSelector((state: State) => state.goldenBasket);
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleToggleModalVisibility = () => {
    if (!selectedClient) return;
    if (!isClientPremium(selectedClient)) {
      upgradeYourPlan(
        "Upgrade your plan to request a Delivery",
        UpgradeYourPlanTypes.Discovery,
        { source: "request-new-iteration", client: selectedClient.uuid }
      );
      return;
    }
    setModalVisible(!modalVisible);
  };

  // If there's no data to export, don't show button
  if (GBList.length === 0) return <></>;

  return (
    <>
      <Tooltip title="Your agent will validate the new results.">
        <Button onClick={handleToggleModalVisibility}>
          Request a Delivery
        </Button>
      </Tooltip>

      <RequestNewIterationModal
        visible={modalVisible}
        handleModalClose={handleModalClose}
        campaignUUID={campaign?.uuid}
        clientUUID={selectedClient?.uuid}
      />
    </>
  );
};

const Button = styled(BaseButton)`
  -webkit-animation: scale-up-center 0.3s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  &:disabled {
    &:hover {
      color: var(--white);
    }
    background-color: var(--disabled-btn-background);
    color: var(--white);
  }
`;
