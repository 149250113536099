import styled from "@emotion/styled";

import teamIcon from "../../../../../assets/icons/team.svg";

export { CompanyListManagementButton } from "./CompanyListManagementButton";
export { CompanyTagManagementButton } from "./CompanyTagManagementButton";
export { Contact } from "./Contact";
export { EmployeesYearFoundedAndStatusSection } from "./EmployeesYearFoundedAndStatusSection";
export { FavouriteButton } from "./FavouriteButton";
export { HeaderActions } from "./HeaderActions";
export { Title } from "./CompanyHeaderName";

export const TeamIcon = styled.div`
  background: url(${teamIcon});
  width: 1.333rem;
  height: 0.933rem;
  margin-right: 0.933rem;
`;

export const SectionTitle = styled.p`
  font-size: 1.2rem;
  font-weight: var(--font-weight-bold);
  line-height: 1.8rem;
  letter-spacing: 0em;
  margin: 0;
  text-align: left;
`;

export const Section = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  color: var(--font-color-campaign-stats);

  li:last-child {
    border-right: none;
    padding-right: 0;
  }

  li:first-of-type {
    padding-left: 0;
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &:last-of-type {
    flex-wrap: nowrap;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }
`;

export const SubSection = styled.li`
  padding: 0 0.8rem;
  border-right: var(--campaign-stats-border-right);
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MetaData = styled.div`
  margin-left: 1.5rem;
  max-width: 80%;
`;

export const Image = styled.img`
  width: 8rem;
  height: 8rem;
`;

export const HeaderInformation = styled.div`
  width: 60%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  height: 12rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 3rem;  
  background: var(--background-color-primary);

  position: relative;
  &::after {
    position: absolute;
    left: 2.5%;
    bottom: 0;
    content: "";
    width: 95%;
    height: 1.5px;
    background: var(--separator-grey);
  }
`;
