import styled from "@emotion/styled";
import { Slider as BaseSlider } from "antd";

interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  disabled: boolean;
}

const labelStyles = {
  color: "var(--font-color-upgrade-plan)",
  fontWeight: "var(--font-weight-bold)",
  fontSize: "1rem",
};

const marks = {
  0: {
    style: labelStyles,
    label: "NOT IMPORTANT",
  },
  1: " ",
  2: " ",
  3: " ",
  4: " ",
  5: " ",
  6: " ",
  7: {
    style: labelStyles,
    label: "IMPORTANT",
  },
};

export const Slider = ({ value, disabled, onChange }: SliderProps) => {
  return (
    <SliderContainer onClick={(e) => e.stopPropagation()}>
      <BaseSlider
        marks={marks}
        step={null}
        included
        max={7}
        tooltipVisible={false}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  width: 30rem;

  .ant-slider {
    & .ant-slider-mark {
      top: 2.5rem;
      left: 3.5rem;
      width: 22.5rem;
      display: flex;
      justify-content: space-between;
    }
    & .ant-slider-handle {
      z-index: 4;
    }
    & .ant-slider-track {
      z-index: 3;
    }
    & .ant-slider-dot {
      z-index: 2;
      top: -0.6rem;
      height: 1.6rem;
      width: 0.1rem;
      margin-left: 0;
      border: none;
      border-radius: 0;
      background: var(--separator-grey);
    }

    & .ant-slider-dot-active {
      border-color: var(--separator-grey);
    }
  }
`;
