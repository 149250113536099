import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fork, put, takeEvery, select } from "redux-saga/effects";
import { v4 as uuidv4 } from 'uuid';

import { backend } from "../utils/http";

export interface ScottThread {
  threadUuid: string;
  initial_conversation: any[];
  threadSummary?: string | null;
}

export interface ScottThreadState {
  getThreadsByUser: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  threadList: any[];
  selectedThread: any;
}

const initialState = {
  getThreadsByUser: {
    loading: false,
    error: "",
    success: false,
  },
  threadList: [],
  selectedThread: null,
} as ScottThreadState;

export const scottThreadsSlice = createSlice({
  name: "scott_threads",
  initialState: initialState,
  reducers: {
    setInitialState(state) {
      return initialState;
    },
    setInitialThreadState(state) {
      state.selectedThread = initialState.selectedThread;
    },
    getThreadsByUser(state, action: PayloadAction<string>) {
      state.getThreadsByUser.loading = true;
      state.getThreadsByUser.success = false;
    },
    getThreadsByUserSuccess(state, action) {
      state.getThreadsByUser.loading = false;
      state.getThreadsByUser.success = true;
      state.threadList = action.payload;
    },
    getThreadsByUserFailure(state, action) {
      state.getThreadsByUser.loading = false;
      state.getThreadsByUser.error = "Error fetching threads";
      state.getThreadsByUser.success = false;
    },
    setSelectedThread(state, action) {
      state.selectedThread = action.payload || null;
    },
  },
});

// #######################
//          SAGAS
// #######################

function* getThreadsByUserSaga() {
  yield takeEvery(scottThreadsSlice.actions.getThreadsByUser, function* (action) {
    try {
      const response: ScottThread[] =
        yield backend.threadsBackend.getThreadsByUser(action.payload);
      
      yield put(scottThreadsSlice.actions.getThreadsByUserSuccess(response));
      yield put(scottThreadsSlice.actions.setSelectedThread({
        'thread_uuid': uuidv4(),
        'initial_conversation': [],
        'timestamp': new Date().toISOString(),
      }));
    } catch (err) {
      yield put(scottThreadsSlice.actions.getThreadsByUserFailure(err));
    }
  });
}

export function* scottThreadSaga() {
  yield fork(getThreadsByUserSaga);
}

export const {
  setInitialState,
  setInitialThreadState,
  getThreadsByUser,
  getThreadsByUserSuccess,
  getThreadsByUserFailure,
  setSelectedThread,
} = scottThreadsSlice.actions;
