import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Table as BaseTable, Input, TableProps } from "antd";
import { Columns } from "./components/columns";
import { ExpandedCompanyRow } from "./components/ExpandedCompanyRow";
import { Campaign } from "../../share/campaigns/store";
import { ExpandCompanyRowArrow } from "../../share/company/components/ui-share";

import { State } from "../../utils/store";
import { useAppSelector } from "../../utils/hooks";
import { getHighlightTopicsFromCampaign } from "../../helpers";
import { Segment } from "../../utils/Segment";

interface GoldenBasketTableProps {
  campaign: Campaign;
  setTotalResults: (totalResults: number) => void;
}

export const GoldenBasketTable: FC<GoldenBasketTableProps> = ({
  campaign,
  setTotalResults,
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const { GBList } = useAppSelector((state: State) => state.goldenBasket);
  const [searchBarContent, setSearchBarContent] = useState("");

  let filteredGBList = [...GBList];
  if (searchBarContent) {
    filteredGBList = filteredGBList.filter((GBcompany) =>
      GBcompany.company.name
        .toLowerCase()
        .includes(searchBarContent.toLowerCase())
    );
    setTotalResults(filteredGBList.length);
  }

  let highlightTopics: string[] = getHighlightTopicsFromCampaign(campaign);

  const handleRowClick = (companyUUID: string) => {
    if (expandedRowKeys.includes(companyUUID)) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys([companyUUID]);
    }
  };

  const tableColumns = Columns();

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: any }
  ) => {
    setTotalResults(extra.currentDataSource.length);
  };

  // Debounce event sent in order to not send an event per keystroke
  const SEND_EVENT_DELAY = 500; //ms
  const SEARCH_EVENT = "table-company-search";

  const sendEvent = () => {
    Segment.analytics?.track(SEARCH_EVENT);
  };

  useEffect(() => {
    if (!searchBarContent) return;

    const timer = setTimeout(() => sendEvent(), SEND_EVENT_DELAY);

    return () => {
      clearTimeout(timer);
    };
  }, [searchBarContent, SEND_EVENT_DELAY]);

  const handleOnSearchBarChange = (value: any) => {
    let data = value.target.value;
    setSearchBarContent(data);
  };

  return (
    <TableWrapper>
      <InputWrapper>
        <Input
          placeholder="Search by company name"
          onChange={handleOnSearchBarChange}
          value={searchBarContent}
        />
      </InputWrapper>

      <Table
        pagination={{ pageSize: 100 }}
        scroll={{
          scrollToFirstRowOnChange: true,
          y: "calc(100vh - 38rem)",
          x: "max-content",
        }}
        dataSource={filteredGBList}
        columns={tableColumns as any}
        rowKey={(record: any) => (record && record.uuid) || ""}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              event.preventDefault();
              handleRowClick(record.uuid);
            }, // click row
          };
        }}
        onChange={handleTableChange}
        expandable={{
          expandedRowKeys: expandedRowKeys,
          expandedRowRender: (record) => (
            <ExpandedCompanyRow
              goldenBasketCompany={record}
              highlightTopics={highlightTopics}
              visibleRows={expandedRowKeys}
            />
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <ExpandCompanyRowArrow
                isExpanded={true}
                onClick={(event) => onExpand(record, event)}
              />
            ) : (
              <ExpandCompanyRowArrow
                isExpanded={false}
                onClick={(event) => onExpand(record, event)}
              />
            ),
        }}
      />
    </TableWrapper>
  );
};

const Table: React.FC<TableProps<any>> = styled(BaseTable)`
  -webkit-animation: scale-up-center 0.3s cubic-bezier(0.26, 0.86, 0.36, 1) both;
  animation: scale-up-center 0.3s cubic-bezier(0.26, 0.86, 0.36, 1) both;

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-cell {
    padding: 12px 16px;
  }
`;

const TableWrapper = styled.div`
  padding: 2rem;
  // Screen height - Header height - some space for results and "padding"
  height: calc(100vh - 8rem);
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
`;

const InputWrapper = styled.div`
  width: 40%;
  margin-bottom: 2rem;
`;
