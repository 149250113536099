import { FC, useEffect } from "react";

import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  successNotification,
  errorNotification,
} from "../../../../../../ui-components/Notification";
import { StylelessButton } from "../../../../../../ui-components/StylelessButton";
import { StyledButtonText } from "../../../../../../ui-components/Typography";

import { deleteInvitation, filteredUserInList } from "../../../../../store";
import { State } from "../../../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../../../utils/hooks";

const { confirm } = Modal;

interface DeleteInvitationButtonProps {
  userUUID: string;
  clientUUID: string | undefined;
}

export const DeleteInvitationButton: FC<DeleteInvitationButtonProps> = ({
  userUUID,
  clientUUID,
}) => {
  const dispatch = useAppDispatch();

  const { success, loading, error, emailRequested } = useAppSelector(
    (state: State) => state.userManagement.deleteInvitation
  );
  const invitationUser = useAppSelector(filteredUserInList(userUUID));

  useEffect(() => {
    if (invitationUser && emailRequested === invitationUser.email) {
      if (success) {
        successNotification("This invitation has been revoked");
      } else if (success === false) {
        errorNotification(error);
      }
    }
  }, [success, loading, error, invitationUser, emailRequested]);

  const handleConfirm = () => {
    if (!clientUUID || !invitationUser) return;

    const data = {
      email: invitationUser.email,
      clientUUID: clientUUID,
    };
    dispatch(deleteInvitation(data));
  };

  const showConfirm = () => {
    confirm({
      title: "Do you want to revoke this invitation?",
      icon: <ExclamationCircleOutlined />,
      content: `User with email ${invitationUser?.email} will not be able to join anymore`,
      onOk() {
        handleConfirm();
      },
      okButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      okText: "Confirm",
    });
  };

  const isProcessingRequest =
    loading && invitationUser?.email === emailRequested;

  return (
    <StylelessButton
      onClick={showConfirm}
      loading={isProcessingRequest}
      disabled={isProcessingRequest}
    >
      <StyledButtonText>Delete</StyledButtonText>
    </StylelessButton>
  );
};
