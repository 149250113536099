import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Tooltip } from "antd";
import { TableOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { CardHeadTitle } from "../../../../ui-components/Typography";
import { ActionButton } from "../../../../ui-components/ButtonAction";

import sparkleIcon from "../../../../assets/icons/sparkle-icon.svg";

import { shouldDisplayTableView } from "../../../../helpers";

interface ResultCountProps {
  campaignUUID: string;
  handleDisplayTableView: (shouldDisplay: boolean) => void;
  children?: any;
}

const SHOW_TABLE_VIEW_ANIMATION_KEY = "show_table_view_animation";
export const ResultCount: FC<ResultCountProps> = ({
  campaignUUID,
  handleDisplayTableView,
  children,
}) => {
  const [_shoulDisplayTableView, setShoulDisplayTableView] = useState(
    shouldDisplayTableView(campaignUUID)
  );

  const handleToggleDisplayTable = () => {
    handleDisplayTableView(!_shoulDisplayTableView);
    setShoulDisplayTableView(!_shoulDisplayTableView);
  };

  const tooltipMessage = _shoulDisplayTableView
    ? "Display your Golden Basket in a list format"
    : "Display Companies in a Table View";
  const ButtonIcon = _shoulDisplayTableView
    ? UnorderedListOutlined
    : TableOutlined;

  // Steps to show the animation only once
  const hasAnimationBeenShown = localStorage.getItem(
    SHOW_TABLE_VIEW_ANIMATION_KEY
  );

  // let BtnWrapperClass = hasAnimationBeenShown ? Div : "BtnContainer";

  useEffect(() => {
    return () => {
      localStorage.setItem(SHOW_TABLE_VIEW_ANIMATION_KEY, "true");
    };
  }, []);

  return (
    <ResultContainer>
      <ResultsTitle>Results</ResultsTitle>
      <ResultsLength>{children}</ResultsLength>
      {/* <BtnWrapperClass> */}
        <Tooltip title={tooltipMessage}>
          <ActionButton
            onClick={handleToggleDisplayTable}
            style={{ marginLeft: "2rem" }}
          >
            <ButtonIcon style={{ color: "var(--primary-color)" }} />
          </ActionButton>
        </Tooltip>
      {/* </BtnWrapperClass> */}
    </ResultContainer>
  );
};

const ResultContainer = styled.div`
  height: 5.2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ResultsTitle = styled(CardHeadTitle)`
  padding: 0;
  margin: 0 0 0 1.6rem;
`;

const ResultsLength = styled.div`
  height: 2.4rem;
  padding: 0 0.5rem;
  border: var(--results-border);
  background: var(--separator-grey);
  border-radius: 7.2rem;
  display: grid;
  place-items: center;
  margin-left: 1rem;
`;

const BtnContainer = styled.div`
  &::after {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    -webkit-animation: wobble-hor-bottom 0.8s 3 both;
    animation: wobble-hor-bottom 0.8s 3 both;
  }
`;

const Div = styled.div`
  &::after {
    display: inline-block;
    content: url(${sparkleIcon});
    width: 2rem;
    height: 2rem;
    -webkit-transform: translateX(40%) translateY(15%);
    transform: translateX(40%) translateY(15%);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
`;
