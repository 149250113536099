import { Button } from "antd";
import styled from "@emotion/styled";

export const GhostButton = styled(Button)`
  background: none;
  border: var(--button-border);

  &:hover {
    background-color: var(--secondary-color);
    border: none;
    p {
      color: var(--font-color-white);
    }
    svg {
      filter: brightness(0) invert(1);
    }
  }
`;
