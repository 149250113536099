import { useEffect, useState } from "react";
import { Formik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { Segment } from "../utils/Segment";
import styled from "@emotion/styled";

import { Col, Empty, Row } from "antd";
import { CompanyCard } from "./components/CompanyCard";
import { MakeItACampaignModal } from "./components/MakeItACampaignModal";
import { Button } from "../ui-components/Button";
import { ActionButton } from "../ui-components/ButtonAction";
import { CenteredSpinner } from "../ui-components/CenteredSpinner";
import { FormikSelect } from "../ui-components/FormikSelect";
import { FormikTreeSelect } from "../ui-components/FormikTreeSelect";
import {
  errorNotification,
  successNotification,
} from "../ui-components/Notification";
import { StylelessButton } from "../ui-components/StylelessButton";
import {
  ButtonActionText,
  NewCampaignSubtitle,
} from "../ui-components/Typography";
import {
  upgradeYourPlan,
  UpgradeYourPlanTypes,
} from "../ui-components/UpgradeYourPlan";

import pendingStatus from "../assets/states/pending-status.svg";

import { searchCompanies, setInitialState } from "./store";
import { State } from "../utils/store";
import { useAppDispatch, useAppSelector } from "../utils/hooks";

import { countries } from "../utils/countries";

const MAX_RESULTS_SEARCH = 15;

interface initialFormikValuesInterface {
  keywords: String[];
  countries: String[];
}
const initialFormikValues: initialFormikValuesInterface = {
  keywords: [],
  countries: [],
};

export const CompaniesSearchView = () => {
  const dispatch = useAppDispatch();

  const [lastSearch, setLastSearch] = useState("");
  const [uuid, setUuid] = useState(uuidv4());

  const [modalVisible, setModalVisible] = useState(false);
  const {
    success: searchRequestSuccess,
    error: searchRequestError,
    loading: searchRequestLoading,
  } = useAppSelector((state: State) => state.companiesSearch.searchRequest);

  const selectedClient = useAppSelector(
    (state: State) => state.clients.selectedClient
  );

  const { loading, success, error } = useAppSelector(
    (state: State) => state.companiesSearch.searchCompanies
  );
  const { companies } = useAppSelector((state: State) => state.companiesSearch);

  const showLoading = loading && !error && !success;
  const showError = error && !loading && !success;
  const showSuccess = success && !loading && !error;
  const showInitial = !loading && !error && !success;
  const showMakeItACampaignCTA = companies.length > 0;

  const buttonShowUpgradeYourPlan = selectedClient?.availableSearches === 0;

  const [trackedOutOfFreeSearch, setTrackedOutOfFreeSearch] = useState(false);

  // Cleanup of company search store
  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (searchRequestSuccess === true && !searchRequestLoading) {
      toggleMakeItACampaignModalVisibility();
      successNotification("Email has been submitted successfully.");
    } else if (searchRequestSuccess === false) {
      errorNotification(searchRequestError);
    }
  }, [searchRequestSuccess, searchRequestError, searchRequestLoading]);

  const toggleMakeItACampaignModalVisibility = () => {
    setModalVisible((prevState) => !prevState);
  };

  const showMakeItACampaignModal = () => {
    setModalVisible(true);
  };

  const showUpgradeYourPlan = () => {
    if (!selectedClient) return;
    if (!trackedOutOfFreeSearch) {
      Segment.analytics?.track("out-of-free-search", {
        clientName: selectedClient.name,
        clientUUID: selectedClient.uuid,
      });
      setTrackedOutOfFreeSearch(true);
    }

    upgradeYourPlan(
      "Upgrade your plan to continue searching",
      UpgradeYourPlanTypes.Discovery,
      { source: "out-of-free-search", client: selectedClient.uuid }
    );
  };

  const handleReset = (resetForm: () => void) => {
    dispatch(setInitialState());
    resetForm();
  };

  return (
    <Content>
      <Formik
        initialValues={initialFormikValues}
        onSubmit={(values) => {
          if (!selectedClient) return;
          if (selectedClient.availableSearches === 0) {
            showUpgradeYourPlan();
            return;
          }

          let queryText = values.keywords.join(",");
          let searchUUID;
          if (queryText === lastSearch) {
            searchUUID = uuid;
          } else {
            searchUUID = uuidv4();
            setUuid(searchUUID);
          }

          setLastSearch(queryText);

          // Date stringified, in ISO format
          const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
          const localISOTime = new Date(Date.now() - tzoffset)
            .toISOString()
            .slice(0, -1);

          const data = {
            uuid: searchUUID,
            clientUUID: selectedClient.uuid,
            searchDate: localISOTime,
            text: queryText,
            countries: values.countries,
          };
          dispatch(searchCompanies(data));
        }}
        // Validation schema doesn't work in this case, since it doesn't
        // detect properly the case in which there is one keyword only
        validate={(values: any) => {
          let errors = {};
          if (values.keywords.length === 0) {
            errors = { keywords: "Please add some keywords" };
          }
          if (values.keywords.length === 15) {
            errors = {
              keywords:
                "Up to 15 keywords, try removing some of them before adding more",
            };
          }
          return errors;
        }}
        validateOnBlur={false}
      >
        {({ values, handleSubmit, resetForm }) => (
          <Form>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                xxl={12}
              >
                <NewCampaignSubtitle>Keywords</NewCampaignSubtitle>
                <FormikSelect
                  name="keywords"
                  mode="tags"
                  placeholder="Type keywords (ie VR, virtual reality, real-estate, consulting)"
                  maxResults={MAX_RESULTS_SEARCH}
                  style={{ marginTop: 0 }}
                />
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                xxl={12}
              >
                <NewCampaignSubtitle>Countries</NewCampaignSubtitle>
                <FormikTreeSelect
                  name="countries"
                  treeData={countries}
                  placeholder="Please select countries of interest, or leave empty for the entire world"
                  style={{ marginTop: 0 }}
                />
              </Col>
            </Row>

            <ButtonContainer>
              {selectedClient?.availableSearches !== undefined &&
                selectedClient?.availableSearches !== null && (
                  <RemainingSearches>
                    Remaining searches: {selectedClient?.availableSearches}
                  </RemainingSearches>
                )}
              <SearchButton
                disabled={values.keywords.length === 0}
                onClick={
                  buttonShowUpgradeYourPlan
                    ? () => showUpgradeYourPlan()
                    : () => handleSubmit()
                }
              >
                Search
              </SearchButton>
              <ActionButton
                htmlType="button"
                onClick={() => handleReset(resetForm)}
              >
                <ButtonActionText>Reset</ButtonActionText>
              </ActionButton>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
      <ResultContainer>
        <Results>
          <ResultsText>
            Results {success && `(${companies.length})`}{" "}
          </ResultsText>
          {showMakeItACampaignCTA ? (
            <StylelessButton onClick={showMakeItACampaignModal}>
              Make it a campaign
            </StylelessButton>
          ) : (
            ""
          )}{" "}
        </Results>

        {/* It's been decided that the error won't be actually shown, rather, the empty result */}
        {showError && (
          <Empty description="Your search has not generated results, change keywords and try again" />
        )}
        {showLoading && <CenteredSpinner />}
        {showSuccess &&
          (companies.length === 0 ? (
            <Empty description="Your search has not generated results, change keywords and try again" />
          ) : (
            <>
              <CompaniesContainer>
                {companies.map((company, index) => (
                  <Company key={company.uuid}>
                    <CompanyCard
                      company={company}
                      searchUUID={uuid}
                      ranking={index}
                    />
                  </Company>
                ))}
              </CompaniesContainer>
              {showMakeItACampaignCTA && (
                <MakeItACampaignButtonContainer>
                  <Button onClick={showMakeItACampaignModal}>
                    Make it a campaign
                  </Button>
                </MakeItACampaignButtonContainer>
              )}
            </>
          ))}
        {showInitial && (
          <>
            <StatusPendingImage />
            <InitialStatusText>Innovation is one click away</InitialStatusText>
          </>
        )}
      </ResultContainer>
      {selectedClient && showMakeItACampaignCTA && (
        <MakeItACampaignModal
          modalVisible={modalVisible}
          toggleModalVisibility={toggleMakeItACampaignModalVisibility}
          clientUUID={selectedClient?.uuid}
          searchHistoryUUID={uuid}
          loading={loading}
        />
      )}
    </Content>
  );
};

const Content = styled.div`
  width: 95%;
  margin: 0 auto;
`;

const Form = styled.form`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;

  & button {
    margin-right: 0.7rem;
  }
`;

const RemainingSearches = styled.span`
  align-self: center;
  margin-right: 0.7rem;
  color: var(--font-color-campaign-stats);
`;

const SearchButton = styled(Button)`
  &:disabled,
  &:disabled:hover {
    border: var(--disabled-button-border) !important;
  }
`;

const ResultContainer = styled.div`
  padding-bottom: 3rem;
`;

const Results = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.75rem;
`;

const ResultsText = styled(NewCampaignSubtitle)`
  margin: 0;
`;

const StatusPendingImage = styled.div`
  margin: 0 auto;
  height: 32rem;
  width: 40rem;
  background-image: url(${pendingStatus});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const InitialStatusText = styled.span`
  display: block;
  text-align: center;
`;

const CompaniesContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
`;

const Company = styled.div`
  width: 36rem;

  @media (min-width: 1000px) {
    width: 50%;
  }
  @media (min-width: 1200px) {
    width: 33%;
  }
  @media (min-width: 1400px) {
    width: 25%;
  }
  @media (min-width: 1600px) {
    width: 20%;
  }
`;

const MakeItACampaignButtonContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
`;
