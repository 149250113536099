import { FC } from "react";

import { InfoIcon } from ".";
import {
  Section,
  SectionHeader,
  SectionTitle,
  SectionContent,
} from "../../ui-share";

export const GeneralInformation: FC<{ description: string }> = ({
  description,
}) => {
  return (
    <Section>
      <SectionHeader>
        <SectionTitle>General information</SectionTitle>
      </SectionHeader>
      <SectionContent>{description}</SectionContent>
    </Section>
  );
};
