import { FC, useState, useEffect } from "react";

import { Modal } from "antd";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Card,
  CardHeader,
  CardContent,
  HeadTitle,
} from "../../ui-components/Card";
import { EditButton } from "../../ui-components/EditButton";
import {
  successNotification,
  errorNotification,
} from "../../ui-components/Notification";

import { AvatarSection } from "./AvatarSection";
import { ProfileInfoForm } from "./ProfileInfoForm";

import { deleteRequest } from "../store";
import { User } from "../../auth/store";
import { State } from "../../utils/store";

import { useAppDispatch, useAppSelector } from "../../utils/hooks";

const { confirm } = Modal;

interface EditProfileCardProps {
  currentUser: User;
  clientUUID: string | undefined;
}

export const EditProfileCard: FC<EditProfileCardProps> = ({
  currentUser,
  clientUUID,
}) => {
  const dispatch = useAppDispatch();
  const [editProfile, setEditableProfile] = useState(false);

  const { success, message, loading } = useAppSelector(
    (state: State) => state.editProfile.deleteRequest
  );

  const handleSetEditable = () => {
    setEditableProfile((prevState) => !prevState);
  };

  const handleOnConfirm = () => {
    if (!clientUUID) return;

    const data = {
      clientUUID: clientUUID,
    };
    dispatch(deleteRequest(data));
  };

  const showConfirm = () => {
    confirm({
      title:
        "You are requesting the deletion of your Novable account. Once deleted, your will lose access to this platform. Are you sure to continue?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleOnConfirm();
      },
      okButtonProps: {
        loading: loading,
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
    });
  };

  useEffect(() => {
    if (success === true) {
      successNotification(
        "Your request has been submitted, we will contact you in one working day"
      );
    } else if (success === false) {
      errorNotification(message);
    }
  }, [success, message]);

  return (
    <Card>
      <CardHeader>
        <HeadTitle>Personal info</HeadTitle>
        <div>
          <EditButton icon={<DeleteOutlined />} onClick={showConfirm}>
            Delete
          </EditButton>
          {!editProfile && (
            <EditButton icon={<EditOutlined />} onClick={handleSetEditable}>
              Edit
            </EditButton>
          )}
        </div>
      </CardHeader>
      <CardContent>
        <AvatarSection
          editProfile={editProfile}
          setEditableProfile={setEditableProfile}
        />
        <ProfileInfoForm
          editProfile={editProfile}
          currentUser={currentUser}
          handleSetEditable={handleSetEditable}
          setEditableProfile={setEditableProfile}
        />
      </CardContent>
    </Card>
  );
};
