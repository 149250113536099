import { Alert, Tooltip } from "antd";
import { FC } from "react";
import { EnrichedProfileStatus } from "../../store";

interface EnrichedProfileStatusIconProps {
  enrichedStatus: string;
}

export const EnrichedProfileStatusIcon: FC<EnrichedProfileStatusIconProps> = ({
  enrichedStatus,
}) => {
  return (
    <>
      {enrichedStatus === EnrichedProfileStatus.APPROVED ? (
        <Tooltip title="This Company profile has been enriched">
          <Alert
            type="success"
            showIcon
            style={{
              padding: "0.3rem 0.6rem",
              border: "none",
              backgroundColor: "transparent",
            }}
          />
        </Tooltip>
      ) : enrichedStatus === EnrichedProfileStatus.PENDING ? (
        <Tooltip title="There's a pending request to enrich the profile for this Company">
          <Alert
            showIcon
            type="info"
            style={{
              padding: "0.3rem 0.6rem",
              border: "none",
              backgroundColor: "transparent",
            }}
          />
        </Tooltip>
      ) : null}
    </>
  );
};
