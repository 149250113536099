import { FC } from "react";
import { Col, Tooltip } from "antd";
import { ActionButton } from "../../../../../share/company/components/ui-share";
import { EyeOutlined, EyeFilled } from '@ant-design/icons';

import { followCompany } from "../../../store";
import { useAppDispatch } from "../../../../../utils/hooks";

interface FollowButtonProps {
  GBCompanyUUID: string;
  isFollowed: boolean;
}

export const FollowButton: FC<FollowButtonProps> = ({
  GBCompanyUUID,
  isFollowed,
}) => {
  const dispatch = useAppDispatch();

  const handleFollow = () => {
    if (!GBCompanyUUID) return;
    let payload = {
      GBCompanyUUID: GBCompanyUUID,
      followed: !isFollowed,
    };
    dispatch(followCompany(payload));
  };

  return (
    <Col
      className="gutter-row"
    >
      <Tooltip
        placement="top"
        title="By following a Company, you will be notified each time Novable detects new information, contextual data or changes about that Company"
      >
        <ActionButton onClick={handleFollow}>
          {isFollowed ? <EyeFilled style={{ color: '#FA8212' }} /> : <EyeOutlined />}
        </ActionButton>
      </Tooltip>
    </Col>
  );
};
