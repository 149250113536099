import { FC } from "react";

import { Section, SubSection, SectionTitle, TeamIcon } from ".";
import { FoundedIcon } from "../../ui-share";

import { CompanyDetail } from "../../../store";
import { useBreakpoint } from "../../../../../utils/styling";

export const EmployeesYearFoundedAndStatusSection: FC<{
  company: CompanyDetail;
}> = ({ company }) => {
  const [isXL] = useBreakpoint(1200, { useBody: true });
  let employeeCount;

  if (company?.employeesRangeStart && company.employeesRangeEnd) {
    employeeCount = `${company.employeesRangeStart}-${company.employeesRangeEnd}`;
  }

  return (
    <Section>
      {employeeCount && (
        <SubSection title="Employees">
          <TeamIcon />
          <SectionTitle>
            {isXL ? "Employee:" : ""} {employeeCount}
          </SectionTitle>
        </SubSection>
      )}
      {company.yearFounded && (
        <SubSection title="Founded in year">
          <FoundedIcon />
          <SectionTitle>
            {isXL ? "Founded in:" : ""} {company.yearFounded}
          </SectionTitle>
        </SubSection>
      )}
      {company.status && (
        <SubSection title="Status">
          <SectionTitle>
            {isXL ? "Status:" : ""} {company.status}
          </SectionTitle>
        </SubSection>
      )}
    </Section>
  );
};
