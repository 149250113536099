import { FC, useEffect } from "react";
import styled from "@emotion/styled";

import { Modal, Form, Input as BaseInput } from "antd";
import { NextButton } from "../../../share/campaigns/components/share-ui";
import { ButtonCancel } from "../../../ui-components/ButtonCancel";
import { FormikInput } from "../../../ui-components/FormikInput";
import {
  NewCampaignSubtitle,
  ButtonText,
} from "../../../ui-components/Typography";

import { setDraftName } from "../../store";
import { canCreateMoreCampaigns } from "../../../share/campaigns/store";
import { warningNoMoreCampaignsAvailable } from "../../../ui-components/layout/share";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

const MAX_CAMPAIGN_NAME_CHARACTERS = 100;
const MAX_CAMPAIGN_DESCRIPTION_CHARACTERS = 140;

interface PublishCampaignModalProps {
  visible: boolean;
  hideModal: () => void;
  submit: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: { [field: string]: any };
  errors: { [field: string]: any };
}

export const PublishCampaignModal: FC<PublishCampaignModalProps> = ({
  visible,
  hideModal,
  submit,
  setFieldValue,
  values,
  errors,
}) => {
  const dispatch = useAppDispatch();

  // Allow publishing a campaign only if the campaign limit has not been reached
  const _canCreateMoreCampaigns = useAppSelector(canCreateMoreCampaigns());
  const { name } = useAppSelector(
    (state: State) => state.newCampaign.draftCampaign
  );
  const { loading } = useAppSelector(
    (state: State) => state.newCampaign.createNewCampaign
  );

  const handleSubmit = () => {
    if (!_canCreateMoreCampaigns) warningNoMoreCampaignsAvailable();
    else submit();
  };

  const setValue = (value: string) => {
    dispatch(setDraftName(value));
    setFieldValue("name", value);
  };

  useEffect(() => {
    if (visible && values.name.length === 0) setFieldValue("name", name);
    // Initial check when the modal is open, to add the name to the values
    // This is triggered when someone starts to create a campaign
    // Then opens the publish-campaign modal, and they try to publish it
    // without changing the name
  }, [visible]); // eslint-disable-line

  return (
    <Modal
      visible={visible}
      onCancel={hideModal}
      footer={[
        <>
          <ButtonCancel onClick={hideModal}>
            <ButtonText>Cancel</ButtonText>
          </ButtonCancel>

          <NextButton
            onClick={handleSubmit}
            loading={loading}
            data-intercom-target="publish-campaign-btn"
          >
            {loading ? "Submitting" : "Submit"}
          </NextButton>
        </>,
      ]}
    >
      <CampaignFormContent>
        <NewCampaignSubtitle className={"name"}>
          Campaign Name
        </NewCampaignSubtitle>

        <Form.Item
          help={errors.name ? errors.name : null}
          validateStatus={errors.name ? "error" : "success"}
        >
          <TextInput
            name="name"
            type="text"
            maxLength={MAX_CAMPAIGN_NAME_CHARACTERS}
            aria-label="campaign name"
            placeholder="Campaign name"
            value={name}
            onChange={(e) => setValue(e.target.value)}
          />
        </Form.Item>

        <NewCampaignSubtitle>Campaign Description</NewCampaignSubtitle>
        <FormikInput
          name="description"
          type="text"
          maxLength={MAX_CAMPAIGN_DESCRIPTION_CHARACTERS}
          as="textarea"
          ariaLabel="campaign description"
          placeholder="Campaign description"
          error={errors.description}
          value={values.description}
        />
      </CampaignFormContent>
    </Modal>
  );
};

const CampaignFormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TextInput = styled(BaseInput)`
  height: 3.6rem;
`;
