import { FC } from "react";

import { Tooltip, Col } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { TagWidget, TagWidgetProps } from "../../tag-widget/TagWidget";
import { ActionButton } from "../../ui-share";

import { TagCompanySource } from "../../../../../tags/store";

interface CompanyTagManagementButtonProps extends TagWidgetProps {
  tagCompanySource: TagCompanySource;
}

export const CompanyTagManagementButton: FC<CompanyTagManagementButtonProps> =
  ({ companyName, companyUUID, tagCompanySource, companyTags }) => {
    return (
      <Col
        className="gutter-row"
        // xs={23}
        // sm={11}
        // md={11}
        // lg={11}
        // xl={11}
        // xxl={11}
      >
        <TagWidget
          companyTags={companyTags}
          companyUUID={companyUUID}
          companyName={companyName}
          tagCompanySource={tagCompanySource}
        >
          <Tooltip
            placement="top"
            title="Manage Company tags"
            trigger={"hover"}
          >
            <ActionButton type="text">
              <TagOutlined style={{ color: '#FA8212' }} />
              {/* &nbsp; <ButtonText>Add to tag</ButtonText> */}
            </ActionButton>
          </Tooltip>
        </TagWidget>
      </Col>
    );
  };

// filter: invert(89%) sepia(14%) saturate(14%) hue-rotate(3deg) brightness(93%) contrast(86%);
