import { FC } from "react";
import { Section, SectionHeader, SectionTitle } from "../../ui-share";

export const CommentSection: FC<{ children: any }> = ({ children }) => {
  return (
    <Section style={{'width': '90%'}}>
      <SectionHeader>
        <SectionTitle>Comments</SectionTitle>
      </SectionHeader>
      {children}
    </Section>
  );
};
