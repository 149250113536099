import styled from "@emotion/styled";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Paragraph, SmallParagraph } from "./Typography";

import customerPhoto from "../assets/novable-agents/Tibault.jpg";

export const novableAgent = {
  name: "Thibaut Jacobs",
  position: "Customer Success Manager",
  email: "thibaut.jacobs@novable.com",
};

export const AgentDetails = () => {
  return (
    <AgentDetailsContainer>
      <Avatar size={60} src={customerPhoto} icon={<UserOutlined />} />
      <UserInfo>
        <AgentName>{novableAgent.name}</AgentName>
        <SmallParagraph>{novableAgent.position}</SmallParagraph>
        <SmallParagraph>
          <a href={`mailto:${novableAgent.email}`}>{novableAgent.email}</a>
        </SmallParagraph>
      </UserInfo>
    </AgentDetailsContainer>
  );
};

const AgentDetailsContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserInfo = styled.div`
  height: 3rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  p {
    margin: 0;
    padding: 0;
  }
`;

const AgentName = styled.p`
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-base);
`;
