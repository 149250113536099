import styled from "@emotion/styled";
import listIcon from "../assets/icons/add-to-list.svg";
import listIconBlue from "../assets/icons/add-to-list-blue.svg";

export const Anchor = styled.a`
  width: 100%;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

// TODO: Export every component from here?

export const ListIcon = styled.div`
  width: 1.667rem;
  height: 1.667rem;
  background: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  background-image: url(${listIcon});
`;

export const ListIconBlue = styled.div`
  width: 1.667rem;
  height: 1.667rem;
  background: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  background-image: url(${listIconBlue});
`;
