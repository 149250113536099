import styled from "@emotion/styled";
import { FC, useEffect, useState } from "react";
import { Popover } from "antd";

import { ExtraTopicsPopover } from "./ExtraTopicsPopover";
import { Topic } from "./Topic";

import { cleanKeyword, highlightTopic } from "../../../../../helpers";
import { Segment } from "../../../../../utils/Segment";

const TOPICS_LIMIT = 12;

interface DisplayTopicsProps {
  topics: string[];
  highlightTopics?: string[];
  topicsLimit?: number;
}

export const DisplayTopics: FC<DisplayTopicsProps> = ({
  topics,
  highlightTopics,
  topicsLimit,
}) => {
  const showTopicsLimit = topicsLimit || TOPICS_LIMIT;
  let [open, setOpen] = useState(false);
  let [uniqueTopics, setUniqueTopics] = useState<string[]>([]);
  let [showMoreTopics, setShowMoreTopics] = useState<string[]>([]);

  useEffect(() => {
    const cleanedKeywords = topics.map((x) => cleanKeyword(x));
    const cleanUniqueTopics = removeDuplicateKeywords(cleanedKeywords);
    // First TOPICS_LIMIT items will be displayed, and the rest will be hidden in a popover
    // that will display on click on "Show more"
    setShowMoreTopics(cleanUniqueTopics.slice(showTopicsLimit));
    setUniqueTopics(cleanUniqueTopics.slice(0, showTopicsLimit));
  }, [showTopicsLimit, topics]);

  const eventName = "show-more-topics";
  const onVisibleChangePopover = (visible: boolean) => {
    setOpen(visible);

    visible && Segment.analytics?.track(eventName);
  };

  const closePopover = () => {
    setOpen(false);
  };

  function removeDuplicateKeywords(keywordList: string[]) {
    const lowercaseKeywords = keywordList.map((x) => x.toLowerCase());
    const uniqueKeywords: string[] = keywordList.filter((c, index) => {
      return lowercaseKeywords.indexOf(c.toLowerCase()) === index;
    });

    return uniqueKeywords;
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {uniqueTopics.map((topic) => (
        <Topic
          key={topic}
          topic={topic}
          highlightTopic={highlightTopic(topic, highlightTopics)}
        />
      ))}
      {showMoreTopics.length > 0 && (
        <Popover
          open={open}
          content={
            <ExtraTopicsPopover
              closePopover={closePopover}
              topics={showMoreTopics}
              highlightTopics={highlightTopics}
            />
          }
          trigger="click"
          onOpenChange={onVisibleChangePopover}
          placement="bottomLeft"
        >
          <ShowMoreButton
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Show more
          </ShowMoreButton>
        </Popover>
      )}
    </div>
  );
};

const ShowMoreButton = styled.button`
  cursor: pointer;
  align-items: normal;
  background-color: var(--background-color-primary);
  border-color: var(--primary-color);
  border-style: none;
  box-sizing: content-box;
  color: var(--primary-color);
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  -webkit-logical-height: 1rem; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */

  /* Mozilla uses a pseudo-element to show focus on buttons, */
  /* but anchors are highlighted via the focus pseudo-class. */
`;
