import styled from "@emotion/styled";

export const NonMobileAvailable = () => {
  return (
    <Container>
      <p>
        Novable is currently unavailable on mobile and on small windows. Try to
        access via desktop or by increasing the width of your window.
      </p>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
`;
