module.exports = {
  // main colors
  "primary-color": "#FA8213",
  "background-color-primary": "#FFFFFF",
  "background-color-secondary": "#EEEEEE",  
  "background-color-tertiery": "#DEDDDE",
  "background-color-neutral": "#F5F5F5",

  "border-color-opaque": "#DEDDDE",
  "border-color-selected": "#FA8213",

  "content-color-primary": "#0A0D2D",
  "content-color-secondary": "#414155",
  "content-color-tertiary": "#747586",
  "content-color-actionable": "#FA8213",

  "background-color-informative": "#E6F4FF",
  "background-color-accentlight": "#FFEFE1",

  // Base
  "link-color": "#FA8213",
  "background-color-base": "#EFEFEF",
  "border-radius-base": "4px",
  // layout
  "layout-sider-background": "#EFEFEF",
  "layout-trigger-background": "#EFEFEF",
  "layout-zero-trigger-height": "44px",
  "layout-header-background": "#EFEFEF",
  "layout-header-height": "80px",
  "layout-header-color": "#EFEFEF",
  "layout-header-padding": "0 16px",
  // menus
  "menu-dark-bg": "@layout-sider-background",
  "menu-dark-item-active-bg": "#152734",
  "menu-dark-inline-submenu-bg": "@layout-sider-background",
  "menu-dark-item-hover-bg": "#364855",
  // buttons
  "btn-default-bg": "#FA8213",
  "btn-default-color": "#FA8213",
  "btn-default-border": "none",
  // cards
  "card-padding-base": "0px",
  "card-head-font-size": "20px",
  "card-head-height": "54px",
  // radio buttons
  "radio-button-checked-bg":
    "linear-gradient(0deg, rgba(33, 174, 255, 0.04), rgba(33, 174, 255, 0.04)), #FFFFFF",
  // switch
  // "switch-color": "#27ca69",
  // slider
  // "slider-handle-background-color": "rgba(2, 62, 125, 1)",
  // "slider-handle-border-width": "0",
  // "slider-rail-background-color": "#e3eaee",
  // "slider-rail-background-color-hover": "#e3eaee",
  // "slider-track-background-color": "rgba(33, 174, 255, 1)",
  // "slider-track-background-color-hover": "rgba(33, 174, 255, 1)",
};
