import styled from "@emotion/styled";
import { Tag } from "./Tag";
import { FC, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

interface TagProps {
  addTag?: () => void;
  removeTag?: () => void;
  clusterTag?: boolean;
  initialActive?: boolean;
  children?: React.ReactNode;
}

export const ToggleTag: FC<TagProps> = ({
  addTag,
  removeTag,
  children,
  initialActive,
}) => {
  const initialActiveState = initialActive === undefined ? true : initialActive;
  const [activeTag, setActiveTag] = useState(initialActiveState);

  const onTagChange = () => {
    if (activeTag && removeTag) {
      removeTag();
    } else if (addTag && !activeTag) {
      addTag();
    }
    setActiveTag((prevState) => !prevState);
  };

  return (
    <StyledTag isActiveTag={activeTag} onClick={onTagChange}>
      {children}
      <ExitIcon isActiveTag={activeTag} />
    </StyledTag>
  );
};

const StyledTag = styled(Tag)<{ isActiveTag: boolean }>`
  padding-right: 2.3rem;
  background-color: ${(props) =>
    !props.isActiveTag && "var(--rightbar-content-background)"};
  color: ${(props) => !props.isActiveTag && "var(--no-active-tag)"};
  cursor: pointer;
`;

const ExitIcon = styled(PlusOutlined)<{ isActiveTag: boolean }>`
  position: absolute;
  top: 0.8rem;
  right: 0.7rem;
  width: 1rem;
  height: 1rem;
  transform: ${(props) =>
    props.isActiveTag ? "rotate(45deg)" : "rotate(0deg)"};
  cursor: pointer;
  transition: all 0.3s;
`;
