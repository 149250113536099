import { FC } from "react";
import { Form, Select } from "antd";
import { Label, SectionExplanation } from "./Typography";
import { useField } from "formik";

export interface FormikSelectWithValuesProps {
  name: string;
  label?: string;
  sectionExplanation?: string;
  placeholder?: string | undefined;
  ariaLabel?: string;
  [x: string]: any;
}

export const FormikSelectWithValues: FC<FormikSelectWithValuesProps> = ({
  name,
  label,
  sectionExplanation,
  placeholder,
  ariaLabel,
  children,
  ...rest
}) => {
  const [field, meta, { setValue, setTouched }] = useField({ name, ...rest });
  const invalid = meta.touched && meta.error; // Show error message & red border
  return (
    <Form.Item
      help={invalid ? meta.error : null}
      validateStatus={invalid ? "error" : "success"}
    >
      <Label>
        {label && label}
        {sectionExplanation && (
          <SectionExplanation>{sectionExplanation}</SectionExplanation>
        )}
        <Select
          value={field.value}
          onChange={(value: any) => setValue(value)}
          onBlur={() => setTouched(true)}
          style={{ width: "100%", marginTop: "1rem" }}
          aria-label={ariaLabel}
          {...rest}
        >
          {children}
        </Select>
      </Label>
    </Form.Item>
  );
};
