import { useLocation } from "react-router-dom";
import { Navigate } from "react-router";

import { ResetPasswordForm } from "./components/ResetPasswordForm";
import { ResultResetPassword } from "./components/result/ResultResetPassword";

import { State } from "../utils/store";
import { useAppSelector } from "../utils/hooks";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ResetPasswordView = () => {
  const { loading, success, message } = useAppSelector(
    (state: State) => state.auth.resetPassword
  );

  let token = useQuery().get("token");

  if (!token) {
    return <Navigate to="/login" />;
  }

  return success ? (
    <ResultResetPassword />
  ) : (
    <ResetPasswordForm loading={loading} message={message} token={token} />
  );
};
