import styled from "@emotion/styled";
import { useCallback, useEffect, useRef, useState } from "react";

import { Spin } from "antd";
import { CenteredSpinner } from "../../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../../ui-components/CenteredErrorMessage";
import { CompanyCard } from "../../share/company/components/company-list/CompanyCard";
import { ResultCount } from "../../share/company/components/company-list/ResultCount";

import { fetchFavouriteList, unfavouriteCompany } from "../store";
import { fetchCompany, BaseCompanyMinified } from "../../share/company/store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

const PAGE_SIZE = 20;
export const FavouriteCompanyList = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);

  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { favouriteList, totalResults, lastPage } = useAppSelector(
    (state: State) => state.favouritePage
  );
  const { error, loading } = useAppSelector(
    (state: State) => state.favouritePage.fetchFavouriteList
  );

  // Type_ React.MutableRefObject<IntersectionObserver>
  const observer: any = useRef();
  const lastCompanyRef = useCallback(
    (node: any) => {
      // Do not start the observer initially, wait until useEffect has made
      // the first request, and then this useCallback will be called again
      // since it listens to some of the values set as a result from
      // the useEffect call (lastPage has an initial value of -1)
      if (lastPage && lastPage === -1) return;

      if (node && observer.current) observer.current.unobserve(node);

      // Element percentage threshold
      const options = {
        threshold: 1,
      };
      observer.current = new IntersectionObserver((entries, observer) => {
        // currentPage can be null if there is no next page

        // Due to multiple oberservers(or ...?) being registered and having different
        // values from each other (can be checked by a simple console.log, when the intersection
        // happens, multiple lines, sometimes with different results, are shown on the console),
        // there is a problem to solve here, the improper update of lastPage value.
        // In here, "totalResults / PAGE_SIZE" is used instead of lastPage
        // due to the wrong update on the lastPage value, when the length of the results changes
        // For instance, A list with 41 items (last page 3), 20 per page. While on the page, a user
        // unfavourites a company, the list is now 40 items (last page 2), but one of the
        // observers still has as a lastPage value 3
        const hasMoreResults =
          page < totalResults / PAGE_SIZE &&
          totalResults !== 0 &&
          favouriteList.length < totalResults;

        if (entries[0].isIntersecting && hasMoreResults) {
          setPage(page + 1);
          observer.unobserve(entries[0].target);
        }
      }, options);
      if (node) observer.current.observe(node);
    },
    [lastPage, totalResults, favouriteList.length, page]
  );

  const handleFetchSelectedCompanyDetails = (companyUUID: string) => {
    if (!selectedClient) return;
    const data = {
      clientUUID: selectedClient?.uuid,
      companyUUID: companyUUID,
    };
    dispatch(fetchCompany(data));
  };

  useEffect(() => {
    if (selectedClient) {
      const data = {
        clientUUID: selectedClient.uuid,
        page: page,
        pageSize: PAGE_SIZE,
      };
      dispatch(fetchFavouriteList(data));
    }
  }, [dispatch, page, selectedClient]);

  if (error) {
    return <CenteredErrorMessage>{error}</CenteredErrorMessage>;
  }

  const displayResultSpin = loading && totalResults !== -1;

  const handleUnfavourite = (e: any, companyUUID: string) => {
    if (!selectedClient) return;
    e.stopPropagation();
    let payload = {
      companyUUID: companyUUID,
      clientUUID: selectedClient.uuid,
    };
    dispatch(unfavouriteCompany(payload));
  };

  return (
    <Container>
      <ResultCount>
        {displayResultSpin ? <Spin /> : totalResults === -1 ? 0 : totalResults}
      </ResultCount>
      {favouriteList.map((company: BaseCompanyMinified) => {
        return (
          <CompanyCard
            key={company.companyUuid}
            companyMinified={company}
            onClick={handleFetchSelectedCompanyDetails}
            handleFavourite={handleUnfavourite}
          />
        );
      })}
      {loading &&
        (favouriteList.length === 0 ? (
          <CenteredSpinner />
        ) : (
          <SpinContainer>
            <Spin />
          </SpinContainer>
        ))}
      {loading && favouriteList.length === 0 && <Spin />}
      {error && <CenteredErrorMessage>{error}</CenteredErrorMessage>}
      <div ref={lastCompanyRef} />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  overflow: scroll;
`;

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
`;
