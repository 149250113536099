import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fork, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { goldenBasketSlice } from "../../campaign/golden-basket/store";
import { clientsSlice } from "../../clients/store";
import { favouriteSlice } from "../../favourite-page/store";

import { backend } from "../../utils/http";

interface Patent {
  patentUrl: string;
  patentName: string;
}

export interface RequestEnrichedCompanyProfileData {
  companyUUID: string;
  companyName: string;
  clientUUID: string;
  campaignUUID: string | null;
}

export interface EnrichedProfileContact {
  name: string | null;
  email: string | null;
  linkedinUrl: string | null;
}

export interface EnrichedProfileContent {
  numberPatents: number | null;
  lastPatentDate: Date | null;
  patents: Patent[] | null;
  mainPatentKeywords: string[] | null;

  uniqueSellingProposition: string | null;
  businessModel: string | null;
  persona: string | null;
  clients: string | null;
  verticals: string | null;

  employeeNumber: number | null;
  founders: EnrichedProfileContact[] | null;
  executiveTeam: EnrichedProfileContact[] | null;
  contacts: EnrichedProfileContact[] | null;
}

export enum EnrichedProfileStatus {
  PENDING = "pending",
  APPROVED = "approved",
  NOT_REQUESTED = "not_requested",
}

export interface EnrichedProfile {
  status: EnrichedProfileStatus | null;
  content: EnrichedProfileContent | null;
}

export interface FetchCompanyData {
  companyUUID: string;
  clientUUID: string;
}
export interface FavouriteCompanyData extends FetchCompanyData {}

export interface NewsArticle {
  articleUrl: string;
  companyCity: string;
  companyCountry: string;
  companyCountryCode: string;
  companyDomain: string;
  companyId: string;
  companyName: string;
  language: string;
  sourceUrl: string;
  timestamp: number;
  title: string;
  date: Date;
}

export interface ActivityIndexTimeSeriesItem {
  activityIndex: number;
  period: string;
  timestamp: number;
}

export interface ActivityIndex {
  activityIndex: number;
  timeSeries: ActivityIndexTimeSeriesItem[];
}

interface AddTagToCompanyPayload {
  companyUUID: string;
  tagName: string;
}

export interface BaseCompany {
  address: string | null;
  activityIndex: ActivityIndex | null;
  categoryList: string[] | null;
  city: string;
  classification: string[];
  country: string;
  companyTags: string[]; // List of (user-created) Company Tags the company belongs to
  crunchbaseUrl: string;
  currency: string;
  email: string;
  employeesRangeEnd: number | null;
  employeesRangeStart: number | null;
  esgLinks: string[] | null;
  facebook: string | null;
  favourite: boolean;
  fundingRounds: number | null;
  lastFundingDate: Date;
  linkedin: string | null;
  lists: string[]; // List of Client Lists the company belongs to
  logo: string | null;
  name: string;
  shortDescription: string | null;
  status: string | null;
  tags: string[];
  textKeywords: string[];
  totalFunding: number | null;
  twitter: string | null;
  url: string | null;
  uuid: string;
  yearFounded: number;
}

export interface CompanyDetail extends BaseCompany {
  news: NewsArticle[];
  enrichedProfile: EnrichedProfile;
}

export interface BaseCompanyMinified {
  company: BaseCompany;
  companyUuid: string;
  enrichedStatus: EnrichedProfileStatus;
}

export interface CompanyState {
  fetchCompany: {
    loading: boolean;
    error: string;
    success: boolean | null;
  };
  favouriteCompany: {
    loading: boolean;
    error: string;
    success: boolean | null;
  };
  unfavouriteCompany: {
    loading: boolean;
    error: string;
    success: boolean | null;
  };
  requestEnrichedCompanyProfile: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  fetchedCompany: CompanyDetail | null;
}

const initialState = {
  fetchCompany: {
    loading: false,
    success: null,
    error: "",
  },
  favouriteCompany: {
    loading: false,
    success: null,
    error: "",
  },
  unfavouriteCompany: {
    loading: false,
    success: null,
    error: "",
  },
  requestEnrichedCompanyProfile: {
    loading: false,
    success: false,
    error: "",
  },
  fetchedCompany: null,
} as CompanyState;

export const companySlice = createSlice({
  name: "company",
  initialState: initialState,
  reducers: {
    setInitialState(state, filter: PayloadAction<string>) {
      state.favouriteCompany = initialState.favouriteCompany;
    },
    setRequestEnrichedCompanyProfileInitialState(state) {
      state.requestEnrichedCompanyProfile = {
        ...initialState.requestEnrichedCompanyProfile,
      };
    },
    // Do nothing, just trigger side-effects for this one on the Saga below
    setEnrichedStatusToPending(state, action) {},
    setCompany(state, action: PayloadAction<CompanyDetail | null>) {
      state.fetchedCompany = action.payload;
    },
    setSelectedCompanyInitialState(state) {
      state.fetchedCompany = null;
    },
    fetchCompany(state, action: PayloadAction<FetchCompanyData>) {
      state.fetchCompany.loading = true;
      state.fetchCompany.success = null;
      state.fetchCompany.error = "";
    },
    fetchCompanySuccess(state, action) {
      state.fetchCompany.loading = false;
      state.fetchCompany.success = true;
      state.fetchCompany.error = "";
      state.fetchedCompany = action.payload.data;
    },
    fetchCompanyFailure(state, action) {
      state.fetchCompany.loading = false;
      state.fetchCompany.success = false;
      state.fetchCompany.error = action.payload.data.message;
    },
    favouriteCompany(state, action: PayloadAction<FavouriteCompanyData>) {
      state.favouriteCompany.loading = true;
      state.favouriteCompany.success = null;
      state.favouriteCompany.error = "";
    },
    favouriteCompanySuccess(
      state,
      action: PayloadAction<FavouriteCompanyData>
    ) {
      state.favouriteCompany.loading = false;
      state.favouriteCompany.success = true;
      state.favouriteCompany.error = "";
    },
    favouriteCompanyFailure(state, action) {
      state.favouriteCompany.loading = false;
      state.favouriteCompany.success = false;
      state.favouriteCompany.error = action.payload.data.message;
    },
    unfavouriteCompany(state, action: PayloadAction<FavouriteCompanyData>) {
      state.unfavouriteCompany.loading = true;
      state.unfavouriteCompany.success = null;
      state.unfavouriteCompany.error = "";
    },
    unfavouriteCompanySuccess(
      state,
      action: PayloadAction<FavouriteCompanyData>
    ) {
      state.unfavouriteCompany.loading = false;
      state.unfavouriteCompany.success = true;
      state.unfavouriteCompany.error = "";
    },
    unfavouriteCompanyFailure(state, action) {
      state.unfavouriteCompany.loading = false;
      state.unfavouriteCompany.success = false;
      state.unfavouriteCompany.error = action.payload.data.message;
    },
    requestEnrichedCompanyProfile(
      state,
      action: PayloadAction<RequestEnrichedCompanyProfileData>
    ) {
      state.requestEnrichedCompanyProfile.loading = true;
      state.requestEnrichedCompanyProfile.success = false;
      state.requestEnrichedCompanyProfile.error = "";
    },
    requestEnrichedCompanyProfileSuccess(
      state,
      action: PayloadAction<RequestEnrichedCompanyProfileData>
    ) {
      state.requestEnrichedCompanyProfile.loading = false;
      state.requestEnrichedCompanyProfile.success = true;
      state.requestEnrichedCompanyProfile.error = "";
    },
    requestEnrichedCompanyProfileFailure(state, action) {
      state.requestEnrichedCompanyProfile.loading = false;
      state.requestEnrichedCompanyProfile.success = false;
      state.requestEnrichedCompanyProfile.error = action.payload.data.message;
    },
    toggleSelectedCompanyFavourite(state) {
      if (state.fetchedCompany) {
        state.fetchedCompany.favourite = !state.fetchedCompany.favourite;
      }
    },
    addCompanyToTag(state, action: PayloadAction<AddTagToCompanyPayload>) {
      if (state.fetchedCompany) {
        state.fetchedCompany.companyTags = [
          ...state.fetchedCompany.companyTags,
          action.payload.tagName,
        ];
      }
    },
    removeTagFromCompany(state, action: PayloadAction<AddTagToCompanyPayload>) {
      if (state.fetchedCompany) {
        state.fetchedCompany.companyTags =
          state.fetchedCompany.companyTags.filter(
            (x) => x !== action.payload.tagName
          );
      }
    },
  },
});

function* fetchCompanySaga() {
  yield takeEvery(companySlice.actions.fetchCompany, function* (action) {
    try {
      const response: AxiosResponse<CompanyDetail> =
        yield backend.companiesBackend.fetchCompany(action.payload);

      yield put(companySlice.actions.fetchCompanySuccess(response));
    } catch (err) {
      yield put(companySlice.actions.fetchCompanyFailure(err));
    }
  });
}

function* favouriteCompanySaga() {
  yield takeEvery(companySlice.actions.favouriteCompany, function* (action) {
    try {
      yield backend.companiesBackend.favouriteCompany(action.payload);

      yield put(companySlice.actions.favouriteCompanySuccess(action.payload));
    } catch (err) {
      yield put(companySlice.actions.favouriteCompanyFailure(err));
    }
  });
}

function* unfavouriteCompanySaga() {
  yield takeEvery(companySlice.actions.unfavouriteCompany, function* (action) {
    try {
      yield backend.companiesBackend.unfavouriteCompany(action.payload);
      yield put(companySlice.actions.unfavouriteCompanySuccess(action.payload));
    } catch (err) {
      yield put(companySlice.actions.unfavouriteCompanyFailure(err));
    }
  });
}

function* requestEnrichedCompanyProfileSaga() {
  yield takeEvery(
    companySlice.actions.requestEnrichedCompanyProfile,
    function* (action) {
      try {
        yield backend.companiesBackend.requestEnrichedCompanyProfile(
          action.payload
        );

        yield put(
          companySlice.actions.requestEnrichedCompanyProfileSuccess(
            action.payload
          )
        );
        yield put(
          clientsSlice.actions.decreaseSelectedClientEnrichedProfileRequestLimit()
        );
      } catch (err) {
        yield put(
          companySlice.actions.requestEnrichedCompanyProfileFailure(err)
        );
      }
    }
  );
}

/**
 * When someone requests an ECP, we need to show not only on the detail view,
 * but also on the company-list component. The company-list uses the Company.enrichedStatus
 * to check the status.
 *
 * There are two possibilities when setEnrichedStatusToPending is triggered, the user is either
 * on the FAVOURITE page, or on the GOLDEN-BASKET company detail page. A simple approach (less complexity)
 * is to just update both, each will try to find the index of the company. If user is on
 * FAV page, there won't be GB, so index will be -1 and update won't happen and viceversa
 *
 * NOTE: If try-catch are removed, the saga enters an infinite loop for some reason
 */
function* setEnrichedStatusToPendingSaga() {
  yield takeEvery(
    companySlice.actions.setEnrichedStatusToPending,
    function* (action) {
      try {
        yield put(
          goldenBasketSlice.actions.setGBEnrichedStatusToPending(action.payload)
        );
      } catch (err) {
        // Do nothing
      }

      try {
        yield put(
          favouriteSlice.actions.setFavEnrichedStatusToPending(action.payload)
        );
      } catch (err) {
        // Do nothing
      }
    }
  );
}

export function* companySaga() {
  yield fork(favouriteCompanySaga);
  yield fork(fetchCompanySaga);
  yield fork(unfavouriteCompanySaga);
  yield fork(requestEnrichedCompanyProfileSaga);
  yield fork(setEnrichedStatusToPendingSaga);
}

export const {
  favouriteCompany,
  fetchCompany,
  requestEnrichedCompanyProfile,
  setInitialState,
  setEnrichedStatusToPending,
  setRequestEnrichedCompanyProfileInitialState,
  setSelectedCompanyInitialState,
  unfavouriteCompany,
} = companySlice.actions;
