import { FC } from "react";
import styled from "@emotion/styled";

import { ThreadCard } from "./ThreadCard";
import { ThreadsCount } from "./ThreadsCount";
import { CenteredSpinner } from "../../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../../ui-components/CenteredErrorMessage";
import { StatusMessage } from "../../ui-components/states";

import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { setSelectedThread } from "../store";

interface ListOfThreadsProps {
  userUuid: string | undefined;
}

export const ListOfThreads: FC<ListOfThreadsProps> = ({ userUuid }) => {
  const dispatch = useAppDispatch();

  const stateThreadList = useAppSelector(
    (state: State) => state.scottThreadList.threadList
  );

  const threadList = stateThreadList?.slice().sort((a, b) => 
    new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  const { success: ThreadListSuccess, loading: ThreadListLoading } = useAppSelector(
    (state: State) => state.scottThreadList.getThreadsByUser
  );

  const handleSelectedThread = (uuid: string) => {
    if (uuid) {
      const selectedThread = threadList.find(thread => thread.thread_uuid === uuid);

      if (selectedThread) {
        dispatch(setSelectedThread(selectedThread));
      }
    }
  };

  const successGetList = true;
  const successWithResults =
    successGetList &&
    threadList !== null &&
    threadList.length > 0;
  const successWithoutResults =
    successGetList &&
    threadList !== null &&
    threadList.length === 0;

  return (
    <List>
      <ThreadsCount>
        {threadList !== null ? threadList.length : "-"}
      </ThreadsCount>
      <ThreadsListContainer>
        {ThreadListLoading ? (
          <CenteredSpinner />
        ) : successWithResults ? (
            threadList.map((thread) => {
                return (
                  <ThreadCard
                    key={thread?.thread_uuid}
                    threadUuid={thread?.thread_uuid}
                    threadSummary={thread.initial_conversation?.length > 0 ? thread?.initial_conversation[0]?.message : ""}
                    onClick={handleSelectedThread}
                  />
                );
              })
        ) : (
          successWithoutResults && (
            <CenteredErrorMessage>
              <StatusMessage>
                You don't have any threads yet.
              </StatusMessage>
            </CenteredErrorMessage>
          )
        )}
      </ThreadsListContainer>
    </List>
  );
};

const List = styled.div`
  background: var(--campaign-stats-background);
  box-shadow: var(--gb-list-container-shadow);
  position: sticky;
  top: 8rem;
  height: calc(100vh - 8rem);
`;

const ThreadsListContainer = styled.div`
  // Total height minus results header
  height: calc(100% - 5.2rem);
  overflow-y: scroll;
`;
