import { FC } from "react";

import { UnkownErrorImage, GoldenBasketStatusWrapper, StatusMessage } from ".";

export const UnkownError: FC = () => {
  return (
    <GoldenBasketStatusWrapper>
      <UnkownErrorImage />
      <StatusMessage>
        An error has ocurred, please try again. If the problem persists, please
        contact us at support@novable.com
      </StatusMessage>
    </GoldenBasketStatusWrapper>
  );
};
