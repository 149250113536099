import { Modal } from "antd";
import { openLinkInNewTabAndTrack } from "../helpers";

const { info: InfoConfirm } = Modal;

const OLIVIER_DISCOVERY_CALL_URL =
  "https://meetings.hubspot.com/olivier72/discovery-call";
const CASPAR_PRODUCT_FEATURE_CALL_URL =
  "https://meetings.hubspot.com/caspar-van-rijn/product-feature";

const DiscoveryContent = (eventProperties: any) => {
  return (
    <span>
      <a
        href={OLIVIER_DISCOVERY_CALL_URL}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="website"
        style={{ width: "fit-content" }}
        onClick={(event: any) =>
          openLinkInNewTabAndTrack(
            event,
            OLIVIER_DISCOVERY_CALL_URL,
            "discovery-call-click",
            eventProperties
          )
        }
      >
        Book a call
      </a>{" "}
      with us to proceed.
    </span>
  );
};
const SalesContent = (eventProperties: any) => {
  return (
    <span>
      Contact us at sales@novable.com or{" "}
      <a
        href={CASPAR_PRODUCT_FEATURE_CALL_URL}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="website"
        style={{ width: "fit-content" }}
        onClick={(event: any) =>
          openLinkInNewTabAndTrack(
            event,
            CASPAR_PRODUCT_FEATURE_CALL_URL,
            "product-feature-call-click",
            eventProperties
          )
        }
      >
        book a call
      </a>{" "}
      with us.
    </span>
  );
};

export enum UpgradeYourPlanTypes {
  Discovery,
  Sales,
}

const UpgradeYourPlanType: { [key in UpgradeYourPlanTypes]: any } = {
  [UpgradeYourPlanTypes.Discovery]: DiscoveryContent,
  [UpgradeYourPlanTypes.Sales]: SalesContent,
};

export const upgradeYourPlan = (
  title: string,
  upgradeYourPlanType?: UpgradeYourPlanTypes,
  eventProperties?: any
) => {
  const _upgradeYourPlanType =
    upgradeYourPlanType || UpgradeYourPlanTypes.Discovery;
  return InfoConfirm({
    title: title,
    content: UpgradeYourPlanType[_upgradeYourPlanType](eventProperties),
  });
};
