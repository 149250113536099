import { FC } from "react";

import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { StylelessButton } from "../../../../ui-components/StylelessButton";
import { StyledButtonText } from "../../../../ui-components/Typography";

import { deleteList } from "../../../store";
import { State } from "../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";

const { confirm } = Modal;

interface DeleteListButtonProps {
  listUUID: string;
  listName: string;
}

/**
 *  Based on the business logic, this is how the delete-user works
 *
 *  - Only admins can do it
 *  - If the user is the only admin, they cannot request to delete themselves
 *  - If the user has already been requested to be deleted, it cannot be done again
 */
export const DeleteListButton: FC<DeleteListButtonProps> = ({
  listUUID,
  listName,
}) => {
  const dispatch = useAppDispatch();

  const { loading, requestedUUID } = useAppSelector(
    (state: State) => state.lists.deleteList
  );

  const handleDeleteList = () => {
    const data = {
      listUUID: listUUID,
    };
    dispatch(deleteList(data));
  };

  const showConfirm = () => {
    confirm({
      title: "Do you want to delete this list?",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to delete the list ${listName}? All the Companies in the list will be lost`,
      okText: "Confirm",
      onOk() {
        handleDeleteList();
      },
      okButtonProps: {
        loading: loading,
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
    });
  };

  const showButtonLoading = loading && requestedUUID === listUUID;
  return (
    <StylelessButton
      disabled={showButtonLoading}
      loading={showButtonLoading}
      onClick={showConfirm}
    >
      <StyledButtonText>Delete</StyledButtonText>
    </StylelessButton>
  );
};
