import { FC } from "react";
import styled from "@emotion/styled";

import openInNewWindowIcon from "../../../../../assets/icons/open-in-new-window-orange.svg";
import { StylelessButton } from "../../../../../ui-components/StylelessButton";

export const CrunchbaseAttribution: FC<{ crunchbaseUrl: string }> = ({
  crunchbaseUrl,
}) => {
  return (
      <StylelessButton 
        onClick={() => window.open(crunchbaseUrl, "_blank")}
        style={{color: "var(--primary-color)"}}
      >
        View on Crunchbase <OpenInNewWindowIcon />
      </StylelessButton>
  );
};

const OpenInNewWindowIcon = styled.div`
  width: 2rem;
  height: 2rem;
  background: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  background-image: url(${openInNewWindowIcon});
`;
