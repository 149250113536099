import { FC, useState } from "react";

import { Popover } from "antd";
import { TagWidgetContent } from "./TagWidgetContent";
import { TagCompanySource } from "../../../../tags/store";
import { Segment } from "../../../../utils/Segment";
import { TooltipPlacement } from "antd/es/tooltip";

export interface TagWidgetProps {
  companyTags: string[];
  companyUUID: string;
  companyName: string;
  tagCompanySource: TagCompanySource;
  popoverPlacement?: TooltipPlacement;
  children?: React.ReactNode;
}

const defaultPopoverPlacement = "bottom" as "bottom";
export const TagWidget: FC<TagWidgetProps> = ({
  companyTags,
  companyUUID,
  companyName,
  tagCompanySource,
  popoverPlacement,
  children,
}) => {
  // Controlled at this level of components to be able to empty
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const popoverContent = TagWidgetContent(
    companyTags,
    companyUUID,
    companyName,
    tagCompanySource
  );

  const handleOnOpenChange = (open: boolean) => {
    setTooltipIsOpen(open);

    if (!open) {
      Segment.analytics?.track("open-tag-tooltip", {
        openedFrom: tagCompanySource,
      });
    }
  };

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      placement={popoverPlacement || defaultPopoverPlacement}
      onOpenChange={handleOnOpenChange}
      open={tooltipIsOpen}
    >
      {children}
    </Popover>
  );
};
