import styled from "@emotion/styled";
import { Navigate } from "react-router";
import { NavLink } from "react-router-dom";

import { CenteredErrorMessage } from "../ui-components/CenteredErrorMessage";
import { errorNotification } from "../ui-components/Notification";
import { ContentSection } from "../ui-components/layout/UserLayout";
import { Button } from "../ui-components/Button";
import { Card, CardContent } from "../ui-components/Card";
import {
  UpgradeYourPlanTypes,
  upgradeYourPlan,
} from "../ui-components/UpgradeYourPlan";

import { State } from "../utils/store";
import { getPublishedCampaignsDuringCurrentContract, getPublishedIterationsDuringCurrentContract } from "../share/campaigns/store";
import { ContractType } from "../clients/store";

import { isClientPremium, dateFormatter } from "../helpers";
import { Segment } from "../utils/Segment";
import { useAppSelector } from "../utils/hooks";

export const AccountInformationView = () => {
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { statistics } = useAppSelector((state: State) => state.campaigns);
  const publishedCampaigns = useAppSelector(
    getPublishedCampaignsDuringCurrentContract()
  );
  const publishedIterations = useAppSelector(
    getPublishedIterationsDuringCurrentContract()
  );

  if (!selectedClient) {
    return <CenteredErrorMessage>There is an error</CenteredErrorMessage>;
  }

  if (selectedClient?.userRole !== "admin") {
    errorNotification("You don't have access to this page");
    return <Navigate to={`/${selectedClient?.slug}/dashboard`} replace />;
  }

  const startDate = dateFormatter(selectedClient.contract.startDate);
  const endDate = dateFormatter(selectedClient.contract.endDate);

  const handleUpgradeYourPlanOnClick = () => {
    upgradeYourPlan(
      "Do you want to upgrade your plan? Contact us!",
      UpgradeYourPlanTypes.Discovery,
      { source: "account-information", client: selectedClient.uuid }
    );
    Segment.analytics?.track("open-upgrade-your-plan", {
      clientUUID: selectedClient.uuid,
      source: "account-information",
    });
  };

  return (
    <ContentSection>
      <Content>
        <Card>
          <CardContent>
            <Section>
              Plan name: {isClientPremium(selectedClient) && "Novable "}
              {selectedClient.contract.contractName}
              {"   "}
              {!isClientPremium(selectedClient) && (
                <Button
                  style={{ marginLeft: "1rem" }}
                  onClick={handleUpgradeYourPlanOnClick}
                >
                  Upgrade your plan!
                </Button>
              )}
            </Section>
            <Section>Start date: {startDate}</Section>
            <Section>
              {selectedClient.contract.contractName === ContractType.PRO
                ? "Renewal date:"
                : "End date:"}{" "}
              {endDate}
            </Section>
            <Section>
              Campaigns: {publishedCampaigns} out of{" "}
              {selectedClient.contract.limitCampaigns}
            </Section>
            <Section>
              Deliveries: {publishedIterations} out of{" "}
              {selectedClient.contract.limitIterations}
            </Section>
            <Section>
              Professional Service Hours left:{" "}
              {selectedClient.contract.limitProServiceHours === 0 ? (
                "Not applicable"
              ) : (
                <>
                  {selectedClient.contract.limitProServiceHours -
                    selectedClient.contract.consumedProServiceHours}{" "}
                  out of {selectedClient.contract.limitProServiceHours}
                </>
              )}
            </Section>

            <Section>
              Users: {statistics?.users}{" "}
              <NavLink to={`/${selectedClient.slug}/user-management`}>
                <Button style={{ marginLeft: "1rem" }}>User management</Button>
              </NavLink>
            </Section>
          </CardContent>
        </Card>
      </Content>
    </ContentSection>
  );
};

const Content = styled.div`
  width: 100%;
`;

const Section = styled.div`
  width: 100%;
  padding: 1rem;
`;
