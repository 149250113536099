import { FC, useEffect } from "react";
import styled from "@emotion/styled";

import { Space as BaseSpace } from "antd";
import { FormikInput } from "../../../ui-components/FormikInput";
import { FormikPhoneInput } from "../../../ui-components/FormikPhoneInput";

import { Button as BaseButton } from "../../../ui-components/Button";
import { ActionButton } from "../../../ui-components/ButtonAction";
import { AuthTitle, ButtonActionText } from "../../../ui-components/Typography";

const MAX_COMPANY_NAME_LENGTH = 100;

interface FormProps {
  errors: { [field: string]: any };
  prevStep: () => void;
  loading: boolean;
}

export const CompanyInformationForm: FC<FormProps> = ({
  errors,
  prevStep,
  loading,
}) => {
  useEffect(() => {
    /* 
      This use effect scrolls to the parent form, in order to display the entire form 
      This is a solution that solves the problem encountered in small screens, in which the
      form will be already scrolled from Step 1 (Personal information) to Step 2 (this current
        component)
      It's a bit of a hacky way to solve it, and it should be improved in the future :)

      TODO: Improve this solution by --> Readjusting the way the layout is done, meaning, the 
      AuthLayout, when the screen is wide enough (as of 25/09/21, 1200px for responsive pages),
      has a container, plus the logo, that already displaces everything down a bit, and the container
      needs to have a background image. This container should expand as much as the content inside, 
      but I haven't found the proper way to do it

      - OR -
      find a way to readjust everything? Don't know, I need suggestions :)

    */
    const signUpForm = document.getElementById("signUpForm");
    if (signUpForm) {
      signUpForm.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <AuthTitle>Setting up your account</AuthTitle>
      <Space direction="vertical" size="small">
        <FormikInput
          name="companyName"
          type="text"
          maxLength={MAX_COMPANY_NAME_LENGTH}
          label="Company name"
          error={errors.companyName}
          isRequired={true}
          responsiveLabel={true}
        />

        <FormikPhoneInput
          name="phoneNumber"
          type="text"
          label="Mobile phone number"
          error={errors.phone}
          responsiveLabel={true}
          isRequired={true}
        />

        <ButtonsSection>
          <BackButton htmlType="button" onClick={prevStep}>
            <ButtonActionText>Back</ButtonActionText>
          </BackButton>
          <Button htmlType="submit" loading={loading}>
            Let's explore innovative Companies
          </Button>
        </ButtonsSection>
      </Space>
    </>
  );
};

const ButtonsSection = styled.div`
  display: flex;
  gap: 1rem;
  padding-bottom: 3rem;
`;

const BackButton = styled(ActionButton)`
  width: 100%;

  @media (max-width: 1199px) {
    border-color: var(--white);
    p {
      color: var(--white);
    }
  }
`;

const Button = styled(BaseButton)`
  width: 100%;
  font-weight: var(--font-weight-bold);
`;

const Space = styled(BaseSpace)`
  width: 100%;
`;
