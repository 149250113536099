import { FC } from "react";
import styled from "@emotion/styled";

// import { WordCloud } from "../WordCloud";
import { BarChart } from "../BarChart";
import { FirmographicsCell } from "../FirmographicsCell";
import { Dictionary } from "../../../share/types";

interface BarChartsProps {
  values: {
    age: Dictionary<number>;
    employeeRange: Dictionary<number>;
    totalFunding: Dictionary<number>;
    wordCloudKeywords: any;
  };
}

export const BarCharts: FC<BarChartsProps> = ({ values }) => {
  const { age, employeeRange, totalFunding, wordCloudKeywords } = values;

  return (
    <BarChartsContainer>
      <BarChartsContainerRow>
        <FirmographicsCell label={"Company age distribution"}>
          <BarChart data={age} />
        </FirmographicsCell>
        <FirmographicsCell label={"Employee count distribution"}>
          <BarChart data={employeeRange} />
        </FirmographicsCell>
      </BarChartsContainerRow>
      <BarChartsContainerRow>
        <FirmographicsCell label={"Funding distribution"}>
          <BarChart data={totalFunding} />
        </FirmographicsCell>
        {/* <FirmographicsCell label={"Most common topics"}>
          <WordCloud keywords={wordCloudKeywords} />
        </FirmographicsCell> */}
      </BarChartsContainerRow>
    </BarChartsContainer>
  );
};

const BarChartsContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
`;

const BarChartsContainerRow = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 1rem;
`;
