import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { fork, put, takeEvery } from "redux-saga/effects";

import {
  BaseCompany,
  companySlice,
  EnrichedProfileStatus,
} from "../share/company/store";

import { backend } from "../utils/http";
import { State } from "../utils/store";
import { goldenBasketSlice } from "../campaign/golden-basket/store";

export const SELECTED_CLIENT_LOCAL_STORAGE_KEY = "selectedClient";

interface ClientAndTagUUID {
  clientUUID: string;
}

export enum TagCompanySource {
  FAVOURITE = "Favourite",
  CAMPAIGN = "Campaign",
  TABLE_VIEW = "Table view",
}

export interface CreateTagData {
  clientUUID: string;
  name: string;
}

export interface UpdateTagData extends ClientAndTagUUID {
  name: string;
}

export interface DeleteTagData {
  tagUUID: string;
}

interface BaseCompanyTagRequestData extends ClientAndTagUUID {
  companyUUID: string;
  tagName: string;
}

export interface AddCompanyToTagData extends BaseCompanyTagRequestData {
  companyName: string;
  addedFrom: TagCompanySource;
}

export interface RemoveCompanyFromTagData extends BaseCompanyTagRequestData {}

export interface Tag {
  uuid: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
}

export interface TagCompany {
  uuid: string;
  companyName: string;
  companyUuid: string;
  createdAt: Date;
  updatedAt: Date;
  added_by: string;
  added_from: string;
  tag: string; // Tag UUID
  company: BaseCompany;
  enrichedStatus: EnrichedProfileStatus;
}

export interface TagsState {
  getClientTags: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  createTag: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  updateTag: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  deleteTag: {
    loading: boolean;
    error: string;
    success: boolean;
    requestedUUID: string;
  };
  addCompanyToTag: {
    loading: boolean;
    error: string;
    success: boolean;
    errorData: object;
  };
  removeCompanyFromTag: {
    loading: boolean;
    error: string;
    success: boolean;
    errorData: object;
  };
  tags: Tag[];
  openedTagWidgetCompanyTags: string[];
  createTagModalVisible: boolean;
}

const initialState = {
  getClientTags: {
    loading: false,
    error: "",
    success: false,
  },
  createTag: {
    loading: false,
    error: "",
    success: false,
  },
  updateTag: {
    loading: false,
    error: "",
    success: false,
  },
  deleteTag: {
    loading: false,
    error: "",
    success: false,
    requestedUUID: "",
  },
  addCompanyToTag: {
    loading: false,
    error: "",
    success: false,
    errorData: {},
  },
  removeCompanyFromTag: {
    loading: false,
    error: "",
    success: false,
    errorData: {},
  },
  tags: [],
  openedTagWidgetCompanyTags: [],
  createTagModalVisible: false,
} as TagsState;

export const tagsSlice = createSlice({
  name: "tags",
  initialState: initialState,
  reducers: {
    setInitialState(state) {
      state = { ...initialState };
    },
    setCreateTagModalVisibility(state, action: PayloadAction<boolean>) {
      state.createTagModalVisible = action.payload;
    },
    getClientTags(state, action: PayloadAction<string>) {
      state.getClientTags.loading = true;
      state.getClientTags.success = false;
      state.getClientTags.error = "";
    },
    getClientTagsSuccess(state, action) {
      state.getClientTags.loading = false;
      state.getClientTags.success = true;
      state.tags = action.payload.data;
    },
    getClientTagsFailure(state, action) {
      state.getClientTags.loading = false;
      state.getClientTags.success = false;
      state.getClientTags.error =
        action.payload.message || action.payload?.data?.message;
    },
    setInitialCreateTagState(state) {
      state.createTag.loading = false;
      state.createTag.success = false;
      state.createTag.error = "";
    },
    createTag(state, action: PayloadAction<CreateTagData>) {
      state.createTag.loading = true;
      state.createTag.success = false;
      state.createTag.error = "";
    },
    createTagSuccess(state, action) {
      state.createTag.loading = false;
      state.createTag.success = true;
      state.tags = [action.payload.data, ...state.tags];
    },
    createTagFailure(state, action) {
      state.createTag.loading = false;
      state.createTag.success = false;
      state.createTag.error =
        action.payload.message || action.payload?.data?.message;
    },
    setInitialUpdateTagState(state) {
      state.updateTag.loading = false;
      state.updateTag.success = false;
      state.updateTag.error = "";
    },
    updateTag(state, action: PayloadAction<UpdateTagData>) {
      state.updateTag.loading = true;
      state.updateTag.success = false;
      state.updateTag.error = "";
    },
    updateTagSuccess(state, action) {
      state.updateTag.loading = false;
      state.updateTag.success = true;

      const position = getTagPositionByTagUUID(
        state.tags,
        action.payload.data.uuid
      );
      if (position !== -1) {
        state.tags[position] = action.payload.data;
      }
    },
    updateTagFailure(state, action) {
      state.updateTag.loading = false;
      state.updateTag.success = false;
      state.updateTag.error =
        action.payload.message || action.payload?.data?.message;
    },
    deleteTag(state, action: PayloadAction<DeleteTagData>) {
      state.deleteTag.loading = true;
      state.deleteTag.success = false;
      state.deleteTag.error = "";
      state.deleteTag.requestedUUID = action.payload.tagUUID;
    },
    deleteTagSuccess(state, action) {
      state.deleteTag.loading = false;
      state.deleteTag.success = true;
      state.deleteTag.error = "";
      state.tags = state.tags.filter(
        (tag: Tag) => tag.uuid !== action.payload.tagUUID
      );
    },
    deleteTagFailure(state, action) {
      state.deleteTag.loading = false;
      state.deleteTag.success = false;
      state.deleteTag.error =
        action.payload.message || action.payload?.data?.message;
    },
    addCompanyToTag(state, action: PayloadAction<AddCompanyToTagData>) {
      state.addCompanyToTag.loading = true;
      state.addCompanyToTag.success = false;
      state.addCompanyToTag.error = "";
      state.addCompanyToTag.errorData = {};
    },
    addCompanyToTagSuccess(state, action) {
      state.addCompanyToTag.loading = false;
      state.addCompanyToTag.success = true;
      state.addCompanyToTag.error = "";
      state.addCompanyToTag.errorData = {};
      state.removeCompanyFromTag.error = "";

      // If the tag is new, the newly created tag information will be returned on the response
      if (action.payload?.data?.tag) {
        state.tags.push(action.payload?.data?.tag);
      }
    },
    addCompanyToTagFailure(state, action) {
      state.addCompanyToTag.loading = false;
      state.addCompanyToTag.success = false;
      state.addCompanyToTag.error =
        action.payload.message || action.payload?.data?.message;
      state.addCompanyToTag.errorData =
        action.payload.data || action.payload?.data?.data;
    },
    removeCompanyFromTag(
      state,
      action: PayloadAction<RemoveCompanyFromTagData>
    ) {
      state.removeCompanyFromTag.loading = true;
      state.removeCompanyFromTag.success = false;
      state.removeCompanyFromTag.error = "";
      state.removeCompanyFromTag.errorData = {};
      state.addCompanyToTag.error = "";
    },
    removeCompanyFromTagSuccess(
      state,
      action: PayloadAction<RemoveCompanyFromTagData>
    ) {
      state.removeCompanyFromTag.loading = false;
      state.removeCompanyFromTag.success = true;
      state.removeCompanyFromTag.error = "";
      state.removeCompanyFromTag.errorData = {};
    },
    removeCompanyFromTagFailure(state, action) {
      state.removeCompanyFromTag.loading = false;
      state.removeCompanyFromTag.success = false;
      state.removeCompanyFromTag.error =
        action.payload.message || action.payload?.data?.message;
      state.removeCompanyFromTag.errorData =
        action.payload.data || action.payload?.data?.data;
    },
  },
});

const getTagPositionByTagUUID = (tags: Tag[], tagUUID: string) =>
  tags.findIndex((tag: Tag) => {
    return tag.uuid === tagUUID;
  });

export const tagsOfCompanies = (state: State) => state.tags.tags;
export const openedTagWidgetCompanyTags = (state: State) =>
  state.tags.openedTagWidgetCompanyTags;

function* getClientTagsSaga() {
  yield takeEvery(tagsSlice.actions.getClientTags, function* (action) {
    try {
      const response: AxiosResponse<Tag[]> =
        yield backend.tagBackend.getClientTags(action.payload);

      yield put(tagsSlice.actions.getClientTagsSuccess(response));
    } catch (err) {
      yield put(tagsSlice.actions.getClientTagsFailure(err));
    }
  });
}

function* createTagSaga() {
  yield takeEvery(tagsSlice.actions.createTag, function* (action) {
    try {
      const response: AxiosResponse<Tag[]> = yield backend.tagBackend.createTag(
        action.payload
      );

      yield put(tagsSlice.actions.createTagSuccess(response));
    } catch (err) {
      yield put(tagsSlice.actions.createTagFailure(err));
    }
  });
}

function* updateTagSaga() {
  yield takeEvery(tagsSlice.actions.updateTag, function* (action) {
    try {
      const response: AxiosResponse<Tag[]> = yield backend.tagBackend.updateTag(
        action.payload
      );

      yield put(tagsSlice.actions.updateTagSuccess(response));
    } catch (err) {
      yield put(tagsSlice.actions.updateTagFailure(err));
    }
  });
}

function* deleteTagSaga() {
  yield takeEvery(tagsSlice.actions.deleteTag, function* (action) {
    try {
      yield backend.tagBackend.deleteTag(action.payload);
      yield put(tagsSlice.actions.deleteTagSuccess(action.payload));
    } catch (err) {
      yield put(tagsSlice.actions.deleteTagFailure(err));
    }
  });
}

function* addCompanyToTagSaga() {
  yield takeEvery(tagsSlice.actions.addCompanyToTag, function* (action) {
    try {
      const response: AxiosResponse = yield backend.tagBackend.addCompanyToTag(
        action.payload
      );

      yield put(tagsSlice.actions.addCompanyToTagSuccess(response));

      const data = {
        companyUUID: action.payload.companyUUID,
        tagName: action.payload.tagName,
      };
      try {
        yield put(companySlice.actions.addCompanyToTag(data));
      } catch {
        // Do nothing
      }

      try {
        yield put(goldenBasketSlice.actions.addTagToCompany(data));
      } catch (err) {
        // Do nothing
      }
    } catch (err) {
      yield put(tagsSlice.actions.addCompanyToTagFailure(err));
    }
  });
}

function* removeCompanyFromTagSaga() {
  yield takeEvery(tagsSlice.actions.removeCompanyFromTag, function* (action) {
    try {
      yield backend.tagBackend.removeCompanyFromTag(action.payload);

      yield put(tagsSlice.actions.removeCompanyFromTagSuccess(action.payload));

      const data = {
        companyUUID: action.payload.companyUUID,
        tagName: action.payload.tagName,
      };

      try {
        yield put(companySlice.actions.removeTagFromCompany(data));
      } catch {
        // Do nothing
      }

      try {
        yield put(goldenBasketSlice.actions.removeTagFromCompany(data));
      } catch {
        // Do nothing
      }
    } catch (err) {
      yield put(tagsSlice.actions.removeCompanyFromTagFailure(err));
    }
  });
}

export function* tagsSaga() {
  yield fork(addCompanyToTagSaga);
  yield fork(createTagSaga);
  yield fork(deleteTagSaga);
  yield fork(getClientTagsSaga);
  yield fork(removeCompanyFromTagSaga);
  yield fork(updateTagSaga);
}

export const {
  addCompanyToTag,
  createTag,
  deleteTag,
  getClientTags,
  removeCompanyFromTag,
  setInitialCreateTagState,
  setInitialState,
  setInitialUpdateTagState,
  setCreateTagModalVisibility,
  updateTag,
} = tagsSlice.actions;
