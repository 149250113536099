import { FC } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";

import { Spin, Alert } from "antd";
import { FormikInput } from "../../ui-components/FormikInput";
import { AuthTitle } from "../../ui-components/Typography";
import {
  Form,
  Space,
  Button,
  ButtonWrapper,
} from "../../share/auth/components/share-ui";
import { TermsAndConditions } from "./TermsAndConditions";

import { SetPasswordSchema } from "../validation";
import { setPassword } from "../store";

import { useAppDispatch } from "../../utils/hooks";

interface FormProps {
  loading: boolean;
  message: string | null;
  invite: string | null;
}

export const SetPasswordFormFromInvite: FC<FormProps> = ({
  loading,
  message,
  invite,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        acceptedTermsAndConditions: false,
        invite: invite || "",
      }}
      onSubmit={(values) => {
        dispatch(setPassword({ ...values }));
      }}
      validationSchema={SetPasswordSchema}
    >
      {({ handleSubmit, errors }) => (
        <Form
          onSubmit={handleSubmit}
          className="set-password-form"
          id="setPasswordForm"
        >
          <AuthTitle>Create password</AuthTitle>
          <Space direction="vertical" size="small">
            <FormikInput
              name="firstName"
              type="text"
              label="First Name"
              error={errors.firstName}
              isRequired={true}
              responsiveLabel={true}
            />
            <FormikInput
              name="lastName"
              type="text"
              label="Last Name"
              error={errors.lastName}
              isRequired={true}
              responsiveLabel={true}
            />
            <FormikInput
              name="password"
              type="password"
              label="Enter password"
              error={errors.password}
              isRequired={true}
              responsiveLabel={true}
            />
            <FormikInput
              name="confirmPassword"
              type="password"
              label="Repeat password"
              error={errors.confirmPassword}
              isRequired={true}
              responsiveLabel={true}
            />
            <TermsAndConditions errors={errors} />

            <ButtonWrapper>
              <Button htmlType="submit" disabled={loading}>
                {loading ? (
                  <>
                    Processing... &nbsp; <Spin />{" "}
                  </>
                ) : (
                  <> Set password </>
                )}
              </Button>
              {message && <Alert type="error" message={message} />}
            </ButtonWrapper>

            <Link to="/login">Back to login</Link>
          </Space>
        </Form>
      )}
    </Formik>
  );
};
