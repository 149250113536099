import { Collapse as BaseCollapse } from "antd";
import styled from "@emotion/styled";
import { DownOutlined } from "@ant-design/icons";
import { Slider } from "../../../ui-components/Slider";
import { ToggleTag } from "../../../ui-components/ToggleTag";

import { Cluster, Keyword } from "../../../share/campaigns/store";
import { cleanKeyword } from "../../../helpers";

interface ClusterCollapseProps {
  clusters: Cluster[];
  handleSliderChange: (value: number, clusterLabel: string) => void;
  addTag: (clusterLabel: string, newKeyword: Keyword) => void;
  removeTag: (clusterLabel: string, deletedKeyword: Keyword) => void;
  setActivePanel: (newActivePanel: string) => void;
}

export const ClusterCollapse = ({
  clusters,
  handleSliderChange,
  addTag,
  removeTag,
  setActivePanel,
}: ClusterCollapseProps) => {
  const handleCollapseOnChange = (value: string | string[]) => {
    if (value && typeof value === "string") {
      setActivePanel(value);
    }
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <DownOutlined rotate={isActive ? 180 : 0} />
      )}
      accordion
      onChange={handleCollapseOnChange}
      defaultActiveKey={["0"]}
    >
      {clusters.map((cluster: Cluster, clusterIndex: number) => (
        <Panel
          key={clusterIndex}
          header={cleanKeyword(cluster.label)}
          extra={
            <Slider
              disabled={
                cluster.keywords.filter((keyword) => keyword.isActive)
                  .length === 0
              }
              value={cluster.keywords.length === 0 ? 0 : cluster.weight}
              onChange={(value: number) =>
                handleSliderChange(value, cluster.label)
              }
            />
          }
        >
          {cluster.keywords.length > 0 ? (
            cluster.keywords.map((keyword: Keyword) => (
              <ToggleTag
                key={keyword.keyword}
                addTag={() => addTag(cluster.label, keyword)}
                removeTag={() => removeTag(cluster.label, keyword)}
                initialActive={keyword.isActive}
              >
                {cleanKeyword(keyword.keyword)}
              </ToggleTag>
            ))
          ) : (
            <p>No topics</p>
          )}
        </Panel>
      ))}
    </Collapse>
  );
};

const Collapse = styled(BaseCollapse)`
  background-color: var(--background-color-base);

  .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: var(--font-weight);
  }

  .ant-collapse-extra {
    margin-left: auto;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 1.2rem 0 1.6rem 0 !important;
    margin: 0 1.6rem 0 1.6rem;
    border-top: var(--campaign-card-border);
  }
`;

const Panel = styled(BaseCollapse.Panel)`
  margin-bottom: 1.6rem;
  background-color: var(--white);
  border: var(--campaign-card-border);
  border-radius: var(--border-radius);
`;
