import { FC, useEffect } from "react";

import { Tooltip } from "antd";
import {
  successNotification,
  errorNotification,
} from "../../../../../../ui-components/Notification";
import { StylelessButton } from "../../../../../../ui-components/StylelessButton";
import { StyledButtonText } from "../../../../../../ui-components/Typography";

import { sendInvitationReminder } from "../../../../../store";
import { State } from "../../../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../../../utils/hooks";

import { getHourDiff } from "../../../../../../helpers";

const REMINDER_INTERVAL = 24;

interface SendInvitationReminderButtonProps {
  userUUID: string;
  lastReminderAt: Date | undefined;
}

export const SendInvitationReminderButton: FC<SendInvitationReminderButtonProps> =
  ({ userUUID, lastReminderAt }) => {
    const dispatch = useAppDispatch();

    const { success, loading, error, invitationUUID } = useAppSelector(
      (state: State) => state.userManagement.sendInvitationReminder
    );

    const reminderRequestForUser = invitationUUID === userUUID;

    useEffect(() => {
      if (reminderRequestForUser) {
        if (success) {
          successNotification("Invitation has been sent successfully");
        } else if (success === false) {
          errorNotification(error);
        }
      }
    }, [success, loading, error, reminderRequestForUser]);

    const handleConfirm = () => {
      const data = {
        invitationUUID: userUUID,
      };
      dispatch(sendInvitationReminder(data));
    };

    const isProcessingRequest = loading && reminderRequestForUser;

    const now = new Date();

    let disableReminderButton = false;
    let tooltipTitle;

    if (!lastReminderAt) {
      tooltipTitle = "You can send a reminder every 24 hours";
      disableReminderButton = true;
    } else {
      const lastReminderDate = new Date(lastReminderAt);
      const hourDiff = getHourDiff(now, lastReminderDate);
      disableReminderButton = hourDiff < REMINDER_INTERVAL;

      if (disableReminderButton) {
        tooltipTitle = "You've sent a reminder in the last 24 hours";
      }
    }

    return (
      <>
        {disableReminderButton ? (
          <Tooltip title={tooltipTitle}>
            <span style={{ cursor: "not-allowed" }}>
              <StylelessButton
                disabled={true}
                style={{ pointerEvents: "none" }}
              >
                <StyledButtonText disabled={true}>
                  Send reminder
                </StyledButtonText>
              </StylelessButton>
            </span>
          </Tooltip>
        ) : (
          <StylelessButton
            onClick={handleConfirm}
            loading={isProcessingRequest}
            disabled={isProcessingRequest}
          >
            <StyledButtonText>Send reminder</StyledButtonText>
          </StylelessButton>
        )}
      </>
    );
  };
