import { Segment } from "../utils/Segment";

export function openLinkInNewTabAndTrack(
  event: React.MouseEvent<HTMLAnchorElement>,
  href: string,
  eventTrackName: string,
  eventProperties?: object | any | undefined
) {
  event.preventDefault();
  event.stopPropagation();

  Segment.analytics?.track(eventTrackName, eventProperties);

  window.open(href, "_blank", "noreferrer");
}
