export const countriesTableFilter = [
  {
    text: "Europe",
    value: 0,
    children: [
      {
        text: "Åland Islands",
        value: "ALA",
      },
      {
        text: "Albania",
        value: "ALB",
      },
      {
        text: "Andorra",
        value: "AND",
      },
      {
        text: "Austria",
        value: "AUT",
      },
      {
        text: "Belgium",
        value: "BEL",
      },
      {
        text: "Bulgaria",
        value: "BGR",
      },
      {
        text: "Bosnia and Herzegovina",
        value: "BIH",
      },
      {
        text: "Belarus",
        value: "BLR",
      },
      {
        text: "Switzerland",
        value: "CHE",
      },
      {
        text: "Cyprus",
        value: "CYP",
      },
      {
        text: "Czechia",
        value: "CZE",
      },
      {
        text: "Germany",
        value: "DEU",
      },
      {
        text: "Denmark",
        value: "DNK",
      },
      {
        text: "Spain",
        value: "ESP",
      },
      {
        text: "Estonia",
        value: "EST",
      },
      {
        text: "Finland",
        value: "FIN",
      },
      {
        text: "France",
        value: "FRA",
      },
      {
        text: "Faroe Islands",
        value: "FRO",
      },
      {
        text: "United Kingdom",
        value: "GBR",
      },
      {
        text: "Guernsey",
        value: "GGY",
      },
      {
        text: "Gibraltar",
        value: "GIB",
      },
      {
        text: "Greece",
        value: "GRC",
      },
      {
        text: "Croatia",
        value: "HRV",
      },
      {
        text: "Hungary",
        value: "HUN",
      },
      {
        text: "Isle of Man",
        value: "IMN",
      },
      {
        text: "Ireland",
        value: "IRL",
      },
      {
        text: "Iceland",
        value: "ISL",
      },
      {
        text: "Italy",
        value: "ITA",
      },
      {
        text: "Jersey",
        value: "JEY",
      },
      {
        text: "Kosovo",
        value: "UNK",
      },
      {
        text: "Liechtenstein",
        value: "LIE",
      },
      {
        text: "Lithuania",
        value: "LTU",
      },
      {
        text: "Luxembourg",
        value: "LUX",
      },
      {
        text: "Latvia",
        value: "LVA",
      },
      {
        text: "Monaco",
        value: "MCO",
      },
      {
        text: "Moldova",
        value: "MDA",
      },
      {
        text: "North Macedonia",
        value: "MKD",
      },
      {
        text: "Malta",
        value: "MLT",
      },
      {
        text: "Montenegro",
        value: "MNE",
      },
      {
        text: "Netherlands",
        value: "NLD",
      },
      {
        text: "Norway",
        value: "NOR",
      },
      {
        text: "Poland",
        value: "POL",
      },
      {
        text: "Portugal",
        value: "PRT",
      },
      {
        text: "Romania",
        value: "ROU",
      },
      {
        text: "Russia",
        value: "RUS",
      },
      {
        text: "Svalbard and Jan Mayen",
        value: "SJM",
      },
      {
        text: "San Marino",
        value: "SMR",
      },
      {
        text: "Serbia",
        value: "SRB",
      },
      {
        text: "Slovakia",
        value: "SVK",
      },
      {
        text: "Slovenia",
        value: "SVN",
      },
      {
        text: "Sweden",
        value: "SWE",
      },
      {
        text: "Ukraine",
        value: "UKR",
      },
      {
        text: "Vatican City",
        value: "VAT",
      },
    ],
  },
  {
    text: "North America",
    value: 1,
    children: [
      {
        text: "Aruba",
        value: "ABW",
      },
      {
        text: "Anguilla",
        value: "AIA",
      },
      {
        text: "Antigua and Barbuda",
        value: "ATG",
      },
      {
        text: "Bahamas",
        value: "BHS",
      },
      {
        text: "Barbados",
        value: "BRB",
      },
      {
        text: "Belize",
        value: "BLZ",
      },
      {
        text: "Bermuda",
        value: "BMU",
      },
      {
        text: "British Virgin Islands",
        value: "VGB",
      },
      {
        text: "Canada",
        value: "CAN",
      },
      {
        text: "Costa Rica",
        value: "CRI",
      },
      {
        text: "Cuba",
        value: "CUB",
      },
      {
        text: "Cayman Islands",
        value: "CYM",
      },
      {
        text: "Dominica",
        value: "DMA",
      },
      {
        text: "Dominican Republic",
        value: "DOM",
      },
      {
        text: "El Salvador",
        value: "SLV",
      },
      {
        text: "Guadeloupe",
        value: "GLP",
      },
      {
        text: "Guatemala",
        value: "GTM",
      },
      {
        text: "Greenland",
        value: "GRL",
      },
      {
        text: "Honduras",
        value: "HND",
      },
      {
        text: "Haiti",
        value: "HTI",
      },
      {
        text: "Jamaica",
        value: "JAM",
      },
      {
        text: "Mexico",
        value: "MEX",
      },
      {
        text: "Montserrat",
        value: "MSR",
      },
      {
        text: "Martinique",
        value: "MTQ",
      },
      {
        text: "Nicaragua",
        value: "NIC",
      },
      {
        text: "Panama",
        value: "PAN",
      },
      {
        text: "Puerto Rico",
        value: "PRI",
      },
      {
        text: "Saint Kitts and Nevis",
        value: "KNA",
      },
      {
        text: "Saint Lucia",
        value: "LCA",
      },
      {
        text: "Turks and Caicos Islands",
        value: "TCA",
      },
      {
        text: "Trinidad and Tobago",
        value: "TTO",
      },
      {
        text: "United States Minor Outlying Islands",
        value: "UMI",
      },
      {
        text: "United States",
        value: "USA",
      },
      {
        text: "United States Virgin Islands",
        value: "VIR",
      },
    ],
  },
  {
    text: "South America",
    value: 2,
    children: [
      {
        text: "Argentina",
        value: "ARG",
      },
      {
        text: "Bolivia",
        value: "BOL",
      },
      {
        text: "Brazil",
        value: "BRA",
      },
      {
        text: "Chile",
        value: "CHL",
      },
      {
        text: "Colombia",
        value: "COL",
      },
      {
        text: "Ecuador",
        value: "ECU",
      },
      {
        text: "Falkland Islands",
        value: "FLK",
      },
      {
        text: "French Guiana",
        value: "GUF",
      },
      {
        text: "Guyana",
        value: "GUY",
      },
      {
        text: "Paraguay",
        value: "PRY",
      },
      {
        text: "Peru",
        value: "PER",
      },
      {
        text: "Uruguay",
        value: "URY",
      },
      {
        text: "Venezuela",
        value: "VEN",
      },
      {
        text: "Caribbean Netherlands",
        value: "BES",
      },
      {
        text: "Curaçao",
        value: "CUW",
      },
      {
        text: "Grenada",
        value: "GRD",
      },
      {
        text: "Saint Barthélemy",
        value: "BLM",
      },
      {
        text: "Saint Martin",
        value: "MAF",
      },
      {
        text: "Saint Pierre and Miquelon",
        value: "SPM",
      },
      {
        text: "Suriname",
        value: "SUR",
      },
      {
        text: "Sint Maarten",
        value: "SXM",
      },
      {
        text: "Saint Vincent and the Grenadines",
        value: "VCT",
      },
    ],
  },
  {
    text: "Asia",
    value: 3,
    children: [
      {
        text: "Afghanistan",
        value: "AFG",
      },
      {
        text: "United Arab Emirates",
        value: "ARE",
      },
      {
        text: "Armenia",
        value: "ARM",
      },
      {
        text: "Azerbaijan",
        value: "AZE",
      },
      {
        text: "Bangladesh",
        value: "BGD",
      },
      {
        text: "Bahrain",
        value: "BHR",
      },
      {
        text: "Brunei",
        value: "BRN",
      },
      {
        text: "Bhutan",
        value: "BTN",
      },
      {
        text: "China",
        value: "CHN",
      },
      {
        text: "Georgia",
        value: "GEO",
      },
      {
        text: "Hong Kong",
        value: "HKG",
      },
      {
        text: "Indonesia",
        value: "IDN",
      },
      {
        text: "India",
        value: "IND",
      },
      {
        text: "Iran",
        value: "IRN",
      },
      {
        text: "Iraq",
        value: "IRQ",
      },
      {
        text: "Israel",
        value: "ISR",
      },
      {
        text: "Jordan",
        value: "JOR",
      },
      {
        text: "Japan",
        value: "JPN",
      },
      {
        text: "Kazakhstan",
        value: "KAZ",
      },
      {
        text: "Kyrgyzstan",
        value: "KGZ",
      },
      {
        text: "Cambodia",
        value: "KHM",
      },
      {
        text: "South Korea",
        value: "KOR",
      },
      {
        text: "Kuwait",
        value: "KWT",
      },
      {
        text: "Laos",
        value: "LAO",
      },
      {
        text: "Lebanon",
        value: "LBN",
      },
      {
        text: "Sri Lanka",
        value: "LKA",
      },
      {
        text: "Macau",
        value: "MAC",
      },
      {
        text: "Maldives",
        value: "MDV",
      },
      {
        text: "Myanmar",
        value: "MMR",
      },
      {
        text: "Mongolia",
        value: "MNG",
      },
      {
        text: "Malaysia",
        value: "MYS",
      },
      {
        text: "Nepal",
        value: "NPL",
      },
      {
        text: "Oman",
        value: "OMN",
      },
      {
        text: "Pakistan",
        value: "PAK",
      },
      {
        text: "Philippines",
        value: "PHL",
      },
      {
        text: "North Korea",
        value: "PRK",
      },
      {
        text: "Palestine",
        value: "PSE",
      },
      {
        text: "Qatar",
        value: "QAT",
      },
      {
        text: "Saudi Arabia",
        value: "SAU",
      },
      {
        text: "Singapore",
        value: "SGP",
      },
      {
        text: "Syria",
        value: "SYR",
      },
      {
        text: "Thailand",
        value: "THA",
      },
      {
        text: "Tajikistan",
        value: "TJK",
      },
      {
        text: "Turkmenistan",
        value: "TKM",
      },
      {
        text: "Timor-Leste",
        value: "TLS",
      },
      {
        text: "Turkey",
        value: "TUR",
      },
      {
        text: "Taiwan",
        value: "TWN",
      },
      {
        text: "Uzbekistan",
        value: "UZB",
      },
      {
        text: "Vietnam",
        value: "VNM",
      },
      {
        text: "Yemen",
        value: "YEM",
      },
    ],
  },
  {
    text: "Oceania",
    value: 4,
    children: [
      {
        text: "American Samoa",
        value: "ASM",
      },
      {
        text: "Australia",
        value: "AUS",
      },
      {
        text: "Cocos (Keeling) Islands",
        value: "CCK",
      },
      {
        text: "Cook Islands",
        value: "COK",
      },
      {
        text: "Christmas Island",
        value: "CXR",
      },
      {
        text: "Fiji",
        value: "FJI",
      },
      {
        text: "Micronesia",
        value: "FSM",
      },
      {
        text: "Guam",
        value: "GUM",
      },
      {
        text: "Kiribati",
        value: "KIR",
      },
      {
        text: "Marshall Islands",
        value: "MHL",
      },
      {
        text: "Northern Mariana Islands",
        value: "MNP",
      },
      {
        text: "New Caledonia",
        value: "NCL",
      },
      {
        text: "Norfolk Island",
        value: "NFK",
      },
      {
        text: "Niue",
        value: "NIU",
      },
      {
        text: "Nauru",
        value: "NRU",
      },
      {
        text: "New Zealand",
        value: "NZL",
      },
      {
        text: "Pitcairn Islands",
        value: "PCN",
      },
      {
        text: "Palau",
        value: "PLW",
      },
      {
        text: "Papua New Guinea",
        value: "PNG",
      },
      {
        text: "French Polynesia",
        value: "PYF",
      },
      {
        text: "Solomon Islands",
        value: "SLB",
      },
      {
        text: "Tokelau",
        value: "TKL",
      },
      {
        text: "Tonga",
        value: "TON",
      },
      {
        text: "Tuvalu",
        value: "TUV",
      },
      {
        text: "Vanuatu",
        value: "VUT",
      },
      {
        text: "Wallis and Futuna",
        value: "WLF",
      },
      {
        text: "Samoa",
        value: "WSM",
      },
    ],
  },
  {
    text: "Africa",
    value: 5,
    children: [
      {
        text: "Angola",
        value: "AGO",
      },
      {
        text: "Burundi",
        value: "BDI",
      },
      {
        text: "Benin",
        value: "BEN",
      },
      {
        text: "Burkina Faso",
        value: "BFA",
      },
      {
        text: "Saint Helena, Ascension and Tristan da Cunha",
        value: "SHN",
      },
      {
        text: "Botswana",
        value: "BWA",
      },
      {
        text: "Central African Republic",
        value: "CAF",
      },
      {
        text: "Ivory Coast",
        value: "CIV",
      },
      {
        text: "Cameroon",
        value: "CMR",
      },
      {
        text: "DR Congo",
        value: "COD",
      },
      {
        text: "Republic of the Congo",
        value: "COG",
      },
      {
        text: "Comoros",
        value: "COM",
      },
      {
        text: "Cape Verde",
        value: "CPV",
      },
      {
        text: "Djibouti",
        value: "DJI",
      },
      {
        text: "Algeria",
        value: "DZA",
      },
      {
        text: "Egypt",
        value: "EGY",
      },
      {
        text: "Eritrea",
        value: "ERI",
      },
      {
        text: "Western Sahara",
        value: "ESH",
      },
      {
        text: "Ethiopia",
        value: "ETH",
      },
      {
        text: "Gabon",
        value: "GAB",
      },
      {
        text: "Ghana",
        value: "GHA",
      },
      {
        text: "Guinea",
        value: "GIN",
      },
      {
        text: "Gambia",
        value: "GMB",
      },
      {
        text: "Guinea-Bissau",
        value: "GNB",
      },
      {
        text: "Equatorial Guinea",
        value: "GNQ",
      },
      {
        text: "British Indian Ocean Territory",
        value: "IOT",
      },
      {
        text: "Kenya",
        value: "KEN",
      },
      {
        text: "Liberia",
        value: "LBR",
      },
      {
        text: "Libya",
        value: "LBY",
      },
      {
        text: "Lesotho",
        value: "LSO",
      },
      {
        text: "Morocco",
        value: "MAR",
      },
      {
        text: "Madagascar",
        value: "MDG",
      },
      {
        text: "Mali",
        value: "MLI",
      },
      {
        text: "Mozambique",
        value: "MOZ",
      },
      {
        text: "Mauritania",
        value: "MRT",
      },
      {
        text: "Mauritius",
        value: "MUS",
      },
      {
        text: "Malawi",
        value: "MWI",
      },
      {
        text: "Mayotte",
        value: "MYT",
      },
      {
        text: "Namibia",
        value: "NAM",
      },
      {
        text: "Niger",
        value: "NER",
      },
      {
        text: "Nigeria",
        value: "NGA",
      },
      {
        text: "Réunion",
        value: "REU",
      },
      {
        text: "Rwanda",
        value: "RWA",
      },
      {
        text: "Sudan",
        value: "SDN",
      },
      {
        text: "Senegal",
        value: "SEN",
      },
      {
        text: "Sierra Leone",
        value: "SLE",
      },
      {
        text: "Somalia",
        value: "SOM",
      },
      {
        text: "South Sudan",
        value: "SSD",
      },
      {
        text: "São Tomé and Príncipe",
        value: "STP",
      },
      {
        text: "Eswatini",
        value: "SWZ",
      },
      {
        text: "Seychelles",
        value: "SYC",
      },
      {
        text: "Chad",
        value: "TCD",
      },
      {
        text: "Togo",
        value: "TGO",
      },
      {
        text: "Tunisia",
        value: "TUN",
      },
      {
        text: "Tanzania",
        value: "TZA",
      },
      {
        text: "Uganda",
        value: "UGA",
      },
      {
        text: "South Africa",
        value: "ZAF",
      },
      {
        text: "Zambia",
        value: "ZMB",
      },
      {
        text: "Zimbabwe",
        value: "ZWE",
      },
    ],
  },
  {
    text: "Antarctic",
    value: 6,
    children: [
      {
        text: "Antarctica",
        value: "ATA",
      },
      {
        text: "French Southern and Antarctic Lands",
        value: "ATF",
      },
      {
        text: "Bouvet Island",
        value: "BVT",
      },
      {
        text: "Heard Island and McDonald Islands",
        value: "HMD",
      },
      {
        text: "South Georgia",
        value: "SGS",
      },
    ],
  },
];
