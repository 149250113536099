export const isEllipsisActive = (element: HTMLElement) => {
  return element.offsetWidth < element.scrollWidth;
};

export const cleanKeyword = (keyword: string) => {
  if (!keyword) return "";
  return keyword
    .trim()
    .replaceAll("_", " ")
    .replaceAll(" - ", "-")
    .replaceAll("-PRON-", "the")
    .replaceAll("datum", "data")
    .replaceAll("medium", "media")
    .replaceAll("&", "and")
    .replace(/\b\w/g, (l: string) => l.toUpperCase());
};

export const toNumberWithSeparators = (x: number) => {
  return Math.round(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export function cleanUrl(url: string) {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
}

export function beautifyUrl(url: string) {
  // Remove http/https from URL
  return url.replace(/^(?:https?:\/\/)/i, "");
}

export const reverseString = (s: string) => {
  return s.split("").reverse().join("");
};

export const formatLabel = (label: string) => {
  const reversedLabel = reverseString(label);
  const formattedLabel = reversedLabel
    .replaceAll("000000", "M")
    .replaceAll("000", "K");
  return reverseString(formattedLabel);
};

export const camelCaseToTitleCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const capitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
