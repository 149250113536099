import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import { Collapse } from "antd";
import { ArchivedCampaignCard } from "./components/ArchivedCampaignCard";
import { ArchivePanelHeader } from "./components/ArchivePanelHeader";
import { CampaignCard } from "./components/CampaignCard";
import { ClientStatistics } from "./components/ClientStatistics";
import { UpgradePlan } from "./components/UpgradePlan";
import { EmptyDashboard } from "./components/EmptyDashboard";

import { CenteredErrorMessage } from "../ui-components/CenteredErrorMessage";
import { CenteredSpinner } from "../ui-components/CenteredSpinner";
import { ContentSection } from "../ui-components/layout/UserLayout";
import {
  errorNotification,
  successNotification,
} from "../ui-components/Notification";
import { DashboardSectionTitle } from "../ui-components/Typography";

import { getClientLists } from "../list/store";
import {
  canCreateMoreCampaigns,
  getCampaigns,
  setInitialArchiveState,
  setInitialRestoreState,
} from "../share/campaigns/store";
import { getClientTags } from "../tags/store";
import { State } from "../utils/store";
import { useAppDispatch, useAppSelector } from "../utils/hooks";

import { Segment } from "../utils/Segment";

const { Panel: BasePanel } = Collapse;

const archivedPanelKey = "archivedCampaign";

export const DashboardView = () => {
  const dispatch = useAppDispatch();

  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { campaigns, archivedCampaigns } = useAppSelector(
    (state: State) => state.campaigns
  );
  const { loading: getCampaignsLoading, error: getCampaignsError } =
    useAppSelector((state: State) => state.campaigns.getCampaigns);
  const { success: archiveSuccess, error: archiveError } = useAppSelector(
    (state: State) => state.campaigns.archiveCampaign
  );
  const { success: restoreSuccess, error: restoreError } = useAppSelector(
    (state: State) => state.campaigns.restoreCampaign
  );

  const _canCreateMoreCampaigns = useAppSelector(canCreateMoreCampaigns());
  const userID = useAppSelector((state: State) => state.auth.user?.uuid);

  const { success: deleteDraftSuccess } = useAppSelector(
    (state: State) => state.newCampaign.deleteDraftCampaign
  );

  const [activePanelKey, setActivePanelKey] = useState(archivedPanelKey);

  const toggleArchivedCampaignsPanel = () => {
    if (activePanelKey) setActivePanelKey("");
    else setActivePanelKey(archivedPanelKey);
  };

  useEffect(() => {
    if (deleteDraftSuccess) successNotification("Draft deleted");
  }, [dispatch, deleteDraftSuccess]);

  useEffect(() => {
    if (archiveSuccess) successNotification("Campaign archived");
    else if (archiveError) errorNotification(archiveError);
    return () => {
      dispatch(setInitialArchiveState());
    };
  }, [dispatch, archiveSuccess, archiveError]);

  useEffect(() => {
    if (restoreSuccess) successNotification("Campaign restored");
    else if (restoreError) errorNotification(restoreError);

    return () => {
      dispatch(setInitialRestoreState());
    };
  }, [dispatch, restoreSuccess, restoreError]);

  useEffect(() => {
    // On some occassion a user may be already logged in from a previous session,
    // but the session has expired. The user may (very rarely) still be able to briefly
    // access dashboard, without data. That even should not be registered
    if (userID) {
      Segment.analytics?.track("page view dashboard");
    }
  }, [userID]);

  // Fetch campaigns on dashboard
  // This is a duplicated call, since this same getCampaigns is done on the
  // userLayout, after a client has been loaded.
  // But it's "acceptable", since it's going to be duplicated only once, and
  // conditionally, only when a user goes to dashboard on first load. This is
  // also done to retrieve updated numbers for campaigns stats shown on the
  // dashboard
  useEffect(() => {
    if (selectedClient) {
      dispatch(getCampaigns(selectedClient));
      dispatch(getClientLists(selectedClient.uuid));
      dispatch(getClientTags(selectedClient.uuid));
    }
  }, [dispatch, selectedClient]);

  if (getCampaignsLoading) {
    return <CenteredSpinner size="large" />;
  }

  if (getCampaignsError) {
    return <CenteredErrorMessage>{getCampaignsError}</CenteredErrorMessage>;
  }

  return (
    <ContentSection>
      <SectionContainer>
        <ClientStatistics />
        <DashboardSectionTitleContainer>
          <DashboardSectionTitle>
            Active campaigns ({campaigns.length})
          </DashboardSectionTitle>
        </DashboardSectionTitleContainer>
        <ContentContainer>
          {campaigns.length === 0 ? (
            <EmptyDashboard />
          ) : (
            campaigns.map((campaign) => (
              <div key={campaign.uuid}>
                <Link
                  to={`/${selectedClient?.slug}/campaign/golden-basket/${campaign.slug}`}
                >
                  <CampaignCard
                    campaign={campaign}
                    clientSlug={selectedClient?.slug}
                  />
                </Link>
              </div>
            ))
          )}
        </ContentContainer>
        <ContentContainer style={{ marginTop: "4rem" }}>
          {archivedCampaigns.length > 0 && (
            <Collapse
              activeKey={activePanelKey}
              //defaultActiveKey={[archivedPanelKey]}
            >
              <Panel
                header={
                  <ArchivePanelHeader
                    activePanelKey={activePanelKey}
                    onclick={toggleArchivedCampaignsPanel}
                  />
                }
                key={archivedPanelKey}
                showArrow={false}
              >
                {archivedCampaigns.map((campaign) => (
                  <ArchivedCampaign key={campaign.uuid}>
                    <ArchivedCampaignCard campaign={campaign} />
                  </ArchivedCampaign>
                ))}
              </Panel>
            </Collapse>
          )}
        </ContentContainer>
        {!_canCreateMoreCampaigns && <UpgradePlan />}
      </SectionContainer>
    </ContentSection>
  );
};

const SectionContainer = styled.div`
  padding: 2.4rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin: 0;
`;

const DashboardSectionTitleContainer = styled.div`
  margin: 2rem 0 1rem 0;
`;

const Panel = styled(BasePanel)`
  & .ant-collapse-content-box {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  & .ant-collapse-header-text {
    width: 100%;
  }
`;

const ArchivedCampaign = styled.li`
  width: 48%;
`;
