import styled from "@emotion/styled";
import { Tooltip } from "antd";

import { isEllipsisActive } from "../../../../../helpers";

export const Title = ({ companyName }: { companyName: string }) => {
  const elementID = "companyHeaderName";
  let showToolTip = false;

  const div = document.getElementById(elementID);
  if (div) showToolTip = isEllipsisActive(div);

  return (
    <>
      {showToolTip ? (
        <Tooltip title={companyName}>
          <CompanyHeaderName id={elementID}>{companyName}</CompanyHeaderName>
        </Tooltip>
      ) : (
        <CompanyHeaderName id={elementID}>{companyName}</CompanyHeaderName>
      )}
    </>
  );
};

export const CompanyHeaderName = styled.h2`
  line-height: 4rem;
  font-size: 2.8rem;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 1200px) {
    line-height: 2.4rem;
    font-size: 2rem;
  }
`;
