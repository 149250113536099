import styled from "@emotion/styled";
import { FC } from "react";

import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { InfoIcon, NonClickableTag } from ".";
import { Section, SectionHeader, SectionTitle } from "../../ui-share";

import { capitalizeWord } from "../../../../../helpers";

interface CompanyClassificationProps {
  tags: string[];
}

export const CompanyClassification: FC<CompanyClassificationProps> = ({
  tags,
}) => {
  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Company Classification</SectionTitle>
        &nbsp;
        {/* <Tooltip title="This is an indication on the categorisation of this company computed by AI. The tag is shown if related activities are detected on the company">
          <QuestionCircleOutlined />
        </Tooltip> */}
      </SectionHeader>
      <Container>
        {tags.map((topic, index) => (
          <NonClickableTag key={index}>{capitalizeWord(topic)}</NonClickableTag>
        ))}
      </Container>
    </Section>
  );
};

const Container = styled.div`
  margin-left: var(--gb-content-section-left-margin);
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;
