import { Result } from "antd";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Button as BaseButton } from "../../../ui-components/Button";

export const ResultResetPassword = () => {
  return (
    <Result
      status="success"
      title="Your password has been reset!"
      subTitle="This change may take a bit of time"
      extra={
        <Link to="login">
          <Button type="primary" key="console">
            Back to login
          </Button>
        </Link>
      }
    />
  );
};

const Button = styled(BaseButton)`
  width: 100%;
  font-weight: var(--font-weight-bold);
  margin-bottom: 1rem;
`;
