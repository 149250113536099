import React from "react";
import { Radar, defaults } from "react-chartjs-2";

import { Cluster } from "../../../share/campaigns/store";
import { cleanKeyword } from "../../../helpers";
import antCssVars from "../../../styles/antCssVars";

// radar options
const primaryColor = antCssVars["primary-color"];
const dataBackgroundColor = "rgba(33, 174, 255, 0.2)";

defaults.scale.grid.color = primaryColor;
defaults.scale.ticks.display = false;

const options = {
  scale: {
    min: 0,
    beginAtZero: true,
    font: {
      size: 40,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

interface RadarChartProps {
  clustersData: Cluster[];
  clusterValues: number[];
}

export const RadarChart = React.memo(
  ({ clustersData, clusterValues }: RadarChartProps) => {
    const data = {
      labels: clustersData.map((cluster: Cluster) =>
        cleanKeyword(cluster.label)
      ),
      datasets: [
        {
          data: clusterValues,
          backgroundColor: dataBackgroundColor,
          borderColor: primaryColor,
          borderWidth: 2,
          pointBackgroundColor: primaryColor,
        },
      ],
    };

    return <Radar data={data} options={options} height={285} width={285} />;
  },
  (prevProps, nextProps) =>
    prevProps.clusterValues.length === nextProps.clusterValues.length &&
    prevProps.clusterValues.every(
      (value, index) => value === nextProps.clusterValues[index]
    )
);
