import { useNavigate } from "react-router";
import styled from "@emotion/styled";

import { Tooltip } from "antd";
import { PlusCircleOutlined as BasePlusCircleOutlined } from "@ant-design/icons";
import { warningNoMoreDraftsAvailable } from "../share";

import { setInitialState } from "../../../new-campaign/store";
import { canCreateMoreDrafts } from "../../../share/campaigns/store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { Segment } from "../../../utils/Segment";

export const CreateCampaignIcon = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const _canCreateMoreDrafts = useAppSelector(canCreateMoreDrafts());
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  function onClick(event: any) {
    // To avoid opening/closing the Campaigns section
    event.stopPropagation();
    if (!_canCreateMoreDrafts) {
      warningNoMoreDraftsAvailable();
    } else {
      Segment.analytics?.track("create-campaign", { source: "sidebar" });
      dispatch(setInitialState());
      navigate(`/${selectedClient?.slug}/new-campaign`);
    }
  }

  return (
    <Tooltip title="Create a new campaign">
      <PlusCircleOutlined onClick={(e) => onClick(e)} />
    </Tooltip>
  );
};

const PlusCircleOutlined = styled(BasePlusCircleOutlined)`
  margin-left: 1rem;
`;
