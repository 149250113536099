import { http } from "../http";

export async function getClients() {
  const { data } = await http.get("/api/clients");
  return data;
}

export async function getClient(slug: string) {
  const { data } = await http.get(`/api/client/${slug}`);
  return data;
}
