import { FC, useState } from "react";
import { useParams } from "react-router";
import styled from "@emotion/styled";

import { Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { TopbarButton } from ".";
import { ExportGoldenBasketModal } from "./ExportGoldenBasketModal";
import {
  upgradeYourPlan,
  UpgradeYourPlanTypes,
} from "../../../UpgradeYourPlan";

import { selectedCampaignSlugSelector } from "../../../../share/campaigns/store";
import { selectedClientHasAutomaticExport } from "../../../../clients/store";

import { State } from "../../../../utils/store";
import { useAppSelector } from "../../../../utils/hooks";
import { isClientPremium } from "../../../../helpers";

export interface ExportGoldenBasketButtonProps {}

export const ExportGoldenBasketButton: FC<ExportGoldenBasketButtonProps> =
  () => {
    const [modalVisible, setModalVisible] = useState(false);

    const { GBList } = useAppSelector((state: State) => state.goldenBasket);
    const { selectedClient } = useAppSelector((state: State) => state.clients);

    const { campaignSlug } = useParams<{ campaignSlug: string }>();
    const campaign = useAppSelector(selectedCampaignSlugSelector(campaignSlug));
    const clientHasAutomaticExport = useAppSelector(
      selectedClientHasAutomaticExport()
    );

    const handleModalClose = () => {
      setModalVisible(false);
    };

    const handleToggleModalVisibility = () => {
      if (!selectedClient) return;
      if (!isClientPremium(selectedClient)) {
        upgradeYourPlan(
          "Upgrade your plan to request an export.",
          UpgradeYourPlanTypes.Sales,
          { source: "export-golden-basket", client: selectedClient.uuid }
        );
        return;
      }
      setModalVisible(!modalVisible);
    };

    // If there's no data to export, don't show button
    if (GBList.length === 0) return <></>;

    return (
      <>
        {campaign?.requests?.exportRequested ? (
          <Tooltip title="The export for this campaign has already been requested. You will receive it shortly.">
            <span style={{ cursor: "not-allowed", marginLeft: "auto" }}>
              <TopBarButtonStyled disabled={true}>
                <DownloadOutlined /> Export
              </TopBarButtonStyled>
            </span>
          </Tooltip>
        ) : (
          <TopBarButtonStyled onClick={handleToggleModalVisibility}>
            <DownloadOutlined /> Export
          </TopBarButtonStyled>
        )}

        <ExportGoldenBasketModal
          visible={modalVisible}
          handleModalClose={handleModalClose}
          campaignUUID={campaign?.uuid}
          clientUUID={selectedClient?.uuid}
          clientHasAutomaticExport={clientHasAutomaticExport}
        />
      </>
    );
  };

const TopBarButtonStyled = styled(TopbarButton)`
  -webkit-animation: scale-up-center 0.3s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  &:disabled {
    &:hover {
      color: var(--white);
    }
    background-color: var(--disabled-btn-background);
    color: var(--white);
  }
`;
