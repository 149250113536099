import { FC } from "react";
import styled from "@emotion/styled";

import { Button as BaseButton } from "antd";

import arrowIcon from "../../../../assets/icons/arrow.svg";

interface RequestButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export const RequestButton: FC<RequestButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button {...props}>
      <ButtonContent>
        <ButtonInformation>{children}</ButtonInformation>
        <ArrowIcon />
      </ButtonContent>
    </Button>
  );
};

const ButtonInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ArrowIcon = styled.div`
  background: url(${arrowIcon});
  width: 0.494rem;
  height: 0.8rem;
`;

const Button = styled(BaseButton)`
  width: 100%;
  background-color: var(--white);
  border: var(--campaign-card-border);
  margin-bottom: 0.4rem;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--primary-color-hover);
    border: var(--button-border);
  }
`;
