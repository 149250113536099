import { FC } from "react";

import { DeleteInvitationButton } from "./DeleteInvitationButton";
import { SendInvitationReminderButton } from "./SendInvitationReminderButton";
import { ButtonsContainer } from "../ButtonsContainer";

interface InvitationActionsProps {
  userUUID: string;
  lastReminderAt: Date;
  clientUUID: string | undefined;
}

export const InvitationActions: FC<InvitationActionsProps> = ({
  userUUID,
  lastReminderAt,
  clientUUID,
}) => {
  return (
    <ButtonsContainer>
      <SendInvitationReminderButton
        userUUID={userUUID}
        lastReminderAt={lastReminderAt}
      />
      <DeleteInvitationButton userUUID={userUUID} clientUUID={clientUUID} />
    </ButtonsContainer>
  );
};
