import styled from "@emotion/styled";

import { Card as BaseCard } from "antd";
// import { MailOutlined } from "@ant-design/icons";
// import { Button } from "../../ui-components/Button";
// import { GhostButton } from "../../ui-components/GhostButton";
import {
  UpgradePlanParagraph,
  // ButtonText,
} from "../../ui-components/Typography";

import upgradePlanIcon from "../../assets/icons/upgrade-plan.svg";
// import LockIcon from "../../assets/icons/lock.svg";

/*
  Buttons commented for the moment since they lack functionality.
  Until they are fixed, they have been replaced with plain text

*/
export const UpgradePlan = () => {
  return (
    <Card>
      <CardBody>
        <UpgradePlanIcon />
        <TextSection>
          <UpgradePlanTitle>Upgrade Plan</UpgradePlanTitle>
          <UpgradePlanParagraph>To add more campaigns</UpgradePlanParagraph>
        </TextSection>
        <ButtonsSection>
          <UpgradePlanParagraph>
            Contact us at support@novable.com
          </UpgradePlanParagraph>

          {/* <UpgradeButton>
            Upgrade plan <Lock />
          </UpgradeButton>
          <ORText>OR</ORText>
          <GhostButton>
            <ButtonText>
              Contact with agent <SendIcon />
            </ButtonText>
          </GhostButton> */}
        </ButtonsSection>
      </CardBody>
    </Card>
  );
};

const Card = styled(BaseCard)`
  border-radius: var(--border-radius);
  background: var(--rightbar-content-background);
  height: 11.7rem;
  border: var(--campaign-stats-border-right);
  margin-top: 4rem;
`;

const CardBody = styled.div`
  width: 100%;
  height: 11.7rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const UpgradePlanIcon = styled.div`
  width: 4.2rem;
  height: 3.4rem;
  margin-right: 2rem;
  background-image: url(${upgradePlanIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const TextSection = styled.div`
  margin-right: 4rem;
`;

const UpgradePlanTitle = styled(UpgradePlanParagraph)`
  font-weight: var(--font-weight-bold);
  font-size: 1.6rem;
`;

const ButtonsSection = styled.div`
  width: 28rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

// const ORText = styled(UpgradePlanParagraph)`
//   font-weight: var(--font-weight-bold);
//   margin: 0 1.2rem;
//   font-size: 1.2rem;
// `;

// const UpgradeButton = styled(Button)`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `;

// const Lock = styled.div`
//   width: 1rem;
//   height: 1.4rem;
//   margin-left: 1rem;
//   background-image: url(${LockIcon});
//   background-size: 100% 100%;
//   background-repeat: no-repeat;
// `;

// const SendIcon = styled(MailOutlined)`
//   color: var(--primary-color);
//   margin-left: 0.9rem;
// `;
