import { FC } from "react";
import styled from "@emotion/styled";
import { Input as BaseInput, Form } from "antd";
import {
  Label,
  RequiredLabel,
  ResponsiveLabel,
  ResponsiveRequiredLabel,
} from "./Typography";
import { useField } from "formik";
import { Dictionary } from "../share/types";

export interface FormikInputProps {
  type: string;
  name: string;
  label?: string;
  responsiveLabel?: boolean;
  as?: string;
  ariaLabel?: string;
  // Formik does not allow access for the field.required information as of 21/09/21
  // To be removed if that access is ever granted
  isRequired?: boolean;
  formItemProps?: Dictionary<any>;
  [x: string]: any;
}

export const FormikInput: FC<FormikInputProps> = ({
  type,
  name,
  label,
  as,
  ariaLabel,
  isRequired,
  responsiveLabel,
  formItemProps,
  ...rest
}) => {
  const [field, meta, { setValue, setTouched }] = useField({
    name,
    type,

    ...rest,
  });
  const invalid = meta.touched && meta.error; // Show error message & red border
  const MyInput = type === "password" ? PasswordInput : TextInput;

  const { disabled } = rest;

  let LabelComponent;
  if (isRequired) {
    if (responsiveLabel) LabelComponent = ResponsiveRequiredLabel;
    else LabelComponent = RequiredLabel;
  } else {
    if (responsiveLabel) LabelComponent = ResponsiveLabel;
    else LabelComponent = Label;
  }

  return (
    <Form.Item
      help={invalid ? meta.error : null}
      style={{ marginBottom: '0.5rem' }}
      validateStatus={invalid ? "error" : "success"}
      {...formItemProps}
    >
      {label && <LabelComponent htmlFor={name}>{label}</LabelComponent>}
      {as === "textarea" ? (
        <TextArea
          name={name}
          value={field.value}
          showCount={!disabled}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => setTouched(true)}
          aria-label={ariaLabel}
          disabled={disabled}
          {...rest}
        />
      ) : (
        <MyInput
          name={name}
          type={type}
          aria-label={ariaLabel}
          value={field.value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => setTouched(true)}
          disabled={disabled}
          {...rest}
        />
      )}
    </Form.Item>
  );
};

const TextInput = styled(BaseInput)<{ disabled: boolean }>`
  height: 3.6rem;

  ${(props) =>
    props.disabled &&
    `
    color: var(--font-color-disabled-input) !important;
    border: none;
    background: none !important;
    cursor: text !important;
    `};
`;

const TextArea = styled(BaseInput.TextArea)<{ disabled: boolean }>`
  textarea.ant-input {
    min-height: 8.8rem;
  }

  ${(props) =>
    props.disabled &&
    `
    color: var(--font-color-disabled-input) !important;
    border: none;
    height: fit-content !important;
    background: none !important;
    resize: none;
    cursor: text !important;
    `};
`;

const PasswordInput = TextInput.withComponent(BaseInput.Password);
