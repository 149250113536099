import { FC } from "react";
import { useField } from "formik";
import styled from "@emotion/styled";

import { Form, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { E164Number } from "libphonenumber-js/types";
import PhoneInput from "react-phone-number-input";
import { ResponsiveRequiredLabel } from "./Typography";

import "react-phone-number-input/style.css";

export interface FormikInputProps {
  type: string;
  name: string;
  label?: string;
  responsiveLabel?: boolean;
  ariaLabel?: string;
  // Formik does not allow access for the field.required information as of 21/09/21
  // To be removed if that access is ever granted
  isRequired?: boolean;
  [x: string]: any;
}

const tooltipText =
  "We handle your contact details with great care and do not share them with anyone. Expect a friendly call from our teams to see how we could help you.";

export const FormikPhoneInput: FC<FormikInputProps> = ({
  type,
  name,
  label,
  ariaLabel,
  isRequired,
  responsiveLabel,
  ...rest
}) => {
  const [field, meta, { setValue, setTouched }] = useField({
    name,
    type,

    ...rest,
  });
  const invalid = meta.touched && meta.error; // Show error message & red border

  return (
    <Form.Item
      help={invalid ? meta.error : null}
      validateStatus={invalid ? "error" : "success"}
    >
      <FlexContainer>
        <ResponsiveRequiredLabel htmlFor={name}>
          {label}
        </ResponsiveRequiredLabel>
        <Tooltip title={tooltipText}>
          <span>
            <QuestionCircleOutlined />
          </span>
        </Tooltip>
      </FlexContainer>

      <PhoneInput
        name="phone"
        placeholder="E.g. +32 485 12 34 56"
        country="US"
        value={field.value}
        label="Phone number*"
        isRequired={isRequired}
        responsiveLabel={true}
        onChange={(value: E164Number) => {
          setValue(value);
        }}
        onBlur={() => setTouched(true)}
        {...rest}
      />
    </Form.Item>
  );
};

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
