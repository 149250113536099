import { FC } from "react";
import styled from "@emotion/styled";

import { ActionButton } from "../../../share/company/components/ui-share";

import eyeIcon from "../../../assets/icons/eye.svg";
import eyeBlueIcon from "../../../assets/icons/eye-blue.svg";

import { followCompany } from "../../golden-basket/store";
import { useAppDispatch } from "../../../utils/hooks";

interface TableFollowButtonProps {
  GBCompanyUUID: string;
  isFollowed: boolean;
}

export const TableFollowButton: FC<TableFollowButtonProps> = ({
  GBCompanyUUID,
  isFollowed,
}) => {
  const dispatch = useAppDispatch();
  const handleFollow = (e: any) => {
    e.stopPropagation();

    if (!GBCompanyUUID) return;
    let payload = {
      GBCompanyUUID: GBCompanyUUID,
      followed: !isFollowed,
    };
    dispatch(followCompany(payload));
  };

  return (
    <ActionButton onClick={handleFollow}>
      <EyeIcon followed={isFollowed} />
    </ActionButton>
  );
};

const EyeIcon = styled.div<{ followed: boolean | undefined }>`
  background: ${(props) =>
    props.followed ? `url(${eyeBlueIcon})` : `url(${eyeIcon})`};
  width: 1.467rem;
  height: 1rem;
  filter: ${(props) => !props.followed && "var(--filter-primary-color)"};
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;
