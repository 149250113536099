import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Formik } from "formik";

import { CommentLabel } from ".";
import {
  Section as BaseSection,
  SectionContent as BaseSectionContent,
} from "../../ui-share";
import { EditButton } from "../../../../../ui-components/EditButton";
import { FormikInput } from "../../../../../ui-components/FormikInput";
import { Button } from "../../../../../ui-components/Button";
import {
  errorNotification,
  successNotification,
} from "../../../../../ui-components/Notification";

import { filteredGBCompanySelector, editCompanyComment } from "../../../store";
import { State } from "../../../../../utils/store";

import { MaxCommentLengthSchema } from "./validation";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import { Segment } from "../../../../../utils/Segment";
import { dateFormatter } from "../../../../../helpers";

const SAVE_COMMENT_EVENT_NAME = "save-comment";
const DELETE_COMMENT_EVENT_NAME = "delete-comment";
const EDIT_COMMENT_EVENT_NAME = "edit-comment";

interface ClientCommentProps {
  comment: string | null | undefined;
  author: string | null | undefined;
  commentDate: string | null | undefined;
}

export const ClientComment: FC<ClientCommentProps> = ({
  comment,
  author,
  commentDate,
}) => {
  const dispatch = useAppDispatch();
  const [isInEditMode, setIsInEditMode] = useState(false);
  const { success, message, loading } = useAppSelector(
    (state: State) => state.goldenBasket.editCompanyComment
  );
  const { user } = useAppSelector((state: State) => state.auth);
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const gbCompany = useAppSelector(filteredGBCompanySelector);

  useEffect(() => {
    if (!comment || comment.length === 0) setIsInEditMode(true);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (success === true) {
      successNotification("Comment saved properly");
      setIsInEditMode(false);
    }
    if (success === false) {
      errorNotification(message);
    }
  }, [success, message]);

  let baseCommentLabel = "Internal note";
  let commentAuthor = author ? `by ${author}` : "";
  let commentAt = commentDate ? `on ${dateFormatter(commentDate)}` : "";
  const commentLabel = baseCommentLabel + " " + commentAuthor + " " + commentAt;

  const handleEditComment = () => {
    setIsInEditMode(true);
    handleTrackEvent(EDIT_COMMENT_EVENT_NAME);
  };

  const handleCancelEditComment = () => {
    setIsInEditMode(false);
  };

  const handleDeleteComment = (e: any) => {
    if (!gbCompany) return;
    handleDispatchEditComment(null, DELETE_COMMENT_EVENT_NAME);
  };

  const handleSaveComment = (newComment: string | null | undefined) => {
    if (!gbCompany || !user) return;

    handleDispatchEditComment(newComment, SAVE_COMMENT_EVENT_NAME);
  };

  const handleDispatchEditComment = (
    commentValue: string | null | undefined,
    eventName: string
  ) => {
    if (!gbCompany || !user) return;

    const data = {
      comment: commentValue || null,
      commentAuthor: commentValue ? user?.email : null,
      commentDate: commentValue ? new Date().toISOString() : null,
      gbCompanyUUID: gbCompany.uuid,
    };
    dispatch(editCompanyComment(data));

    handleTrackEvent(eventName);
  };

  const handleTrackEvent = (eventName: string) => {
    if (!gbCompany || !user) return;
    Segment.analytics?.track(eventName, {
      companyUUID: gbCompany.company.uuid,
      companyName: gbCompany.company.name,
      campaignCompanyUUID: gbCompany.uuid,
      campaignUUID: gbCompany.campaign,
      clientUUID: selectedClient?.uuid,
      clientName: selectedClient?.name,
    });
  };

  return (
    <Section>
      {isInEditMode ? (
        <>
          <Formik
            initialValues={{ comment: comment }}
            onSubmit={(values, { resetForm }) => {
              handleSaveComment(values.comment);
            }}
            validationSchema={MaxCommentLengthSchema}
          >
            {({ handleSubmit, values, errors }) => (
              <form onSubmit={handleSubmit}>
                <SectionContent>
                  <FormikInput
                    name="comment"
                    type="text"
                    value={values.comment}
                    error={errors.comment}
                    formItemProps={{ style: { width: "100%", margin: 0 } }}
                    disabled={loading}
                  />
                  <ButtonsContainer>
                    <Button
                      htmlType="submit"
                      title="Save"
                      loading={loading}
                      disabled={loading}
                    >
                      <CheckOutlined />
                    </Button>
                  </ButtonsContainer>
                </SectionContent>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <>
          {" "}
          {comment && <CommentLabel>{commentLabel}</CommentLabel>}
          <SectionContent>
            <CommentContent title={comment || ""}>
              {comment ? (
                comment
              ) : (
                <i
                  onClick={() => setIsInEditMode(true)}
                  style={{ cursor: "pointer", fontSize: "1.2rem" }}
                >
                  Add your company comment here
                </i>
              )}
            </CommentContent>
            <div>
              <EditButton
                style={{ padding: "4 8" }}
                title="Edit the comment"
                onClick={handleEditComment}
              >
                <EditOutlined />
              </EditButton>
              {comment && (
                <EditButton
                  title="Delete the comment"
                  onClick={handleDeleteComment}
                >
                  <DeleteOutlined />
                </EditButton>
              )}
            </div>
          </SectionContent>
        </>
      )}
    </Section>
  );
};

const Section = styled(BaseSection)`
  margin-bottom: 0.5rem;
  margin-left: var(--gb-content-section-left-margin);
`;

const SectionContent = styled(BaseSectionContent)`
  padding: 1.3rem;
  border-radius: var(--border-radius);
  background-color: var(--background-color-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--gb-content-font-size);
`;

const CommentContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  overflow-wrap: break-word;

  & p {
    margin: 0;
  }
`;

const ButtonsContainer = styled.div`
  margin-left: 0.5rem;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
`;
