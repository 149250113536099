import styled from "@emotion/styled";
import { FC } from "react";
import {
  Section,
  SectionContent as BaseSectionContent,
} from "../../ui-share";
import { CompanyPageFieldName } from "../../../../../ui-components/Typography";
import { FeedbackDetails } from "../../../store";

export const Feedback: FC<{ feedback: FeedbackDetails }> = ({ feedback }) => {
  let username: any = feedback.author?.firstName;
  if (!username) username = feedback.author?.displayName;
  else
    username +=
      (feedback.author?.lastName && " " + feedback.author?.lastName) || "";

  let formattedDate: string = "";
  if (typeof feedback.createdAt === "string") {
    formattedDate = feedback.createdAt;
  } else if (typeof feedback.createdAt === "object") {
    try {
      formattedDate = formatDate(feedback.createdAt);
    } catch (e) {
      formattedDate = JSON.stringify(feedback.createdAt);
    }
  }

  return (
    <Section>
      <SectionContent isMatch={feedback.isMatch}>
        <FeedbackMultiItem>
          <FeedbackItem>
            <CompanyPageFieldName>Decision:</CompanyPageFieldName>
            <Content>{username}</Content>
          </FeedbackItem>

          <FeedbackItem>
            <CompanyPageFieldName>Date: </CompanyPageFieldName>
            <Content>{formattedDate}</Content>
          </FeedbackItem>
        </FeedbackMultiItem>
        {feedback.reason && (
          <FeedbackItem>
            <CompanyPageFieldName>Reason:</CompanyPageFieldName>
            <Content>{feedback.reason}</Content>
          </FeedbackItem>
        )}
        {feedback.comment && (
          <FeedbackItem>
            <CompanyPageFieldName>Comment:</CompanyPageFieldName>
            <Content>{feedback.comment}</Content>
          </FeedbackItem>
        )}
      </SectionContent>
    </Section>
  );
};

const SectionContent = styled(BaseSectionContent)<{ isMatch: boolean }>`
  padding: 1.5rem;
  border-radius: var(--border-radius);
  width: 90%;
  background-color: ${(props) =>
    props.isMatch
      ? "var(--match-green-hover-background)"
      : "var(--miss-red-hover-background)"};
`;

const FeedbackItem = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;

const FeedbackMultiItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Content = styled.div`
  word-wrap: anywhere;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

function formatDate(dateObj: Date): string {
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0-11
  const year = String(dateObj.getFullYear()).slice(-2); // Get last two digits of the year

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}