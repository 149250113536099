import { FC, useState } from "react";
import styled from "@emotion/styled";

import {
  Card,
  CardContent,
  CardHeader,
  CardHeaderText,
  CompanyHeaderDetails,
  CompanyHeaderDetailsText,
  CompanyTitle,
  Description,
  Location,
  Logo,
  Team,
} from "../../../../ui-components/CompanyCardStyles";
import defaultLogo from "../../../../assets/defaults/default-logo.png";

import { GoldenBasketCompany } from "../../store";
import {
  followCompany,
  favouriteGBCompany,
  getPositionByCompanyUUIDSelector,
} from "../../store";
import { State } from "../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";

import { checkCompanyDetailsLength, getLocation } from "../../../../helpers";

interface CompanyCardProps {
  gbCompany: GoldenBasketCompany;
  onClick: (uuid: string) => void;
}

export const CompanyCard: FC<CompanyCardProps> = ({ gbCompany, onClick }) => {
  const dispatch = useAppDispatch();
  const [allDescription, showAllDescription] = useState(false);
  const company = gbCompany.company;
  const location = getLocation(company.city, company.country);
  const gbRank = useAppSelector(getPositionByCompanyUUIDSelector(company.uuid));
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { selectedCompany } = useAppSelector(
    (state: State) => state.goldenBasket
  );

  const isSelected = selectedCompany?.uuid === gbCompany.company.uuid;

  const handleShowAllDescription = (e: any) => {
    e.stopPropagation();
    showAllDescription((prevState) => !prevState);
  };

  const handleFollow = (e: any) => {
    e.stopPropagation();
    let payload = {
      GBCompanyUUID: gbCompany.uuid,
      followed: !gbCompany.followed,
    };
    dispatch(followCompany(payload));
  };

  const handleFavourite = (e: any) => {
    if (!selectedClient) return;
    e.stopPropagation();
    let payload = {
      companyUUID: gbCompany.company.uuid,
      clientUUID: selectedClient.uuid,
    };
    dispatch(favouriteGBCompany(payload));
  };

  return (
    <Card
      onClick={() => onClick(gbCompany.uuid)}
      isSelected={isSelected || false}
    >
      <CardHeader>
        <Logo
          src={company.logo || defaultLogo}
          alt="Snapshot of the company's website"
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = defaultLogo;
          }}
        />

        <CardHeaderText>
          <CompanyTitle>{company.name}</CompanyTitle>
          <CompanyHeaderDetails
            length={checkCompanyDetailsLength(
              location,
              company.employeesRangeStart,
              company.employeesRangeEnd
            )}
          >
            {location ? (
              <CompanyHeaderDetailsText>
                <Location /> {location}
              </CompanyHeaderDetailsText>
            ) : null}
            {company.employeesRangeStart && company.employeesRangeEnd ? (
              <CompanyHeaderDetailsText>
                {" "}
                <Team />{" "}
                {`${company.employeesRangeStart} - ${company.employeesRangeEnd}`}
              </CompanyHeaderDetailsText>
            ) : null}
          </CompanyHeaderDetails>
        </CardHeaderText>
      </CardHeader>
      <CardContent>
        <Description allDescription={allDescription}>
          {company.shortDescription}
        </Description>
      </CardContent>
    </Card>
  );
};
