import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Navigate } from "react-router";

import { GoldenBasketDetail } from "./components/GoldenBasketDetail";
import { GoldenBasketCompanyList } from "./components/GoldenBasketCompanyList";
import { GoldenBasketTableView } from "../table/GoldenBasketTableView";
import { CenteredSpinner } from "../../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../../ui-components/CenteredErrorMessage";
import { UnkownError } from "../../ui-components/states/UnknownError";

import { getGBList, setInitialState, Filter } from "./store";
import {
  handleDisplayTableViewPreference,
  shouldDisplayTableView,
} from "../../helpers";
import { selectedCampaignSlugSelector } from "../../share/campaigns/store";
import { setSelectedCompanyInitialState } from "../../share/company/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { State } from "../../utils/store";

import { Segment } from "../../utils/Segment";

export const GoldenBasketView = () => {
  const [_shouldDisplayTableView, setShouldDisplayTableView] = useState(false);

  const dispatch = useAppDispatch();
  // A filter can be passed in the URL if the user clicks on one of the
  // filter options in the campaign card
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const filter = query.get("filter") || Filter.TOREVIEW;
  // If a filter is requested in the campaign card, the automatic change in filter
  // if there are no results is not allowed to happen
  const canResetFilterIfEmptyResults = query.get("filter") ? false : true;

  const { loading, error } = useAppSelector(
    (state: State) => state.goldenBasket.fetchSelectedCompany
  );
  const { success: GBListSuccess, loading: GBListLoading } = useAppSelector(
    (state: State) => state.goldenBasket.getGBList
  );

  const { campaignSlug } = useParams<{ campaignSlug: string }>();
  const campaign = useAppSelector(selectedCampaignSlugSelector(campaignSlug));

  const { selectedClient } = useAppSelector((state: State) => state.clients);

  // IMPORTANT: DO NOT ADD FILTER as a dependency. This useEffect
  // should not trigger on each filter change.
  // However, it is necessary to fire the actions in this particular order
  // (first initial state, and then getGBList), that's why the first dispatch
  // cannot be placed outside in a stand-alone useEffect
  useEffect(() => {
    if (campaignSlug && campaign) {
      dispatch(setInitialState(filter));
      dispatch(getGBList(campaign.uuid));
      Segment.analytics?.track(`open a campaign`, {
        campaignSlug: campaignSlug,
      });
    }
  }, [dispatch, campaignSlug]); // eslint-disable-line

  // Since golden basket view, list view and favourite view all use the
  // state.company.selectedCompany variable, it's necessary to reset it after
  // this component unmounts to avoid unwanted effects
  useEffect(() => {
    return () => {
      dispatch(setSelectedCompanyInitialState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (campaign) {
      setShouldDisplayTableView(shouldDisplayTableView(campaign.uuid));
    }
  }, [campaign, setShouldDisplayTableView]);

  if (!campaignSlug || !campaign) {
    return <Navigate to={`/${selectedClient?.slug}/dashboard`} />;
  }

  const handleDisplayTableView = (shouldDisplay: boolean) => {
    setShouldDisplayTableView(shouldDisplay);
    handleDisplayTableViewPreference(campaign.uuid, shouldDisplay);
  };

  if (_shouldDisplayTableView) {
    return (
      <GoldenBasketTableView handleDisplayTableView={handleDisplayTableView} />
    );
  }

  return (
    <DetailContainer>
      <GoldenBasketCompanyList
        canResetFilter={canResetFilterIfEmptyResults}
        campaign={campaign}
        handleDisplayTableView={handleDisplayTableView}
      />

      {/* Error GoldenBasketDetail flow */}
      {error ? (
        <CenteredErrorMessage>
          {" "}
          There was an error loading the selected Company. {error}{" "}
        </CenteredErrorMessage>
      ) : /* Loading GoldenBasketDetail flow */
      (loading || GBListLoading) && !error ? (
        <CenteredSpinner size="large" />
      ) : /* GoldenBasketCompany fetched succesfully */
      !loading && !error && GBListSuccess ? (
        <GoldenBasketDetail
          campaign={campaign}
          statusPending={campaign.statusPending}
        />
      ) : (
        <UnkownError />
      )}
    </DetailContainer>
  );
};

const DetailContainer = styled.div`
  height: calc(100vh - 8rem);
  display: grid;
  grid-template-columns: 32rem 1fr;
  grid-gap: 0rem;

  @media (max-width: 1200px) {
    grid-template-columns: 24rem 1fr;
  }
`;
