import { http } from "../http";

import {
  AlterUserRoleData,
  DeleteInvitationData,
  DeleteUserRequestData,
  SendInvitationData,
  SendInvitationReminderData,
} from "../../user-management/store";

export async function alterUserRole(alterUserRoleData: AlterUserRoleData) {
  const { data } = await http.put(
    "/user-management/alter-role",
    alterUserRoleData
  );
  return data;
}

export async function deleteInvitation(
  deleteInvitationData: DeleteInvitationData
) {
  const config = {
    data: deleteInvitationData,
  };
  const { data } = await http.delete("/user-management/invite", config);
  return data;
}

export async function deleteUser(deleteUserRequestData: DeleteUserRequestData) {
  const config = {
    data: deleteUserRequestData,
  };
  const { data } = await http.delete("/user-management/alter-role", config);
  return data;
}

export async function getUserList(clientUUID: string) {
  const { data } = await http.get(
    `/user-management/users/client/${clientUUID}`
  );
  return data;
}

export async function sendInvitation(invitationData: SendInvitationData) {
  const { data } = await http.post("/user-management/invite", invitationData);
  return data;
}

export async function sendInvitationReminder(
  invitationReminderData: SendInvitationReminderData
) {
  const { data } = await http.post(
    "/user-management/invite/reminder",
    invitationReminderData
  );
  return data;
}
