import { FC } from "react";
import styled from "@emotion/styled";
import { Row, Col } from "antd";
import { Outlet } from "react-router";

import heroImage from "../../assets/mockups/hero-image.png";

interface AuthLayoutProps {}

export const AuthLayout: FC<AuthLayoutProps> = () => {

  return (
    <Row>
      <Col xl={12} lg={24} sm={24} xs={24}>
          <Content>
            <Outlet />
          </Content>
      </Col>
      <Col xl={12} lg={0} sm={0} xs={0}>
        <RightContainer>
          <HeroImage />
        </RightContainer>
      </Col>
    </Row>
  );
};

const RightContainer = styled.div`
  position: relative;
  height: 100vh;
  background: #FFEFE1;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 6rem;
`;

const Content = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const HeroImage = styled.div`
  width: 80%;
  height: 75%;
  background-image: url(${heroImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
