import { FC } from "react";

import { NonClickableTag } from ".";

interface TagProps {
  tag: string;
}

export const Tag: FC<TagProps> = ({ tag }) => {
  return <NonClickableTag key={tag}>{tag}</NonClickableTag>;
};
