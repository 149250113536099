import styled from "@emotion/styled";

import { PoweredText, PowerededBy } from "../../ui-share";

export const ClearbitAttribution = () => {
  return (
    <PowerededBy>
      <Attribution
        href="https://clearbit.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <PoweredText>Logo provided by Clearbit</PoweredText>
      </Attribution>
    </PowerededBy>
  );
};

const Attribution = styled.a`
  font-size: 1.2rem;
  // line-height: 1.3rem;
  opacity: 0.8;
  color: var(--font-color-campaign-stats);
`;
