import styled from "@emotion/styled";

import { Space as BaseSpace, List } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button as BaseButton } from "../../../ui-components/Button";
import { FormikInput } from "../../../ui-components/FormikInput";
import { AuthTitle } from "../../../ui-components/Typography";
import { Span } from "../../SignUpView";
import { TermsAndConditions } from "../TermsAndConditions";

import { FC } from "react";

interface FormProps {
  errors: { [field: string]: any };
  handleSubmit: () => void;
  loading: boolean;
}

export const PersonalInformationForm: FC<FormProps> = ({
  errors,
  handleSubmit,
  loading,
}) => {
  return (
    <>
      <AuthTitle>Start discovering startups</AuthTitle>

      <Space direction="vertical" size="small">
        <FormikInput
          formItemProps={{ style: { marginBottom: "1.2rem" } }}
          name="firstName"
          type="text"
          label="First Name"
          error={errors.firstName}
          isRequired={true}
          responsiveLabel={true}
        />
        <FormikInput
          formItemProps={{ style: { marginBottom: "1.2rem" } }}
          name="lastName"
          type="text"
          label="Last Name"
          error={errors.lastName}
          isRequired={true}
          responsiveLabel={true}
        />
        <FormikInput
          formItemProps={{ style: { marginBottom: "0" } }}
          name="email"
          type="email"
          label="Work email address"
          isRequired={true}
          responsiveLabel={true}
        />

        <TermsAndConditions
          errors={errors}
          formItemProps={{ style: { marginBottom: "1.2rem" } }}
        />
        <A href="/login">Already have an account? Log in here</A>

        <Button onClick={handleSubmit} loading={loading}>
          Start now
        </Button>

        <List style={{ paddingBottom: "3rem" }}>
          <ListItem style={{ borderBottom: "none" }}>
            <List.Item.Meta
              title={
                <Span>
                  Submit your innovation briefing in less than a minute
                </Span>
              }
              avatar={<BlueCheckMark />}
            />
          </ListItem>
          <ListItem style={{ borderBottom: "none" }}>
            <List.Item.Meta
              title={
                <Span>
                  Explore startups and innovations in any industry, domain or
                  country
                </Span>
              }
              avatar={<BlueCheckMark />}
            />
          </ListItem>
          <ListItem style={{ borderBottom: "none" }}>
            <List.Item.Meta
              title={
                <Span>
                  Obtain immediate results thanks to the real-time search
                  feature
                </Span>
              }
              avatar={<BlueCheckMark />}
            />
          </ListItem>
          <ListItem style={{ borderBottom: "none" }}>
            <List.Item.Meta
              title={
                <Span>
                  Receive support from the Novable Agents, our scouting experts
                </Span>
              }
              avatar={<BlueCheckMark />}
            />
          </ListItem>
        </List>
      </Space>
    </>
  );
};

const A = styled.a`
  @media (max-width: 1199px) {
    color: var(--white);
    text-decoration: underline;
  }
  margin-bottom: 1.2rem;
`;

const Button = styled(BaseButton)`
  width: 100%;
  font-weight: var(--font-weight-bold);
  margin-bottom: 2rem;
`;

const Space = styled(BaseSpace)`
  width: 100%;
  padding: 0rem;
`;

const ListItem = styled(List.Item)`
  padding: 0.4rem 0;
`;

const BlueCheckMark = styled((props: any) => <CheckCircleOutlined {...props} />)`
  color: var(--primary-color);
`;
