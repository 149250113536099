export enum LocalStorageKeys {
  access = "access",
  refresh = "refresh",
  selectedClient = "selectedClient",
}

export function getAccessToken() {
  return localStorage.getItem(LocalStorageKeys.access);
}

export function getRefreshToken() {
  return localStorage.getItem(LocalStorageKeys.refresh);
}

export function setAccessToken(token: string) {
  return localStorage.setItem(LocalStorageKeys.access, token);
}

export function setRefreshToken(refreshToken: string) {
  return localStorage.setItem(LocalStorageKeys.refresh, refreshToken);
}
