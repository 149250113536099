import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { fork, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { backend } from "../utils/http";
import { State } from "../utils/store";

export enum Role {
  admin = "admin",
  contributor = "contributor",
}

export enum AlterRoleRequestType {
  Role,
  Enable,
}

// Requests data interfaces
export interface AlterUserRoleData {
  userUUID: string;
  clientUUID: string;
  role?: Role;
  isEnabled?: boolean;
}

export interface DeleteInvitationData {
  email: string;
  clientUUID: string;
}

export interface DeleteUserRequestData {
  userUUID: string;
  clientUUID: string;
}

export interface SendInvitationData {
  email: string;
  role: string;
  clientUUID: string;
}

export interface SendInvitationReminderData {
  invitationUUID: string;
}

// Structures interfaces
export interface UserInList {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  invitationPendingStatus: boolean;
  role: string;
  requestedToBeDeleted: boolean;
  isEnabled: boolean;
}

export interface UserManagementState {
  alterUserRole: {
    loading: boolean;
    success: boolean | null;
    error: string;
    uuidRequested: string;
    requestType: AlterRoleRequestType | null;
  };
  deleteInvitation: {
    loading: boolean;
    success: boolean | null;
    error: string;
    emailRequested: string;
  };
  deleteUser: {
    loading: boolean;
    success: boolean | null;
    error: string;
    uuidRequested: string;
  };
  getUserList: {
    loading: boolean;
    success: boolean;
    error: string;
  };
  sendInvitation: {
    loading: boolean;
    success: boolean | null;
    error: string;
  };
  sendInvitationReminder: {
    loading: boolean;
    success: boolean | null;
    error: string;
    invitationUUID: string;
  };
  userList: UserInList[];
}

const initialState = {
  alterUserRole: {
    loading: false,
    success: null,
    error: "",
    uuidRequested: "",
    requestType: null,
  },
  deleteInvitation: {
    loading: false,
    success: null,
    error: "",
    emailRequested: "",
  },
  deleteUser: {
    loading: false,
    success: null,
    error: "",
    uuidRequested: "",
  },
  getUserList: {
    loading: false,
    success: false,
    error: "",
  },
  sendInvitation: {
    loading: false,
    success: null,
    error: "",
  },
  sendInvitationReminder: {
    loading: false,
    success: null,
    error: "",
    invitationUUID: "",
  },
  userList: [],
} as UserManagementState;

export const userManagementSlice = createSlice({
  name: "user_management",
  initialState: initialState,
  reducers: {
    alterUserRole(state, action: PayloadAction<AlterUserRoleData>) {
      state.alterUserRole.loading = true;
      state.alterUserRole.error = "";
      state.alterUserRole.success = null;
      state.alterUserRole.uuidRequested = action.payload.userUUID;
      if (action.payload.role)
        state.alterUserRole.requestType = AlterRoleRequestType.Role;
      else state.alterUserRole.requestType = AlterRoleRequestType.Enable;
    },
    alterUserRoleSuccess(state, action) {
      state.alterUserRole.loading = false;
      state.alterUserRole.error = "";
      state.alterUserRole.success = true;
    },
    alterUserRoleFailure(state, action) {
      state.alterUserRole.loading = false;
      state.alterUserRole.error =
        action.payload.data?.message || "There was an error. Please try again";
      state.alterUserRole.success = false;
    },
    deleteInvitation(state, action: PayloadAction<DeleteInvitationData>) {
      state.deleteInvitation.loading = true;
      state.deleteInvitation.error = "";
      state.deleteInvitation.success = null;
      state.deleteInvitation.emailRequested = action.payload.email;
    },
    deleteInvitationSuccess(state, action) {
      state.deleteInvitation.loading = false;
      state.deleteInvitation.error = action.payload.message;
      state.deleteInvitation.success = true;
    },
    deleteInvitationFailure(state, action) {
      state.deleteInvitation.loading = false;
      state.deleteInvitation.error =
        action.payload.data?.message || "There was an error. Please try again";
      state.deleteInvitation.success = false;
    },
    deleteUser(state, action: PayloadAction<DeleteUserRequestData>) {
      state.deleteUser.loading = true;
      state.deleteUser.error = "";
      state.deleteUser.success = null;
      state.deleteUser.uuidRequested = action.payload.userUUID;
    },
    deleteUserSuccess(state, action) {
      state.deleteUser.loading = false;
      state.deleteUser.error = action.payload.message;
      state.deleteUser.success = true;
    },
    deleteUserFailure(state, action) {
      state.deleteUser.loading = false;
      state.deleteUser.error =
        action.payload.data?.message || "There was an error. Please try again";
      state.deleteUser.success = false;
    },
    getUserList(state, action) {
      state.getUserList.loading = true;
      state.getUserList.error = "";
      state.getUserList.success = false;
    },
    getUserListSuccess(state, action) {
      state.getUserList.loading = false;
      state.getUserList.error = "";
      state.getUserList.success = true;
      state.userList = action.payload.data;
    },
    getUserListFailure(state, action) {
      state.getUserList.loading = false;
      state.getUserList.error =
        action.payload.data?.message || "There was an error. Please try again";
      state.getUserList.success = false;
    },
    sendInvitation(state, action: PayloadAction<SendInvitationData>) {
      state.sendInvitation.loading = true;
      state.sendInvitation.error = "";
      state.sendInvitation.success = null;
    },
    sendInvitationSuccess(state, action) {
      state.sendInvitation.loading = false;
      state.sendInvitation.error = action.payload.message;
      state.sendInvitation.success = true;
    },
    sendInvitationFailure(state, action) {
      state.sendInvitation.loading = false;
      state.sendInvitation.error =
        action.payload.data?.message || "There was an error. Please try again";
      state.sendInvitation.success = false;
    },
    sendInvitationReminder(
      state,
      action: PayloadAction<SendInvitationReminderData>
    ) {
      state.sendInvitationReminder.loading = true;
      state.sendInvitationReminder.error = "";
      state.sendInvitationReminder.success = null;
      state.sendInvitationReminder.invitationUUID =
        action.payload.invitationUUID;
    },
    sendInvitationReminderSuccess(state, action) {
      state.sendInvitationReminder.loading = false;
      state.sendInvitationReminder.error = action.payload.message;
      state.sendInvitationReminder.success = true;
    },
    sendInvitationReminderFailure(state, action) {
      state.sendInvitationReminder.loading = false;
      state.sendInvitationReminder.error =
        action.payload.data?.message || "There was an error. Please try again";
      state.sendInvitationReminder.success = false;
    },
    setInitialSendInvitationState(state) {
      state.sendInvitation = initialState.sendInvitation;
    },
    setInitialUserManagementState(state) {
      state.alterUserRole = initialState.alterUserRole;
      state.deleteInvitation = initialState.deleteInvitation;
      state.deleteUser = initialState.deleteUser;
      state.getUserList = initialState.getUserList;
      state.sendInvitation = initialState.sendInvitation;
      state.sendInvitationReminder = initialState.sendInvitationReminder;
    },
    modifyUser(state, action) {
      let position = getPositionByUserUUID(state.userList, action.payload.uuid);
      if (position !== -1) state.userList[position] = action.payload;
    },
    setUser(state, action) {
      const newUserList = [...state.userList];
      newUserList.push(action.payload);

      newUserList.sort((a: UserInList, b: UserInList) => {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      });
      state.userList = [...newUserList];
    },
    unsetUser(state, action) {
      state.userList = state.userList.filter(
        (x: UserInList) => x.email !== action.payload
      );
    },
    unsetUserByUUID(state, action) {
      state.userList = state.userList.filter(
        (x: UserInList) => x.uuid !== action.payload
      );
    },
  },
});

const getPositionByUserUUID = (UserList: UserInList[], UserUUID: string) =>
  UserList.findIndex((user: UserInList) => {
    return user.uuid === UserUUID;
  });

const userList = (state: State) => state.userManagement.userList;

export const filteredUserInList = (uuid: string) =>
  createSelector([userList], (userList: UserInList[]) => {
    if (userList) {
      return userList.find((userInList) => userInList.uuid === uuid);
    }
  });

export const isNumberOfAdminsInUserListLessThanTwo = () =>
  createSelector([userList], (userList: UserInList[]) => {
    if (userList) {
      return (
        userList
          .filter((userInList) => !userInList.invitationPendingStatus)
          .filter((userInList) => userInList.isEnabled)
          .filter((userInList) => userInList.role === Role.admin).length < 2
      );
    }
  });

function* alterUserRoleSaga() {
  yield takeEvery(
    userManagementSlice.actions.alterUserRole,
    function* (action) {
      try {
        const response: AxiosResponse =
          yield backend.userManagementBackend.alterUserRole(action.payload);

        const userData = response.data;
        yield put(userManagementSlice.actions.alterUserRoleSuccess(response));
        yield put(userManagementSlice.actions.modifyUser(userData));
      } catch (err) {
        yield put(userManagementSlice.actions.alterUserRoleFailure(err));
      }
    }
  );
}

function* deleteInvitationSaga() {
  yield takeEvery(
    userManagementSlice.actions.deleteInvitation,
    function* (action) {
      try {
        const response: AxiosResponse =
          yield backend.userManagementBackend.deleteInvitation(action.payload);

        yield put(
          userManagementSlice.actions.deleteInvitationSuccess(response)
        );
        yield put(userManagementSlice.actions.unsetUser(action.payload.email));
      } catch (err) {
        yield put(userManagementSlice.actions.deleteInvitationFailure(err));
      }
    }
  );
}

function* deleteUserSaga() {
  yield takeEvery(
    userManagementSlice.actions.deleteUser,

    function* (action) {
      try {
        const response: AxiosResponse =
          yield backend.userManagementBackend.deleteUser(action.payload);

        const userData = response.data;
        yield put(userManagementSlice.actions.deleteUserSuccess(response));
        yield put(userManagementSlice.actions.modifyUser(userData));
      } catch (err) {
        yield put(userManagementSlice.actions.deleteUserFailure(err));
      }
    }
  );
}

function* getUserListSaga() {
  yield takeEvery(userManagementSlice.actions.getUserList, function* (action) {
    try {
      const response: AxiosResponse =
        yield backend.userManagementBackend.getUserList(action.payload);

      yield put(userManagementSlice.actions.getUserListSuccess(response));
    } catch (err) {
      yield put(userManagementSlice.actions.getUserListFailure(err));
    }
  });
}

function* sendInvitationSaga() {
  yield takeEvery(
    userManagementSlice.actions.sendInvitation,
    function* (action) {
      try {
        const response: AxiosResponse =
          yield backend.userManagementBackend.sendInvitation(action.payload);

        const userData = response.data;
        yield put(userManagementSlice.actions.sendInvitationSuccess(response));
        yield put(userManagementSlice.actions.setUser(userData));
      } catch (err) {
        yield put(userManagementSlice.actions.sendInvitationFailure(err));
      }
    }
  );
}

function* sendInvitationReminderSaga() {
  yield takeEvery(
    userManagementSlice.actions.sendInvitationReminder,
    function* (action) {
      try {
        const response: AxiosResponse =
          yield backend.userManagementBackend.sendInvitationReminder(
            action.payload
          );

        const userData = response.data;
        yield put(
          userManagementSlice.actions.sendInvitationReminderSuccess(response)
        );
        yield put(userManagementSlice.actions.modifyUser(userData));
      } catch (error: any) {
        yield put(
          userManagementSlice.actions.sendInvitationReminderFailure(error)
        );
      }
    }
  );
}

export function* userManagementSaga() {
  yield fork(alterUserRoleSaga);
  yield fork(deleteInvitationSaga);
  yield fork(deleteUserSaga);
  yield fork(getUserListSaga);
  yield fork(sendInvitationSaga);
  yield fork(sendInvitationReminderSaga);
}

export const {
  alterUserRole,
  deleteInvitation,
  deleteUser,
  getUserList,
  sendInvitation,
  sendInvitationReminder,
  setInitialSendInvitationState,
  setInitialUserManagementState,
} = userManagementSlice.actions;
