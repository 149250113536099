import { FC, useState, useEffect } from "react";
import { Tag as BaseTag, Popover } from "antd";
import { LikeFilled, DislikeFilled } from '@ant-design/icons';
import { Button } from 'antd';

import styled from "@emotion/styled";

import {
  successNotification,
  errorNotification,
} from "../../../ui-components/Notification";
import { MissPopover } from "./detail-components/footer/MissPopover";
import { MatchPopover } from "./detail-components/footer/MatchPopover";

import { filteredGBCompanySelector, setFeedbackInitialState } from "../store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

interface GoldenBasketDetailFooterProps {
  campaignUUID: string;
}

export const GoldenBasketDetailFooter: FC<GoldenBasketDetailFooterProps> = ({
  campaignUUID,
}) => {
  const dispatch = useAppDispatch();
  const { success, message } = useAppSelector(
    (state: State) => state.goldenBasket.submitFeedback
  );
  const GBCompany = useAppSelector(filteredGBCompanySelector);

  useEffect(() => {
    if (success === true) {
      successNotification("Feedback has been submitted successfully.");
      hideMatch();
      hideMiss();
    } else if (success === false) {
      errorNotification(message);
    }
  }, [success, message]);

  useEffect(() => {
    return () => {
      dispatch(setFeedbackInitialState());
    };
  }, [dispatch]);

  const [showMatch, setShowMatch] = useState(false);
  const [showMiss, setShowMiss] = useState(false);

  const onVisibleChangeMatch = (visible: boolean) => {
    setShowMatch(visible);
  };

  const onVisibleChangeMiss = (visible: boolean) => {
    setShowMiss(visible);
  };

  const hideMatch = () => {
    setShowMatch(false);
  };

  const hideMiss = () => {
    setShowMiss(false);
  };

  return (
    <Container>
      <Popover
        placement="topRight"
        content={
          <MatchPopover hidePopover={hideMatch} campaignUUID={campaignUUID} />
        }
        visible={showMatch}
        trigger="click"
        onVisibleChange={onVisibleChangeMatch}
      >
        <GreenButton isMatch={GBCompany?.isMatch || false} isMiss={GBCompany?.isMatch === false}>
          <LikeFilled />
          Match
        </GreenButton>
      </Popover>
      <Popover
        placement="topLeft"
        content={
          <MissPopover hidePopover={hideMiss} campaignUUID={campaignUUID} />
        }
        visible={showMiss}
        trigger="click"
        onVisibleChange={onVisibleChangeMiss}
      >
        <RedButton isMatch={GBCompany?.isMatch || false} isMiss={GBCompany?.isMatch === false}>
          <DislikeFilled />
          Miss
        </RedButton>
      </Popover>
    </Container>
  );
};

const GreenButton = styled(Button)<{ isMatch: boolean; isMiss?: boolean }>`
  width: 12.4rem;
  height: 5.2rem;
  background-color: #5DB75D;
  background: ${(props) => props.isMiss ? "#d9d9d9" : "#5DB75D"};
  border: none;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  font-size: 20px;
  border-radius: 15px;
  color: white;

  &:hover, &:focus {
    background-color: #529a52;
    border-color: #529a52;
    color: white !important;
  }
`;

const RedButton = styled(Button)<{ isMatch: boolean; isMiss?: boolean }>`
  width: 12.4rem;
  height: 5.2rem;
  background: ${(props) => props.isMatch ? "#d9d9d9" : "#ff4d4f"};
  border: none;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  font-size: 20px;
  border-radius: 15px;
  color: white;

  &:hover, &:focus {
    background-color: #d33a3f;
    border-color: #d33a3f;
    color: white;
  }
`;

const Container = styled.div`
  height: 100%;
  padding: 1rem 0;
  margin: 0.5rem 0rem 0.5rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7.8rem;
  width: 100%;
  background: var(--background-color-secondary);
`;
