import { FC } from "react";
import styled from "@emotion/styled";
import { Spin } from "antd";
import { Formik } from "formik";

import { Modal } from "../../ui-components/Modal";
import { FormikInput } from "../../ui-components/FormikInput";
import { Paragraph } from "../../ui-components/Typography";
import { Button } from "../../ui-components/Button";

import { searchRequest, SearchRequestType } from "../store";
import { useAppDispatch } from "../../utils/hooks";

interface MakeItACampaignModalProps {
  modalVisible: boolean;
  toggleModalVisibility: () => void;
  searchHistoryUUID: string;
  clientUUID: string;
  loading: boolean;
}

const MAX_COMMENT_LENGTH = 500;

export const MakeItACampaignModal: FC<MakeItACampaignModalProps> = ({
  modalVisible,
  toggleModalVisibility,
  searchHistoryUUID,
  clientUUID,
  loading,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Formik
      initialValues={{ comment: "" }}
      onSubmit={(values, { resetForm }) => {
        const data = {
          ...values,
          clientUUID: clientUUID,
          searchHistoryUUID: searchHistoryUUID,
          requestType: SearchRequestType.MakeItACampaign,
        };
        dispatch(searchRequest(data));
        resetForm();
      }}
    >
      {({ handleSubmit, values, errors }) => (
        <form>
          <Modal
            title="Turn your Search into a Campaign"
            visible={modalVisible}
            handleSubmit={handleSubmit}
            onCancel={toggleModalVisibility}
            footer={[
              <Button key="cancel" onClick={toggleModalVisibility}>
                Cancel
              </Button>,
              <Button
                key="send"
                htmlType="submit"
                onClick={() => handleSubmit()}
              >
                {loading ? (
                  <>
                    Sending &nbsp; <Spin />
                  </>
                ) : (
                  "Send"
                )}
              </Button>,
            ]}
            bodyStyle={{ paddingBottom: "5px" }}
          >
            <div>
              <Info>
                Your Novable Agent will turn this search into a draft campaign,
                and will get back to you very soon.
              </Info>

              <FormikInput
                name="comment"
                type="text"
                label="Comment"
                as="textarea"
                placeholder="Feel free to add a comment to give more context about your needs"
                maxLength={MAX_COMMENT_LENGTH}
                value={values.comment}
                error={errors.comment}
              />
            </div>
          </Modal>
        </form>
      )}
    </Formik>
  );
};

const Info = styled(Paragraph)`
  margin-bottom: 1.6rem;
`;
