import { FC, useEffect, useState } from "react";

import { Modal, Select, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  successNotification,
  errorNotification,
} from "../../../../ui-components/Notification";

import {
  alterUserRole,
  filteredUserInList,
  isNumberOfAdminsInUserListLessThanTwo,
  Role,
  AlterRoleRequestType,
} from "../../../store";
import { State } from "../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";

const { confirm } = Modal;
const { Option } = Select;

interface RoleSelectProps {
  userUUID: string;
  clientUUID: string | undefined;
  value: Role;
}

/**
 *  Based on the business logic, this is how the role change works
 *
 *  - Only admins can do it
 *  - If the user is the only admin, they cannot change their role
 *  - When upgrading the role, the change will happen immediately
 *  - When downgrading the user, a confirm popup will be displayed
 *  - If the "user" is an invitation, the role cannot be changed
 */
export const RoleSelect: FC<RoleSelectProps> = ({
  userUUID,
  clientUUID,
  value,
}) => {
  const dispatch = useAppDispatch();

  // The current value is for the select, the previous one is in case the request fails
  const [selectedValue, setSelectedValue] = useState(value);
  const [previousRole, setPreviousRole] = useState(value);

  const { success, loading, error, uuidRequested, requestType } =
    useAppSelector((state: State) => state.userManagement.alterUserRole);
  const { user } = useAppSelector((state: State) => state.auth);
  const userInList = useAppSelector(filteredUserInList(userUUID));

  // To check if the user is the only admin
  const numberOfAdminsLessThanTwo = useAppSelector(
    isNumberOfAdminsInUserListLessThanTwo()
  );

  // To avoid displaying a notification for each of the select components
  const roleRequestForUser =
    userUUID === uuidRequested && requestType === AlterRoleRequestType.Role;

  const handleChangeRole = (value: Role) => {
    if (!clientUUID) return;

    setSelectedValue(value);

    const data = {
      role: value,
      userUUID: userUUID,
      clientUUID: clientUUID,
    };
    dispatch(alterUserRole(data));
  };

  // Handles the different logic for "downgrade vs upgrade"
  const handleOnchange = (value: Role) => {
    if (value === "contributor") showConfirm(value);
    else handleChangeRole(value);
  };

  const showConfirm = (value: Role) => {
    confirm({
      title: "Do you want to change the role of this user?",
      icon: <ExclamationCircleOutlined />,
      content: `User with email ${userInList?.email} will lose access to the user management page`,
      onOk() {
        handleChangeRole(value);
      },
      okButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
    });
  };

  // Do not add selectedValue, nor previousRole as dependencies
  useEffect(() => {
    if (roleRequestForUser) {
      if (success) {
        successNotification("Role has been updated successfully");
        setPreviousRole(selectedValue);
      } else if (success === false) {
        errorNotification(error);
        setSelectedValue(previousRole);
      }
    }
  }, [success, loading, error, roleRequestForUser]); // eslint-disable-line

  const isLoading = loading && roleRequestForUser;
  const isDisabled =
    isLoading ||
    (user?.uuid === userUUID && numberOfAdminsLessThanTwo) ||
    userInList?.invitationPendingStatus;

  let displayTooltip = false;
  let tooltipMessage;
  if (user?.uuid === userUUID && numberOfAdminsLessThanTwo) {
    displayTooltip = true;
    tooltipMessage =
      "You cannot change your role since you are the only active admin of your platform";
  }

  return (
    <>
      {displayTooltip ? (
        <Tooltip title={tooltipMessage}>
          <Select
            style={{ width: "100%" }}
            value={selectedValue}
            disabled={true}
            onChange={(value: Role) => handleOnchange(value)}
            loading={false}
          >
            <Option value="admin">Admin</Option>
            <Option value="contributor">Contributor</Option>
          </Select>
        </Tooltip>
      ) : (
        <Select
          style={{ width: "100%" }}
          value={selectedValue}
          disabled={isDisabled}
          onChange={(value: Role) => handleOnchange(value)}
          loading={isLoading}
        >
          <Option value="admin">Admin</Option>
          <Option value="contributor">Contributor</Option>
        </Select>
      )}
    </>
  );
};
