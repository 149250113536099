import { FC } from "react";

import { ButtonsContainer } from "./ButtonsContainer";
import { UpdateListButton } from "./UpdateListButton";
import { DeleteListButton } from "./DeleteListButton";

interface ListManagementActionsProps {
  listName: string;
  listUUID: string;
}

export const ListManagementActions: FC<ListManagementActionsProps> = ({
  listName,
  listUUID,
}) => {
  return (
    <ButtonsContainer>
      <UpdateListButton listUUID={listUUID} listName={listName} />
      <DeleteListButton listUUID={listUUID} listName={listName} />
    </ButtonsContainer>
  );
};
