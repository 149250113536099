import styled from "@emotion/styled";
import { FC } from "react";

import { Section, SectionHeader, SectionTitle } from "../../ui-share";
import { DisplayTopics } from "./DisplayTopics";

interface TopicsProps {
  topics: string[];
  highlightTopics?: string[];
  topicsLimit?: number;
}

export const Topics: FC<TopicsProps> = ({
  topics,
  highlightTopics,
  topicsLimit,
}) => {
  return (
    <Section style={{'width': '90%'}}>
      <SectionHeader>
        <SectionTitle>Topics</SectionTitle>
      </SectionHeader>
      <DisplayTopics
        topics={topics}
        highlightTopics={highlightTopics}
        topicsLimit={topicsLimit}
      />
    </Section>
  );
};
