import { FC, useState } from "react";

import { StylelessButton } from "../../../../ui-components/StylelessButton";
import { StyledButtonText } from "../../../../ui-components/Typography";

import { UpdateListModal } from "../UpdateListModal";

interface UpdateListButtonProps {
  listUUID: string;
  listName: string;
}

export const UpdateListButton: FC<UpdateListButtonProps> = ({
  listUUID,
  listName,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <UpdateListModal
        visible={modalVisible}
        handleModalClose={() => setModalVisible(false)}
        listName={listName}
        listUUID={listUUID}
      />
      <StylelessButton onClick={() => setModalVisible(true)}>
        <StyledButtonText>Edit</StyledButtonText>
      </StylelessButton>
    </>
  );
};
