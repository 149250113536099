import { Tag } from "antd";
import { FC } from "react";

interface PendingStatusTagProps {
  color: string;
  isPending: boolean;
  label: string;
}

export const PendingStatusTag: FC<PendingStatusTagProps> = ({
  color,
  isPending,
  label,
}) => {
  return (
    <Tag color={color} key={isPending.toString()}>
      {label.toUpperCase()}
    </Tag>
  );
};
