import styled from "@emotion/styled";

import { Space as BaseSpace } from "antd";

import { Button as BaseButton } from "../../../../ui-components/Button";

export const ButtonWrapper = styled.div`
  margin-bottom: 3rem;
`;

export const Button = styled(BaseButton)`
  width: 100%;
  font-weight: var(--font-weight-bold);
  margin-bottom: 1rem;
`;

export const Space = styled(BaseSpace)`
  width: 100%;
`;

export const Form = styled.form`
  max-width: 47.2rem;
  width: 100%;

  @media (max-width: 576px) {
    width: 90%;
  }
`;
