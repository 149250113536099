import * as Yup from "yup";

import {
  FIRST_NAME_FIELD_LENGTH,
  LAST_NAME_FIELD_LENGTH,
} from "../share/constants";
// import { string } from 'yargs'

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email format is not valid")
    .required("Email is required."),
  password: Yup.string().required("Password is required."),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email format is not valid")
    .required("This field is required."),
});

// Accepts any character, as long as the length is 12
const passwordRegex = /^.{12,}$/;
const passwordYupValidation = Yup.string()
  .required("The password is required.")
  .matches(passwordRegex, "Password must contain at least 12 characters.");

const confirmPasswordValidation = Yup.string()
  .oneOf([Yup.ref("password"), null], `Password doesn't match`)
  .required("You need to confirm your password.");
const termsAndconfitionsValidation = Yup.boolean()
  .required(
    "In order to proceed, you will have to accept our general Terms and Privacy Policy"
  )
  .isTrue(
    "In order to proceed, you will have to accept our general Terms and Privacy Policy"
  );

export const ResetPasswordSchema = Yup.object().shape({
  password: passwordYupValidation,
  confirmPassword: confirmPasswordValidation,
});

export const SetPasswordSchema = Yup.object().shape({
  password: passwordYupValidation,
  confirmPassword: confirmPasswordValidation,
  acceptedTermsAndConditions: termsAndconfitionsValidation,
});

let timeout: any = null;
let lastEmailChecked = "";
let lastResultForEmail = true;
const nonAllowedEmailProvidersRegexExpression = "(?:gmail|yahoo|hotmail)";
const nonAllowedEmailProvidersRegex = new RegExp(
  nonAllowedEmailProvidersRegexExpression
);

export const SignUpPersonalInformationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required.")
    .max(
      FIRST_NAME_FIELD_LENGTH,
      `First name cannot be longer than ${FIRST_NAME_FIELD_LENGTH} characters`
    ),
  lastName: Yup.string()
    .required("Last name is required.")
    .max(
      LAST_NAME_FIELD_LENGTH,
      `Last name cannot be longer than ${LAST_NAME_FIELD_LENGTH} characters`
    ),
  acceptedTermsAndConditions: termsAndconfitionsValidation,
  email: Yup.string()
    .email("Email format is not valid")
    .required("Email is required.")
    // Theoretically this test function could be simplified by finding the opposite
    // regex expression for the non-allowed emails
    .test(
      "Check email allowed",
      "Please use a valid work email address",
      function (email) {
        if (!email) return true;
        if (email.trim().length < 3) return true;
        const isEmailAllowed = nonAllowedEmailProvidersRegex.test(email);
        this.createError({
          message: "Please use a valid work email address",
        });
        return !isEmailAllowed;
      }
    )
    .test(
      "checkEmailUnique",
      "This email is already registered.",
      function (email) {
        clearTimeout(timeout);
        if (!email) return true;
        if (email.trim().length < 3) return true;
        if (email === lastEmailChecked) return lastResultForEmail;
        return new Promise((resolve, reject) => {
          timeout = setTimeout(async () => {
            fetch(
              `${process.env.REACT_APP_BACKEND_URL}/user-management/email`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: email }),
              }
            )
              .then(async (res) => {
                const { data } = await res.json();
                const { emailInUse } = data.email;
                lastEmailChecked = email;
                lastResultForEmail = !emailInUse;
                this.createError({
                  message: "This email is already registered.",
                });
                resolve(!emailInUse);
              })
              .catch((error) => {
                lastEmailChecked = email;
                lastResultForEmail = false;
                resolve(
                  this.createError({
                    message: "Cannot currently check if email is being used.",
                  })
                );
              });
          }, 300);
        });
      }
    ),
});

const MAX_PHONE_LENGTH = 20;
const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

export const SignUpCompanyInformationSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required."),
  phoneNumber: Yup.string()
    .matches(PHONE_REGEX, "Phone number is not valid")
    .required("Phone number is required.")
    .max(
      MAX_PHONE_LENGTH,
      "That phone seems a bit long, are you sure it's ok?"
    ),
  useCaseText: Yup.string(),
});
