import { FC } from "react";
import styled from "@emotion/styled";

import { Spin, Checkbox } from "antd";
import { Button } from "../../../../../../ui-components/Button";
import { Modal } from "../../../../../../ui-components/Modal";

import { requestEnrichedCompanyProfile } from "../../../../store";
import { State } from "../../../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../../../utils/hooks";

import { Segment } from "../../../../../../utils/Segment";

interface EnrichedCompanyProfileRequestModalProps {
  modalVisible: boolean;
  toggleModalVisibility: () => void;
  companyUUID: string;
  companyName: string;
  campaignUUID: string | null;
}

export const EnrichedCompanyProfileRequestModal: FC<EnrichedCompanyProfileRequestModalProps> =
  ({
    modalVisible,
    toggleModalVisibility,
    companyUUID,
    companyName,
    campaignUUID,
  }) => {
    const dispatch = useAppDispatch();

    const { loading } = useAppSelector(
      (state: State) => state.company.requestEnrichedCompanyProfile
    );
    const { selectedClient } = useAppSelector((state: State) => state.clients);
    const enrichedCompanyProfilePreferences =
      selectedClient?.enrichedCompanyProfilePreferences;

    const handleRequest = () => {
      if (!selectedClient) return;
      const data = {
        companyUUID,
        companyName,
        clientUUID: selectedClient.uuid,
        campaignUUID,
      };

      dispatch(requestEnrichedCompanyProfile(data));

      Segment.analytics?.track("request-enriched-profile", {
        clientUUID: selectedClient.uuid,
        companyUUID: companyUUID,
        campaignUUID: campaignUUID,
      });
    };

    if (!enrichedCompanyProfilePreferences) {
      return (
        <Modal
          title={`Request Enriched Profile for ${companyName}`}
          visible={modalVisible}
          handleSubmit={handleRequest}
          onCancel={toggleModalVisibility}
        >
          <h3>
            It seems like we don't have your preferences yet. Please talk to
            your Novable Expert to register them to be able to request the
            complementary information for Companies
          </h3>
        </Modal>
      );
    }

    return (
      <Modal
        title={`Request an Enriched Profile for ${companyName}`}
        visible={modalVisible}
        handleSubmit={handleRequest}
        onCancel={toggleModalVisibility}
        footer={[
          <Button key="cancel" onClick={toggleModalVisibility}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            htmlType="submit"
            onClick={() => handleRequest()}
          >
            {loading ? (
              <>
                Sending &nbsp; <Spin />
              </>
            ) : (
              "Confirm"
            )}
          </Button>,
        ]}
        bodyStyle={{ padding: "24px" }}
      >
        <div>
          <ModalSection>
            Based on your preferences, the following data categories will be
            included in the Enriched Profile:
          </ModalSection>
          <ModalSection>
            <Category>
              <TickContainer>
                <Checkbox
                  checked={enrichedCompanyProfilePreferences.hasPatentCategory}
                  disabled={true}
                />
              </TickContainer>
              <CategoryName>
                IP (patents, number of filings, main interests, titles and
                links)
              </CategoryName>
            </Category>
            <Category>
              <TickContainer>
                <Checkbox
                  checked={
                    enrichedCompanyProfilePreferences.hasBusinessCategory
                  }
                  disabled={true}
                />
              </TickContainer>
              <CategoryName>
                Business (USP, business model, persona, clients, verticals)
              </CategoryName>
            </Category>
            <Category>
              <TickContainer>
                <Checkbox
                  checked={enrichedCompanyProfilePreferences.hasContactCategory}
                  disabled={true}
                />
              </TickContainer>
              <CategoryName>
                People (founders, employees, executives, contacts)
              </CategoryName>
            </Category>
          </ModalSection>
          <br />
          <span>
            For any change in your preferences, please contact your Novable
            Expert.
          </span>
        </div>
      </Modal>
    );
  };

const ModalSection = styled.div`
  margin-bottom: 1rem;
`;

const Category = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1rem;
`;

const TickContainer = styled.div`
  width: 2rem;
`;

const CategoryName = styled.div``;
