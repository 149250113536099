import { useEffect } from "react";
import { Formik } from "formik";
import { useParams } from "react-router-dom";

import { Spin } from "antd";
import { CampaignDetails } from "./components/CampaignDetails";
import {
  successNotification,
  errorNotification,
} from "../../ui-components/Notification";

import { EditCampaignSchema } from "../../share/campaigns/validation";
import { cleanKeyword } from "../../helpers/index";

import { getCampaign, setInitialState, updateCampaign } from "./store";
import { selectedCampaignSlugSelector } from "../../share/campaigns/store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

export const EditCampaignView = () => {
  const dispatch = useAppDispatch();
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { success, message } = useAppSelector(
    (state: State) => state.editCampaign.updateCampaign
  );
  const { campaign } = useAppSelector((state: State) => state.editCampaign);

  // We get campaigns for validation
  const { campaigns } = useAppSelector((state: State) => state.campaigns);

  const { campaignSlug } = useParams<{ campaignSlug: string }>();
  const campaignSelected = useAppSelector(
    selectedCampaignSlugSelector(campaignSlug)
  );

  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedClient && campaignSlug && campaignSelected) {
      dispatch(getCampaign(campaignSelected?.uuid));
    }
  }, [dispatch, selectedClient, campaignSelected, campaignSlug]);

  useEffect(() => {
    if (success === true) {
      successNotification(message);
    } else if (success === false) {
      errorNotification(message);
    }
  }, [success, message]);

  if (campaign === null) {
    return <Spin />;
  }

  return (
    <Formik
      initialValues={{
        name: campaign.name,
        description: campaign.description,
        types: campaign.types,
        strategyPlainText: campaign.strategyPlainText,
        lookalikes: campaign.lookalikes,
        keywords: campaign.keywords.map((x) => cleanKeyword(x)),
        keywordsExcluded: campaign.keywordsExcluded.map((x) => cleanKeyword(x)),
        countries: campaign.countries,
        clusters: campaign.clusters || [],
        activate: true,
      }}
      onSubmit={(values) => {
        if (selectedClient && campaignSelected) {
          const readyData = {
            uuid: campaignSelected.uuid,
            clientUUID: selectedClient.uuid,
            name: values.name,
            description: values.description,
          };
          dispatch(updateCampaign(readyData));
        }
      }}
      validationSchema={EditCampaignSchema(campaigns, campaign.uuid)}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <CampaignDetails values={values} errors={errors} touched={touched} />
        </form>
      )}
    </Formik>
  );
};
