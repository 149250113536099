import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { fork, put, takeEvery } from "redux-saga/effects";

import { backend } from "../../utils/http";
import { State } from "../../utils/store";
import { Contract } from "../../clients/store";

export interface Campaign {
  uuid: string;

  ageThreshold: null;
  creator: string;
  client: number;
  clusters: Cluster[];
  createdAt: Date;
  companiesDelivered: number;
  countries: string[];
  description: string;
  exclusions: string;
  favourites: number;
  followed: number;
  isDemo: boolean;
  iterations: number;
  keywords: string[];
  keywordsExcluded: string[];
  lastEditedBy: string;
  lookalikes: string[];
  matches: number;
  name: string;
  requests: {
    exportRequested: boolean;
    iterationRequested: boolean;
  };
  slug: string;
  status: string;
  statusPending: boolean;
  strategyPlainText: string;
  toReview: number;
  types: Array<number> | null;
  updatedAt: Date;
  publishedAt: Date | null;
}

export interface Keyword {
  keyword: string;
  weight: number;
  isActive?: boolean;
}

export interface Cluster {
  keywords: Keyword[];
  label: string;
  weight: number;
}

export interface ClientStatistics {
  companies: number;
  matches: number;
  favourites: number;
  publishedCampaigns: number;
  iterations: number;
  users: number;
}

export interface ArchiveCampaignData {
  campaignUUID: string;
}

export interface RestoreCampaignData {
  campaignUUID: string;
}

interface ClientUUID {
  uuid: string;
}

export interface CampaignsState {
  getCampaigns: {
    success: boolean;
    loading: boolean;
    error: string;
  };
  archiveCampaign: {
    success: boolean;
    loading: boolean;
    error: string;
  };
  restoreCampaign: {
    success: boolean;
    loading: boolean;
    error: string;
  };
  archivedCampaigns: Campaign[];
  // De facto published campaigns
  campaigns: Campaign[];
  draftCampaigns: Campaign[];
  statistics: ClientStatistics | null;
}

const initialState = {
  getCampaigns: {
    success: false,
    loading: false,
    error: "",
  },
  archiveCampaign: {
    success: false,
    loading: false,
    error: "",
  },
  restoreCampaign: {
    success: false,
    loading: false,
    error: "",
  },
  archivedCampaigns: [],
  campaigns: [],
  draftCampaigns: [],
  statistics: null,
} as CampaignsState;

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState: initialState,
  reducers: {
    getCampaigns(state, action: PayloadAction<ClientUUID>) {
      state.getCampaigns.loading = true;
      state.getCampaigns.error = "";
      state.getCampaigns.success = false;
    },
    getCampaignsSuccess(state, action) {
      state.getCampaigns.loading = false;
      state.getCampaigns.error = "";
      state.getCampaigns.success = true;
      state.archivedCampaigns = action.payload?.data?.archivedCampaigns;
      state.campaigns = action.payload?.data?.campaigns;
      state.draftCampaigns = action.payload?.data?.draftCampaigns;
      state.statistics = action.payload?.data?.statistics;
    },
    getCampaignsFailure(state, action) {
      state.getCampaigns.loading = false;
      state.getCampaigns.success = false;
      state.getCampaigns.error = action.payload.message;
    },
    // Archive campaign
    archiveCampaign(state, action: PayloadAction<ArchiveCampaignData>) {
      state.archiveCampaign.loading = true;
      state.archiveCampaign.error = "";
      state.archiveCampaign.success = false;
    },
    archiveCampaignSuccess(state, action) {
      state.archiveCampaign.loading = false;
      state.archiveCampaign.error = "";
      state.archiveCampaign.success = true;
      state.statistics = action.payload.data.statistics;
    },
    archiveCampaignFailure(state, action) {
      state.archiveCampaign.loading = false;
      state.archiveCampaign.success = false;
      state.archiveCampaign.error = action.payload.message;
    },
    // Restore campaign
    restoreCampaign(state, action: PayloadAction<RestoreCampaignData>) {
      state.restoreCampaign.loading = true;
      state.restoreCampaign.error = "";
      state.restoreCampaign.success = false;
    },
    restoreCampaignSuccess(state, action) {
      state.restoreCampaign.loading = false;
      state.restoreCampaign.error = "";
      state.restoreCampaign.success = true;
      state.statistics = action.payload.data.statistics;
    },
    restoreCampaignFailure(state, action) {
      state.restoreCampaign.loading = false;
      state.restoreCampaign.success = false;
      state.restoreCampaign.error = action.payload.message;
    },
    addCampaignToStore(state, action) {
      state.campaigns = [action.payload, ...state.campaigns];
    },
    addDraftToStore(state, action) {
      // Try to find draft. If it doesn't exist yet, simply add it, otherwise, replace it
      let draftCampaign = state.draftCampaigns.find(
        (draftCampaign) => draftCampaign.uuid === action.payload.uuid
      );
      if (!draftCampaign) {
        state.draftCampaigns = [action.payload, ...state.draftCampaigns];
      } else {
        state.draftCampaigns = [
          action.payload,
          ...state.draftCampaigns.filter(
            (draftCampaign) => draftCampaign.uuid !== action.payload.uuid
          ),
        ];
      }
    },
    deleteDraftFromStore(state, action) {
      state.draftCampaigns = state.draftCampaigns.filter(
        (campaign) => campaign.uuid !== action.payload.uuid
      );
    },
    updateCampaign(state, action) {
      let selectedCampaign = state.campaigns.find(
        (campaign) => campaign.uuid === action.payload.uuid
      );
      if (selectedCampaign) {
        // Don't update campaign slug, as it will delete the topbar campaign name
        let { slug, ...restActionPayload } = action.payload;
        let updatedCampaign = {
          ...selectedCampaign,
          ...restActionPayload,
        };
        state.campaigns = [
          updatedCampaign,
          ...state.campaigns.filter(
            (campaign) => campaign.uuid !== action.payload.uuid
          ),
        ];
      }
    },
    // Set requests exportedRequested to true for a given company
    setCampaignExport(state, action) {
      let selectedCampaign = state.campaigns.find(
        (campaign) => campaign.uuid === action.payload.campaignUUID
      );
      if (selectedCampaign) {
        let updatedCampaign = {
          ...selectedCampaign,
          requests: {
            exportRequested: true,
            iterationRequested: selectedCampaign.requests.iterationRequested,
          },
        };
        state.campaigns = [
          updatedCampaign,
          ...state.campaigns.filter(
            (campaign) => campaign.uuid !== action.payload.campaignUUID
          ),
        ];
      }
    },
    // Set requests iterationRequested to true for a given company
    setCampaignIteration(state, action) {
      let selectedCampaign = state.campaigns.find(
        (campaign) => campaign.uuid === action.payload.campaignUUID
      );
      if (selectedCampaign) {
        let updatedCampaign = {
          ...selectedCampaign,
          requests: {
            exportRequested: selectedCampaign.requests.exportRequested,
            iterationRequested: true,
          },
        };
        state.campaigns = [
          updatedCampaign,
          ...state.campaigns.filter(
            (campaign) => campaign.uuid !== action.payload.campaignUUID
          ),
        ];
      }
    },
    // internally archive campaign
    moveCampaignToArchived(state, action) {
      let selectedCampaignIndex = state.campaigns.findIndex(
        (campaign) => campaign.uuid === action.payload.campaignUUID
      );
      if (selectedCampaignIndex !== -1) {
        const archivedCampaign = state.campaigns[selectedCampaignIndex];

        state.campaigns = [
          ...state.campaigns.filter(
            (campaign) => campaign.uuid !== archivedCampaign.uuid
          ),
        ];
        // Only add to archived campaigns if campaign is not demo
        // demo campaigns are deleted upon archival
        if (!archivedCampaign.isDemo) {
          state.archivedCampaigns = [
            archivedCampaign,
            ...state.archivedCampaigns,
          ];
        }
      }
    },
    // internally restore campaign
    moveCampaignToPublished(state, action) {
      let selectedCampaignIndex = state.archivedCampaigns.findIndex(
        (campaign) => campaign.uuid === action.payload.campaignUUID
      );
      if (selectedCampaignIndex !== -1) {
        const restoredCampaign = state.archivedCampaigns[selectedCampaignIndex];

        state.archivedCampaigns = [
          ...state.archivedCampaigns.filter(
            (campaign) => campaign.uuid !== restoredCampaign.uuid
          ),
        ];
        state.campaigns = [restoredCampaign, ...state.campaigns];
      }
    },
    setInitialArchiveState(state) {
      state.archiveCampaign = initialState.archiveCampaign;
    },
    setInitialRestoreState(state) {
      state.restoreCampaign = initialState.restoreCampaign;
    },
  },
});

const publishedCampaigns = (state: State) => state.campaigns.campaigns;
const archivedCampaigns = (state: State) => state.campaigns.archivedCampaigns;
const draftCampaigns = (state: State) => state.campaigns.draftCampaigns;
const LIMIT_DRAFTS = (state: State) =>
  state.clients.selectedClient?.limitDrafts;
const currentContract = (state: State) =>
  state.clients.selectedClient?.contract;

// selected campaign selector
export const selectedCampaignSelector = (uuid: string | undefined) =>
  createSelector(publishedCampaigns, (campaigns) => {
    if (!uuid) return;
    return campaigns?.find((campaign) => campaign.uuid === uuid);
  });

export const selectedCampaignSlugSelector = (slug: string | undefined) =>
  createSelector(publishedCampaigns, (campaigns) => {
    if (!slug) return;
    return campaigns?.find((campaign) => campaign.slug === slug);
  });

export const getCampaignByIDSelector = (campaignUUID: string) =>
  createSelector(publishedCampaigns, (campaigns) =>
    campaigns?.filter((element) => element.uuid === campaignUUID)
  );

export const getPublishedCampaignsDuringCurrentContract = () =>
  createSelector(
    [publishedCampaigns, archivedCampaigns, currentContract],
    (
      publishedCampaigns: Campaign[],
      archivedCampaigns: Campaign[],
      currentContract: Contract | undefined
    ) => {
      if (
        !currentContract ||
        (!currentContract.limitCampaigns &&
          currentContract.limitCampaigns !== 0)
      ) {
        return 0;
      }

      return computePublishedCampaignsDuringCurrentContract(
        publishedCampaigns,
        archivedCampaigns,
        currentContract
      );
    }
  );

export const getPublishedIterationsDuringCurrentContract = () =>
  createSelector(
    [publishedCampaigns, archivedCampaigns, currentContract],
    (
      publishedCampaigns: Campaign[],
      archivedCampaigns: Campaign[],
      currentContract: Contract | undefined
    ) => {
      if (
        !currentContract ||
        (!currentContract.limitCampaigns &&
          currentContract.limitCampaigns !== 0)
      ) {
        return 0;
      }

      const nonDemoCampaignsDuringContractPeriod =
        _getListOfPublishedCampaignsDuringCurrentContract(
          publishedCampaigns,
          archivedCampaigns,
          currentContract
        );

      if (nonDemoCampaignsDuringContractPeriod.length === 0) {
        return 0;
      }

      return nonDemoCampaignsDuringContractPeriod
        .map((x) => x.iterations)
        .reduce((x, y) => x + y, 0);
    }
  );

const computePublishedCampaignsDuringCurrentContract = (
  publishedCampaigns: Campaign[],
  archivedCampaigns: Campaign[],
  currentContract: Contract
) => {
  const nonDemoCampaignsDuringContractPeriod =
    _getListOfPublishedCampaignsDuringCurrentContract(
      publishedCampaigns,
      archivedCampaigns,
      currentContract
    );

  return nonDemoCampaignsDuringContractPeriod.length;
};

const _getListOfPublishedCampaignsDuringCurrentContract = (
  publishedCampaigns: Campaign[],
  archivedCampaigns: Campaign[],
  currentContract: Contract
) => {
  const nonDemoCampaigns = [
    ...publishedCampaigns.filter((x) => !x.isDemo),
    ...archivedCampaigns.filter((x) => !x.isDemo),
  ];

  const currentContractEndDateTime = new Date(
    currentContract.endDate
  ).getTime();
  const currentContractStartDateTime = new Date(
    currentContract.startDate
  ).getTime();
  const nonDemoCampaignsDuringContractPeriod = nonDemoCampaigns.filter((x) => {
    const campaignPublishedAt = x.publishedAt;
    if (!campaignPublishedAt) return false;
    const campaignPublishedAtDateTime = new Date(campaignPublishedAt).getTime();
    return (
      currentContractEndDateTime >= campaignPublishedAtDateTime &&
      campaignPublishedAtDateTime >= currentContractStartDateTime
    );
  });
  return nonDemoCampaignsDuringContractPeriod;
};

// As per NOV-522, since the addition of archived campaigns, both published and archived
// status count towards limit of campaigns
// canCreateMoreCampaigns will return true if LIMIT_CAMPAIGNS is null, or
// if number of published + archived campaigns is null
export const canCreateMoreCampaigns = () =>
  createSelector(
    [publishedCampaigns, archivedCampaigns, currentContract],
    (
      publishedCampaigns: Campaign[],
      archivedCampaigns: Campaign[],
      currentContract: Contract | undefined
    ) => {
      if (
        !currentContract ||
        (!currentContract.limitCampaigns &&
          currentContract.limitCampaigns !== 0)
      ) {
        return true;
      }

      const nonDemoCampaignsDuringContractPeriod =
        computePublishedCampaignsDuringCurrentContract(
          publishedCampaigns,
          archivedCampaigns,
          currentContract
        );
      return (
        nonDemoCampaignsDuringContractPeriod < currentContract.limitCampaigns
      );
    }
  );

export const canCreateMoreDrafts = () =>
  createSelector(
    [draftCampaigns, LIMIT_DRAFTS],
    (draftCampaigns: Campaign[], LIMIT_DRAFTS) => {
      if (!LIMIT_DRAFTS) return false;
      return draftCampaigns.length < LIMIT_DRAFTS;
    }
  );

const BASE_DRAFT_NAME = "Campaign Title";
export const generateDraftName = () =>
  createSelector([draftCampaigns], (draftCampaigns: Campaign[]) => {
    if (!draftCampaigns) return BASE_DRAFT_NAME;
    else {
      for (let i = 0; i < draftCampaigns.length; i++) {
        let draftName = `${BASE_DRAFT_NAME} ${i}`;
        let nameAlreadyExists = draftCampaigns.some(
          (draftCampaign) => draftCampaign.name === draftName
        );
        if (!nameAlreadyExists) return draftName;
      }
    }
  });

function* getCampaignsSaga() {
  yield takeEvery(campaignsSlice.actions.getCampaigns, function* (action) {
    try {
      const response: AxiosResponse<Campaign[]> =
        yield backend.campaignBackend.getCampaigns(action.payload.uuid);
      yield put(campaignsSlice.actions.getCampaignsSuccess(response));
    } catch (err) {
      yield put(campaignsSlice.actions.getCampaignsFailure(err));
    }
  });
}

function* archiveCampaignSaga() {
  yield takeEvery(campaignsSlice.actions.archiveCampaign, function* (action) {
    try {
      const response: AxiosResponse<Campaign[]> =
        yield backend.campaignBackend.archiveCampaign(action.payload);
      yield put(campaignsSlice.actions.archiveCampaignSuccess(response));
      // Delete campaign from list of campaigns, add to list of archived
      yield put(campaignsSlice.actions.moveCampaignToArchived(action.payload));
    } catch (err) {
      yield put(campaignsSlice.actions.archiveCampaignFailure(err));
    }
  });
}

function* restoreCampaignSaga() {
  yield takeEvery(campaignsSlice.actions.restoreCampaign, function* (action) {
    try {
      const response: AxiosResponse<Campaign[]> =
        yield backend.campaignBackend.restoreCampaign(action.payload);
      yield put(campaignsSlice.actions.restoreCampaignSuccess(response));
      // Delete campaign from list of archived, add to list of campaigns
      yield put(campaignsSlice.actions.moveCampaignToPublished(action.payload));
    } catch (err) {
      yield put(campaignsSlice.actions.restoreCampaignFailure(err));
    }
  });
}

export function* campaignsSaga() {
  yield fork(getCampaignsSaga);
  yield fork(archiveCampaignSaga);
  yield fork(restoreCampaignSaga);
}

export const {
  archiveCampaign,
  addCampaignToStore,
  addDraftToStore,
  deleteDraftFromStore,
  getCampaigns,
  restoreCampaign,
  setInitialArchiveState,
  setInitialRestoreState,
} = campaignsSlice.actions;
