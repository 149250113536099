import styled from "@emotion/styled";
import { FC } from "react";

import { CompanyPageFieldName } from "../../../../../../ui-components/Typography";
import { ContactSection } from "./ContactSection";

import { EnrichedProfileContact } from "../../../../store";

interface ContactsSectionProps {
  enrichedProfileContacts: EnrichedProfileContact[];
  label: string;
}

export const ContactsSection: FC<ContactsSectionProps> = ({
  enrichedProfileContacts,
  label,
}) => {
  return (
    <div>
      <CompanyPageFieldName>{label}</CompanyPageFieldName>
      <ContactsContainer>
        {enrichedProfileContacts.map((contact) => (
          <ContactSection contact={contact} />
        ))}
      </ContactsContainer>
    </div>
  );
};

const ContactsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 0.5rem;
`;
