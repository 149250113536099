import { httpScott } from "../http";

export async function getThreadsByUser(userUuid: string) {
  const { data } = await httpScott.get(`/get_threads_by_user/${userUuid}`);
  return data;
}

export async function getMessagesByThread(threadUuid: string) {
  const { data } = await httpScott.get(`/get_messages_by_thread/${threadUuid}`);
  return data;
}
