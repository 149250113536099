import styled from "@emotion/styled";

export const CommentLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <CommentLabelContainer>
      <CommentLabelItalized>{children}</CommentLabelItalized>
    </CommentLabelContainer>
  );
};

const CommentLabelContainer = styled.div`
  margin-bottom: -1rem;
`;

const CommentLabelItalized = styled.i`
  font-size: 1.2rem;
`;
