import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";

import { QuestionCircleOutlined as BaseQuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { CampaignMetaPieChart } from "./components/CampaignMetaPieChart";
import { CampaignDimensionalityScatterPlot } from "./components/CampaignDimensionalityScatterPlot";
import { AuthSubtitle } from "../../ui-components/Typography";
import { GoldenBasketStatusWrapper } from "../../ui-components/states";
import { CenteredSpinner } from "../../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../../ui-components/CenteredErrorMessage";
import { BarCharts } from "../../ui-components/charts/Firmographics/BarCharts";
import { FirmographicsCell } from "../../ui-components/charts/FirmographicsCell";
import { MapChartWithCountryList } from "../../ui-components/charts/MapChart/MapChartWithCountryList";
import { StatusPendingImage } from "../ui-share";

import { selectedCampaignSlugSelector } from "../../share/campaigns/store";
import { getCampaignInsights } from "./store";

import { Segment } from "../../utils/Segment";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import { getWordCloudKeywordsNormalized } from "../../helpers/charts";
import { useBreakpoint } from "../../utils/styling";

export const CampaignInsightsView = () => {
  const isScreenM = useBreakpoint(1200, { useBody: true });
  const dispatch = useAppDispatch();
  const { campaignSlug } = useParams<{ campaignSlug: string }>();
  const campaign = useAppSelector(selectedCampaignSlugSelector(campaignSlug));

  const { loading, error, success } = useAppSelector(
    (state: State) => state.campaignInsights.getCampaignInsights
  );
  const { goldenBasketInsights, campaignMeta, dimensionality } = useAppSelector(
    (state: State) => state.campaignInsights
  );

  const { textKeywords, ...values } = goldenBasketInsights;
  const firmographicsValues = {
    wordCloudKeywords: getWordCloudKeywordsNormalized(textKeywords),
    ...values,
  };

  useEffect(() => {
    if (campaignSlug && campaign) {
      dispatch(getCampaignInsights(campaign.uuid));
      Segment.analytics?.track(`open insights`, {
        campaignSlug: campaignSlug,
      });
    }
  }, [dispatch, campaignSlug]); // eslint-disable-line

  if (campaign?.statusPending) {
    return (
      <GoldenBasketStatusWrapper>
        <StatusPendingImage />
        <AuthSubtitle>
          Our agents are working on your campaign right now
        </AuthSubtitle>
      </GoldenBasketStatusWrapper>
    );
  }

  const { country } = firmographicsValues;

  const DimensionalityChartLabel = () => {
    return (
      <div>
        Proximity distribution
        <Tooltip title="The graph represents the proximity between Companies in terms of semantic field.">
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    );
  };
  return (
    <Container>
      {loading && <CenteredSpinner />}
      {error && <CenteredErrorMessage>{error}</CenteredErrorMessage>}
      {success && (
        <>
          <FirstRow>
            <CampaignMetaPieChart campaignMeta={campaignMeta} />
            <FirmographicsCell
              label={<DimensionalityChartLabel />}
              height={"fit-content"}
              width={isScreenM ? "600px" : "500px"}
            >
              <CampaignDimensionalityScatterPlot
                dimensionality={dimensionality}
              />
            </FirmographicsCell>
          </FirstRow>
          <SecondRow>
            <FirmographicsCell
              label="Map distribution"
              height={"fit-content"}
              width={"60%"}
            >
              <MapChartContainer>
                <MapChartWithCountryList data={country} />
              </MapChartContainer>
            </FirmographicsCell>
            <BarCharts values={firmographicsValues} />
          </SecondRow>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 2.4rem;
`;

const FirstRow = styled.div`
  display: flex;
  gap: 2rem;
`;

const SecondRow = styled(FirstRow)`
  margin-top: 3rem;
`;

const MapChartContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const QuestionCircleOutlined = styled(BaseQuestionCircleOutlined)`
  margin-left: 0.5rem;
  color: var(--primary-color);
`;
