import { http } from "../http";

import {
  NewCampaignData,
  SuggestedKeywordsData,
  FirmographicsData,
  DeleteDraftData,
} from "../../new-campaign/store";
import { EditCampaignData } from "../../campaign/edit-campaign/store";
import {
  ArchiveCampaignData,
  RestoreCampaignData,
} from "../../share/campaigns/store";

export async function archiveCampaign(
  archiveCampaignData: ArchiveCampaignData
) {
  const { data } = await http.post(
    `/api/campaign/archive`,
    archiveCampaignData
  );
  return data;
}

export async function createNewCampaign(campaignData: NewCampaignData) {
  const { data } = await http.post("/api/new-campaign", campaignData, {
    timeout: 20 * 1000,
  });
  return data;
}

export async function deleteDraftCampaign(deleteDraftData: DeleteDraftData) {
  const config = {
    data: deleteDraftData,
  };
  const { data } = await http.delete("/api/draft-campaign", config);
  return data;
}

export async function getCampaign(uuid: string) {
  const { data } = await http.get(`/api/campaign/${uuid}`);
  return data;
}

export async function getCampaignInsights(uuid: string) {
  const { data } = await http.get(`/api/campaign/${uuid}/insights`);
  return data;
}

export async function getCampaigns(uuid: string) {
  const { data } = await http.get(`/api/campaigns/${uuid}`);
  return data;
}

export async function getDraftCampaign(uuid: string) {
  const { data } = await http.get(`/api/draft-campaign/${uuid}`);
  return data;
}

export async function fetchClusters(briefingData: SuggestedKeywordsData) {
  const { data } = await http.post("/api/get-clusters", briefingData, {
    timeout: 20 * 1000,
  });
  return data;
}

export async function fetchFirmographics(briefingData: FirmographicsData) {
  const { data } = await http.post("/api/firmographics", briefingData, {
    timeout: 20 * 1000,
  });
  return data;
}

export async function fetchSuggestedKeywords(
  briefingData: SuggestedKeywordsData
) {
  const { data } = await http.post("/api/get-keywords", briefingData);
  return data;
}

export async function restoreCampaign(
  restoreCampaignData: RestoreCampaignData
) {
  const { data } = await http.post(
    `/api/campaign/restore`,
    restoreCampaignData
  );
  return data;
}

export async function saveDraftCampaign(campaignData: NewCampaignData) {
  const { data } = await http.post("/api/draft-campaign", campaignData);
  return data;
}

export async function updateCampaign(campaignData: EditCampaignData) {
  const { data } = await http.put(
    `/api/campaign/${campaignData.uuid}`,
    campaignData
  );
  return data;
}
