import { FC } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import { Space as BaseSpace, Spin, Alert } from "antd";
import { Button as BaseButton } from "../../ui-components/Button";
import { FormikInput } from "../../ui-components/FormikInput";
import { AuthTitle } from "../../ui-components/Typography";

import { ResetPasswordSchema } from "../validation";
import { resetPassword } from "../store";

import { useAppDispatch } from "../../utils/hooks";

interface FormProps {
  loading: boolean;
  message: string | null;
  token: string | null;
}

export const ResetPasswordForm: FC<FormProps> = ({
  loading,
  message,
  token,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        token: token || "",
      }}
      onSubmit={({ password, token }) => {
        dispatch(resetPassword({ password, token }));
      }}
      validationSchema={ResetPasswordSchema}
    >
      {({ handleSubmit, errors }) => (
        <Form
          onSubmit={handleSubmit}
          className="reset-password-form"
          id="resetPasswordForm"
        >
          <AuthTitle>Create new password</AuthTitle>
          <Space direction="vertical" size="small">
            <FormikInput
              name="password"
              type="password"
              label="Enter new password"
              error={errors.password}
              responsiveLabel={true}
            />
            <FormikInput
              name="confirmPassword"
              type="password"
              label="Repeat password"
              error={errors.confirmPassword}
              responsiveLabel={true}
            />

            <ButtonWrapper>
              <Button htmlType="submit" disabled={loading}>
                {loading ? (
                  <>
                    Sending information &nbsp; <Spin />{" "}
                  </>
                ) : (
                  <> Set new password </>
                )}
              </Button>
              {message && <Alert type="error" message={message} />}
            </ButtonWrapper>

            <Link to="/login">Back to login</Link>
          </Space>
        </Form>
      )}
    </Formik>
  );
};

const ButtonWrapper = styled.div`
  margin-bottom: 3rem;
`;

const Button = styled(BaseButton)`
  width: 100%;
  font-weight: var(--font-weight-bold);
  margin-bottom: 1rem;
`;

const Space = styled(BaseSpace)`
  width: 100%;
`;

const Form = styled.form`
  max-width: 47.2rem;
  width: 100%;
  padding-bottom: 11rem;

  @media (max-width: 576px) {
    width: 90%;
  }
`;
