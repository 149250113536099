import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Navigate } from "react-router";

import { PlusOutlined } from "@ant-design/icons";
import { InviteUserModal } from "./components/invitation/InviteUserModal";
import { UserManagementTable } from "./components/table/UserManagementTable";
import { Button } from "../ui-components/Button";
import { Title as BaseTitle } from "../ui-components/Typography";
import {
  errorNotification,
  successNotification,
} from "../ui-components/Notification";

import { setInitialUserManagementState } from "./store";
import { State } from "../utils/store";
import { useAppDispatch, useAppSelector } from "../utils/hooks";

export const UserManagementView = () => {
  const dispatch = useAppDispatch();

  const [inviteUserModalVisible, setInviteUserModalVisible] = useState(false);
  const { success, loading, error } = useAppSelector(
    (state: State) => state.userManagement.sendInvitation
  );
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  useEffect(() => {
    return () => {
      dispatch(setInitialUserManagementState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (success && !loading) {
      successNotification("Invitation has been sent successfully");
      setInviteUserModalVisible(false);
    } else if (success === false) {
      errorNotification(error);
    }
  }, [success, loading, error]);

  if (selectedClient?.userRole !== "admin") {
    errorNotification("You don't have access to this page");
    return <Navigate to={`/${selectedClient?.slug}/dashboard`} replace />;
  }

  return (
    <Content>
      <SectionHeader>
        <Title>User List</Title>
        <Button onClick={() => setInviteUserModalVisible(true)}>
          <PlusOutlined /> Invite Users
        </Button>
      </SectionHeader>

      <UserManagementTable />

      <InviteUserModal
        visible={inviteUserModalVisible}
        handleModalClose={() => setInviteUserModalVisible(false)}
        loading={loading}
      />
    </Content>
  );
};

const Content = styled.div`
  padding: 2.4rem;
  background-color: var(--white);
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Title = styled(BaseTitle)`
  color: var(--152734);
`;
