import styled from "@emotion/styled";
import { FC } from "react";

import { Tooltip } from "antd";
import { NonClickableTag } from "..";
import {
  SectionContainer,
  SectionItem,
} from "./EnrichedCompanyProfileSectionComponents";
import { EnrichedSection } from "./Section";
import { CompanyPageFieldName } from "../../../../../../ui-components/Typography";

import { EnrichedProfileContent } from "../../../../store";

interface EnrichedCompanyProfilePatentSectionProps {
  enrichedProfileContent: EnrichedProfileContent;
}

const PATENT_NAME_CHAR_LIMIT = 10;

export const EnrichedCompanyProfilePatentSection: FC<EnrichedCompanyProfilePatentSectionProps> =
  ({ enrichedProfileContent }) => {
    if (!enrichedProfileContent) return <></>;

    const { mainPatentKeywords, patents, numberPatents, lastPatentDate } =
      enrichedProfileContent;
    const contentHasMainPatentKeywords =
      mainPatentKeywords && mainPatentKeywords.length > 0;
    const contentHasPatents = patents && patents.length > 0;
    const contentHastNumberPatents = numberPatents || numberPatents === 0;

    const contentHasPatentInformation =
      contentHastNumberPatents ||
      lastPatentDate ||
      contentHasPatents ||
      contentHasMainPatentKeywords;

    if (!contentHasPatentInformation) return <></>;

    const shortenPatentName = (patentName: string) => {
      if (patentName.length < PATENT_NAME_CHAR_LIMIT) return patentName;
      return patentName.slice(0, PATENT_NAME_CHAR_LIMIT) + "...";
    };

    return (
      <EnrichedSection title="Patents information">
        <SectionContainer>
          {(enrichedProfileContent.numberPatents === 0 ||
            enrichedProfileContent.numberPatents) && (
            <SectionItem>
              <CompanyPageFieldName>Number of filings:</CompanyPageFieldName>
              {enrichedProfileContent.numberPatents}
            </SectionItem>
          )}
          {enrichedProfileContent.lastPatentDate && (
            <SectionItem>
              <CompanyPageFieldName>Last patent date:</CompanyPageFieldName>
              {enrichedProfileContent?.lastPatentDate?.toLocaleDateString()}
            </SectionItem>
          )}
          {contentHasMainPatentKeywords && (
            <SectionItem>
              <CompanyPageFieldName>Main interests:</CompanyPageFieldName>

              {mainPatentKeywords.map((patentKeyword: string) => (
                <NonClickableTag key={patentKeyword}>
                  {patentKeyword}
                </NonClickableTag>
              ))}
            </SectionItem>
          )}
          {contentHasPatents && (
            <SectionItem>
              <CompanyPageFieldName>Patents:</CompanyPageFieldName>
              <FlexContainer>
                {patents.map((patent) => (
                  <Tooltip title={patent.patentName}>
                    <Patent
                      href={patent.patentUrl || ""}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {shortenPatentName(patent.patentName)}
                    </Patent>
                  </Tooltip>
                ))}
              </FlexContainer>
            </SectionItem>
          )}
        </SectionContainer>
      </EnrichedSection>
    );
  };

const Patent = styled.a`
  width: fit-content;
  padding: 0.5rem;
  border: var(--card-border);
  border-radius: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
`;
