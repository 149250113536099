import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Select } from "antd";
import type { SelectProps } from "antd";
import { errorNotification } from "../../../../ui-components/Notification";

import {
  addCompanyToTag,
  removeCompanyFromTag,
  TagCompanySource,
  tagsOfCompanies,
} from "../../../../tags/store";
import { State } from "../../../../utils/store";
import { Segment } from "../../../../utils/Segment";

import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";

/**
 * Future improvement:
 *
 * On handleAddTagToCompany --> add an "intermediary" tag that acts as a loader. It would be the same just-added tag
 *                              but with a loader icon next to it. Then, on an useEffect, control if the response was
 *                              positive or negative from the backend, and replace it for a normal tag or delete it
 * So, in short:
 * 1. On handleAddTagToCompany, add "Itermediary" tag --> tag with spinner/loader
 * 2. Register in the state the tag strings that are awaiting confirmation
 * 3. Create listeners through useEffect for the success/error of state state.tags.addCompanyToTag
 *    3a. If Success, modify the tag X (returned in the response) to a valid tag
 *    3b. If fail, remove tag X and inform the user
 *
 */

export const TagWidgetContent = (
  companyTags: string[],
  companyUUID: string,
  companyName: string,
  tagCompanySource: TagCompanySource
) => {
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<string[]>([]);

  const clientTags = useAppSelector(tagsOfCompanies);
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const { loading: addCompanyToTagLoading, error: addCompanyToTagError } =
    useAppSelector((state: State) => state.tags.addCompanyToTag);

  const {
    loading: removeCompanyFromTagLoading,
    error: removeCompanyFromTagError,
  } = useAppSelector((state: State) => state.tags.removeCompanyFromTag);

  useEffect(() => {
    setValues(companyTags);
  }, [companyTags, setValues]);

  useEffect(() => {
    if (addCompanyToTagError) errorNotification(addCompanyToTagError);
  }, [addCompanyToTagError]);

  useEffect(() => {
    if (removeCompanyFromTagError) errorNotification(removeCompanyFromTagError);
  }, [removeCompanyFromTagError]);

  const handleAddTagToCompany = (newTag: string) => {
    if (!selectedClient) return;

    const tag = newTag?.trim();

    if (!tag || tag.length === 0) return;

    if (!values.includes(tag)) {
      setValues([...values, newTag]);
    }

    dispatch(
      addCompanyToTag({
        companyUUID: companyUUID,
        companyName: companyName,
        tagName: newTag,
        addedFrom: tagCompanySource,
        clientUUID: selectedClient.uuid,
      })
    );

    Segment.analytics?.track("add-tag-to-company", {
      client: selectedClient.name,
      clientUUID: selectedClient.uuid,
      tagName: newTag,
      source: "tag-widget",
    });
  };

  const handleRemoveTagFromCompany = (removedTag: string) => {
    if (!selectedClient) return;

    setValues(values.filter((x) => x !== removedTag));

    dispatch(
      removeCompanyFromTag({
        companyUUID: companyUUID,
        tagName: removedTag,
        clientUUID: selectedClient?.uuid,
      })
    );

    Segment.analytics?.track("remove-tag-from-company", {
      client: selectedClient.name,
      clientUUID: selectedClient.uuid,
      tagName: removedTag,
      source: "tag-widget",
    });
  };

  const options: SelectProps["options"] = [];
  clientTags.forEach((tag) => {
    options.push({
      value: tag.name,
      label: tag.name,
      uuid: tag.uuid,
    });
  });

  // Stop propagation to prevent the company detail page loading on any click
  return (
    <WidgetContainer onClick={(e) => e.stopPropagation()}>
      <Select
        className="tagSelect"
        defaultOpen={true}
        autoFocus={true}
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Search or Add your tags here"
        onSelect={handleAddTagToCompany}
        onDeselect={handleRemoveTagFromCompany}
        options={options}
        //defaultValue={companyTags}
        value={values}
        loading={addCompanyToTagLoading || removeCompanyFromTagLoading || false}
        status={(addCompanyToTagError || removeCompanyFromTagError) && "error"}
      />
    </WidgetContainer>
  );
};

const WidgetContainer = styled.div`
  display: flex;
  max-height: 30vh;
  max-width: 25vw;
  min-width: 25rem;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;
