import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fork, put, takeEvery, select } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { backend } from "../../utils/http";
import { State } from "../../utils/store";
import { User } from "../../auth/store";
import {
  companySlice,
  BaseCompany,
  CompanyDetail,
  EnrichedProfileStatus,
} from "../../share/company/store";
import { campaignsSlice } from "../../share/campaigns/store";

export const FEEDBACK_COMMENT_MAX_NUMBER_CHARACTERS = 500;

export enum Filter {
  MATCH = "match",
  MISS = "miss",
  FAVOURITE = "favourite",
  TOREVIEW = "toReview",
}

export enum GoldenBasketCompanyRequestType {
  Share = 5,
}

export enum CampaignRequestType {
  Export = 1,
  Iteration = 2,
}

interface Feedback {
  isMatch: boolean;
  companyUUID: string;
  campaignUUID: string;
  comment: string | null;
  type: number | null;
}

export interface FeedbackDetails extends Feedback {
  author: User;
  createdAt: Date | string;
  reason: string | null;
}

export interface GBCompanyDetail extends CompanyDetail {
  feedback: FeedbackDetails | null;
  agentComment?: string | null;
  clientComment?: string | null;
  clientCommentAuthor?: string | null;
  clientCommentCommentedAt?: string | null;
}

export interface CampaignRequest {
  clientUUID: string;
  campaignUUID: string;
  requestType: CampaignRequestType;
  comment?: string;
}

export interface ShareCompanyData {
  destinationEmail: string;
  companyUUID: string;
  requestType: number;
}

export interface FollowCompanyData {
  GBCompanyUUID: string;
  followed: boolean;
}

export interface EditCompanyCommentData {
  comment: string | null;
  commentAuthor: string | null;
  commentDate: string | null;
  gbCompanyUUID: string;
}

export interface GoldenBasketCompany {
  campaign: number;
  agentComment?: string | null;
  clientComment?: string | null;
  clientCommentAuthor?: string | null;
  clientCommentCommentedAt?: string | null;
  company: BaseCompany;
  followed: boolean;
  enrichedStatus: EnrichedProfileStatus;
  isMatch: boolean | undefined;
  iteration: number;
  keywords: string;
  score: number;
  status: string | null;
  uuid: string;
}

interface RequestStatus {
  loading: boolean;
  message: string;
  success: boolean | null;
}

interface AddTagToCompanyPayload {
  // NOT GBCompanyUUID
  companyUUID: string;
  tagName: string;
}

export interface GoldenBasketState {
  getGBList: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  fetchSelectedCompany: {
    loading: boolean;
    error: string;
  };
  submitFeedback: RequestStatus;
  submitGBExportRequest: RequestStatus;
  submitGBIterationRequest: RequestStatus;
  shareCompany: RequestStatus;
  followCompany: RequestStatus;
  favouriteCompany: RequestStatus;
  editCompanyComment: RequestStatus;
  filters: {
    toReview: boolean;
    match: boolean;
    miss: boolean;
    favourite: boolean;
  };
  logoStatus: {
    loading: boolean;
    error: boolean;
  };
  GBList: GoldenBasketCompany[];
  selectedCompany: GBCompanyDetail | null;
  // To keep track if a company becomes "fully tagged"
  isGBCandidateForConfetti: boolean;
}

const initialState = {
  getGBList: {
    loading: false,
    error: "",
    success: false,
  },
  fetchSelectedCompany: {
    loading: false,
    error: "",
  },
  submitFeedback: {
    loading: false,
    success: null,
    message: "",
  },
  submitGBExportRequest: {
    loading: false,
    success: null,
    message: "",
  },
  submitGBIterationRequest: {
    loading: false,
    success: null,
    message: "",
  },
  shareCompany: {
    loading: false,
    success: null,
    message: "",
  },
  followCompany: {
    loading: false,
    success: null,
    message: "",
  },
  favouriteCompany: {
    loading: false,
    success: null,
    message: "",
  },
  editCompanyComment: {
    loading: false,
    success: null,
    message: "",
  },
  GBList: [],
  filters: {
    toReview: true,
    match: false,
    miss: false,
    favourite: false,
  },
  logoStatus: {
    loading: true,
    error: false,
  },
  selectedCompany: null,
  isGBCandidateForConfetti: false,
} as GoldenBasketState;

export const goldenBasketSlice = createSlice({
  name: "golden_basket",
  initialState: initialState,
  reducers: {
    setInitialState(state, filter: PayloadAction<string>) {
      state.getGBList = initialState.getGBList;
      state.fetchSelectedCompany = initialState.fetchSelectedCompany;
      state.submitFeedback = initialState.submitFeedback;
      state.shareCompany = initialState.shareCompany;
      state.followCompany = initialState.followCompany;
      state.favouriteCompany = initialState.favouriteCompany;
      state.GBList = initialState.GBList;
      state.selectedCompany = initialState.selectedCompany;
      state.filters = {
        match: false,
        miss: false,
        toReview: false,
        favourite: false,
      };
      state.filters = { ...state.filters, [filter.payload]: true };
      state.isGBCandidateForConfetti = false;
      state.submitGBExportRequest = initialState.submitGBExportRequest;
      state.submitGBIterationRequest = initialState.submitGBIterationRequest;
      state.editCompanyComment = initialState.editCompanyComment;
    },
    setInitialCompanyDetails(state) {
      state.submitFeedback = initialState.submitFeedback;
      state.shareCompany = initialState.shareCompany;
      state.followCompany = initialState.followCompany;
      state.favouriteCompany = initialState.favouriteCompany;
      state.shareCompany = initialState.shareCompany;
      state.editCompanyComment = initialState.editCompanyComment;
    },
    setInitialGoldenBasketCompanyState(state) {
      state.selectedCompany = initialState.selectedCompany;
      state.submitFeedback = initialState.submitFeedback;
      state.shareCompany = initialState.shareCompany;
      state.followCompany = initialState.followCompany;
      state.favouriteCompany = initialState.favouriteCompany;
      state.shareCompany = initialState.shareCompany;
    },
    setInitialShareCompanyState(state) {
      state.shareCompany = initialState.shareCompany;
    },
    setFeedbackInitialState(state) {
      state.submitFeedback = initialState.submitFeedback;
    },
    setGBExportInitialState(state) {
      state.submitGBExportRequest = initialState.submitGBExportRequest;
    },
    setGBIterationInitialState(state) {
      state.submitGBIterationRequest = initialState.submitGBIterationRequest;
    },
    setIsGBCandidateForConfettiInitialState(state) {
      state.isGBCandidateForConfetti = false;
    },
    favouriteGBCompany(state, action) {},
    favouriteGBCompanySuccess(state, action) {
      let position = getPositionByCompanyUUID(
        state.GBList,
        action.payload.companyUUID
      );
      state.GBList[position].company.favourite =
        !state.GBList[position].company.favourite;
    },
    fetchSelectedCompany(state, action: PayloadAction<string>) {
      state.fetchSelectedCompany.loading = true;
      state.fetchSelectedCompany.error = "";
    },
    fetchSelectedCompanySuccess(state, action) {
      state.fetchSelectedCompany.loading = false;
      state.selectedCompany = action.payload.data;
      state.logoStatus = initialState.logoStatus;
    },
    fetchSelectedCompanyFailure(state, action) {
      state.fetchSelectedCompany.loading = false;
      state.fetchSelectedCompany.error = action.payload.data.message;
      state.selectedCompany = null;
    },
    followCompany(state, action: PayloadAction<FollowCompanyData>) {
      state.followCompany.loading = true;
      state.followCompany.success = null;
    },
    followCompanySuccess(state, action: PayloadAction<FollowCompanyData>) {
      state.followCompany.loading = false;
      state.followCompany.success = true;
      state.followCompany.message = "";
      let position = getPositionByUUID(
        state.GBList,
        action.payload.GBCompanyUUID
      );
      state.GBList[position].followed = action.payload.followed;
    },
    followCompanyFailure(state, action) {
      state.followCompany.loading = false;
      state.followCompany.success = false;
      state.followCompany.message = action.payload.data.message;
    },
    getGBList(state, action: PayloadAction<string>) {
      state.getGBList.loading = true;
      state.getGBList.success = false;
      state.isGBCandidateForConfetti = false;
    },
    getGBListSuccess(state, action) {
      const gbList = action.payload.data;
      state.getGBList.loading = false;
      state.GBList = [...gbList];
      state.isGBCandidateForConfetti =
        gbList.filter(
          (company: GoldenBasketCompany) => company.isMatch === null
        ).length !== 0;
      state.getGBList.success = true;
    },
    getGBListFailure(state, action) {
      state.getGBList.loading = false;
      state.getGBList.error = action.payload.data.message;
      state.getGBList.success = false;
      state.isGBCandidateForConfetti = false;
    },
    setFeedback(state, action) {
      let position = getPositionByGBCompanyUUID(
        state.GBList,
        action.payload.uuid
      );
      state.GBList[position].isMatch = action.payload.feedback.isMatch;
      if (state.selectedCompany)
        state.selectedCompany.feedback = action.payload.feedback;
    },
    setFilter(state, action) {
      state.filters = {
        match: false,
        miss: false,
        toReview: false,
        favourite: false,
      };
      state.filters = { ...state.filters, [action.payload]: true };
    },
    setFollow(state, action) {
      let position = getPositionByUUID(state.GBList, action.payload.uuid);
      state.GBList[position].followed = action.payload.followed;
    },
    setLogoStatus(state, action) {
      state.logoStatus = { ...state.logoStatus, ...action.payload };
    },
    setSelectedCompany(state, action) {
      state.selectedCompany = action.payload || null;
      state.logoStatus = initialState.logoStatus;
    },
    setGBEnrichedStatusToPending(state, action) {
      let position = getPositionByCompanyUUID(
        state.GBList,
        action.payload.uuid
      );
      if (position !== -1) {
        state.GBList[position].enrichedStatus = EnrichedProfileStatus.PENDING;
      }
    },
    shareCompany(state, action: PayloadAction<ShareCompanyData>) {
      state.shareCompany.loading = true;
      state.shareCompany.success = null;
      state.shareCompany.message = "";
    },
    shareCompanySuccess(state) {
      state.shareCompany.loading = false;
      state.shareCompany.success = true;
      state.shareCompany.message = "";
    },
    shareCompanyFailure(state, action) {
      state.shareCompany.loading = false;
      state.shareCompany.success = false;
      state.shareCompany.message = action.payload.data.message;
    },
    submitFeedback(state, action: PayloadAction<Feedback>) {
      state.submitFeedback.loading = true;
      state.submitFeedback.success = null;
    },
    submitFeedbackSuccess(state) {
      state.submitFeedback.loading = false;
      state.submitFeedback.success = true;
      state.submitFeedback.message = "";
    },
    submitFeedbackFailure(state, action) {
      state.submitFeedback.loading = false;
      state.submitFeedback.success = false;
      state.submitFeedback.message = action.payload.data.message;
    },
    submitGBExportRequest(state, action: PayloadAction<CampaignRequest>) {
      state.submitGBExportRequest.loading = true;
      state.submitGBExportRequest.success = null;
    },
    submitGBExportRequestSuccess(state) {
      state.submitGBExportRequest.loading = false;
      state.submitGBExportRequest.success = true;
      state.submitGBExportRequest.message = "";
    },
    submitGBExportRequestFailure(state, action) {
      state.submitGBExportRequest.loading = false;
      state.submitGBExportRequest.success = false;
      state.submitGBExportRequest.message = action.payload.data.message;
    },
    submitGBIterationRequest(state, action: PayloadAction<CampaignRequest>) {
      state.submitGBIterationRequest.loading = true;
      state.submitGBIterationRequest.success = null;
      state.submitGBIterationRequest.message = "";
    },
    submitGBIterationRequestSuccess(state) {
      state.submitGBIterationRequest.loading = false;
      state.submitGBIterationRequest.success = true;
      state.submitGBIterationRequest.message = "";
    },
    submitGBIterationRequestFailure(state, action) {
      state.submitGBIterationRequest.loading = false;
      state.submitGBIterationRequest.success = false;
      state.submitGBIterationRequest.message = action.payload.data.message;
    },
    editCompanyComment(state, action: PayloadAction<EditCompanyCommentData>) {
      state.editCompanyComment.loading = true;
      state.editCompanyComment.success = null;
      state.editCompanyComment.message = "";
    },
    editCompanyCommentSuccess(state) {
      state.editCompanyComment.loading = false;
      state.editCompanyComment.success = true;
      state.editCompanyComment.message = "";
    },
    editCompanyCommentFailure(state, action) {
      state.editCompanyComment.loading = false;
      state.editCompanyComment.success = false;
      state.editCompanyComment.message = action.payload.data.message;
    },
    // Update both the shown company and the companies in the list, in case they change from the
    // detailed GB view to the Table GB view
    setGBCompanyClientComment(
      state,
      action: PayloadAction<EditCompanyCommentData>
    ) {
      if (state.selectedCompany) {
        state.selectedCompany.clientComment = action.payload.comment;
        state.selectedCompany.clientCommentAuthor =
          action.payload.commentAuthor;
        state.selectedCompany.clientCommentCommentedAt =
          action.payload.commentDate;
      }

      let position = getPositionByUUID(
        state.GBList,
        action.payload.gbCompanyUUID
      );
      if (position !== -1) {
        state.GBList[position].clientComment = action.payload.comment;
        state.GBList[position].clientCommentAuthor =
          action.payload.commentAuthor;
        state.GBList[position].clientCommentCommentedAt =
          action.payload.commentDate;
      }
    },
    addTagToCompany(state, action: PayloadAction<AddTagToCompanyPayload>) {
      let position = getPositionByCompanyUUID(
        state.GBList,
        action.payload.companyUUID
      );
      if (position !== -1) {
        state.GBList[position].company.companyTags = [
          ...state.GBList[position].company.companyTags,
          action.payload.tagName,
        ];
      }

      if (
        state.selectedCompany &&
        state.selectedCompany.uuid === action.payload.companyUUID
      ) {
        state.selectedCompany.companyTags = [
          ...state.selectedCompany.companyTags,
          action.payload.tagName,
        ];
      }
    },
    removeTagFromCompany(state, action: PayloadAction<AddTagToCompanyPayload>) {
      let position = getPositionByCompanyUUID(
        state.GBList,
        action.payload.companyUUID
      );
      if (position !== -1) {
        state.GBList[position].company.companyTags = state.GBList[
          position
        ].company.companyTags.filter((x) => x !== action.payload.tagName);
      }

      if (
        state.selectedCompany &&
        state.selectedCompany.uuid === action.payload.companyUUID
      ) {
        state.selectedCompany.companyTags =
          state.selectedCompany.companyTags.filter(
            (x) => x !== action.payload.tagName
          );
      }
    },
  },
});

const getPositionByGBCompanyUUID = (
  GBList: GoldenBasketCompany[],
  GBCompanyUUID: string
) =>
  GBList.findIndex((GBCompany: GoldenBasketCompany) => {
    return GBCompany.uuid === GBCompanyUUID;
  });

const getPositionByUUID = (GBList: GoldenBasketCompany[], uuid: string) =>
  GBList.findIndex((GBCompany: GoldenBasketCompany) => {
    return GBCompany.uuid === uuid;
  });

const getPositionByCompanyUUID = (
  GBList: GoldenBasketCompany[],
  uuid: string
) =>
  GBList.findIndex((GBCompany: GoldenBasketCompany) => {
    return GBCompany.company.uuid === uuid;
  });

// filters selector
const companies = (state: State) => state.goldenBasket.GBList;
const matchFilter = (state: State) => state.goldenBasket.filters.match;
const missFilter = (state: State) => state.goldenBasket.filters.miss;
const toReviewFilter = (state: State) => state.goldenBasket.filters.toReview;
const favouriteFilter = (state: State) => state.goldenBasket.filters.favourite;
export const filteredGBListSelector = createSelector(
  [companies, matchFilter, missFilter, toReviewFilter, favouriteFilter],
  (companies, match, miss, toReview, favourite) => {
    return companies
      .filter((x) => (toReview ? x.isMatch === null : true))
      .filter((x) => (match ? x.isMatch === true : true))
      .filter((x) => (miss ? x.isMatch === false : true))
      .filter((x) => (favourite ? x.company.favourite : true));
  }
);

const selectedCompanySelector = (state: State) =>
  state.goldenBasket.selectedCompany;

export const filteredGBCompanySelector = createSelector(
  [companies, selectedCompanySelector],
  (companies, selectedCompany) => {
    if (selectedCompany) {
      return companies.find(
        (gbCompany) => gbCompany.company.uuid === selectedCompany.uuid
      );
    }
  }
);

export const getPositionByCompanyUUIDSelector = (uuid: string) =>
  createSelector(companies, (companies) =>
    companies?.findIndex((element) => element.company.uuid === uuid)
  );

export const topicsFilter = (state: State) => {
  let gbList = [...state.goldenBasket.GBList];
  let allFilters = new Set<string>();

  for (let gbCompany of gbList) {
    let { company } = gbCompany;
    let gbCompanyTopics: string[] = [];
    if (company.tags) gbCompanyTopics = [...company.tags];
    if (company.textKeywords)
      gbCompanyTopics = [...gbCompanyTopics, ...company.textKeywords];

    gbCompanyTopics.forEach((x) => allFilters.add(x.toLowerCase()));
  }
  return Array.from(allFilters);
};

export const iterationsFilter = (state: State) => {
  let gbList = [...state.goldenBasket.GBList];
  let allFilters = new Set<number>();

  gbList.forEach((x) => allFilters.add(x.iteration));
  return Array.from(allFilters);
};

// #######################
//          SAGAS
// #######################
function* favouriteGBCompanySaga() {
  yield takeEvery(
    goldenBasketSlice.actions.favouriteGBCompany,
    function* (action) {
      try {
        yield put(companySlice.actions.favouriteCompany(action.payload));
        yield put(
          goldenBasketSlice.actions.favouriteGBCompanySuccess(action.payload)
        );
      } catch (err) {
        // Nothing, error is caught in the companySlice
      }
    }
  );
}

function* fetchSelectedCompanySaga() {
  yield takeEvery(
    goldenBasketSlice.actions.fetchSelectedCompany,
    function* (action) {
      try {
        const response: AxiosResponse<GoldenBasketCompany> =
          yield backend.goldenBasketBackend.fetchGoldenBasketCompanyDetail(
            action.payload
          );
        yield put(
          goldenBasketSlice.actions.fetchSelectedCompanySuccess(response)
        );
      } catch (err) {
        yield put(goldenBasketSlice.actions.fetchSelectedCompanyFailure(err));
      }
    }
  );
}

function* followCompanySaga() {
  yield takeEvery(goldenBasketSlice.actions.followCompany, function* (action) {
    try {
      yield backend.goldenBasketBackend.followCompany(action.payload);

      let payload = {
        GBCompanyUUID: action.payload.GBCompanyUUID,
        followed: action.payload.followed,
      };
      yield put(goldenBasketSlice.actions.followCompanySuccess(payload));
    } catch (err) {
      yield put(goldenBasketSlice.actions.followCompanyFailure(err));
    }
  });
}

function* getGBListSaga() {
  yield takeEvery(goldenBasketSlice.actions.getGBList, function* (action) {
    try {
      const response: AxiosResponse<GoldenBasketCompany[]> =
        yield backend.goldenBasketBackend.getGBList(action.payload);

      const match: boolean = yield select(matchFilter);
      const miss: boolean = yield select(missFilter);
      const toReview: boolean = yield select(toReviewFilter);
      const favourite: boolean = yield select(favouriteFilter);

      const filteredResponse: any = response.data
        .filter((company: GoldenBasketCompany) =>
          toReview ? company.isMatch === null : true
        )
        .filter((company: GoldenBasketCompany) =>
          match ? company.isMatch === true : true
        )
        .filter((company: GoldenBasketCompany) =>
          miss ? company.isMatch === false : true
        )
        .filter((company: GoldenBasketCompany) =>
          favourite ? company.company.favourite : true
        );

      if (filteredResponse.length > 0) {
        yield put(
          goldenBasketSlice.actions.fetchSelectedCompany(
            filteredResponse[0].uuid
          )
        );
      } else {
        yield put(goldenBasketSlice.actions.setSelectedCompany(null));
      }
      yield put(goldenBasketSlice.actions.getGBListSuccess(response));
    } catch (err) {
      yield put(goldenBasketSlice.actions.getGBListFailure(err));
    }
  });
}

function* setFilterSaga() {
  yield takeEvery(goldenBasketSlice.actions.setFilter, function* (action) {
    const companies: GoldenBasketCompany[] = yield select(
      filteredGBListSelector
    );
    const selectedCompany: BaseCompany = yield select(selectedCompanySelector);

    let filterMatchesSelectedCompany;
    if (selectedCompany && companies.length > 0) {
      filterMatchesSelectedCompany =
        selectedCompany.uuid === companies[0].company.uuid;
    }

    if (companies.length === 0) {
      yield put(
        goldenBasketSlice.actions.fetchSelectedCompanySuccess({ data: null })
      );
    } else if (companies.length > 0 && !filterMatchesSelectedCompany) {
      yield put(
        goldenBasketSlice.actions.fetchSelectedCompany(companies[0].uuid)
      );
    }
  });
}

function* shareCompanySaga() {
  yield takeEvery(goldenBasketSlice.actions.shareCompany, function* (action) {
    try {
      yield backend.goldenBasketBackend.shareCompany(action.payload);
      yield put(goldenBasketSlice.actions.shareCompanySuccess());
    } catch (err) {
      yield put(goldenBasketSlice.actions.shareCompanyFailure(err));
    }
  });
}

/**
 * After tagging a company for the first time (that is, user is in "To Review" section),
 * another one has to be shown, with the following rules
 * - If there are no more companies, do nothing (let the confetti fly)
 * - If there are more companies, and the tagged one was the last one, the previous to the last one has to be shown
 * - If there are more companies, and the tagged one was not the last one, show the next one
 *
 */
function* submitFeedbackSaga() {
  yield takeEvery(
    goldenBasketSlice.actions.submitFeedback,
    function* (action): any {
      try {
        const response: AxiosResponse<Feedback> =
          yield backend.goldenBasketBackend.submitFeedback(
            action.payload.isMatch,
            action.payload.companyUUID,
            action.payload.campaignUUID,
            action.payload.comment || null,
            action.payload.type || null
          );

        try {
          const isToReviewSelected = yield select(toReviewFilter);
          const filteredGBList: GoldenBasketCompany[] = yield select(
            filteredGBListSelector
          );
          // Do nothing if the list is empty or if there's only one company in the filter
          // Or filter is not <To Review>
          if (isToReviewSelected && filteredGBList.length >= 2) {
            const companyIndex = filteredGBList.findIndex(
              (company) => company.uuid === action.payload.companyUUID
            );

            let companyToSelectIndex = 0;
            if (companyIndex === -1) {
              throw new Error("Company not found");
            }
            // Company is the last, selectedCompany is the previous one
            // findIndex returns a 0-based index
            if (companyIndex + 1 === filteredGBList.length) {
              companyToSelectIndex = filteredGBList.length - 2;
            } else {
              companyToSelectIndex = companyIndex + 1;
            }
            yield put(
              goldenBasketSlice.actions.fetchSelectedCompany(
                filteredGBList[companyToSelectIndex].uuid
              )
            );
          } else if (filteredGBList.length === 1) {
            yield put(goldenBasketSlice.actions.setSelectedCompany(null));
          }
        } catch (err) {}

        let payload = {
          uuid: action.payload.companyUUID,
          feedback: response.data,
        };
        yield put(goldenBasketSlice.actions.setFeedback(payload));
        yield put(goldenBasketSlice.actions.submitFeedbackSuccess());
      } catch (err) {
        yield put(goldenBasketSlice.actions.submitFeedbackFailure(err));
      }
    }
  );
}

function* submitGBExportRequestSaga() {
  yield takeEvery(
    goldenBasketSlice.actions.submitGBExportRequest,
    function* (action) {
      try {
        yield backend.goldenBasketBackend.submitGBCampaignRequest(
          action.payload
        );
        yield put(goldenBasketSlice.actions.submitGBExportRequestSuccess());
        yield put(campaignsSlice.actions.setCampaignExport(action.payload));
      } catch (err) {
        yield put(goldenBasketSlice.actions.submitGBExportRequestFailure(err));
      }
    }
  );
}

function* submitGBIterationRequestSaga() {
  yield takeEvery(
    goldenBasketSlice.actions.submitGBIterationRequest,
    function* (action) {
      try {
        yield backend.goldenBasketBackend.submitGBCampaignRequest(
          action.payload
        );
        yield put(goldenBasketSlice.actions.submitGBIterationRequestSuccess());
        yield put(campaignsSlice.actions.setCampaignIteration(action.payload));
      } catch (err) {
        yield put(
          goldenBasketSlice.actions.submitGBIterationRequestFailure(err)
        );
      }
    }
  );
}

function* editCompanyCommentSaga() {
  yield takeEvery(
    goldenBasketSlice.actions.editCompanyComment,
    function* (action) {
      try {
        yield backend.goldenBasketBackend.editCompanyComment(action.payload);

        yield put(goldenBasketSlice.actions.editCompanyCommentSuccess());
        yield put(
          goldenBasketSlice.actions.setGBCompanyClientComment(action.payload)
        );
      } catch (err) {
        yield put(goldenBasketSlice.actions.editCompanyCommentFailure(err));
      }
    }
  );
}

export function* goldenBasketSaga() {
  yield fork(editCompanyCommentSaga);
  yield fork(favouriteGBCompanySaga);
  yield fork(fetchSelectedCompanySaga);
  yield fork(followCompanySaga);
  yield fork(getGBListSaga);
  yield fork(setFilterSaga);
  yield fork(shareCompanySaga);
  yield fork(submitFeedbackSaga);
  yield fork(submitGBExportRequestSaga);
  yield fork(submitGBIterationRequestSaga);
}

export const {
  editCompanyComment,
  favouriteGBCompany,
  fetchSelectedCompany,
  followCompany,
  getGBList,
  setFilter,
  setFeedbackInitialState,
  setGBExportInitialState,
  setGBIterationInitialState,
  setIsGBCandidateForConfettiInitialState,
  setInitialCompanyDetails,
  setInitialGoldenBasketCompanyState,
  setInitialShareCompanyState,
  setInitialState,
  setLogoStatus,
  shareCompany,
  submitFeedback,
  submitGBExportRequest,
  submitGBIterationRequest,
} = goldenBasketSlice.actions;
