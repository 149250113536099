import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { Card, Tooltip } from "antd";
import { QuestionCircleOutlined as BaseQuestionCircleOutlined } from "@ant-design/icons";
import { CenteredSpinner } from "../../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../../ui-components/CenteredErrorMessage";

import { UserRole } from "../../clients/store";
import { State } from "../../utils/store";
import { useAppSelector } from "../../utils/hooks";

export const ClientStatistics = () => {
  const { statistics } = useAppSelector((state: State) => state.campaigns);
  const { loading, error } = useAppSelector(
    (state: State) => state.campaigns.getCampaigns
  );

  const { selectedClient } = useAppSelector((state: State) => state.clients);

  return (
    <Card title={<span style={{ paddingLeft: "1rem" }}>My statistics</span>}>
      {error ? (
        <CenteredErrorMessage>{error}</CenteredErrorMessage>
      ) : loading ? (
        <CenteredSpinner />
      ) : (
        <CardContent>
          <Stat>
            <StatNumber>{statistics?.companies}</StatNumber>
            <SectionExplanation>
              <InfoText>Companies delivered</InfoText>
              <Tooltip title="The number of companies delivered by the platform across all campaigns (including archived campaigns but excluding default demo campaigns)">
                <QuestionCircleOutlined />
              </Tooltip>
            </SectionExplanation>
          </Stat>
          <Stat>
            <StatNumber>{statistics?.matches}</StatNumber>
            <SectionExplanation>
              <InfoText>Matches</InfoText>
              <Tooltip title="The number of companies your team tagged as match in all campaigns (including archived campaigns but excluding default demo campaigns)">
                <QuestionCircleOutlined />
              </Tooltip>
            </SectionExplanation>
          </Stat>
          <Stat>
            <StatNumber>{statistics?.favourites}</StatNumber>
            <SectionExplanation>
              <InfoText>Companies favourited</InfoText>
              <Tooltip title="The number of companies your team tagged as favorites across all campaigns">
                <QuestionCircleOutlined />
              </Tooltip>
            </SectionExplanation>
          </Stat>
          <Stat>
            <StatNumber>{statistics?.iterations}</StatNumber>
            <SectionExplanation>
              <InfoText>Deliveries</InfoText>
              <Tooltip title="The number of Deliveries across all your campaigns (including archived campaigns but excluding default demo campaigns)">
                <QuestionCircleOutlined />
              </Tooltip>
            </SectionExplanation>
          </Stat>
          <Stat>
            <StatNumber>{statistics?.users}</StatNumber>
            <SectionExplanation>
              <InfoText>Active users</InfoText>
            </SectionExplanation>
            {selectedClient?.userRole === UserRole.ADMIN && (
              <Link to={`/${selectedClient?.slug}/user-management`}>
                Manage your team
              </Link>
            )}
          </Stat>
        </CardContent>
      )}
    </Card>
  );
};

const CardContent = styled.div`
  display: flex;
  justify-items: space-between;
  padding: 1rem;
  gap: 0.8rem;
`;

const Stat = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border: var(--stat-card-border);
  border-radius: var(--firmographics-cell-border-radius);
`;

const StatNumber = styled.span`
  font-size: 5rem;
  font-weight: var(--font-weight-bold);
  color: var(--font-color-campaign-stats);
`;

const SectionExplanation = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const InfoText = styled.span`
  font-size: var(--font-size-base);
`;

const QuestionCircleOutlined = styled(BaseQuestionCircleOutlined)`
  color: var(--primary-color);
`;
