import { FC } from "react";

import { Pie } from "@ant-design/plots";

import {
  CampaignMeta,
  CompanyStatusColors,
  CompanyStatusMapping,
} from "../store";
import styled from "@emotion/styled";
import { useBreakpoint } from "../../../utils/styling";

interface CampaignMetaPieChartProps {
  campaignMeta: CampaignMeta;
}

export const CampaignMetaPieChart: FC<CampaignMetaPieChartProps> = ({
  campaignMeta,
}) => {
  const [isScreenXL] = useBreakpoint(1200, { useBody: true });

  let customCampaignMeta: any = { ...campaignMeta };

  const totalCompanies =
    campaignMeta.matches + campaignMeta.misses + campaignMeta.toReview;

  const pieChartData = Object.keys(customCampaignMeta)
    .filter((key) => key !== "iterations")
    .map((key: string) => {
      // Let TypeScript know that the key we are going to use for customCampaignMeta
      // is a key of type 'customCampaignMeta key'
      const typedKey = key as keyof typeof customCampaignMeta;
      return {
        name: key,
        value: customCampaignMeta[typedKey],
      };
    });

  pieChartData.sort((itemA, itemB) => itemA.value - itemB.value);

  function renderStatistic(containerWidth: number) {
    const textStyleStr = `width:${containerWidth - 20}px;`;
    const textFontSize = isScreenXL ? "2.5rem" : "2rem";
    const numberFontSize = isScreenXL ? "7rem" : "4rem";
    return `<div style="${textStyleStr};font-size:${textFontSize};text-align:center;">
                <div>Total Companies</div>
                <div style="display:block;font-size: ${numberFontSize};text-align: center">
                    ${totalCompanies}
                </div>
            </div>`;
  }

  const config = {
    height: isScreenXL ? 300 : 200,
    width: isScreenXL ? 300 : 200,
    appendPadding: 8,
    data: pieChartData,
    angleField: "value",
    colorField: "name",
    color: (type: any) => {
      let typedKey = type.name.trim() as keyof typeof CompanyStatusColors;
      return CompanyStatusColors[typedKey];
    },
    radius: 1,
    innerRadius: 0.9,
    label: false as false,
    legend: false as false,
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    tooltip: {
      formatter: (datum: any) => {
        const typedDatumName = datum.name as keyof typeof CompanyStatusMapping;
        return {
          name: CompanyStatusMapping[typedDatumName],
          value: datum.value,
        };
      },
    },
    statistic: {
      title: false as false,
      content: {
        offsetY: 4,
        style: {
          whiteSpace: "no-wrap",
          overflow: "hidden",
        },
        customHtml: (container: any) => {
          const { width } = container.getBoundingClientRect();
          return renderStatistic(width);
        },
      },
    },
  };

  return (
    <Container>
      <Pie {...config} />
      <LegendContainer>
        <LegendItem isScreenXL={isScreenXL}>
          <div>
            <ColorDot backgroundColor={CompanyStatusColors.matches} />
            Matches
          </div>
          <div>
            <strong>{campaignMeta.matches}</strong> &nbsp; (
            {((campaignMeta.matches * 100) / totalCompanies).toPrecision(3)}%)
          </div>
        </LegendItem>
        <LegendItem isScreenXL={isScreenXL}>
          <div>
            <ColorDot backgroundColor={CompanyStatusColors.misses} />
            Misses
          </div>
          <div>
            <strong>{campaignMeta.misses}</strong> &nbsp; (
            {((campaignMeta.misses * 100) / totalCompanies).toPrecision(3)}%)
          </div>
        </LegendItem>
        <LegendItem isScreenXL={isScreenXL}>
          <div>
            <ColorDot backgroundColor={CompanyStatusColors.toReview} />
            To Review
          </div>
          <div>
            <strong>{campaignMeta.toReview}</strong> &nbsp; (
            {((campaignMeta.toReview * 100) / totalCompanies).toPrecision(3)}%)
          </div>
        </LegendItem>
        <Iterations>
          Total Deliveries: <strong>{campaignMeta.iterations}</strong>
        </Iterations>
      </LegendContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 1rem;
  height: fit-content;
  border: var(--firmographics-cell-border);
  border-radius: var(--firmographics-cell-border-radius);
`;

const LegendContainer = styled.div`
  width: 100%;
`;

const LegendItem = styled.div<{ isScreenXL: boolean }>`
  padding: 1rem ${(props) => (props.isScreenXL ? "3rem" : "1rem")};
  display: flex;
  justify-content: space-between;
`;

const ColorDot = styled.span<{ backgroundColor: string }>`
  display: inline-block;
  background-color: ${(props) => props.backgroundColor};
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin-right: 0.8rem;
`;

const Iterations = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
`;
