import { FC } from "react";
import { Form, Select } from "antd";
import { useField } from "formik";
import styled from "@emotion/styled";

import {
  Label,
  SectionExplanation,
  StyledButtonText,
} from "../../../ui-components/Typography";

import { StylelessButton } from "../../../ui-components/StylelessButton";

export interface FormikSelectProps {
  name: string;
  label?: string;
  sectionExplanation?: string;
  placeholder?: string | undefined;
  maxResults?: number;
  disabledExtraTopicsButton: boolean;
  openExtraTopicsModalAndTrack: () => void;
  [x: string]: any;
}

export const FreeSoloTopicsSelect: FC<FormikSelectProps> = ({
  name,
  label,
  sectionExplanation,
  placeholder,
  maxResults,
  disabledExtraTopicsButton,
  openExtraTopicsModalAndTrack,
  ...rest
}) => {
  const [field, meta, { setValue, setTouched }] = useField({
    name,
    ...rest,
  });

  const invalid = meta.touched && meta.error; // Show error message & red border

  const reachedMaxResults =
    maxResults && maxResults !== 0 && field.value?.length === maxResults;

  return (
    <>
      <FormItem
        help={invalid ? meta.error : null}
        validateStatus={invalid ? "error" : "success"}
      >
        <Label>
          {label && label}
          {sectionExplanation && (
            <SectionExplanation>{sectionExplanation}</SectionExplanation>
          )}
          <Select
            value={field.value}
            onChange={(values: any) => {
              // Remove falsy values
              let cleanValues = values.filter(Boolean);
              // Remove spaces. Example for when spaces as a value may happen:
              // When someone pastes "a, , b" the values are --> ["a", " ", " b"]
              // We want that list to become ["a", "b"]
              cleanValues = cleanValues
                .map((x: any) => x.toString().trim())
                .filter(Boolean);
              // Remove duplicates. This happens when someone pastes, example:
              // Having ["a", "b"] as keys, past "1, b". Result will be
              // ["a", "b", "b", "1"], with an error due to duplicated keys.
              // Creating a set after cleaning values removes duplicated values
              let setCleanValues: Set<string> = new Set(cleanValues);
              let valuesToSet = Array.from(setCleanValues);
              if (reachedMaxResults) {
                valuesToSet = valuesToSet.slice(0, maxResults);
              }
              setValue(valuesToSet);
            }}
            onInputKeyDown={(e) => {
              // Adding ability to adding words by clicking enter and when value is empty, clicking enter means submit form
              const target = e.target as HTMLInputElement;
              if (e.key === "Enter" && target.value) {
                e.preventDefault();
              }
            }}
            onBlur={() => setTouched(true)}
            showSearch
            style={{ width: "100%", marginTop: "0rem" }}
            placeholder={placeholder ? placeholder : "Please input your values"}
            tokenSeparators={[",", ";"]}
            allowClear
            mode="tags"
            open={false}
            {...rest}
          />
        </Label>
      </FormItem>
      <ExtraTopicsButtonContainer>
        {disabledExtraTopicsButton ? (
            <span style={{ cursor: "not-allowed" }}>
              <StylelessButton
                htmlType="button"
                type="dashed"
                onClick={() => {}}
                disabled={true}
                style={{ pointerEvents: "none" }}
              >
                <StyledButtonText>
                + Add AI generated topics
                </StyledButtonText>
              </StylelessButton>
            </span>
        ) : (
          <StylelessButton
            htmlType="button"
            type="dashed"
            onClick={openExtraTopicsModalAndTrack}
          >
            <StyledButtonText>
              + Add AI generated topics
            </StyledButtonText>
          </StylelessButton>
        )}
      </ExtraTopicsButtonContainer>
    </>
  );
};

const FormItem = styled(Form.Item)`
  margin-bottom: 0.5rem;
`;

const ExtraTopicsButtonContainer = styled.div`
  margin-bottom: 2.4rem;
`;
