import { FC } from "react";
import styled from "@emotion/styled";

import {
  ListWidget,
  ListWidgetProps,
} from "../../../../../share/company/components/list-widget/ListWidget";
import { ActionButton as BaseActionButton } from "../../../../../share/company/components/ui-share";
import { ButtonText } from "../../../../../ui-components/Typography";
import plusIcon from "../../../../../assets/icons/plus_blue.svg";

import { ListCompanySource } from "../../../../../list/store";

interface AddToListDecissionButtonProps extends ListWidgetProps {
  listCompanySource: ListCompanySource;
}

export const AddToListDecissionButton: FC<AddToListDecissionButtonProps> = ({
  companyLists,
  companyName,
  companyUUID,
  listCompanySource,
}) => {
  return (
    <ListWidget
      companyLists={companyLists}
      companyUUID={companyUUID}
      companyName={companyName}
      listCompanySource={listCompanySource}
      popoverPlacement="top"
    >
      <ActionButton type="text">
        <PlusIcon /> &nbsp;
        <ButtonText>Add this Company to a list</ButtonText>
      </ActionButton>
    </ListWidget>
  );
};

const PlusIcon = styled.div`
  width: 1.667rem;
  height: 1.667rem;
  background: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  background-image: url(${plusIcon});
`;

const ActionButton = styled(BaseActionButton)`
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
`;
