import { http } from "../http";

import {
  SearchData,
  SearchRequestData,
  UpdateFavouriteCountData,
} from "../../companies-search/store";

export async function searchCompanies(searchData: SearchData) {
  const { data } = await http.post("/api/companies-search", searchData);
  return data;
}

export async function searchRequest(SearchRequestData: SearchRequestData) {
  const { data } = await http.post("/api/request/search", SearchRequestData);
  return data;
}

export async function updateFavouriteCount(
  updateFavouriteCountData: UpdateFavouriteCountData
) {
  const { data } = await http.post(
    "/api/companies-search/favourite-count",
    updateFavouriteCountData
  );
  return data;
}
