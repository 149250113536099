import { useState, useEffect } from "react";
import { Formik } from "formik";
import styled from "@emotion/styled";

import { EditOutlined } from "@ant-design/icons";
import { ButtonSection, CancelButton, SaveButton } from "./share-ui";
import {
  Card,
  CardHeader,
  CardContent,
  HeadTitle,
} from "../../ui-components/Card";
import { EditButton } from "../../ui-components/EditButton";
import { FormikInput } from "../../ui-components/FormikInput";
import {
  successNotification,
  errorNotification,
} from "../../ui-components/Notification";
import { ButtonText, Date as BaseDate } from "../../ui-components/Typography";

import { changePassword } from "../store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import { PasswordSchema } from "../../share/validation";

interface FormValues {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}

const getLocale = () => "fr";

export const EditPasswordCard = () => {
  const [editPassword, setEditablePassword] = useState(false);
  const dispatch = useAppDispatch();
  const { success, message } = useAppSelector(
    (state: State) => state.editProfile.changePassword
  );
  const passwordLastUpdated = useAppSelector(
    (state: State) => state.layout.currentUser?.passwordLastUpdated
  );

  let passwordLastUpdatedDate: string;
  if (passwordLastUpdated) {
    let d = new Date(passwordLastUpdated);
    passwordLastUpdatedDate = d.toLocaleDateString(getLocale());
  } else {
    passwordLastUpdatedDate = "Never";
  }

  useEffect(() => {
    if (success === true) {
      successNotification("Password has been saved.");
      setEditablePassword(false);
    } else if (success === false) {
      errorNotification(message);
      setEditablePassword(false);
    }
  }, [success, message]);

  const handleSetEditable = (resetForm: () => void) => {
    setEditablePassword((prevState) => !prevState);
    resetForm();
  };

  return (
    <Card>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          repeatPassword: "",
        }}
        onSubmit={(values: FormValues, { resetForm }) => {
          dispatch(changePassword(values));
          resetForm();
        }}
        validationSchema={PasswordSchema}
      >
        {({ handleSubmit, errors, resetForm }) => (
          <>
            <CardHeader>
              <HeadTitle>Password</HeadTitle>
              {!editPassword && (
                <EditButton
                  icon={<EditOutlined />}
                  onClick={() => handleSetEditable(resetForm)}
                >
                  Edit
                </EditButton>
              )}
            </CardHeader>
            {editPassword ? (
              <CardContent>
                <Form onSubmit={handleSubmit}>
                  <FormikInput
                    name="oldPassword"
                    type="password"
                    label="Current password"
                    error={errors.oldPassword}
                  />
                  <FormikInput
                    name="newPassword"
                    type="password"
                    label="Enter new password"
                    error={errors.newPassword}
                  />
                  <FormikInput
                    name="repeatPassword"
                    type="password"
                    label="Repeat password"
                    error={errors.repeatPassword}
                  />
                  <ButtonSection>
                    <CancelButton onClick={() => handleSetEditable(resetForm)}>
                      <ButtonText>Cancel</ButtonText>
                    </CancelButton>
                    <SaveButton htmlType="submit">Save</SaveButton>
                  </ButtonSection>
                </Form>
              </CardContent>
            ) : (
              <CardContent>
                Last changed: <LastDate>{passwordLastUpdatedDate}</LastDate>
              </CardContent>
            )}
          </>
        )}
      </Formik>
    </Card>
  );
};

const Form = styled.form`
  width: 54.2rem;
`;

const LastDate = styled(BaseDate)`
  margin-left: 0.8rem;
`;
