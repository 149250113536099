import { FC } from "react";

import {
  SectionContainer,
  SectionItem,
} from "./EnrichedCompanyProfileSectionComponents";
import { EnrichedSection } from "./Section";
import { CompanyPageFieldName } from "../../../../../../ui-components/Typography";

import { EnrichedProfileContent } from "../../../../store";

interface EnrichedCompanyProfileBusinessSectionProps {
  enrichedProfileContent: EnrichedProfileContent;
}

export const EnrichedCompanyProfileBusinessSection: FC<EnrichedCompanyProfileBusinessSectionProps> =
  ({ enrichedProfileContent }) => {
    const contentHasBusinessInformation =
      enrichedProfileContent?.uniqueSellingProposition ||
      enrichedProfileContent?.businessModel ||
      enrichedProfileContent?.persona ||
      enrichedProfileContent?.clients ||
      enrichedProfileContent?.verticals;
    if (!contentHasBusinessInformation) return <></>;

    return (
      <EnrichedSection title="Business information">
        <SectionContainer>
          {enrichedProfileContent.uniqueSellingProposition && (
            <SectionItem title="Unique Selling Proposition">
              <CompanyPageFieldName>USP:</CompanyPageFieldName>
              {enrichedProfileContent.uniqueSellingProposition}
            </SectionItem>
          )}
          {enrichedProfileContent.businessModel && (
            <SectionItem>
              <CompanyPageFieldName>Business model:</CompanyPageFieldName>
              {enrichedProfileContent.businessModel}
            </SectionItem>
          )}
          {enrichedProfileContent.persona && (
            <SectionItem>
              <CompanyPageFieldName>Persona:</CompanyPageFieldName>
              {enrichedProfileContent.persona}
            </SectionItem>
          )}
          {enrichedProfileContent.clients && (
            <SectionItem>
              <CompanyPageFieldName>Clients:</CompanyPageFieldName>
              {enrichedProfileContent.clients}
            </SectionItem>
          )}
          {enrichedProfileContent.verticals && (
            <SectionItem>
              <CompanyPageFieldName>Verticals:</CompanyPageFieldName>
              {enrichedProfileContent.verticals}
            </SectionItem>
          )}
        </SectionContainer>
      </EnrichedSection>
    );
  };
