import { FC } from "react";
import { Modal as BaseModal } from "antd";

interface ModalProps {
  visible: boolean;
  onCancel: () => void;
  okText?: string;
  title: string;
  handleSubmit: () => void;
  footer?: React.ReactNode[];
  [x: string]: any;
}

export const Modal: FC<ModalProps> = ({
  visible,
  onCancel,
  okText,
  title,
  handleSubmit,
  children,
  footer,
  ...rest
}) => {
  return (
    <BaseModal
      title={title}
      centered
      visible={visible}
      onOk={() => handleSubmit()}
      onCancel={onCancel}
      cancelButtonProps={{ style: { display: "none" } }}
      width="60rem"
      okText={okText}
      footer={footer}
      {...rest}
    >
      {children}
    </BaseModal>
  );
};
