import { useEffect } from "react";
import styled from "@emotion/styled";

import { TagManagementTable } from "./components/TagManagementTable";
import { Button } from "../../ui-components/Button";
import {
  errorNotification,
  successNotification,
} from "../../ui-components/Notification";
import { CreateTagModal } from "./components/CreateTagModal";
import { Title as BaseTitle } from "../../ui-components/Typography";
import plusIcon from "../../assets/icons/plus.svg";

import { setCreateTagModalVisibility, setInitialState } from "../store";
import { State } from "../../utils/store";
import { useAppSelector, useAppDispatch } from "../../utils/hooks";
import { Segment } from "../../utils/Segment";

export const TagManagementView = () => {
  const dispatch = useAppDispatch();

  const { createTagModalVisible } = useAppSelector(
    (state: State) => state.tags
  );
  const setCreateModalVisible = (visibility: boolean) => {
    Segment.analytics?.track("open-create-tag", {
      source: "tag-management-view",
    });
    dispatch(setCreateTagModalVisibility(visibility));
  };

  const { error: updateTagError, success: updateTagSuccess } = useAppSelector(
    (state: State) => state.tags.updateTag
  );
  const { error: deleteTagError, success: deleteTagSuccess } = useAppSelector(
    (state: State) => state.tags.deleteTag
  );
  const { error: createTagError, success: createTagSuccess } = useAppSelector(
    (state: State) => state.tags.createTag
  );

  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (updateTagSuccess) {
      successNotification("Tag updated successfully");
    } else if (updateTagError) {
      errorNotification(updateTagError);
    }
  }, [updateTagSuccess, updateTagError]);

  useEffect(() => {
    if (deleteTagSuccess) {
      successNotification("Tag deleted successfully");
    } else if (deleteTagError) {
      errorNotification(deleteTagError);
    }
  }, [deleteTagSuccess, deleteTagError]);

  useEffect(() => {
    if (createTagSuccess) {
      successNotification("Tag created successfully");
    } else if (createTagError) {
      errorNotification(createTagError);
    }
  }, [createTagSuccess, createTagError]);

  return (
    <Content>
      <SectionHeader>
        <Title>Tags</Title>
        <Button onClick={() => setCreateModalVisible(true)}>
          <ButtonContent>
            <PlusIcon /> Create tag
          </ButtonContent>
        </Button>
      </SectionHeader>

      <TagManagementTable />

      <CreateTagModal
        handleModalClose={() => setCreateModalVisible(false)}
        visible={createTagModalVisible}
      />
    </Content>
  );
};

const Content = styled.div`
  padding: 2.4rem;
  background-color: var(--white);
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Title = styled(BaseTitle)`
  color: var(--152734);
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled.div`
  width: 1.667rem;
  height: 1.667rem;
  margin-right: 0.3rem;
  background: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  background-image: url(${plusIcon});
`;
