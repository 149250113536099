import { Layout } from "antd";
import styled from "@emotion/styled";
import { Title } from "../../../Typography";
import { Button } from "../../../Button";
import novableLogoBlack from "../../../../assets/logos/novable-new-black-large.png";

export const Header = styled(Layout.Header)`
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color-primary);
`;

export const SectionTitle = styled(Title)`
  padding: 0;
  margin: 0;
  color: var(--font-color-black);
`;

export const NovableLogo = styled.div`
  height: 100%;
  width: 13.3rem;
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-left: var(--novable-logo-border);
`;

export const Logo = styled.div`
  width: 10.1rem;
  height: 2rem;
  background-image: url(${novableLogoBlack});
  background-size: contain; 
  background-repeat: no-repeat;
  background-position: center;
`;

export const TopbarButton = styled(Button)`
  margin-right: 2rem;
  margin-left: auto;
`;
