import styled from "@emotion/styled";
import { Card as BaseCard } from "antd";

import { Paragraph } from "./Typography";
import locationIcon from "../assets/icons/location.svg";
import teamIcon from "../assets/icons/team.svg";
import ArrowIcon from "../assets/icons/arrow.svg";
import websiteIcon from "../assets/icons/website.svg";
import externalLinkIcon from "../assets/icons/external-link.svg";

export const CompanyHeaderDetails = styled.div<{ length: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.length ? "column" : "row")};
  align-items: ${(props) => (props.length ? "flex-start" : "center")};
  justify-content: space-between;
  margin-left: 1.2rem;
  display: block;
`;

export const CompanyHeaderDetailsText = styled.div`
  min-width: 8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--font-color-campaign-stats);
`;

export const Card = styled(BaseCard)<{ isSelected?: boolean }>`
  padding: 1.2rem 1.2rem 0 1.2rem;
  margin: 0 1.2rem 1.2rem 1.2rem;
  cursor: pointer;
  border-color: ${(props) => (props.isSelected ? `var(--secondary-color)` : "")};
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 4rem;
  height: 4rem;
`;

export const CompanyTitle = styled(Paragraph)`
  line-height: 1.6rem;
  padding: 0;
  margin: 0;
  margin-left: 1.2rem;
  font-weight: var(--font-weight-bold);
`;

export const CardHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  max-width: 80%;
  overflow: hidden;
  word-break: break-word;
`;

export const Location = styled.div`
  width: 1rem;
  height: 1.4rem;
  margin-right: 1rem;
  background-image: url(${locationIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

export const Team = styled.div`
  width: 1.3rem;
  height: 0.93rem;
  margin-right: 1rem;
  background-image: url(${teamIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

export const CardContent = styled.div`
  position: relative;
`;

export const ExpandCompanyCardDescriptionArrow = styled.button<{
  isExpanded: boolean;
}>`
  position: absolute;
  right: 0;
  top: 2rem;
  width: 0.9rem;
  height: 1.8rem;
  margin-top: 0.5rem;
  border: none;
  background: none;
  display: inline-block;
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  transform: ${(props) =>
    props.isExpanded ? "rotate(270deg)" : "rotate(90deg)"};
  cursor: pointer;
  outline: none;
  filter: grayscale(1);
`;

export const Description = styled.p<{ allDescription: boolean }>`
  padding: 2rem 0 0 0;
  margin: 0 1.5rem 2 0;
  hyphens: auto;
  text-overflow: ellipsis;
  display: ${(props) => (props.allDescription ? "block" : "-webkit-box")};
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: ${(props) => (props.allDescription ? "visible" : "hidden")};
`;

export const CardActions = styled.div`
  height: 4.4rem;
  margin-top: 1.6rem;
  border-top: var(--campaign-card-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Website = styled.div`
  background: url(${websiteIcon});
  width: 1.333rem;
  height: 1.333rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-right: 1rem;
`;

export const ExternalLinkIcon = styled.div`
  background: url(${externalLinkIcon});
  width: 1.8rem;
  height: 1.6rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-right: 1rem;
`;

export { Anchor } from "../ui-components";
