import { FC, useState } from "react";
import styled from "@emotion/styled";

import { ActivityIndex } from "./ActivityIndex";
import { EnrichedProfileStatusIcon } from "./EnrichedProfileStatusIcon";
import { ListWidget } from "../list-widget/ListWidget";
import { SocialMedia } from "../ui-share/SocialMedia";
import {
  Card as BaseCard,
  CardActions,
  CardContent,
  CardHeader,
  CardHeaderText,
  CompanyHeaderDetails,
  CompanyHeaderDetailsText,
  CompanyTitle,
  Description,
  ExpandCompanyCardDescriptionArrow,
  Location,
  Logo,
  Team,
} from "../../../../ui-components/CompanyCardStyles";
import { ListIconBlue } from "../../../../ui-components";
import starBlueIcon from "../../../../assets/icons/star-blue.svg";
import defaultLogo from "../../../../assets/defaults/default-logo.png";

import { BaseCompanyMinified } from "../../store";
import { ListCompanySource } from "../../../../list/store";
import { State } from "../../../../utils/store";
import { useAppSelector } from "../../../../utils/hooks";

import { checkCompanyDetailsLength, getLocation } from "../../../../helpers";

interface CompanyCardProps {
  companyMinified: BaseCompanyMinified;
  onClick: (uuid: string) => void;
  handleFavourite: (e: any, companyUUID: string) => void;
}

export const CompanyCard: FC<CompanyCardProps> = ({
  companyMinified,
  onClick,
  handleFavourite,
}) => {
  const [allDescription, showAllDescription] = useState(false);
  const { fetchedCompany } = useAppSelector((state: State) => state.company);

  const company = companyMinified.company;
  const location = getLocation(company.city, company.country);

  const isSelected = fetchedCompany?.uuid === company.uuid;

  const handleShowAllDescription = (e: any) => {
    e.stopPropagation();
    showAllDescription((prevState) => !prevState);
  };

  return (
    <Card onClick={() => onClick(company.uuid)} isSelected={isSelected}>
      <CardHeader>
        <Logo
          src={company.logo || defaultLogo}
          alt="Snapshot of the Company's website"
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = defaultLogo;
          }}
        />

        <CardHeaderText>
          <CompanyTitle>{company.name}</CompanyTitle>
          <CompanyHeaderDetails
            length={checkCompanyDetailsLength(
              location,
              company.employeesRangeStart,
              company.employeesRangeEnd
            )}
          >
            {location ? (
              <CompanyHeaderDetailsText>
                <Location /> {location}
              </CompanyHeaderDetailsText>
            ) : null}
            {company.employeesRangeStart && company.employeesRangeEnd ? (
              <CompanyHeaderDetailsText>
                {" "}
                <Team />{" "}
                {`${company.employeesRangeStart} - ${company.employeesRangeEnd}`}
              </CompanyHeaderDetailsText>
            ) : null}
          </CompanyHeaderDetails>
        </CardHeaderText>
        {/* {company.activityIndex && (
          <ActivityIndex
            activityIndex={company?.activityIndex?.activityIndex}
          />
        )} */}
      </CardHeader>
      <CardContent>
        <Description allDescription={allDescription}>
          {company.shortDescription}
        </Description>
        {company.shortDescription && company.shortDescription.length > 70 ? (
          <ExpandCompanyCardDescriptionArrow
            isExpanded={allDescription}
            onClick={(e) => handleShowAllDescription(e)}
          />
        ) : null}
      </CardContent>
      <CardActions>
        <SocialMedia company={company} isCompanyDetail={false} />
        <EngagementContainer>
          <EnrichedProfileStatusIcon
            enrichedStatus={companyMinified.enrichedStatus}
          />
          <ListWidget
            companyLists={company.lists}
            companyUUID={company.uuid}
            companyName={company.name}
            listCompanySource={ListCompanySource.RTS}
          >
            <IconContainer
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ListIconBlue />
            </IconContainer>
          </ListWidget>
          <IconContainer onClick={(e) => handleFavourite(e, company.uuid)}>
            <Star isFavourite={company.favourite} />
          </IconContainer>
        </EngagementContainer>
      </CardActions>
    </Card>
  );
};

const Card = styled(BaseCard)`
  // Normally would be 1.6rem, but the sidebar to the right
  // makes it be displaced even more than 1.6
  margin-right: 0.35rem;
`;

const EngagementContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const IconContainer = styled.div`
  width: 2.5rem;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const Star = styled.div<{ isFavourite: boolean }>`
  background: url(${starBlueIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1.333rem;
  height: 1.267rem;
  filter: ${(props) => (props.isFavourite ? "grayscale(0)" : "grayscale(1)")};
`;
