import { FC, useState } from "react";

import ReactTooltip from "react-tooltip";
import { MapChart } from "./MapChart";

import { Dictionary } from "../../../share/types";
import { countries } from "../../../utils/countries";
import styled from "@emotion/styled";
import { MultiColorBar } from "./MultiColorBar";
import { EmptyChartResult } from "../EmptyChartResult";

interface MapChartWithCountryListProps {
  data: Dictionary<number>;
}

const NULL_LABEL = "null";
export const MapChartWithCountryList: FC<MapChartWithCountryListProps> = ({
  data,
}) => {
  const [tooltipContent, setTooltipContent] = useState("");

  let countriesData = { ...data };
  if (NULL_LABEL in countriesData) delete countriesData[NULL_LABEL];
  let countriesInData = Object.keys(countriesData);

  if (countriesInData.filter((label) => label !== NULL_LABEL).length < 1)
    return <EmptyChartResult />;

  let countriesListData = [];
  // countries.json is a json with countries data, grouped by continent
  // Continents have to be iterated and then mapped to find the name of
  // the countries that we are looking for
  for (let continent of countries) {
    let countriesFound = continent.children.filter((country) =>
      countriesInData.includes(country.value)
    );
    let countriesInContinentData = countriesFound.map((country) => {
      return {
        country: country.title,
        value: countriesData[country.value],
      };
    });
    countriesListData.push(...countriesInContinentData);
  }

  // Sort in decreasing order of the countries value
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
  countriesListData.sort((countryA, countryB) => {
    if (countryA.value > countryB.value) return -1;
    else if (countryA.value < countryB.value) return 1;
    return 0;
  });

  return (
    <Container>
      <ReactTooltip>{tooltipContent}</ReactTooltip>
      <MapChart data={countriesData} setTooltipContent={setTooltipContent} />
      <MultiColorBar data={countriesData} />
    </Container>
  );
};

const Container = styled.div`
  margin-top: -2rem;
  width: 100%;
`;
