import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";

import { authSlice, authSaga, AuthState } from "../auth/store";
import {
  editCampaignSlice,
  EditCampaignState,
  editCampaignSaga,
} from "../campaign/edit-campaign/store";
import {
  goldenBasketSaga,
  goldenBasketSlice,
  GoldenBasketState,
} from "../campaign/golden-basket/store";
import {
  campaignInsightsSaga,
  campaignInsightsSlice,
  CampaignInsightsState,
} from "../campaign/insights/store";
import { clientsSlice, ClientsState, clientsSaga } from "../clients/store";
import {
  companiesSearchSlice,
  CompaniesSearchState,
  companiesSearchSaga,
} from "../companies-search/store";
import {
  editProfileSlice,
  EditProfileState,
  editProfileSaga,
} from "../edit-profile/store";
import {
  favouriteSlice,
  FavouriteState,
  favouriteSaga,
} from "../favourite-page/store";
import { listsSlice, ListsState, listsSaga } from "../list/store";
import {
  newCampaignSlice,
  NewCampaignState,
  newCampaignSaga,
} from "../new-campaign/store";
import {
  campaignsSaga,
  campaignsSlice,
  CampaignsState,
} from "../share/campaigns/store";
import {
  companySaga,
  companySlice,
  CompanyState,
} from "../share/company/store";
import { layoutSaga, layoutSlice, LayoutState } from "../share/layout/store";
import { tagsSaga, tagsSlice, TagsState } from "../tags/store";
import {
  userManagementSlice,
  UserManagementState,
  userManagementSaga,
} from "../user-management/store";
import {
  scottThreadsSlice,
  scottThreadSaga,
  ScottThreadState,
} from "../scott/store";

export interface State {
  auth: AuthState;
  campaigns: CampaignsState;
  campaignInsights: CampaignInsightsState;
  clients: ClientsState;
  companiesSearch: CompaniesSearchState;
  company: CompanyState;
  editCampaign: EditCampaignState;
  editProfile: EditProfileState;
  favouritePage: FavouriteState;
  goldenBasket: GoldenBasketState;
  lists: ListsState;
  layout: LayoutState;
  newCampaign: NewCampaignState;
  tags: TagsState;
  userManagement: UserManagementState;
  scottThreadList: ScottThreadState;
}

const production = "PRODUCTION";
const demo = "DEMO";
const env = process.env.REACT_APP_ENV;
const shouldDisplayDevTools = !(env === production || env === demo);

// Exported for utils/testUtils, to reset the store at each test
export function initStore() {
  const rootReducer = combineReducers<State>({
    auth: authSlice.reducer,
    campaigns: campaignsSlice.reducer,
    campaignInsights: campaignInsightsSlice.reducer,
    clients: clientsSlice.reducer,
    companiesSearch: companiesSearchSlice.reducer,
    company: companySlice.reducer,
    editCampaign: editCampaignSlice.reducer,
    editProfile: editProfileSlice.reducer,
    favouritePage: favouriteSlice.reducer,
    goldenBasket: goldenBasketSlice.reducer,
    layout: layoutSlice.reducer,
    lists: listsSlice.reducer,
    newCampaign: newCampaignSlice.reducer,
    tags: tagsSlice.reducer,
    userManagement: userManagementSlice.reducer,
    scottThreadList: scottThreadsSlice.reducer
  });
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
    devTools: shouldDisplayDevTools,
  });

  sagaMiddleware.run(authSaga);
  sagaMiddleware.run(campaignsSaga);
  sagaMiddleware.run(campaignInsightsSaga);
  sagaMiddleware.run(clientsSaga);
  sagaMiddleware.run(companiesSearchSaga);
  sagaMiddleware.run(companySaga);
  sagaMiddleware.run(editCampaignSaga);
  sagaMiddleware.run(editProfileSaga);
  sagaMiddleware.run(favouriteSaga);
  sagaMiddleware.run(goldenBasketSaga);
  sagaMiddleware.run(layoutSaga);
  sagaMiddleware.run(listsSaga);
  sagaMiddleware.run(newCampaignSaga);
  sagaMiddleware.run(tagsSaga);
  sagaMiddleware.run(userManagementSaga);
  sagaMiddleware.run(scottThreadSaga);

  return store;
}

export const store = initStore();
export type AppDispatch = typeof store.dispatch;
