import { useState, FC } from "react";
import { Input, Radio, RadioChangeEvent } from "antd";
import styled from "@emotion/styled";

import { State } from "../../../../../utils/store";
import {
  CardHeadTitle,
  ButtonText,
  SmallParagraph,
} from "../../../../../ui-components/Typography";
import {
  DecissionButtonsContainer,
  CloseIcon,
  DecissionButton,
} from "../../ui-share";

import {
  submitFeedback,
  filteredGBCompanySelector,
  FEEDBACK_COMMENT_MAX_NUMBER_CHARACTERS,
} from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import { AddToListDecissionButton } from "./AddToListDecissionButton";
import { ListCompanySource } from "../../../../../list/store";

const { TextArea } = Input;

const radioStyle = {
  display: "block",
  height: "auto",
};

const OPTIONS = [
  "Not in my briefing scope",
  "Not in the business model I am looking for",
  "Not the right Company criteria",
  "Other (please specify)",
];

interface MissPopoverProps {
  hidePopover: () => void;
  campaignUUID: string;
}

export const MissPopover: FC<MissPopoverProps> = ({
  hidePopover,
  campaignUUID,
}) => {
  const dispatch = useAppDispatch();
  const [comment, setComment] = useState("");
  const [selectedOption, setSelectedOption] = useState(0);
  const { loading } = useAppSelector(
    (state: State) => state.goldenBasket.submitFeedback
  );

  const GBCompany = useAppSelector(filteredGBCompanySelector);

  let company;
  if (GBCompany) company = GBCompany.company;

  function handleSetComment(e: any) {
    setComment(e.target.value);
  }

  function handleSelectOption(e: RadioChangeEvent) {
    setSelectedOption(e.target.value);
  }

  function submitNegativeFeedback() {
    if (GBCompany && campaignUUID) {
      const feedbackData = {
        isMatch: false,
        companyUUID: GBCompany.uuid,
        campaignUUID: campaignUUID,
        comment: comment,
        type: selectedOption || 0,
      };
      dispatch(submitFeedback(feedbackData));
    }
  }

  return (
    <PopoverContent style={{ position: "relative" }}>
      <Close onClick={hidePopover}>
        {" "}
        <CloseIcon />{" "}
      </Close>
      <PopperTitle>
        <CardHeadTitle>Seems like you did not like the result</CardHeadTitle>
      </PopperTitle>
      <SmallParagraph style={{ padding: "0 1rem" }}>
        Could you tell us the reason for the missmatch?
      </SmallParagraph>
      <OptionsContainer>
        <RadioGroup onChange={handleSelectOption} value={selectedOption}>
          {OPTIONS.map((option, index) => {
            return (
              <RadioButton style={radioStyle} value={index} key={index}>
                <ButtonText>{option}</ButtonText>
              </RadioButton>
            );
          })}
        </RadioGroup>
      </OptionsContainer>
      <CommentContainer>
        <SmallParagraph>
          Would you like to add a comment to your review? It's not mandatory
        </SmallParagraph>

        <TextArea
          showCount
          maxLength={FEEDBACK_COMMENT_MAX_NUMBER_CHARACTERS}
          id="outlined-multiline-static"
          rows={3}
          style={{ resize: "none" }}
          value={comment}
          onChange={handleSetComment}
          placeholder="Start typing here..."
        />
      </CommentContainer>

      <DecissionButtonsContainer>
        {company && (
          <AddToListDecissionButton
            companyName={company.name}
            companyLists={company.lists}
            companyUUID={company.uuid}
            listCompanySource={ListCompanySource.MISS}
          />
        )}
        <DecissionButton disabled={loading} onClick={submitNegativeFeedback}>
          {" "}
          Confirm
        </DecissionButton>
      </DecissionButtonsContainer>
    </PopoverContent>
  );
};

const OptionsContainer = styled.div`
  width: 100%;
  padding: 1rem;
`;

const Close = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
  padding: 0.5rem;
  cursor: pointer;
`;

const PopoverContent = styled.div`
  width: 40rem;
`;

const PopperTitle = styled.div`
  width: 100%;
  padding: 1rem;
`;

const CommentContainer = styled.div`
  padding: 1rem;
`;

const RadioGroup = styled(Radio.Group)`
  width: 100%;
  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper-checked {
    background-color: var(--white);
    margin-bottom: 0.8rem;
    border-radius: var(--border-radius);
  }
`;

const RadioButton = styled(Radio.Button)`
  background-color: var(--white);
`;
