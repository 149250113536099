import { Result } from "antd";

export const CampaignSubmitted = () => {
  // Previous screen ends up at the bottom of the page, we need to scroll to show the Result
  // on top of everything
  window.scrollTo({ top: 0 });

  return (
    <div>
      <Result
        status="success"
        title="Your Campaign has been successfully published"
        subTitle="We’re on it, you will receive news from us very soon."
      />
      <div>
        <h2 style={{ margin: "auto", width: "80%", textAlign: "center" }}>
          Contact your Novable agent if you&apos;d like to discuss the results.
        </h2>
      </div>
    </div>
  );
};
