import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { InfoCircleOutlined } from "@ant-design/icons";
import { EnrichedCompanyProfileBusinessSection } from "./enriched-company-profile/EnrichedCompanyProfileBusinessSection";
import { EnrichedCompanyProfilePanelHeader } from "./enriched-company-profile/EnrichedCompanyProfilePanelHeader";
import { EnrichedCompanyProfilePatentSection } from "./enriched-company-profile/EnrichedCompanyProfilePatentSection";
import { EnrichedCompanyProfilePeopleSection } from "./enriched-company-profile/EnrichedCompanyProfilePeopleSection";
import { EnrichedCompanyProfileRequestModal } from "./enriched-company-profile/EnrichedCompanyProfileRequestModal";
import { SectionContent } from "../../ui-share";
import { Button } from "../../../../../ui-components/Button";
import {
  errorNotification,
  successNotification,
} from "../../../../../ui-components/Notification";
import {
  upgradeYourPlan,
  UpgradeYourPlanTypes,
} from "../../../../../ui-components/UpgradeYourPlan";

import {
  CompanyDetail,
  EnrichedProfileStatus,
  setRequestEnrichedCompanyProfileInitialState,
  setEnrichedStatusToPending,
} from "../../../store";
import { State } from "../../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";

import { Segment } from "../../../../../utils/Segment";
import { isClientPremium } from "../../../../../helpers";

interface EnrichedCompanyProfileProps {
  company: CompanyDetail;
  campaignUUID: string | null;
}

const OPEN_ENRICHED_PROFILE_REQUEST_EVENT_NAME =
  "open-enriched-profile-request";
const FREEMIUM_ENRICHED_PROFILE_REQUEST_EVENT_NAME =
  "freemium-enriched-profile-request";
const OUT_OF_ENRICHED_PROFILE_REQUESTS_EVENT_NAME =
  "out-of-enriched-profile-requests";
const SHOW_ENRICHED_PROFILE_SECTION_EVENT_NAME =
  "show-enriched-profile-section";
const HIDE_ENRICHED_PROFILE_SECTION_EVENT_NAME =
  "hide-enriched-profile-section";

export const EnrichedCompanyProfile: FC<EnrichedCompanyProfileProps> = ({
  company,
  campaignUUID,
}) => {
  const dispatch = useAppDispatch();

  const [enrichedProfileHasBeenRequested, setEnrichedProfileHasBeenRequested] =
    useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showContent, setShowContent] = useState(true);

  const { error, success } = useAppSelector(
    (state: State) => state.company.requestEnrichedCompanyProfile
  );
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { user } = useAppSelector((state: State) => state.auth);

  const requestRequested =
    company.enrichedProfile.status === EnrichedProfileStatus.PENDING ||
    company.enrichedProfile.status === EnrichedProfileStatus.APPROVED ||
    enrichedProfileHasBeenRequested;

  const clientCanRequestEnrichedProfile =
    selectedClient && selectedClient?.enrichedCompanyProfileRequests > 0;

  const buttonText = requestRequested ? (
    <span>
      The request <br /> has been sent
    </span>
  ) : (
    <span>
      Request an <br /> Enriched Profile
    </span>
  );

  const requestText = requestRequested
    ? "The Enriched Profile has been requested. You will be notified when the data is available"
    : `The Enriched Profile for ${company.name} not been requested yet.`;

  const titleText = requestRequested
    ? "Enriched Profile"
    : "Want more data?";

  const enrichedProfileContent = company.enrichedProfile.content;

  // An ECP has content if it has any array with content, any number (including 0s),
  // or values that are not null
  const enrichedProfileHasContent =
    enrichedProfileContent &&
    Object.values(enrichedProfileContent).filter((x: any) => {
      if (Array.isArray(x)) {
        if (x.length > 0) return x;
        else return false;
      } else return Number.isInteger(x) || Boolean(x);
    }).length > 0;

  // For freemium users, they will get the "update your plan" popup.
  // For premium users, we show the modal with the request information and the confirm button
  const handleOnClick = () => {
    if (!selectedClient) return;
    if (!isClientPremium(selectedClient)) {
      upgradeYourPlan(
        "Upgrade your plan to request Enriched Profiles",
        UpgradeYourPlanTypes.Sales,
        { source: "request-enriched-profile", client: selectedClient.uuid }
      );
      Segment.analytics?.track(FREEMIUM_ENRICHED_PROFILE_REQUEST_EVENT_NAME, {
        clientUUID: selectedClient.uuid,
        clientIsPremium: false,
        companyUUID: company.uuid,
        campaignUUID: campaignUUID,
        user: user?.uuid,
      });
      return;
    } else if (!clientCanRequestEnrichedProfile) {
      upgradeYourPlan(
        "Oops. You have reached your limit of Enriched Profiles requests",
        UpgradeYourPlanTypes.Sales,
        { source: "request-enriched-profile", client: selectedClient.uuid }
      );
      Segment.analytics?.track(OUT_OF_ENRICHED_PROFILE_REQUESTS_EVENT_NAME, {
        clientUUID: selectedClient.uuid,
        clientIsPremium: true,
        companyUUID: company.uuid,
        campaignUUID: campaignUUID,
        user: user?.uuid,
      });
      return;
    }

    Segment.analytics?.track(OPEN_ENRICHED_PROFILE_REQUEST_EVENT_NAME, {
      clientUUID: selectedClient.uuid,
      clientIsPremium: true,
      companyUUID: company.uuid,
      campaignUUID: campaignUUID,
      user: user?.uuid,
    });
    setModalVisible(true);
  };

  const toggleModelVisibility = () => {
    setModalVisible((prevState) => !prevState);
  };

  const toggleShowContent = () => {
    const eventName = showContent
      ? HIDE_ENRICHED_PROFILE_SECTION_EVENT_NAME
      : SHOW_ENRICHED_PROFILE_SECTION_EVENT_NAME;
    setShowContent(!showContent);

    if (!selectedClient) return;
    Segment.analytics?.track(eventName, {
      clientUUID: selectedClient.uuid,
      clientIsPremium: isClientPremium(selectedClient),
      companyUUID: company.uuid,
      campaignUUID: campaignUUID,
      user: user?.uuid,
    });
  };

  useEffect(() => {
    if (success) {
      successNotification("Request submitted successfully");
      setEnrichedProfileHasBeenRequested(true);
      dispatch(setEnrichedStatusToPending({ uuid: company.uuid }));
      setModalVisible(false);
    } else if (error) errorNotification(error);

    return () => {
      dispatch(setRequestEnrichedCompanyProfileInitialState());
    };
  }, [success, error, dispatch, company]);

  return (
    <EnrichedProfileContainer>
      <EnrichedCompanyProfilePanelHeader
        showContent={showContent}
        titleText={titleText}
        onclick={toggleShowContent}
      />
      {showContent && (
        <RequestContainer>
          {company.enrichedProfile.status === EnrichedProfileStatus.APPROVED &&
            enrichedProfileHasContent && (
              <div>
                <EnrichedCompanyProfilePatentSection
                  enrichedProfileContent={enrichedProfileContent}
                />
                <EnrichedCompanyProfileBusinessSection
                  enrichedProfileContent={enrichedProfileContent}
                />
                <EnrichedCompanyProfilePeopleSection
                  enrichedProfileContent={enrichedProfileContent}
                />
              </div>
            )}
          {company.enrichedProfile.status === EnrichedProfileStatus.APPROVED &&
            !enrichedProfileHasContent && (
              <SectionContent>
                We could not gather any information about this Company at the
                moment. This request will not incur any cost. Please reach out
                to your agent to understand more.
              </SectionContent>
            )}
          {(company.enrichedProfile.status === EnrichedProfileStatus.PENDING ||
            company.enrichedProfile.status ===
              EnrichedProfileStatus.NOT_REQUESTED) && (
            <NonContentContainer>
              <InfoIcon style={{ fontSize: "2.5rem" }} />
              <SectionContent>{requestText}</SectionContent>
              <Button
                disabled={requestRequested}
                onClick={handleOnClick}
                style={{ maxWidth: "15rem", height: "auto" }}
              >
                {buttonText}
              </Button>
              <EnrichedCompanyProfileRequestModal
                modalVisible={modalVisible}
                toggleModalVisibility={toggleModelVisibility}
                companyName={company.name}
                companyUUID={company.uuid}
                campaignUUID={campaignUUID}
              />
            </NonContentContainer>
          )}
        </RequestContainer>
      )}
    </EnrichedProfileContainer>
  );
};

const EnrichedProfileContainer = styled.div`
  padding: 1rem;
`;

const RequestContainer = styled.div`
  display: flex;
  padding: 0.8rem;
`;

const NonContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const InfoIcon = styled(InfoCircleOutlined)`
  color: var(--font-color-grey-icon);
`;
