import styled from "@emotion/styled";
import { Button as BaseButton } from "antd";

export const Button = styled(BaseButton)`
  border: none;
  color: var(--font-color-white);

  &:hover {
    border: none;
    color: var(--font-color-white);
    background-color: var(--secondary-color);
  }

  &:active:hover {
    border: none;
    color: var(--font-color-white);
  }

  &:focus {
    color: var(--font-color-white);
  }

  &:disabled,
  &:disabled:hover {
    border: none;
    color: var(--font-color-white);
    background-color: var(--secondary-color);
  }
`;
