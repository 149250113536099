// Due to how ant design charts and react work, if someone opens
// the insights page first,the icons (and certain other components)
// won't render properly.
// One solution then is to copy directly
export const locationIconSVG = `
<svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.99984 0.333374C8.2065 0.333374 9.99984 2.10671 9.99984 4.30004C9.99984 7.27337 5.99984 11.6667 5.99984 11.6667C5.99984 11.6667 1.99984 7.27337 1.99984 4.30004C1.99984 2.10671 3.79317 0.333374 5.99984 0.333374ZM5.99984 3.00004C5.64621 3.00004 5.30708 3.14052 5.05703 3.39056C4.80698 3.64061 4.6665 3.97975 4.6665 4.33337C4.6665 4.687 4.80698 5.02613 5.05703 5.27618C5.30708 5.52623 5.64621 5.66671 5.99984 5.66671C6.35346 5.66671 6.6926 5.52623 6.94265 5.27618C7.19269 5.02613 7.33317 4.687 7.33317 4.33337C7.33317 3.97975 7.19269 3.64061 6.94265 3.39056C6.6926 3.14052 6.35346 3.00004 5.99984 3.00004ZM11.3332 11.6667C11.3332 13.14 8.9465 14.3334 5.99984 14.3334C3.05317 14.3334 0.666504 13.14 0.666504 11.6667C0.666504 10.8067 1.47984 10.04 2.73984 9.55337L3.1665 10.16C2.4465 10.46 1.99984 10.8734 1.99984 11.3334C1.99984 12.2534 3.79317 13 5.99984 13C8.2065 13 9.99984 12.2534 9.99984 11.3334C9.99984 10.8734 9.55317 10.46 8.83317 10.16L9.25984 9.55337C10.5198 10.04 11.3332 10.8067 11.3332 11.6667Z" fill="#A5AEB5"/>
</svg>
`;

export const teamIconSVG = `
<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0002 5.00004C10.219 5.00004 10.4358 4.95693 10.638 4.87317C10.8402 4.78942 11.0239 4.66665 11.1787 4.51189C11.3334 4.35712 11.4562 4.17339 11.54 3.97118C11.6237 3.76897 11.6668 3.55224 11.6668 3.33337C11.6668 2.89135 11.4912 2.46742 11.1787 2.15486C10.8661 1.8423 10.4422 1.66671 10.0002 1.66671C9.55814 1.66671 9.13421 1.8423 8.82165 2.15486C8.50909 2.46742 8.3335 2.89135 8.3335 3.33337C8.3335 3.55224 8.37661 3.76897 8.46036 3.97118C8.54412 4.17339 8.66689 4.35712 8.82165 4.51189C9.13421 4.82445 9.55814 5.00004 10.0002 5.00004ZM5.00016 4.33337C5.5306 4.33337 6.0393 4.12266 6.41438 3.74759C6.78945 3.37252 7.00016 2.86381 7.00016 2.33337C7.00016 1.80294 6.78945 1.29423 6.41438 0.919161C6.0393 0.544088 5.5306 0.333374 5.00016 0.333374C4.46973 0.333374 3.96102 0.544088 3.58595 0.919161C3.21088 1.29423 3.00016 1.80294 3.00016 2.33337C3.00016 2.86381 3.21088 3.37252 3.58595 3.74759C3.96102 4.12266 4.46973 4.33337 5.00016 4.33337ZM10.0002 6.33337C8.78016 6.33337 6.3335 6.94671 6.3335 8.16671V9.66671H13.6668V8.16671C13.6668 6.94671 11.2202 6.33337 10.0002 6.33337ZM5.00016 5.66671C3.44683 5.66671 0.333496 6.44671 0.333496 8.00004V9.66671H5.00016V8.16671C5.00016 7.60004 5.22016 6.60671 6.58016 5.85337C6.00016 5.73337 5.44016 5.66671 5.00016 5.66671Z" fill="#A5AEB5"/>
</svg>
`;
