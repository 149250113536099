import * as Yup from "yup";

import { CLIENT_COMMENT_MAX_LENGTH } from "../../../../../share/constants";

export const MaxCommentLengthSchema = Yup.object().shape({
  comment: Yup.string()
    .max(
      CLIENT_COMMENT_MAX_LENGTH,
      `Comment cannot be longer than ${CLIENT_COMMENT_MAX_LENGTH} characters`
    )
    .nullable(),
});
