import { FC } from "react";
import styled from "@emotion/styled";
import { Button as BaseButton } from "antd";
import { FormikInput } from "../../../ui-components/FormikInput";
import deleteInputIcon from "../../../assets/icons/delete-input.svg";
import { StyledButtonText } from "../../../ui-components/Typography";
import { StylelessButton } from "../../../ui-components/StylelessButton";

interface ExamplesOfCompaniesRenderProps {
  values: { [field: string]: any };
  arrayHelpers: any;
  disabled?: boolean;
}

export const ExamplesOfCompaniesRender: FC<ExamplesOfCompaniesRenderProps> = ({
  values,
  arrayHelpers,
  disabled,
}) => {
  return (
    <div>
      {values.lookalikes.map((company: string, index: number) => (
        <InputContainer key={index}>
          <FormikInput
            name={`lookalikes[${index}]`}
            type="text"
            ariaLabel="lookalikes url"
            placeholder="https://novable.com/"
            disabled={disabled || false}
          />
          {!disabled && (
            <DeleteInput
              type="button"
              onClick={() => arrayHelpers.remove(index)}
            />
          )}
        </InputContainer>
      ))}
      {<StylelessButton
            htmlType="button"
            type="dashed"
            onClick={() => arrayHelpers.push("")}>
          <StyledButtonText>+ Add Company URL</StyledButtonText>
      </StylelessButton>}
    </div>
  );
};

const DeleteInput = styled.button`
  width: 1.333rem;
  height: 1.333rem;
  border: none;
  background-color: none;
  background: url(${deleteInputIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 0 0 0.25rem 1rem;
  outline: none;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .ant-form-item {
    width: 100%;
  }
`;
