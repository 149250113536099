import { FC } from "react";
import styled from "@emotion/styled";

import { ListCompanyDetailHeader } from "./ListCompanyDetailHeader";
import { ListCompanyDetailBody } from "./ListCompanyDetailBody";

import { CompanyDetail } from "../../../share/company/store";

interface ListCompanyDetailProps {
  company: CompanyDetail;
}

export const ListCompanyDetail: FC<ListCompanyDetailProps> = ({ company }) => {
  return (
    <Container>
      <ListCompanyDetailHeader company={company} />
      <ListCompanyDetailBody company={company} />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 5rem 5rem 0.5rem 5rem;
`;
