import { FC } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { Menu as BaseMenu, Divider, Tag } from "antd";
import {
  InfoCircleOutlined,
  LogoutOutlined,
  RetweetOutlined,
  TagsOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { StylelessButton } from "../../StylelessButton";

import { logout } from "../../../auth/store";
import {
  setInitialSelectedClientState,
  UserRole,
} from "../../../clients/store";
import {
  Filter,
  setInitialState as setInitialCampaignsState,
} from "../../../campaign/golden-basket/store";
import { setInitialState as setInitialListsState } from "../../../list/store";
import { useAppDispatch } from "../../../utils/hooks";

const { Item } = BaseMenu;

interface SidebarHeaderDropDownProps {
  hasMultipleClients: boolean | null | undefined;
  userEmail: string | null | undefined;
  userRole: string | null | undefined;
  userIsStaff: boolean | undefined;
  selectedClientSlug: string | undefined;
  handleOnVisibleChange: () => void;
}

export const SidebarHeaderDropDown: FC<SidebarHeaderDropDownProps> = ({
  hasMultipleClients,
  userEmail,
  userRole,
  userIsStaff,
  selectedClientSlug,
  handleOnVisibleChange,
}) => {
  // Important to not have a collapsed menu, in case the Sidebar has been collapsed.
  // This is done to avoid the propagation of style from the collapse event
  // Into all the menus within the sidebar
  const nonCollapseMenu = document.getElementById("non-collapsed-menu");
  nonCollapseMenu &&
    nonCollapseMenu.classList.remove("ant-menu-inline-collapsed");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleChangeClient = () => {
    dispatch(setInitialSelectedClientState());
    dispatch(setInitialCampaignsState(Filter.TOREVIEW));
    dispatch(setInitialListsState());
    navigate("/clients");
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Container>
      <InfoContainer>
        {userEmail && <UserEmailTitle>{userEmail}</UserEmailTitle>}
        {userRole && (
          <Tag color={userRole === UserRole.ADMIN ? "blue" : "green"}>
            {userRole}
          </Tag>
        )}
      </InfoContainer>
      <Divider
        style={{
          backgroundColor: "var(--separator-grey)",
          margin: "1.8rem 0 1rem 0",
        }}
      />
      <Menu theme="light" selectedKeys={[pathname]} id="non-collapsed-menu">
        <Item key={`/${selectedClientSlug}/profile`} icon={<UserOutlined />}>
          <NavLink
            to={`/${selectedClientSlug}/profile`}
            onClick={handleOnVisibleChange}
          >
            {" "}
            <Span> My profile</Span>
          </NavLink>
        </Item>
        {userRole === UserRole.ADMIN && (
          <Item
            key={`/${selectedClientSlug}/account`}
            icon={<InfoCircleOutlined />}
          >
            <NavLink
              to={`/${selectedClientSlug}/account`}
              onClick={handleOnVisibleChange}
            >
              {" "}
              <Span> Account information</Span>
            </NavLink>
          </Item>
        )}
        <Item
          key={`/${selectedClientSlug}/list-management`}
          icon={<UsergroupAddOutlined />}
        >
          <NavLink
            to={`/${selectedClientSlug}/list-management`}
            onClick={handleOnVisibleChange}
          >
            <Span>List Management</Span>
          </NavLink>
        </Item>
        <Item
          key={`/${selectedClientSlug}/tag-management`}
          icon={<TagsOutlined />}
        >
          <NavLink
            to={`/${selectedClientSlug}/tag-management`}
            onClick={handleOnVisibleChange}
          >
            <Span>Tag Management</Span>
          </NavLink>
        </Item>
        {userRole && userRole === UserRole.ADMIN && (
          <Item
            key={`/${selectedClientSlug}/user-management`}
            icon={<UsergroupAddOutlined />}
          >
            <NavLink
              to={`/${selectedClientSlug}/user-management`}
              onClick={handleOnVisibleChange}
            >
              <Span>User Management</Span>
            </NavLink>
          </Item>
        )}
        <Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
          <Span>Logout</Span>
        </Item>
      </Menu>
      {userIsStaff && hasMultipleClients && (
        <GearButton onClick={handleChangeClient}>
          <RetweetOutlined />
        </GearButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  background-color: var(--dropdown-menu-dark-bg-color);
  color: var(--font-color-black);

  width: 22rem;
  border-radius: 1rem;
  border: var(--dropdown-container-border);
  box-shadow: var(--dropdown-container-box-shadow);

  margin-left: 0.5rem;
  justify-content: center;
  align-items: center;
  // flex-direction: row;
  padding: 1rem;
`;

const InfoContainer = styled.div`
  padding: 0 1rem;
  color: var(--font-color-black);
`;

const UserEmailTitle = styled.p`
  font-color: var(--font-color-black);
  font-style: bold;
  weight: var(--font-weight-bold);

  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Menu = styled(BaseMenu)`
  background-color: var(--dropdown-menu-dark-bg-color);
  color: var(--font-color-black);
  justify-content: space;
  border-right: 0;

  .ant-dropdown-menu-item {
    line-height: 4rem;
    margin: 0.4 0 0.8rem 0;
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-item > a {
    color: var(--font-color-black);

    &:after {
      color: @text-color-secondary-dark;
    }
    &:hover {
      
      background-color: var(--dropdown-menu-dark-active-color);
    }
  }
  .ant-dropdown-menu-item-selected {
      background-color: var(--dropdown-menu-dark-active-color) !important;
      color: var(--) !important;
    }
  }
`;

const Span = styled.span`
  color: var(--font-color-black);
`;

const GearButton = styled(StylelessButton)`
  cursor: pointer !important;
  color: var(--font-color-black);
  position: absolute;
  bottom: 10.5rem;
  right: 1rem;
  border-radius: 0.3rem;
`;
