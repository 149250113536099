import styled from "@emotion/styled";
import { useEffect } from "react";

import { NoResult } from "../ui-components/states";
import { CenteredSpinner } from "../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../ui-components/CenteredErrorMessage";
import { FavouriteCompanyDetail } from "./components/FavouriteCompanyDetail";
import { FavouriteCompanyList } from "./components/FavouriteCompanyList";

import { setInitialState } from "./store";
import {
  fetchCompany,
  setSelectedCompanyInitialState,
} from "../share/company/store";
import { State } from "../utils/store";
import { useAppDispatch, useAppSelector } from "../utils/hooks";
import { Segment } from "../utils/Segment";

export const FavouriteView = () => {
  const dispatch = useAppDispatch();
  const {
    loading: loadingSelectedCompany,
    error: errorFetchingSelectedCompany,
  } = useAppSelector((state: State) => state.company.fetchCompany);
  const { fetchedCompany } = useAppSelector((state: State) => state.company);
  const {
    success: favouriteCompanyListSuccess,
    loading: loadingFavouriteList,
  } = useAppSelector((state: State) => state.favouritePage.fetchFavouriteList);
  const unfavouriteCompanySuccess = useAppSelector(
    (state: State) => state.favouritePage.unfavouriteCompanySuccess
  );
  const { favouriteList, totalResults } = useAppSelector(
    (state: State) => state.favouritePage
  );
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  useEffect(() => {
    if (
      unfavouriteCompanySuccess &&
      favouriteList.length !== 0 &&
      !fetchedCompany &&
      selectedClient
    ) {
      const data = {
        clientUUID: selectedClient.uuid,
        companyUUID: favouriteList[0].companyUuid,
      };
      dispatch(fetchCompany(data));
      Segment.analytics?.track("open company detail", {
        source: "favourite-view",
        client: selectedClient.name,
      });
    }
  }, [
    dispatch,
    favouriteList,
    fetchedCompany,
    unfavouriteCompanySuccess,
    selectedClient,
  ]);

  useEffect(() => {
    if (
      favouriteCompanyListSuccess &&
      favouriteList.length !== 0 &&
      !loadingSelectedCompany &&
      !fetchedCompany &&
      selectedClient
    ) {
      const data = {
        clientUUID: selectedClient.uuid,
        companyUUID: favouriteList[0].companyUuid,
      };
      dispatch(fetchCompany(data));
      Segment.analytics?.track("open company detail", {
        source: "favourite-view",
        client: selectedClient.name,
      });
    }
  }, [
    favouriteCompanyListSuccess,
    favouriteList,
    loadingSelectedCompany,
    fetchedCompany,
    selectedClient,
    dispatch,
  ]);

  // Since golden basket view, list view and favourite view all use the
  // state.company.fetchedCompany variable, it's necessary to reset it after
  // this component unmounts to avoid unwanted effects
  useEffect(() => {
    return () => {
      dispatch(setInitialState());
      dispatch(setSelectedCompanyInitialState());
    };
  }, [dispatch]);

  const showLoader =
    (loadingFavouriteList && !fetchedCompany) ||
    (loadingSelectedCompany && !errorFetchingSelectedCompany);

  if (totalResults === 0) {
    return (
      <NoResult>
        Looks like you don't have any favourites yet. When you favourite a
        Company, you will see it here
      </NoResult>
    );
  }

  return (
    <Container>
      <FavouriteCompanyList />
      {/* Error FavouriteCompany flow */}
      {errorFetchingSelectedCompany && (
        <CenteredErrorMessage>
          {" "}
          There was an error loading the selected Company.{" "}
          {errorFetchingSelectedCompany}{" "}
        </CenteredErrorMessage>
      )}
      {/* Loading FavouriteCompany flow */}
      {!errorFetchingSelectedCompany && showLoader && (
        <CenteredSpinner size="large" />
      )}
      {/* FavouriteCompanyList fetched succesfully */}
      {!showLoader && fetchedCompany && (
        <FavouriteCompanyDetail company={fetchedCompany} />
      )}
    </Container>
  );
};

const Container = styled.div`
  height: calc(100vh - 8rem);
  display: grid;
  grid-template-columns: 32rem 1fr;
  grid-gap: 0rem;
`;
