import styled from "@emotion/styled";
import { FC } from "react";

import {
  CompanyDetailHeaderSubSection as SubSection,
  CompanyDetailHeaderSectionTitle as SectionTitle,
} from "../../ui-share";

import mailIcon from "../../../../../assets/icons/mail.svg";

export const Contact: FC<{ email: string }> = ({ email }) => {
  return (
    <SubSection>
      <MailIcon />
      <SectionTitle>{email}</SectionTitle>
    </SubSection>
  );
};

const MailIcon = styled.div`
  background: url(${mailIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1.4rem;
  height: 1rem;
  margin-right: 0.85rem;
`;
