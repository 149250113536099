import { Dictionary } from "../share/types";
import { normalizeData } from ".";

export const getWordCloudKeywordsNormalized = (
  textKeywords: Dictionary<number>
) => {
  if (!textKeywords) return [];

  const values = Object.values(textKeywords);
  const min = Math.min(...values);
  const max = Math.max(...values);

  return Object.keys(textKeywords).map(function (keyword: string) {
    return {
      keyword: keyword,
      // 0.001 is added here to display as well keywords with a very slow score
      weight: normalizeData(textKeywords[keyword], min, max) + 0.01,
    };
  });
};

export const getMapChartNormalizedData = (data: Dictionary<number>) => {
  const values = Object.values(data);
  const max = Math.max(...values);
  const min = Math.min(...values) === max ? 0 : Math.min(...values);

  let normalizedData: Dictionary<number> = {};

  // If we allow a value of 0, the countries with the lowest value
  // will have no color to show
  const lowerTargetScale = 0.2;
  const upperTargetScale = 1;
  for (const countryISO in data) {
    normalizedData[countryISO] = normalizeData(
      data[countryISO],
      min,
      max,
      lowerTargetScale,
      upperTargetScale
    );
  }
  return normalizedData;
};
