import { FC, ReactNode } from "react";
import { NoResultImage, GoldenBasketStatusWrapper } from ".";

interface NoResultProps {
  children?: ReactNode; // Explicitly define children prop
}

export const NoResult: FC<NoResultProps> = ({ children }) => {
  return (
    <GoldenBasketStatusWrapper>
      <NoResultImage />
      {children}
    </GoldenBasketStatusWrapper>
  );
};
