import { FC } from "react";
import styled from "@emotion/styled";

import { FollowButton } from "./detail-components/header/FollowButton";
import { ShareButton } from "./detail-components/header/ShareButton";
import { SocialMedia } from "../../../share/company/components/ui-share";
import {
  CompanyListManagementButton,
  CompanyTagManagementButton,
  Contact,
  EmployeesYearFoundedAndStatusSection,
  FavouriteButton,
  Header,
  HeaderInformation,
  Image,
  MetaData,
  Title,
  Section,
  SubSection,
} from "../../../share/company/components/company-detail/header";

import {
  CompanyHeaderDetailsText,
  Location,
} from "../../../ui-components/CompanyCardStyles";

import defaultCompanyLogo from "../../../assets/icons/default-company-logo.png";

import { setLogoStatus } from "../store";
import {
  GBCompanyDetail,
  favouriteGBCompany,
  filteredGBCompanySelector,
} from "../store";
import { ListCompanySource } from "../../../list/store";
import { TagCompanySource } from "../../../tags/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { getLocation } from "../../../helpers";

export const GoldenBasketDetailHeader: FC<{
  company: GBCompanyDetail;
}> = ({ company }) => {
  const location = getLocation(company.city, company.country);

  const dispatch = useAppDispatch();
  const GBCompany = useAppSelector(filteredGBCompanySelector);

  const setLogoLoadedState = () => {
    const status = { loading: false };
    dispatch(setLogoStatus(status));
  };

  const setLogoErrorState = () => {
    const status = { error: true };
    dispatch(setLogoStatus(status));
  };

  return (
    <Header>
      <HeaderInformation>
        <Image
          alt="Company Logo"
          src={company.logo || defaultCompanyLogo}
          onLoad={setLogoLoadedState}
          onError={(e) => {
            setLogoErrorState();
            e.currentTarget.onerror = null;
            e.currentTarget.src = defaultCompanyLogo;
          }}
        />
        <MetaData>
          <Title companyName={company.name} />
          <EmployeesYearFoundedAndStatusSection company={company} />
          <Section>
            <SubSection>
              <SocialMedia company={company} isCompanyDetail={true} />
            </SubSection>
            {company.email && <Contact email={company.email} />}
            <SubSection>
              {company.country && (
                <CompanyHeaderDetailsText>
                  <Location /> {location}
                </CompanyHeaderDetailsText>
              )}
            </SubSection>
          </Section>
        </MetaData>
      </HeaderInformation>
      {GBCompany && (
        // <HeaderActions>
        <HeaderButtons>
          <CompanyListManagementButton
            companyName={company.name}
            companyLists={company.lists}
            companyUUID={company.uuid}
            listCompanySource={ListCompanySource.CAMPAIGN}
          />
          <CompanyTagManagementButton
            companyTags={company.companyTags}
            companyName={company.name}
            companyUUID={company.uuid}
            tagCompanySource={TagCompanySource.CAMPAIGN}
          />
          <ShareButton GBCompanyUUID={GBCompany.uuid} />
          <FollowButton
            GBCompanyUUID={GBCompany?.uuid}
            isFollowed={GBCompany?.followed}
          />
          <FavouriteButton
            companyUUID={GBCompany.company.uuid}
            isFavourite={GBCompany.company.favourite}
            toggleFavouriteFunction={favouriteGBCompany}
            favouriteSource={"campaign"}
          />
        </HeaderButtons>
        // </HeaderActions>
      )}
    </Header>
  );
};

const HeaderButtons = styled.div`
  display: flex;
  gap: var(--gb-header-icon-margin);
  svg {
    width: var(--gb-header-icon-size);
    height: var(--gb-header-icon-size);
  }
`;
