import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "antd/dist/antd.less";
import styled from "@emotion/styled";

import { NonMobileAvailable } from "./ui-components/NonMobileAvailable";
import { CenteredSpinner } from "./ui-components/CenteredSpinner";

import { AppRoutes } from "./Router";
import { State } from "./utils/store";
import { useAppSelector } from "./utils/hooks";

import { useBreakpoint } from "./utils/styling";
import { Segment } from "./utils/Segment";

const SIGNUP_PATHNAME = "/signup";

function App() {
  let location = useLocation();

  const userLoading = useAppSelector(
    (state: State) => state.auth.loadUser.loading
  );

  const [isDesktop] = useBreakpoint(1000, { useBody: true });
  const isSignUpPage = location.pathname === SIGNUP_PATHNAME;

  useEffect(() => {
    Segment.analytics?.page(location.pathname);

    // Start every view at te top of the page
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (!isDesktop && !isSignUpPage) {
    return <NonMobileAvailable />;
  }

  if (userLoading) {
    return (
      <Container>
        <CenteredSpinner />;
      </Container>
    );
  }

  return <AppRoutes />;
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
`;

export default App;
