import { ResultForgotPassword } from "./components/result/ResultForgotPassword";
import { ForgotPasswordForm } from "./components/ForgotPasswordForm";

import { State } from "../utils/store";
import { useAppSelector } from "../utils/hooks";

export const ForgotPasswordView = () => {
  const { loading, success, message } = useAppSelector(
    (state: State) => state.auth.forgotPassword
  );

  return success ? (
    <ResultForgotPassword />
  ) : (
    <ForgotPasswordForm loading={loading} message={message} />
  );
};
