import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Button } from "antd";
import {
  Anchor,
  Card as BaseCard,
  CardActions,
  CardContent,
  CardHeader,
  CardHeaderText as BaseCardHeaderText,
  CompanyHeaderDetails,
  CompanyHeaderDetailsText,
  CompanyTitle,
  Description as BaseDescription,
  Location,
  Logo,
  Team,
  Website,
} from "../../ui-components/CompanyCardStyles";
import { StylelessButton } from "../../ui-components/StylelessButton";
import { ListWidget } from "../../share/company/components/list-widget/ListWidget";

import defaultLogo from "../../assets/defaults/default-logo.png";
import starBlueIcon from "../../assets/icons/star-blue.svg";

import { CompanyWithListsAndFavourite, updateFavouriteCount } from "../store";
import { ListCompanySource } from "../../list/store";
import { favouriteCompany } from "../../share/company/store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import {
  addUTMSource,
  getLocation,
  openLinkInNewTabAndTrack,
} from "../../helpers";
import { Segment } from "../../utils/Segment";

interface CompanyCardProps {
  company: CompanyWithListsAndFavourite;
  searchUUID: string;
  ranking: number;
}

/**
 * On company favourite, trigger the favourite request from state.company
 * On success from the favourite request, trigger the fav count update
 *
 */
export const CompanyCard: FC<CompanyCardProps> = ({
  company,
  searchUUID,
  ranking,
}) => {
  const dispatch = useAppDispatch();
  const [isFavourite, setIsFavourite] = useState(company.favourited);
  // To keep track of _which_ CompanyCard has made the request to favourite a company
  const [requestedFavouriteChange, setRequestedFavouriteChange] =
    useState(false);

  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { success: favouriteCompanySuccess, loading: loadingFavouriteCompany } =
    useAppSelector((state: State) => state.company.favouriteCompany);

  const location = getLocation(company.city, company.country);

  const eventProperties = {
    client: selectedClient?.uuid,
    searchUUID: searchUUID,
    companyRanking: ranking,
  };
  const companyURL = (company.url && addUTMSource(company.url)) || "";

  const handleToggleFavourite = () => {
    if (!selectedClient) return;
    setRequestedFavouriteChange(true);
    const data = {
      companyUUID: company.uuid,
      clientUUID: selectedClient.uuid,
    };
    dispatch(favouriteCompany(data));

    Segment.analytics?.track("favourite-company", {
      source: "real-time-search",
      uuid: company.uuid,
    });
  };

  // Listen to the success on favouriteCompany request, not
  // to the change in isFavourite, since the request may not
  // be successful
  useEffect(() => {
    if (favouriteCompanySuccess && requestedFavouriteChange) {
      const data = {
        uuid: searchUUID,
        favourite: !isFavourite,
      };
      dispatch(updateFavouriteCount(data));
      setIsFavourite(!isFavourite);
      // Reset status variable
      setRequestedFavouriteChange(false);
    }
  }, [dispatch, favouriteCompanySuccess, requestedFavouriteChange, searchUUID]); // eslint-disable-line

  return (
    <Card>
      <CardHeader>
        <Logo
          src={company.logo || defaultLogo}
          alt="Snapshot of the Company's website"
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = defaultLogo;
          }}
        />

        <CardHeaderText>
          <CompanyTitle>{company.name}</CompanyTitle>
          <CompanyHeaderDetails length={false}>
            {location ? (
              <CompanyHeaderDetailsText>
                <Location /> {location}
              </CompanyHeaderDetailsText>
            ) : null}
            {company.employeesRangeStart && company.employeesRangeEnd ? (
              <CompanyHeaderDetailsText>
                {" "}
                <Team />{" "}
                {`${company.employeesRangeStart} - ${company.employeesRangeEnd}`}
              </CompanyHeaderDetailsText>
            ) : null}
          </CompanyHeaderDetails>
        </CardHeaderText>
        <ListWidget
          companyLists={company.lists}
          companyUUID={company.uuid}
          companyName={company.name}
          listCompanySource={ListCompanySource.RTS}
        >
          <Button type="text">+</Button>
        </ListWidget>
        <StylelessButton
          onClick={handleToggleFavourite}
          disabled={loadingFavouriteCompany}
        >
          <Star isFavourite={isFavourite} />
        </StylelessButton>
      </CardHeader>
      <CardContent>
        <Description allDescription={false}>
          {company.shortDescription}
        </Description>
      </CardContent>
      <CardActions>
        <Website />
        <Anchor
          href={companyURL}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="website"
          onClick={(event) =>
            openLinkInNewTabAndTrack(
              event,
              companyURL,
              "rts-company-click",
              eventProperties
            )
          }
        >
          {(company.url && company.url) || "-"}
        </Anchor>
      </CardActions>
    </Card>
  );
};

const Card = styled(BaseCard)`
  cursor: default;
`;

const CardHeaderText = styled(BaseCardHeaderText)`
  height: 4.8em; // 3 lines
`;

const Description = styled(BaseDescription)`
  -webkit-line-clamp: 3;
  height: 4.725em; // line-height(1.575) * 3 lines
`;

const Star = styled.div<{ isFavourite: boolean }>`
  background: url(${starBlueIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1.333rem;
  height: 1.267rem;
  filter: ${(props) => (props.isFavourite ? "grayscale(0)" : "grayscale(1)")};
`;
