import { ResponsiveContainer } from "recharts";
import { Empty } from "antd";
import styled from "@emotion/styled";

export const EmptyChartResult = () => {
  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <Container>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Container>
    </ResponsiveContainer>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
