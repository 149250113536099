import { http } from "../http";

import { DeleteUserData } from "../../edit-profile/store";

export async function changePassword(oldPassword: string, newPassword: string) {
  const { data } = await http.put("/user-management/user/password", {
    oldPassword,
    newPassword,
  });
  return data;
}

export async function changeProfileInfo(
  email: string,
  firstName: string,
  lastName: string
) {
  const { data } = await http.put("/user-management/user", {
    email,
    firstName,
    lastName,
  });
  return data;
}

export async function getCurrentUser() {
  const { data } = await http.get("/user-management/current-user");
  return data;
}

export async function uploadAvatar(FormData: FormData) {
  const { data } = await http.put("/user-management/user/avatar", FormData);
  return data;
}

export async function deleteAvatar() {
  const { data } = await http.delete("/user-management/user/avatar");
  return data;
}

export async function deleteRequest(deleteUserData: DeleteUserData) {
  const config = {
    data: deleteUserData,
  };
  const { data } = await http.delete("/user-management/user", config);
  return data;
}
