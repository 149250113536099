import { FC, useEffect } from "react";
import { Formik } from "formik";

import { Spin } from "antd";
import { Button } from "../../../ui-components/Button";
import { FormikInput } from "../../../ui-components/FormikInput";
import { Modal } from "../../../ui-components/Modal";

import { updateList, setInitialUpdateListState } from "../../store";
import { State } from "../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { EditListSchema } from "../validation";

interface UpdateListModalProps {
  visible: boolean;
  handleModalClose: () => void;
  listUUID: string;
  listName: string;
}

export const UpdateListModal: FC<UpdateListModalProps> = ({
  visible,
  handleModalClose,
  listUUID,
  listName,
}) => {
  const dispatch = useAppDispatch();
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { loading, success } = useAppSelector(
    (state: State) => state.lists.updateList
  );

  useEffect(() => {
    return () => {
      dispatch(setInitialUpdateListState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      handleModalClose();
    }
  }, [success, handleModalClose]);

  return (
    <Formik
      initialValues={{
        name: listName,
      }}
      onSubmit={(values, { resetForm }) => {
        const uuid = selectedClient?.uuid;
        if (!uuid) return;
        const data = {
          ...values,
          clientUUID: uuid,
          listUUID: listUUID,
        };
        dispatch(updateList(data));
        resetForm();
      }}
      validationSchema={EditListSchema}
    >
      {({ handleSubmit }) => (
        <form>
          <Modal
            title="Edit list name"
            visible={visible}
            handleSubmit={handleSubmit}
            onCancel={handleModalClose}
            afterClose={() => dispatch(setInitialUpdateListState())}
            footer={[
              <Button key="cancel" onClick={handleModalClose}>
                Cancel
              </Button>,
              <Button
                key="confirm"
                htmlType="submit"
                onClick={() => handleSubmit()}
              >
                {loading ? (
                  <>
                    Sending &nbsp; <Spin />
                  </>
                ) : (
                  "Confirm"
                )}
              </Button>,
            ]}
          >
            <FormikInput
              name="name"
              type="name"
              label="Name"
              ariaLabel="name"
              placeholder=""
              isRequired={true}
            />
          </Modal>
        </form>
      )}
    </Formik>
  );
};
