import { FC, useEffect, useRef } from "react";
import { Formik, FormikProps } from "formik";

import { Modal } from "../../../../ui-components/Modal";
import { Paragraph } from "../../../../ui-components/Typography";
import { Button } from "../../../../ui-components/Button";
import {
  errorNotification,
  successNotification,
} from "../../../../ui-components/Notification";
import { FormikInput } from "../../../../ui-components/FormikInput";

import {
  submitGBIterationRequest,
  setGBIterationInitialState,
  CampaignRequestType,
} from "../../../../campaign/golden-basket/store";
import { State } from "../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";

interface RequestNewIterationModalProps {
  visible: boolean;
  handleModalClose: () => void;
  campaignUUID?: string;
  clientUUID?: string;
}

export const RequestNewIterationModal: FC<RequestNewIterationModalProps> = ({
  handleModalClose,
  visible,
  campaignUUID,
  clientUUID,
}) => {
  const dispatch = useAppDispatch();
  const { success, loading, message } = useAppSelector(
    (state: State) => state.goldenBasket.submitGBIterationRequest
  );

  const formikRef = useRef<FormikProps<Record<string, unknown>>>(null);
  useEffect(() => {
    if (success) {
      formikRef?.current?.resetForm();
      successNotification(
        "Your Delivery request has been submitted successfully"
      );
      dispatch(setGBIterationInitialState());
      handleModalClose();
    } else if (success === false) {
      errorNotification(message || "There was an error submitting the request");
    }
  }, [dispatch, success, message, handleModalClose]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ comment: "" }}
      onSubmit={(values, { resetForm }) => {
        if (!clientUUID || !campaignUUID) return;

        const data = {
          clientUUID: clientUUID,
          campaignUUID: campaignUUID,
          requestType: CampaignRequestType.Iteration,
          ...values,
        };
        dispatch(submitGBIterationRequest(data));
      }}
    >
      {({ handleSubmit }) => (
        <form>
          <Modal
            title="Request a Delivery"
            visible={visible}
            handleSubmit={() => {}}
            onCancel={handleModalClose}
            footer={[
              <Button key="cancel" onClick={handleModalClose}>
                Close
              </Button>,
              <Button
                key="submit"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                onClick={() => handleSubmit()}
              >
                {loading ? "Submitting" : "Submit"}
              </Button>,
            ]}
          >
            <Paragraph>
              You can leave a message to your Novable Agent on this Delivery
              request.
            </Paragraph>
            <FormikInput
              name="comment"
              type="text"
              as="textarea"
              ariaLabel="comment"
              placeholder="Comment"
              maxLength={500}
            />
          </Modal>
        </form>
      )}
    </Formik>
  );
};
