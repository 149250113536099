import { useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { ClientErrorView } from "./ClientErrorView";
import { CenteredSpinner } from "../ui-components/CenteredSpinner";

import { getClient } from "./store";
import { State } from "../utils/store";
import { useAppDispatch, useAppSelector } from "../utils/hooks";

import { Segment } from "../utils/Segment";
import { isClientPremium } from "../helpers";

export const ClientView = () => {
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const locationState: any = location?.state;
  const locationSlug = locationState?.slug;

  const user = useAppSelector((state: State) => state.auth.user);

  const selectedClient = useAppSelector(
    (state: State) => state.clients.selectedClient
  );
  const { loading, success, error } = useAppSelector(
    (state: State) => state.clients.getClient
  );
  const { clientSlug } = useParams<{ clientSlug: string }>();
  const slug = clientSlug || locationSlug;

  useEffect(() => {
    dispatch(getClient(slug));
  }, [dispatch, slug]);

  if (error) {
    return <ClientErrorView />;
  }

  if (loading && !success) {
    return <CenteredSpinner />;
  }

  // If request was made but no client was found
  if ((success && !selectedClient) || !slug) {
    return <Navigate to={"/clients"} />;
  }

  if (success && selectedClient) {
    const redirectTo =
      location?.state?.from || `/${selectedClient.slug}/dashboard`;
    user &&
      Segment.analytics?.identify(user.uuid, {
        ...user,
        isPaidUser: isClientPremium(selectedClient),
        clientContractType: selectedClient.contract.contractName,
        clientID: selectedClient.uuid,
        clientName: selectedClient.name,
      });

    try {
      user &&
        FS.identify(user.uuid, {
          ...user,
          isPaidUser: isClientPremium(selectedClient),
          clientContractType: selectedClient.contract.contractName,
          clientID: selectedClient.uuid,
          clientName: selectedClient.name,
        });
    } catch (error) {
      console.error(error);
      // Expected output: ReferenceError: nonExistentFunction is not defined
      // (Note: the exact output may be browser-dependent)
    }

    return <Navigate to={redirectTo} />;
  }

  return <></>;
};
