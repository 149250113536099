import { useNavigate } from "react-router";
import styled from "@emotion/styled";
import { PlusOutlined } from "@ant-design/icons";

import { Card as BaseCard, Space } from "antd";
import { AgentDetails } from "../../ui-components/AgentDetails";
import { Button } from "../../ui-components/Button";
import { warningNoMoreDraftsAvailable } from "../../ui-components/layout/share";

import emptyDashboardImg from "../../assets/states/empty-dashboard.svg";

import { canCreateMoreDrafts } from "../../share/campaigns/store";
import { State } from "../../utils/store";
import { useAppSelector } from "../../utils/hooks";

import { Segment } from "../../utils/Segment";

export const EmptyDashboard = () => {
  const navigate = useNavigate();
  const _canCreateMoreDrafts = useAppSelector(canCreateMoreDrafts());
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  function onClick() {
    if (!_canCreateMoreDrafts) {
      warningNoMoreDraftsAvailable();
    } else {
      Segment.analytics?.track("create-campaign", { source: "dashboard" });

      navigate(`/${selectedClient?.slug}/new-campaign`);
    }
  }

  return (
    <EmptyContainer>
      <Card>
        <Space direction={"vertical"} align={"center"}>
          <EmptyDashboardImg />
          <h2>You don't have any active campaign at the moment.</h2>
          <Row>
            <FlexItem>
              <p> You can start a campaign </p>
              <Button onClick={onClick}>
                {" "}
                <PlusOutlined /> New campaign
              </Button>
            </FlexItem>
            <FlexItem>
              <p>
                We got your back! You can contact your Novable agent at any time
              </p>
              <AgentDetails />
            </FlexItem>
          </Row>
        </Space>
      </Card>
    </EmptyContainer>
  );
};

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Card = styled(BaseCard)`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const EmptyDashboardImg = styled.div`
  height: 23rem;
  width: 30rem;
  background-image: url(${emptyDashboardImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const FlexItem = styled.div`
  width: 50%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5rem;
  justify-content: space-around;
  align-items: baseline;
  padding: 0 5rem;
  font-size: var(--font-size-base);
`;
