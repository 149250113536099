import { FC, MouseEvent } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import { Alert as BaseAlert, Modal } from "antd";
import { ContainerOutlined, EditOutlined } from "@ant-design/icons";

import { CampaignStats } from "../../ui-components/CampaignStats";
import {
  Card,
  CardHeader,
  CardContent,
  HeadTitle as BaseHeadTitle,
} from "../../ui-components/Card";
import { EditButton } from "../../ui-components/EditButton";
import { CardDescription } from "../../ui-components/Typography";

import AlgorithmMock from "../../assets/mockups/campaign-algo.png";

import { UserRole } from "../../clients/store";
import { Campaign, archiveCampaign } from "../../share/campaigns/store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import { useBreakpoint } from "../../utils/styling";

const { confirm } = Modal;

const nonDemoArchiveConfirmMessage =
  "This campaign will no longer be visible in your dashboard and left panel. You can find them at the bottom of the dashboard and restore them. Archiving campaigns will not affect the numbers on the “My statistics” section of your dashboard.";
const demoArchiveConfirmMessage =
  "You will delete this campaign forever, and its data cannot be restored";

interface CampaignCardProps {
  campaign: Campaign;
  clientSlug: string | undefined;
}

export const CampaignCard: FC<CampaignCardProps> = ({
  campaign,
  clientSlug,
}) => {
  const [isParentWide, ref] = useBreakpoint(1184, { useBody: false });
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(
    (state: State) => state.campaigns.archiveCampaign
  );

  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const handleRedirectClick = (e: MouseEvent<HTMLButtonElement>) => {
    navigate(`/${clientSlug}/campaign/home/${campaign.slug}`);
    e.preventDefault();
  };

  const handleArchiveClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    confirm({
      title: campaign.isDemo
        ? demoArchiveConfirmMessage
        : nonDemoArchiveConfirmMessage,
      onOk() {
        handleArchiveCampaign();
      },
      okButtonProps: {
        loading: loading,
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
    });
  };

  const handleArchiveCampaign = () => {
    dispatch(archiveCampaign({ campaignUUID: campaign.uuid }));
  };

  return (
    <Card>
      <CardHeader>
        <HeaderItemContainer>
          <HeadTitle>{campaign.name}</HeadTitle>
          {campaign.statusPending && (
            <Alert type="warning" message="Status pending" />
          )}
          {campaign.isDemo && <Alert type="info" message="Demo Campaign" />}
        </HeaderItemContainer>
        <HeaderItemContainer>
          {selectedClient?.userRole === UserRole.ADMIN && (
            <EditButton
              icon={<ContainerOutlined />}
              onClick={handleArchiveClick}
              className="archive-campaign-button"
            >
              Archive
            </EditButton>
          )}

          <EditButton
            icon={<EditOutlined />}
            onClick={handleRedirectClick}
            className="edit-campaign-button"
          >
            Edit campaign
          </EditButton>
        </HeaderItemContainer>
      </CardHeader>
      <CardContent ref={ref}>
        <CampaignInfo isParentWide={isParentWide}>
          <CardDescription>
            {campaign.description || campaign.strategyPlainText}
          </CardDescription>
          <CampaignStats
            clientSlug={clientSlug}
            campaignSlug={campaign.slug}
            favourites={campaign.favourites}
            followed={campaign.followed}
            toReview={campaign.toReview}
            matches={campaign.matches}
          />
        </CampaignInfo>
        <AlgorithmSection hidden isParentWide={isParentWide} />
      </CardContent>
    </Card>
  );
};

const CampaignInfo = styled.div<{ isParentWide: boolean }>`
  width: ${(props) => (props.isParentWide ? "70%" : "100%")};
  padding-right: 2rem;
  margin-right: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 12rem;
  border-right: ${(props) =>
    props.isParentWide ? "var(--campaign-card-border)" : "none"};
`;

const HeadTitle = styled(BaseHeadTitle)`
  &::after {
    position: absolute;
    left: 0;
    bottom: -2px;
    top: 3.8rem;
    content: "";
    width: 100%;
    height: 2px;
    background: var(--primary-color);
  }
`;

const AlgorithmSection = styled.div<{ isParentWide: boolean }>`
  background: url(${AlgorithmMock});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 33rem;
  height: 12rem;
  margin-top: ${(props) => (props.isParentWide ? "0" : "2rem")};
`;

const Alert = styled(BaseAlert)`
  margin-left: 1rem;
`;

const HeaderItemContainer = styled.div`
  display: flex;
  align-items: center;
`;
