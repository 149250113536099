import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { fork, put, takeEvery } from "redux-saga/effects";

import { backend } from "../utils/http";

import { BaseCompany } from "../share/company/store";

import { clientsSlice } from "../clients/store";
import { Segment } from "../utils/Segment";

export enum SearchRequestType {
  MakeItACampaign = 1,
}

export interface SearchData {
  uuid: string;
  clientUUID: string;
  searchDate: string;
  text: string;
  countries?: String[];
}

export interface SearchRequestData {
  clientUUID: string;
  searchHistoryUUID: string;
  comment: string;
  requestType: number;
}

export interface UpdateFavouriteCountData {
  uuid: string;
  favourite: boolean;
}

export interface CompanyWithListsAndFavourite extends BaseCompany {
  favourited: boolean;
  lists: string[];
}

export interface CompaniesSearchState {
  searchCompanies: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  searchRequest: {
    loading: boolean;
    error: string;
    success: boolean | null;
  };
  updateFavouriteCount: {
    loading: boolean;
    error: string;
    success: boolean;
  };
  companies: CompanyWithListsAndFavourite[];
}

const initialState = {
  searchCompanies: {
    loading: false,
    error: "",
    success: false,
  },
  searchRequest: {
    loading: false,
    error: "",
    success: null,
  },
  updateFavouriteCount: {
    loading: false,
    error: "",
    success: false,
  },
  companies: [],
} as CompaniesSearchState;

export const companiesSearchSlice = createSlice({
  name: "companiesSearch",
  initialState: initialState,
  reducers: {
    searchCompanies(state, action: PayloadAction<SearchData>) {
      state.searchCompanies.loading = true;
      state.searchCompanies.success = false;
      state.searchCompanies.error = "";
    },
    searchCompaniesSuccess(state, action) {
      state.searchCompanies.loading = false;
      state.searchCompanies.success = true;
      state.searchCompanies.error = "";
      state.companies = action.payload;
    },
    searchCompaniesFailure(state, action) {
      state.searchCompanies.loading = false;
      state.searchCompanies.success = false;
      state.searchCompanies.error = action.payload.data.message;
    },
    searchRequest(state, action: PayloadAction<SearchRequestData>) {
      state.searchRequest.loading = true;
      state.searchRequest.success = null;
      state.searchRequest.error = "";
    },
    searchRequestSuccess(state) {
      state.searchRequest.loading = false;
      state.searchRequest.success = true;
      state.searchRequest.error = "";
    },
    searchRequestFailure(state, action) {
      state.searchRequest.loading = false;
      state.searchRequest.success = false;
      state.searchRequest.error = action.payload.data.message;
    },
    setInitialState(state) {
      state.searchCompanies = initialState.searchCompanies;
      state.updateFavouriteCount = initialState.updateFavouriteCount;
      state.searchRequest = initialState.searchRequest;
      state.companies = [];
    },
    updateFavouriteCount(
      state,
      action: PayloadAction<UpdateFavouriteCountData>
    ) {},
  },
});

function* searchCompaniesSaga() {
  yield takeEvery(
    companiesSearchSlice.actions.searchCompanies,
    function* (action) {
      Segment.analytics?.track("search-companies");
      try {
        const response: AxiosResponse =
          yield backend.companiesSearchBackend.searchCompanies(action.payload);

        const { companies } = response.data;

        yield put(
          companiesSearchSlice.actions.searchCompaniesSuccess(companies)
        );
        yield put(clientsSlice.actions.decreaseAvailableSearches());
      } catch (err) {
        yield put(companiesSearchSlice.actions.searchCompaniesFailure(err));
      }
    }
  );
}

function* searchRequestSaga() {
  yield takeEvery(
    companiesSearchSlice.actions.searchRequest,
    function* (action) {
      try {
        yield backend.companiesSearchBackend.searchRequest(action.payload);
        yield put(companiesSearchSlice.actions.searchRequestSuccess());
      } catch (err) {
        yield put(companiesSearchSlice.actions.searchRequestFailure(err));
      }
    }
  );
}

function* updateFavouriteCountSaga() {
  yield takeEvery(
    companiesSearchSlice.actions.updateFavouriteCount,
    function* (action) {
      try {
        yield backend.companiesSearchBackend.updateFavouriteCount(
          action.payload
        );
      } catch (err) {
        // Do nothing
      }
    }
  );
}

export function* companiesSearchSaga() {
  yield fork(searchCompaniesSaga);
  yield fork(searchRequestSaga);
  yield fork(updateFavouriteCountSaga);
}

export const {
  searchCompanies,
  searchRequest,
  setInitialState,
  updateFavouriteCount,
} = companiesSearchSlice.actions;
