import { FC } from "react";
import { AnyAction } from "redux";

import { Col, Tooltip } from "antd";
import { ActionButton } from "../../ui-share";

import { StarOutlined, StarFilled } from '@ant-design/icons';

import { State } from "../../../../../utils/store";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";

import { Segment } from "../../../../../utils/Segment";

interface FavouriteButtonProps {
  toggleFavouriteFunction: (payload: any) => AnyAction;
  isFavourite: boolean;
  companyUUID: string;
  favouriteSource?: string;
}

export const FavouriteButton: FC<FavouriteButtonProps> = ({
  toggleFavouriteFunction,
  isFavourite,
  companyUUID,
  favouriteSource,
}) => {
  const dispatch = useAppDispatch();
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const handleFavourite = () => {
    if (!selectedClient) return;
    let payload = {
      companyUUID: companyUUID,
      clientUUID: selectedClient.uuid,
    };
    dispatch(toggleFavouriteFunction(payload));

    if (!isFavourite && favouriteSource) {
      Segment.analytics?.track("favourite-company", {
        source: favouriteSource,
        uuid: companyUUID,
      });
    }
  };

  return (
    <Col className="gutter-row">
      <Tooltip
        placement="top"
        title="Favourite a Company in order to filter only the Companies that really matter and find them back rapidly"
      >
        <ActionButton onClick={handleFavourite}>
          {isFavourite ? <StarFilled style={{ color: '#FA8212' }} /> : <StarOutlined />}
        </ActionButton>
      </Tooltip>
    </Col>
  );
};