import styled from "@emotion/styled";
import { Button as BaseButton } from "./Button";

export const ButtonCancel = styled(BaseButton)`
  background-color: var(--white);
  border: var(--campaign-card-border);

  &:hover,
  &:active,
  &:focus {
    background-color: var(--primary-color-hover);
    border: var(--button-border);
  }
`;
