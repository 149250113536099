import { FC } from "react";
import styled from "@emotion/styled";

import { Form, TreeSelect as BaseTreeSelect } from "antd";
import { Label } from "./Typography";
import { useField } from "formik";

export interface FormikTreeSelectProps {
  name: string;
  label?: string;
  [x: string]: any;
}

export const FormikTreeSelect: FC<FormikTreeSelectProps> = ({
  name,
  label,
  ...rest
}) => {
  const [field, meta, { setValue, setTouched }] = useField({ name, ...rest });
  const invalid = meta.touched && meta.error; // Show error message & red border

  const { disabled } = rest;

  return (
    <Form.Item
      help={invalid ? meta.error : null}
      validateStatus={invalid ? "error" : "success"}
    >
      <Label>
        {label && label}
        <TreeSelect
          value={field.value}
          onChange={(e) => setValue(e)}
          onBlur={() => setTouched(true)}
          showSearch
          treeNodeFilterProp="title"
          treeCheckable
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          placeholder="Please select"
          allowClear
          multiple
          treeDefaultExpandAll
          disabled={disabled}
          // In case the select is disabled, which only happens on the EditCampaignView,
          // hide the border and the background
          bordered={!disabled}
          {...rest}
        />
      </Label>
    </Form.Item>
  );
};

const TreeSelect = styled(BaseTreeSelect)<{ disabled?: boolean }>`
  width: 100%;
  margin-top: 0rem;

  ${(props) =>
    props.disabled &&
    `
    ant-select-selector {
      cursor: default !important;
    }

    ant-select-selection-overflow-item {
      cursor: default !important;
    }

    span {
      color: var(--font-color-disabled-input) !important;
      cursor: default !important;
    }
    `};
`;
