import * as Yup from "yup";

import {
  FIRST_NAME_FIELD_LENGTH,
  LAST_NAME_FIELD_LENGTH,
} from "../share/constants";

export const EditProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required.")
    .max(
      FIRST_NAME_FIELD_LENGTH,
      "First name cannot be longer than 30 characters"
    ),
  lastName: Yup.string()
    .required("Last name is required.")
    .max(
      LAST_NAME_FIELD_LENGTH,
      "First name cannot be longer than 30 characters"
    ),
  email: Yup.string().required("Email is required."),
});
