import { FC } from "react";
import styled from "@emotion/styled";

import {
  Anchor,
  Card as BaseCard,
  CardActions,
  CardContent,
  CardHeader,
  CardHeaderText,
  CompanyHeaderDetails as BaseCompanyHeaderDetails,
  CompanyHeaderDetailsText as BaseCompanyHeaderDetailsText,
  CompanyTitle as BaseCompanyTitle,
  Description as BaseDescription,
  Location,
  Logo,
  Team,
  Website,
} from "../../../../ui-components/CompanyCardStyles";
import defaultLogo from "../../../../assets/defaults/default-logo.png";

import { CompanyInformation } from "../../../store";
import {
  checkCompanyDetailsLength,
  addUTMSource,
  formatLabel,
} from "../../../../helpers";

interface CompanyCardProps {
  company: CompanyInformation;
}

export const CompanyCard: FC<CompanyCardProps> = ({ company }) => {
  const employeesRangeStart =
    company.employeesRangeStart === 0 || company.employeesRangeStart || "";
  const employeesRangeEnd =
    company.employeesRangeEnd === 0 || company.employeesRangeEnd || "";
  let companyEmployees = employeesRangeStart + " - " + employeesRangeEnd;
  companyEmployees = formatLabel(companyEmployees);

  return (
    <Card>
      <CardHeader>
        <Logo
          src={company.logo || defaultLogo}
          alt="Snapshot of the Company's website"
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = defaultLogo;
          }}
        />

        <CardHeaderText>
          <CompanyTitle>{company.name}</CompanyTitle>
          <CompanyHeaderDetails
            length={checkCompanyDetailsLength(
              company.country,
              company.employeesRangeStart,
              company.employeesRangeEnd
            )}
          >
            <CompanyHeaderDetailsText>
              <Location /> {company.country || "-"}
            </CompanyHeaderDetailsText>
            <CompanyHeaderDetailsText>
              {" "}
              <Team />
              <Employees>{companyEmployees}</Employees>
            </CompanyHeaderDetailsText>
          </CompanyHeaderDetails>
        </CardHeaderText>
      </CardHeader>
      <CardContent>
        <Description
          allDescription={false}
          title={company.shortDescription || ""}
        >
          {company.shortDescription}
        </Description>
      </CardContent>
      <CardActions>
        <Website />
        <Anchor
          href={(company.url && addUTMSource(company.url)) || ""}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="website"
        >
          {(company.url && company.url) || "-"}
        </Anchor>
      </CardActions>
    </Card>
  );
};

const CompanyHeaderDetails = styled(BaseCompanyHeaderDetails)<{
  length: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.length ? "column" : "row")};
  align-items: ${(props) => (props.length ? "flex-start" : "center")};
  flex-wrap: wrap;
  margin-left: 1.2rem;
  gap: 1rem;
`;

const CompanyHeaderDetailsText = styled(BaseCompanyHeaderDetailsText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--font-color-campaign-stats);
`;

const Card = styled(BaseCard)`
  height: fit-content;
  width: 18%;
  max-width: 20%;
  padding: 1.2rem 1.2rem 0 1.2rem;
  margin-bottom: 0;
`;

const CompanyTitle = styled(BaseCompanyTitle)`
  line-height: 1.6rem;
  padding: 0;
  margin: 0;
  margin-left: 1.2rem;
  font-weight: var(--font-weight-bold);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Employees = styled.span`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Description = styled(BaseDescription)`
  -webkit-line-clamp: 3;
`;
