import { AxiosResponse } from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { fork, put, takeEvery } from "redux-saga/effects";

import { backend } from "../utils/http";
import { authSlice } from "../auth/store";

export interface DeleteUserData {
  clientUUID: string;
}

export interface EditProfileState {
  changeProfileInfo: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
  changePassword: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
  deleteAvatar: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
  deleteRequest: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
  uploadAvatar: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
}

const initialState = {
  changeProfileInfo: {
    loading: false,
    success: null,
    message: "",
  },
  changePassword: {
    loading: false,
    success: null,
    message: "",
  },
  deleteAvatar: {
    loading: false,
    message: "",
    success: null,
  },
  deleteRequest: {
    loading: false,
    message: "",
    success: null,
  },
  uploadAvatar: {
    loading: false,
    message: "",
    success: null,
  },
} as EditProfileState;

export const editProfileSlice = createSlice({
  name: "edit_profile",
  initialState: initialState,
  reducers: {
    changeProfileInfo(state, action) {
      state.changeProfileInfo = {
        ...initialState.changeProfileInfo,
        loading: true,
      };
    },
    changeProfileInfoSuccess(state, action) {
      state.changeProfileInfo.loading = false;
      state.changeProfileInfo.message = action.payload.message;
      state.changeProfileInfo.success = true;
    },
    changeProfileInfoFailure(state, action) {
      state.changeProfileInfo.loading = false;
      state.changeProfileInfo.message = action.payload.response.data.message;
      state.changeProfileInfo.success = false;
    },
    changePassword(state, action) {
      state.changePassword = { ...initialState.changePassword, loading: true };
    },
    changePasswordSuccess(state, action) {
      state.changePassword.loading = false;
      state.changePassword.message = action.payload.message;
      state.changePassword.success = true;
    },
    changePasswordFailure(state, action) {
      state.changePassword.loading = false;
      state.changePassword.message = action.payload.response.data.message;
      state.changePassword.success = false;
    },
    deleteAvatar(state) {
      state.deleteAvatar = { ...initialState.deleteAvatar, loading: true };
    },
    deleteAvatarSuccess(state, action) {
      state.deleteAvatar.loading = false;
      state.deleteAvatar.success = true;
    },
    deleteAvatarFailure(state, action) {
      state.deleteAvatar.loading = false;
      state.deleteAvatar.message = action.payload.response.data.message;
      state.deleteAvatar.success = false;
    },
    deleteRequest(state, action) {
      state.deleteRequest.loading = true;
      state.deleteRequest.message = "";
      state.deleteRequest.success = null;
    },
    deleteRequestSuccess(state) {
      state.deleteRequest.loading = false;
      state.deleteRequest.message = "";
      state.deleteRequest.success = true;
    },
    deleteRequestFailure(state, action) {
      state.deleteRequest.loading = false;
      state.deleteRequest.message = action.payload.response.data.message;
      state.deleteRequest.success = false;
    },
    uploadAvatar(state, action) {
      state.uploadAvatar = { ...initialState.uploadAvatar, loading: true };
    },
    uploadAvatarSuccess(state, action) {
      state.uploadAvatar.loading = false;
      state.uploadAvatar.success = true;
    },
    uploadAvatarFailure(state, action) {
      state.uploadAvatar.loading = false;
      state.uploadAvatar.message = action.payload.response?.data?.message;
      state.uploadAvatar.success = false;
    },
    setInitialState(state) {
      state.changePassword = initialState.changePassword;
      state.changeProfileInfo = initialState.changeProfileInfo;
      state.uploadAvatar = initialState.uploadAvatar;
      state.deleteAvatar = initialState.deleteAvatar;
      state.deleteRequest = initialState.deleteRequest;
    },
  },
});

function* changeProfileInfoSaga() {
  yield takeEvery(
    editProfileSlice.actions.changeProfileInfo,
    function* (action) {
      try {
        const response: AxiosResponse<void> =
          yield backend.userBackend.changeProfileInfo(
            action.payload.email,
            action.payload.firstName,
            action.payload.lastName
          );
        yield put(authSlice.actions.updateUserData(response.data));
        yield put(editProfileSlice.actions.changeProfileInfoSuccess(response));
      } catch (err) {
        yield put(editProfileSlice.actions.changeProfileInfoFailure(err));
      }
    }
  );
}

export function* changePasswordSaga() {
  yield takeEvery(editProfileSlice.actions.changePassword, function* (action) {
    try {
      const response: AxiosResponse<void> =
        yield backend.userBackend.changePassword(
          action.payload.oldPassword,
          action.payload.newPassword
        );

      yield put(editProfileSlice.actions.changePasswordSuccess(response));
    } catch (err) {
      yield put(editProfileSlice.actions.changePasswordFailure(err));
    }
  });
}

function* uploadAvatarSaga() {
  yield takeEvery(editProfileSlice.actions.uploadAvatar, function* (action) {
    const formData = new FormData();
    formData.append("avatar", action.payload);
    try {
      const response: AxiosResponse<void> =
        yield backend.userBackend.uploadAvatar(formData);
      yield put(authSlice.actions.updateUserData(response.data));
      yield put(editProfileSlice.actions.uploadAvatarSuccess(response));
    } catch (err) {
      yield put(editProfileSlice.actions.uploadAvatarFailure(err));
    }
  });
}

function* deleteAvatarSaga() {
  yield takeEvery(editProfileSlice.actions.deleteAvatar, function* (action) {
    try {
      const response: AxiosResponse<void> =
        yield backend.userBackend.deleteAvatar();

      yield put(authSlice.actions.updateUserData(response.data));
      yield put(editProfileSlice.actions.deleteAvatarSuccess(response));
    } catch (err) {
      yield put(editProfileSlice.actions.deleteAvatarFailure(err));
    }
  });
}

function* deleteRequestSaga() {
  yield takeEvery(editProfileSlice.actions.deleteRequest, function* (action) {
    try {
      yield backend.userBackend.deleteRequest(action.payload);
      yield put(editProfileSlice.actions.deleteRequestSuccess());
    } catch (err) {
      yield put(editProfileSlice.actions.deleteRequestFailure(err));
    }
  });
}

export function* editProfileSaga() {
  yield fork(changeProfileInfoSaga);
  yield fork(changePasswordSaga);
  yield fork(deleteAvatarSaga);
  yield fork(deleteRequestSaga);
  yield fork(uploadAvatarSaga);
}

export const {
  changeProfileInfo,
  changePassword,
  setInitialState,
  uploadAvatar,
  deleteAvatar,
  deleteRequest,
} = editProfileSlice.actions;
