import { FC } from "react";
import styled from "@emotion/styled";

import { useDelayUnmount } from "../helpers";

interface ShowWithAnimationProps {
  isMounted: boolean;
  children?: React.ReactNode;
  inAnimation?: string;
  outAnimation?: string;
}

export const ShowWithAnimation: FC<ShowWithAnimationProps> = ({
  isMounted,
  children,
  inAnimation,
  outAnimation,
}) => {
  inAnimation = inAnimation || "inAnimation 450ms ease-in";
  outAnimation = outAnimation || "outAnimation 470ms ease-out";

  const showDiv = useDelayUnmount(isMounted, 450);

  return (
    <div>
      {showDiv && (
        <AnimatedDiv
          isMounted={isMounted}
          inAnimation={inAnimation}
          outAnimation={outAnimation}
        >
          {children}
        </AnimatedDiv>
      )}
    </div>
  );
};

const AnimatedDiv = styled.div<{
  isMounted: boolean;
  inAnimation: string;
  outAnimation: string;
}>`
  animation: ${(props) =>
    props.isMounted ? props.inAnimation : props.outAnimation};

  animationfillmode: ${(props) => (props.isMounted ? undefined : "forwards")};
`;
