import { FC } from "react";
import styled from "@emotion/styled";

import { Space, Tooltip } from "antd";
import {
  ActivityIndexProgressCircle,
  PROGRESS_CIRCLE_DEFAULT_WIDTH,
} from "../ui-share/ActivityIndexProgressCircle";
import runIcon from "../../../../assets/icons/run-svgrepo-com.svg";

import { useBreakpoint } from "../../../../utils/styling";

interface ActivityIndexProps {
  activityIndex: number;
}

const SMALL_SCREEN_CIRCLE_WIDTH = 30;

export const ActivityIndex: FC<ActivityIndexProps> = ({ activityIndex }) => {
  const [isXL] = useBreakpoint(1200, { useBody: true });

  if (!activityIndex || activityIndex === 0) return <></>;

  return (
    <Tooltip title="Activity index of the Company. It's a Novable-calculated index for this Company">
      <Space>
        <ActivityIndexProgressCircle
          activityIndex={activityIndex}
          width={
            isXL ? PROGRESS_CIRCLE_DEFAULT_WIDTH : SMALL_SCREEN_CIRCLE_WIDTH
          }
        />
      </Space>
    </Tooltip>
  );
};

const ActivityIndexIcon = styled.div`
  background: url(${runIcon});
  background-size: 100%;
  background-repeat: no-repeat;
  width: 1.334rem;
  height: 1.667rem;
`;
