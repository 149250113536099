import { Button } from "antd";
import styled from "@emotion/styled";

export const ActionButton = styled(Button)`
  background: none;
  border: var(--button-border);

  &:active,
  &:focus {
    background: none;
    border: var(--button-border);
  }

  &:hover {
    border: var(--button-border);
    background-color: var(--primary-color);
    p {
      color: var(--font-color-white);
    }
    svg {
      filter: brightness(0) invert(1);
    }
  }
`;
