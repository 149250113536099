import { FC } from "react";
import styled from "@emotion/styled";

import { Row } from "antd";

export const HeaderActions: FC<{ children: any }> = ({ children }) => {
  return (
    <HeaderActionsContainer>
      <HeaderActionsRow style={{ columnGap: "1.5rem" }}>
        {children}
      </HeaderActionsRow>
    </HeaderActionsContainer>
  );
};

const HeaderActionsRow = styled(Row)`
  padding: 0.5rem;

  button:last-child {
    margin-right: 0;
  }
`;

const HeaderActionsContainer = styled(Row)`
  width: 40%;
  display: flex;
  justify-content: flex-end;
`;
