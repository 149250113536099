import { FC } from "react";

import { Tooltip } from "antd";

import {
  NonClickableTag,
  HighlightedNonClickableTag,
  TAG_HIGHLIGHT_COLOR,
  TAG_HIGHLIGHT_TOOLTIP_TEXT,
} from ".";

interface TopicProps {
  topic: string;
  highlightTopic: boolean;
}

export const Topic: FC<TopicProps> = ({ topic, highlightTopic }) => {
  return (
    <>
      {highlightTopic ? (
        <Tooltip title={TAG_HIGHLIGHT_TOOLTIP_TEXT}>
          <HighlightedNonClickableTag color={TAG_HIGHLIGHT_COLOR}>
            {topic}
          </HighlightedNonClickableTag>
        </Tooltip>
      ) : (
        <NonClickableTag key={topic}>{topic}</NonClickableTag>
      )}
    </>
  );
};
