import styled from "@emotion/styled";
import { Spin } from "antd";

export const CenteredSpinner = ({ ...rest }: any) => {
  return (
    <Container>
      <Spin {...rest}></Spin>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
