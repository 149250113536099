import { FC } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";

import { Spin, Alert } from "antd";
import { FormikInput } from "../../ui-components/FormikInput";
import { AuthTitle } from "../../ui-components/Typography";
import {
  Form,
  Space,
  Button,
  ButtonWrapper,
} from "../../share/auth/components/share-ui";

import { ResetPasswordSchema } from "../validation";
import { resetPassword } from "../store";

import { useAppDispatch } from "../../utils/hooks";

interface FormProps {
  loading: boolean;
  message: string | null;
  token: string | null;
}

export const SetPasswordFormFromToken: FC<FormProps> = ({
  loading,
  message,
  token,
}) => {
  const dispatch = useAppDispatch();
  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        token: token || "",
      }}
      onSubmit={({ password, token }) => {
        dispatch(resetPassword({ password, token }));
      }}
      validationSchema={ResetPasswordSchema}
    >
      {({ handleSubmit, errors }) => (
        <Form
          onSubmit={handleSubmit}
          className="set-password-form"
          id="setPasswordForm"
        >
          <AuthTitle>Create password</AuthTitle>
          <Space direction="vertical" size="small">
            <FormikInput
              name="password"
              type="password"
              label="Enter password"
              error={errors.password}
              responsiveLabel={true}
            />
            <FormikInput
              name="confirmPassword"
              type="password"
              label="Repeat password"
              error={errors.confirmPassword}
              responsiveLabel={true}
            />

            <ButtonWrapper>
              <Button htmlType="submit" disabled={loading}>
                {loading ? (
                  <>
                    Processing... &nbsp; <Spin />{" "}
                  </>
                ) : (
                  <> Set password </>
                )}
              </Button>
              {message && <Alert type="error" message={message} />}
            </ButtonWrapper>

            <Link to="/login">Back to login</Link>
          </Space>
        </Form>
      )}
    </Formik>
  );
};
