import { FC, useState } from "react";
import styled from "@emotion/styled";

import {
    CommentOutlined,
  } from "@ant-design/icons";

import {
  Card as BaseCard,
} from "../../ui-components/CompanyCardStyles";
import { useAppSelector } from "../../utils/hooks";

interface ThreadCardProps {
  threadUuid: string;
  threadSummary: string;
  onClick: (uuid: string) => void;
}

export const ThreadCard: FC<ThreadCardProps> = ({
  threadUuid,
  threadSummary,
  onClick,

}) => {
  const selectedThread = useAppSelector(
    (state) => state.scottThreadList.selectedThread
  );
  const isSelected = selectedThread?.thread_uuid === threadUuid;

  return (
    <Card 
      onClick={() => onClick(threadUuid)} 
      isSelected={isSelected}
    >
      <CardContent>
        <IconDescriptionContainer>
            <CommentOutlined style={{ fontSize: '2rem', paddingRight: '2rem' }} />
            <Description>
                {threadSummary.slice(0, 140)}
            </Description>
        </IconDescriptionContainer>
      </CardContent>
    </Card>
  );
};

const Card = styled(BaseCard)`
    padding: 1.2rem 1.2rem 1.2rem 1.2rem;
    margin: 0 1.2rem 1.2rem 1.2rem;
`;

// display: "-webkit-box"
const Description = styled.p`
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  hyphens: auto;
  text-overflow: ellipsis;
  display: "block";
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: "hidden";
`;

export const CardContent = styled.div`
  position: relative;
`;

const IconDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
