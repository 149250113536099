import styled from "@emotion/styled";

import { CardHeadTitle } from "../../../../ui-components/Typography";

export const ResultCount = ({ children }: { children: any }) => {
  return (
    <ResultContainer>
      <ResultsTitle>Results</ResultsTitle>
      <ResultsLength>{children}</ResultsLength>
    </ResultContainer>
  );
};

const ResultContainer = styled.div`
  height: 5.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ResultsTitle = styled(CardHeadTitle)`
  padding: 0;
  margin: 0 0 0 1.6rem;
`;

const ResultsLength = styled.div`
  height: 2.4rem;
  padding: 0 0.5rem;
  border: var(--results-border);
  background: var(--separator-grey);
  border-radius: 7.2rem;
  display: grid;
  place-items: center;
  margin-left: 1rem;
`;
