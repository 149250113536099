import { FC } from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";
import { Link } from "react-router-dom";

import { Space as BaseSpace, Spin, Alert } from "antd";
import { Button as BaseButton } from "../../ui-components/Button";
import { FormikInput } from "../../ui-components/FormikInput";
import { AuthTitle, AuthSubtitle } from "../../ui-components/Typography";

import { ForgotPasswordSchema } from "../validation";
import { forgotPassword } from "../store";

import { useAppDispatch } from "../../utils/hooks";

interface FormProps {
  loading: boolean;
  message: string | null;
}

export const ForgotPasswordForm: FC<FormProps> = ({ loading, message }) => {
  const dispatch = useAppDispatch();

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={(email) => {
        dispatch(forgotPassword(email));
      }}
      validationSchema={ForgotPasswordSchema}
    >
      {({ handleSubmit, errors }) => (
        <Form
          onSubmit={handleSubmit}
          className="forgot-password-form"
          id="forgotPasswordForm"
        >
          <AuthTitle>Forgot your password?</AuthTitle>
          <AuthSubtitle>
            Enter the email associated with your account to receive password
            reset instructions
          </AuthSubtitle>
          <Space direction="vertical" size="small">
            <FormikInput
              name="email"
              type="email"
              label="Email"
              error={errors.email}
              responsiveLabel={true}
            />
            <ButtonWrapper>
              <Button htmlType="submit" disabled={loading}>
                {loading ? (
                  <>
                    Sending instructions &nbsp; <Spin />{" "}
                  </>
                ) : (
                  <> Send instructions </>
                )}
              </Button>
              {message && <Alert type="error" message={message} />}
            </ButtonWrapper>

            <Link to="/login">Back to login</Link>
          </Space>
        </Form>
      )}
    </Formik>
  );
};

const ButtonWrapper = styled.div`
  margin-bottom: 3rem;
`;

const Button = styled(BaseButton)`
  width: 100%;
  font-weight: var(--font-weight-bold);
  margin-bottom: 1rem;
`;

const Space = styled(BaseSpace)`
  width: 100%;
`;

const Form = styled.form`
  max-width: 47.2rem;
  width: 100%;
  padding-bottom: 11rem;

  @media (max-width: 576px) {
    width: 90%;
  }
`;
