import styled from "@emotion/styled";
import { Button, Spin, Tag as BaseTag } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import ArrowIcon from "../../../../assets/icons/arrow.svg";
import foundedIcon from "../../../../assets/icons/founded.svg";
import twitterIcon from "../../../../assets/icons/twitter.svg";
import runIcon from "../../../../assets/icons/run-svgrepo-com.svg";

export { RequestButton } from "./RequestButton";
export { SocialMedia } from "./SocialMedia";

export const FoundedIcon = styled.div`
  background: url(${foundedIcon});
  width: 1.6rem;
  height: 1.333rem;
  margin-right: 0.8rem;
`;

export const TwitterIcon = styled.div`
  background: url(${twitterIcon});
  width: 1.533rem;
  height: 1.333rem;
  margin-right: 0.8rem;
  background-repeat: no-repeat;
`;

export const Section = styled.div`
  border-bottom: var(--separator-border);
`;

export const SectionHeader = styled.div`
  margin-bottom: var(--gb-header-bottom-margin);
  padding: 0;
  display: flex;
  align-items: center;
`;

export const SectionContent = styled.div`
  margin-left: var(--gb-content-section-left-margin);
  font-size: var(--gb-content-font-size);
  line-height: 2rem;
`;

export const SectionTitle = styled.h6`
  font-color: var(--font-color-black);
  font-size: var(--gb-content-titles-font-size);
  margin-bottom: var(--gb-header-bottom-margin);
  line-height: 3rem;
  font-weight: var(--font-weight-bold-strong);
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`;

export const SubSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.2rem;
  border: var(--separator-border);
  border-radius: 0.5rem;
`;

export const CenteredSpinner = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionButton = styled(Button)`
  background-color: var(--white);
  border: var(--action-button-border);
  display: flex;
  align-items: center;
  padding: 0.4rem 1rem;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--primary-color-hover);
    // border: var(--button-border);
  }
`;

export const HeaderActionButton = styled(ActionButton)`
  border: var(--action-button-border);
  padding: 1.2rem;
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
`;

export const Tag = styled(BaseTag)`
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
`;

export const CloseIcon = styled(PlusOutlined)`
  transform: rotate(45deg);
`;

export const CompanyDetailHeaderSectionTitle = styled.p`
  font-size: 1.2rem;
  font-weight: var(--font-weight-bold);
  line-height: 1.8rem;
  letter-spacing: 0em;
  margin: 0;
  text-align: left;
`;

export const CompanyDetailHeaderSubSection = styled.div`
  padding: 0 0.8rem;
  border-right: var(--campaign-stats-border-right);
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PoweredText = styled.p`
  font-size: 1.2rem;
  color: var(--font-color-campaign-stats);
  margin-bottom: 0rem;
`;

export const PowerededBy = styled.div`
  margin-bottom: 0rem;
`;

export const ActivityIndexIcon = styled.div`
  background: url(${runIcon});
  background-size: 100%;
  background-repeat: no-repeat;
  width: 1.334rem;
  height: 1.667rem;
`;

export const ExpandCompanyRowArrow = styled.button<{
  isExpanded: boolean;
}>`
  width: 0.9rem;
  height: 1.8rem;
  margin-top: 0.5rem;
  border: none;
  background: none;
  display: inline-block;
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  transform: ${(props) =>
    props.isExpanded ? "rotate(270deg)" : "rotate(90deg)"};
  cursor: pointer;
  outline: none;
  filter: grayscale(1);
`;
