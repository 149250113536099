import { useEffect } from "react";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router";

import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SecondaryButton } from "../NewCampaignView";
import {
  errorNotification,
} from "../../ui-components/Notification";

import { deleteDraftCampaign } from "../store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

const { confirm } = Modal;

export const DeleteDraftButton: React.FC = ({ 
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formik = useFormikContext();

  const {
    success: deleteDraftSuccess,
    message: deleteDraftMessage,
    loading: deleteDraftLoading,
  } = useAppSelector((state: State) => state.newCampaign.deleteDraftCampaign);
  const { selectedClient } = useAppSelector((state: State) => state.clients);

  useEffect(() => {
    // Success notification would never be shown from this component. It has been
    // placed in the DashboardView
    if (!deleteDraftSuccess && deleteDraftMessage)
      errorNotification(deleteDraftMessage);
  }, [dispatch, deleteDraftSuccess, deleteDraftMessage]);

  const showConfirmDelete = () => {
    confirm({
      title: "You will lose all progress, are you sure you want to delete the draft?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteDraftAndRedirect();
      },
      okButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      okText: "Delete",
      cancelText: "Cancel",
    });
  };

  const deleteDraftAndRedirect = () => {
    if (!selectedClient) return;
    let currentValues: any = formik.values;
    const { uuid } = currentValues;
    dispatch(
      deleteDraftCampaign({ clientUUID: selectedClient.uuid, uuid: uuid })
    );
    navigate(`/${selectedClient.slug}/dashboard`);
  };

  return (
    <>
      <SecondaryButton 
          onClick={showConfirmDelete} 
          disabled={deleteDraftLoading}>
          Delete draft
      </SecondaryButton>
    </>
  );
};
