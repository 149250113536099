import { FC, ReactNode, useState } from "react";
import { useField } from "formik";
import styled from "@emotion/styled";

import { CopyOutlined } from "@ant-design/icons";
import { Button, Form, Popover, Select as BaseSelect } from "antd";
import { Label, SectionExplanation } from "./Typography";

export interface FormikSelectProps {
  name: string;
  label?: string;
  sectionExplanation?: string;
  placeholder?: string | undefined;
  maxResults?: number;
  extraContent?: ReactNode;
  [x: string]: any;
}

export const FormikSelect: FC<FormikSelectProps> = ({
  name,
  label,
  sectionExplanation,
  placeholder,
  maxResults,
  extraContent,
  ...rest
}) => {
  const [field, meta, { setValue, setTouched }] = useField({
    name,
    ...rest,
  });

  const { disabled } = rest;

  const invalid = meta.touched && meta.error; // Show error message & red border

  const reachedMaxResults =
    maxResults && maxResults !== 0 && field.value?.length === maxResults;

  const [visible, setVisible] = useState(false);
  let timer: any;

  const handleOnClickCopy = () => {
    // nothing to copy, don't show the Confirm display
    if (field.value?.length === 0) return;
    navigator.clipboard.writeText(field.value?.toString());
    clearTimeout(timer);
    timer = setTimeout(() => setVisible(false), 2 * 1000);
  };

  const handleOnVisibleChange = (isVisible: boolean) => {
    setVisible(isVisible);
  };

  return (
    <Form.Item
      help={invalid ? meta.error : null}
      validateStatus={invalid ? "error" : "success"}
    >
      <Label>
        {label && label}
        {sectionExplanation && (
          <SectionExplanation>{sectionExplanation}</SectionExplanation>
        )}
        <Select
          className="testy_test"
          value={field.value}
          onChange={(values: any) => {
            // Remove falsy values
            let cleanValues = values.filter(Boolean);
            // Remove spaces. Example for when spaces as a value may happen:
            // When someone pastes "a, , b" the values are --> ["a", " ", " b"]
            // We want that list to become ["a", "b"]
            cleanValues = cleanValues
              .map((x: any) => x.toString().trim())
              .filter(Boolean);
            // Remove duplicates. This happens when someone pastes, example:
            // Having ["a", "b"] as keys, past "1, b". Result will be
            // ["a", "b", "b", "1"], with an error due to duplicated keys.
            // Creating a set after cleaning values removes duplicated values
            let setCleanValues: Set<string> = new Set(cleanValues);
            let valuesToSet = Array.from(setCleanValues);
            if (reachedMaxResults) {
              valuesToSet = valuesToSet.slice(0, maxResults);
            }
            setValue(valuesToSet);
          }}
          onInputKeyDown={(e) => {
            // Adding ability to adding words by clicking enter and when value is empty, clicking enter means submit form
            const target = e.target as HTMLInputElement;
            if (e.key === "Enter" && target.value) {
              e.preventDefault();
            }
          }}
          onBlur={() => setTouched(true)}
          showSearch
          placeholder={placeholder ? placeholder : "Please input your values"}
          tokenSeparators={[",", ";"]}
          allowClear
          mode="tags"
          open={false}
          disabled={disabled}
          // In case the select is disabled, which only happens on the EditCampaignView,
          // hide the border and the background
          bordered={!disabled}
          {...rest}
        />
        {field.value?.length > 0 && (
          <Popover
            content="Copied!"
            trigger="click"
            visible={visible}
            onVisibleChange={handleOnVisibleChange}
          >
            <CopyButton
              title="Copy keywords to clipboard"
              onClick={handleOnClickCopy}
            >
              <CopyOutlined />
            </CopyButton>
          </Popover>
        )}
      </Label>
      {extraContent && extraContent}
    </Form.Item>
  );
};

const CopyButton = styled(Button)`
  position: absolute;
  top: -4rem;
  right: 0rem;
  padding: 0.7rem 0.7rem 2.5rem 0.7rem;
  background: none;
  color: var(--primary-color);
  border: none;

  &:hover,
  &:focus {
    background: none;
  }
`;

const Select = styled(BaseSelect)<{ disabled?: boolean }>`
  width: 100%;
  margin-top: 1rem;

  ${(props) =>
    props.disabled &&
    `
    ant-select-selector {
      cursor: default !important;
    }

    ant-select-selection-overflow-item {
      cursor: default !important;
    }

    span {
      color: var(--font-color-disabled-input) !important;
      cursor: default !important;
    }
    `};
`;
