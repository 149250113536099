import { PendingStatusTag } from "./PendingStatusTag";
import { InvitationActions } from "./actions/invitation/InvitationActions";
import { UserActions } from "./actions/user/UserActions";
import { RoleSelect } from "./RoleSelect";

import { Role } from "../../../store";

export const columns = (clientUUID: string | undefined) => [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Name",
    dataIndex: "fullName",
    key: "fullName",
    render: (value: string, record: any) => {
      return record.firstName && `${record.firstName} ${record.lastName}`;
    },
    ellipsis: true,
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    align: "center" as "center",
    render: (role: Role, record: any) => {
      return (
        <RoleSelect
          value={role}
          userUUID={record.uuid}
          clientUUID={clientUUID}
        />
      );
    },
    filters: [
      { text: "Admin", value: "admin" },
      { text: "Contributor", value: "contributor" },
    ],
    onFilter: (value: any, record: any) => record.role === value,
  },
  {
    title: "Invitation status",
    key: "invitationPendingStatus",
    dataIndex: "invitationPendingStatus",
    align: "center" as "center",
    render: (isPending: boolean) => {
      let color = isPending ? "red" : "green";
      let label = isPending ? "pending" : "accepted";
      return (
        <PendingStatusTag color={color} label={label} isPending={isPending} />
      );
    },
    filters: [
      { text: "Accepted", value: false },
      { text: "Pending", value: true },
    ],
    onFilter: (value: any, record: any) =>
      record.invitationPendingStatus === value,
  },
  {
    title: "Action",
    key: "action",
    render: (value: string, record: any) => {
      if (record.invitationPendingStatus)
        return (
          <InvitationActions
            userUUID={record.uuid}
            lastReminderAt={record.lastReminderAt}
            clientUUID={clientUUID}
          />
        );

      return (
        <UserActions
          userUUID={record.uuid}
          clientUUID={clientUUID}
          isEnabled={record.isEnabled}
        />
      );
    },
  },
];
