//@ts-nocheck
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { UserLayout } from "./ui-components/layout/UserLayout";
import { AuthLayout } from "./ui-components/layout/AuthLayout";

import { LoginView } from "./auth/LoginView";
import { ForgotPasswordView } from "./auth/ForgotPasswordView";
import { ResetPasswordView } from "./auth/ResetPasswordView";
import { NewUserView } from "./auth/NewUserView";
import { SignUpView } from "./auth/SignUpView";

import { AccountInformationView } from "./account-information/AccountInformationView";
import { CampaignInsightsView } from "./campaign/insights/CampaignInsightsView";
import { EditCampaignView } from "./campaign/edit-campaign/EditCampaignView";
import { GoldenBasketView } from "./campaign/golden-basket/GoldenBasketView";
import { ClientManagerView } from "./clients/ClientManagerView";
import { CompaniesSearchView } from "./companies-search/CompaniesSearchView";
import { DashboardView } from "./dashboard/DashboardView";
import { ProfileView } from "./edit-profile/ProfileView";
import { FavouriteView } from "./favourite-page/FavouriteView";
import { NewCampaignView } from "./new-campaign/NewCampaignView";
import { ListManagementView } from "./list/list-management/ListManagementView";
import { ListView } from "./list/list-view/ListView";
import { TagManagementView } from "./tags/tag-management.ts/TagManagementView";
import { UserManagementView } from "./user-management/UserManagementView";
import { ScottView } from "./scott/ScottView";

import { State } from "./utils/store";
import { useAppSelector } from "./utils/hooks";

const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAppSelector((state: State) => state.auth);
  const location = useLocation();

  if (!user)
    return (
      <Navigate to={"/login"} replace state={{ from: location.pathname }} />
    );
  return children;
};

export const AppRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={
        <RequireAuth>
          <Navigate to="/clients" />
        </RequireAuth>
      }
    />
    <Route
      path="/clients"
      element={
        <RequireAuth>
          <ClientManagerView />
        </RequireAuth>
      }
    />
    <Route path="/:clientSlug">
      <Route
        path="/:clientSlug/account"
        element={
          <RequireAuth>
            <UserLayout title="Account Information">
              <AccountInformationView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/profile"
        element={
          <RequireAuth>
            <UserLayout title="My Profile">
              <ProfileView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/campaign/golden-basket/:campaignSlug"
        element={
          <RequireAuth>
            <UserLayout withNav>
              <GoldenBasketView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/campaign/home/:campaignSlug"
        element={
          <RequireAuth>
            <UserLayout withNav>
              <EditCampaignView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/campaign/insights/:campaignSlug"
        element={
          <RequireAuth>
            <UserLayout withNav>
              <CampaignInsightsView />
            </UserLayout>
          </RequireAuth>
        }
      />

      <Route
        path="/:clientSlug/dashboard"
        element={
          <RequireAuth>
            <UserLayout title="Dashboard" activeNewCampaign>
              <DashboardView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/favourites"
        element={
          <RequireAuth>
            <UserLayout title="Favourites" activeNewCampaign>
              <FavouriteView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/list/:listSlug"
        element={
          <RequireAuth>
            <UserLayout title="List">
              <ListView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/list-management"
        element={
          <RequireAuth>
            <UserLayout title="List Managenent">
              <ListManagementView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/new-campaign/:uuid"
        element={
          <RequireAuth>
            <UserLayout isNewCampaign>
              <NewCampaignView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/new-campaign"
        element={
          <RequireAuth>
            <UserLayout isNewCampaign>
              <NewCampaignView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/real-time-search"
        element={
          <RequireAuth>
            <UserLayout title="Real-time search">
              <CompaniesSearchView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/scott"
        element={
          <RequireAuth>
            <UserLayout title="Scott: Your AI Scouting Agent" activeNewChat={true}>
              <ScottView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/tag-management"
        element={
          <RequireAuth>
            <UserLayout title="Tag Managenent">
              <TagManagementView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path="/:clientSlug/user-management"
        element={
          <RequireAuth>
            <UserLayout title="User Management">
              <UserManagementView />
            </UserLayout>
          </RequireAuth>
        }
      />
      <Route
        path=""
        element={
          <RequireAuth>
            <ClientManagerView />
          </RequireAuth>
        }
      />
    </Route>
    <Route path="*" element={<Navigate to="/" />} />

    {/**
     * Anonymous routes
     *
     * Both LoginView and SignupView will redirect to /clients if the user is logged in
     *
     */}
    <Route element={<AuthLayout />}>
      <Route path="/login" element={<LoginView />} />
      <Route path="/create-password" element={<NewUserView />} />
      <Route path="/forgot-password" element={<ForgotPasswordView />} />
      <Route path="/reset-password" element={<ResetPasswordView />} />
      <Route path="/signup" element={<SignUpView />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Route>

    {/*
      The following routes are added to make the dashboard-redirection backwards compatible-ish
      
      Routes start HERE
    */}
    <Route
      path="/dashboard"
      element={
        <RequireAuth>
          <Navigate
            to="/clients"
            state={{ addClientSlug: true, from: "dashboard" }}
          />
        </RequireAuth>
      }
    />
    <Route
      path="/account"
      element={
        <RequireAuth>
          <Navigate
            to="/clients"
            state={{ addClientSlug: true, from: "account" }}
          />
        </RequireAuth>
      }
    />
    <Route
      path="/campaign*"
      element={
        <RequireAuth>
          <Navigate to="/clients" />
        </RequireAuth>
      }
    />
    <Route
      path="/new-campaign"
      element={
        <RequireAuth>
          <Navigate
            to="/clients"
            state={{ addClientSlug: true, from: "new-campaign" }}
          />
        </RequireAuth>
      }
    />
    <Route
      path="/new-campaign/*"
      element={
        <RequireAuth>
          <Navigate to="/clients" />
        </RequireAuth>
      }
    />
    <Route
      path="/real-time-search"
      element={
        <RequireAuth>
          <Navigate
            to="/clients"
            state={{ addClientSlug: true, from: "real-time-search" }}
          />
        </RequireAuth>
      }
    />
    <Route
      path="/user-management"
      element={
        <RequireAuth>
          <Navigate
            to="/clients"
            state={{ addClientSlug: true, from: "user-management" }}
          />
        </RequireAuth>
      }
    />
    {/*
      The backwards compatible routes end HERE
    */}
  </Routes>
);
