import { useState, useEffect } from "react";
import { Formik } from "formik";
import styled from "@emotion/styled";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router";

import { Result } from "antd";
import { CompanyInformationForm } from "./components/signup/CompanyInformationForm";
import { PersonalInformationForm } from "./components/signup/PersonalInformationForm";
import { errorNotification } from "../ui-components/Notification";

import { SignUpData, signUp, setInitialSignUpState } from "./store";
import { Segment } from "../utils/Segment";
import { State } from "../utils/store";
import { useAppDispatch, useAppSelector } from "../utils/hooks";

import {
  SignUpCompanyInformationSchema,
  SignUpPersonalInformationSchema,
} from "./validation";

enum Step {
  PERSONAL_INFORMATION = 0,
  COMPANY_INFORMATION = 1,
  SUCCESS = 2,
}

// Has to have same order as Step enum
const validationSchema = [
  SignUpPersonalInformationSchema,
  SignUpCompanyInformationSchema,
];

const PERSONAL_INFO_TRACK_LABEL = "signup-personal-info";
const COMPANY_INFO_TRACK_LABEL = "signup-company-info";
const SUBMIT_TRACK_LABEL = "signup-confirm";

export const SignUpView = () => {
  const navigate = useNavigate();
  // Generate once, use to track, and to set up user eventually
  const [userUUID, setUserUUID] = useState("");
  const [userEmail, setuserEmail] = useState("");

  const dispatch = useAppDispatch();
  const [trackedCompanyInfo, setTrackedCompanyInfo] = useState(false);
  const [currentStep, setCurrentStep] = useState(Step.PERSONAL_INFORMATION);
  const { loading, success, message } = useAppSelector(
    (state: State) => state.auth.signUp
  );
  const { user } = useAppSelector((state: State) => state.auth);

  const goBackToPersonalInformation = () => {
    setCurrentStep(Step.PERSONAL_INFORMATION);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (user) navigate("/clients");
  }, [user, navigate]);

  useEffect(() => {
    if (success === false && message) {
      errorNotification(message);
      window.scrollTo(0, 0);
    }
  }, [success, message]);

  useEffect(() => {
    const _userUUID = uuidv4();
    setUserUUID(_userUUID);

    track_personal_info(_userUUID);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setInitialSignUpState());
    };
  }, [dispatch]);

  const track_personal_info = (_userUUID: string) => {
    Segment.analytics?.track(PERSONAL_INFO_TRACK_LABEL, {
      "user-uuid": _userUUID,
    });
  };

  const track_company_info = () => {
    Segment.analytics?.track(COMPANY_INFO_TRACK_LABEL, {
      "user-uuid": userUUID,
    });
    setTrackedCompanyInfo(true);
  };

  const track_submit = () => {
    Segment.analytics?.track(SUBMIT_TRACK_LABEL, { "user-uuid": userUUID });
  };

  if (success) {
    track_submit();

    return (
      <Result
        status="success"
        title={<Span>Welcome to Novable!</Span>}
        subTitle={
          <Span>
            <br />
            <BigSpan>
              We've just sent an email to {userEmail} with a login link.
            </BigSpan>
            <br />
            <br />
            With the link, you will be able to launch your first campaign on our
            desktop application!
            <br />
            If you didn't receive it, please check your spam folder or contact
            us at support@novable.com
          </Span>
        }
      />
    );
  }

  return (
    <Formik
      initialValues={{
        uuid: "",
        firstName: "",
        lastName: "",
        email: "",
        acceptedTermsAndConditions: false,
        companyName: "",
        role: "",
        useCase: "",
        useCaseText: "",
        phoneNumber: "",
      }}
      onSubmit={(values: SignUpData) => {
        if (currentStep === Step.PERSONAL_INFORMATION) {
          if (!trackedCompanyInfo) {
            track_company_info();
          }
          setCurrentStep(Step.COMPANY_INFORMATION);
        } else {
          const signupData = {
            ...values,
            uuid: userUUID,
          };
          dispatch(signUp(signupData));
          setuserEmail(values.email);
        }
      }}
      validationSchema={validationSchema[currentStep]}
    >
      {({ handleSubmit, values, errors, isValidating }) => (
        <Form onSubmit={handleSubmit} className="sign-up-form" id="signUpForm">
          {currentStep === Step.PERSONAL_INFORMATION && (
            <PersonalInformationForm
              errors={errors}
              handleSubmit={handleSubmit}
              loading={isValidating}
            />
          )}
          {currentStep === Step.COMPANY_INFORMATION && (
            <CompanyInformationForm
              errors={errors}
              prevStep={goBackToPersonalInformation}
              loading={isValidating || loading}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

const Form = styled.form`
  max-width: 47.2rem;
  width: 100%;
  max-height: 100%;
  margin-top: 3rem;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const Span = styled.span`
  color: var(--font-color-black);
  @media (max-width: 1199px) {
    color: var(--white);
  }
`;

const BigSpan = styled(Span)`
  font-size: 1.8rem;
`;
