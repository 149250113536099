import { FC } from "react";

import { ContactsSection } from "./ContactsSection";
import { EnrichedSection } from "./Section";
import {
  SectionItem,
  SectionContainer,
} from "./EnrichedCompanyProfileSectionComponents";

import { EnrichedProfileContent } from "../../../../store";
import { CompanyPageFieldName } from "../../../../../../ui-components/Typography";

interface EnrichedCompanyProfilePeopleSectionProps {
  enrichedProfileContent: EnrichedProfileContent;
}

export const EnrichedCompanyProfilePeopleSection: FC<EnrichedCompanyProfilePeopleSectionProps> =
  ({ enrichedProfileContent }) => {
    const { employeeNumber, founders, executiveTeam, contacts } =
      enrichedProfileContent;

    const contentHasFounders = founders && founders.length > 0;
    const contentHasexecutiveTeam = executiveTeam && executiveTeam.length > 0;
    const contentHasContacts = contacts && contacts.length > 0;
    const contentHasPeopleInformation =
      employeeNumber ||
      contentHasFounders ||
      contentHasexecutiveTeam ||
      contentHasContacts;

    if (!contentHasPeopleInformation) return <></>;

    return (
      <EnrichedSection title="People">
        {employeeNumber && (
          <SectionItem>
            <CompanyPageFieldName>Company size (est.): </CompanyPageFieldName>
            {employeeNumber}
          </SectionItem>
        )}
        <SectionContainer>
          {contentHasFounders && (
            <SectionItem>
              <ContactsSection
                label="Founders"
                enrichedProfileContacts={founders}
              />
            </SectionItem>
          )}
          {contentHasexecutiveTeam && (
            <SectionItem>
              <ContactsSection
                label="Executive team"
                enrichedProfileContacts={executiveTeam}
              />
            </SectionItem>
          )}
          {contentHasContacts && (
            <SectionItem>
              <ContactsSection
                label="Contacts"
                enrichedProfileContacts={contacts}
              />
            </SectionItem>
          )}
        </SectionContainer>
      </EnrichedSection>
    );
  };
