import { FC } from "react";

import { Tooltip, Col } from "antd";
import { ListWidget, ListWidgetProps } from "../../list-widget/ListWidget";
import { ActionButton } from "../../ui-share";
import { UnorderedListOutlined } from "@ant-design/icons";

import { ListCompanySource } from "../../../../../list/store";

interface CompanyListManagementButtonProps extends ListWidgetProps {
  listCompanySource: ListCompanySource;
}

export const CompanyListManagementButton: FC<CompanyListManagementButtonProps> =
  ({ companyLists, companyName, companyUUID, listCompanySource }) => {
    return (
      <Col
        className="gutter-row"
        // xs={23}
        // sm={11}
        // md={11}
        // lg={11}
        // xl={11}
        // xxl={11}
      >
        <ListWidget
          companyLists={companyLists}
          companyUUID={companyUUID}
          companyName={companyName}
          listCompanySource={listCompanySource}
        >
          <Tooltip
            placement="top"
            title="Manage to which lists this Company belongs to"
            trigger={"hover"}
          >
            <ActionButton type="text">
              <UnorderedListOutlined style={{ color: '#FA8212' }}/>
              {/* &nbsp; <ButtonText>Add to list</ButtonText> */}
            </ActionButton>
          </Tooltip>
        </ListWidget>
      </Col>
    );
  };

// filter: invert(89%) sepia(14%) saturate(14%) hue-rotate(3deg) brightness(93%) contrast(86%);
