import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { fork, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { State } from "../utils/store";
import {
  addCampaignToStore,
  addDraftToStore,
  deleteDraftFromStore,
  Cluster,
  Campaign,
} from "../share/campaigns/store";
import { Dictionary } from "../share/types";

import {
  addIsActiveToClustersKeywords,
  filterEmptyStrings,
  normalizeClusterWeight,
} from "../helpers";
import { backend } from "../utils/http";

export interface CompanyInformation {
  name: string;
  city: string;
  country: string;
  employeesRangeStart: number | null;
  employeesRangeEnd: number | null;
  shortDescription: string | null;
  logo: string | null;
  url: string | null;
}

export interface Firmographics {
  age: Dictionary<number>;
  country: Dictionary<number>;
  employeeRange: Dictionary<number>;
  textKeywords: Dictionary<number>;
  totalFunding: Dictionary<number>;
  companies: CompanyInformation[];
}

interface BriefingData {
  countries: string[];
  keywords: string[];
  lookalikes: string[];
}

export interface SuggestedKeywordsData extends BriefingData {
  strategy: any;
}

export interface FirmographicsData extends BriefingData {
  clusters: Cluster[];
  strategy: any;
  clientUUID: string;
}

export interface NewCampaignData extends BriefingData {
  clientUUID: string;
  name: string;
  description: string;
  activate: boolean;
  clusters: Cluster[];
  strategyPlainText: any;
}

export interface FetchDraftData {
  uuid: string;
}

export interface DeleteDraftData {
  clientUUID: string;
  uuid: string;
}

export interface NewCampaignState {
  saveDraftCampaign: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
  fetchDraftCampaign: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
  deleteDraftCampaign: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
  createNewCampaign: {
    loading: boolean;
    message: string;
    success: boolean | null;
    newCampaignSlug: string | null;
  };
  fetchSuggestedKeywords: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
  fetchClusters: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
  fetchFirmographics: {
    loading: boolean;
    message: string;
    success: boolean | null;
  };
  suggestedKeywords: string[];
  selectedKeywords: string[];
  clusters: Cluster[];
  firmographics: Firmographics;
  draftCampaign: Campaign;
}

const initialState = {
  saveDraftCampaign: {
    loading: false,
    success: null,
    message: "",
  },
  fetchDraftCampaign: {
    loading: false,
    success: null,
    message: "",
  },
  deleteDraftCampaign: {
    loading: false,
    success: null,
    message: "",
  },
  createNewCampaign: {
    loading: false,
    success: null,
    message: "",
    newCampaignSlug: null,
  },
  fetchSuggestedKeywords: {
    loading: false,
    success: null,
    message: "",
  },
  fetchClusters: {
    loading: false,
    success: null,
    message: "",
  },
  fetchFirmographics: {
    loading: false,
    success: null,
    message: "",
  },
  suggestedKeywords: [],
  selectedKeywords: [],
  clusters: [],
  firmographics: {
    age: {},
    country: {},
    employeeRange: {},
    textKeywords: {},
    totalFunding: {},
    companies: [],
  },
  draftCampaign: {
    uuid: "",
    favourites: 0,
    followed: 0,
    liked: 0,
    toReview: 0,
    clusters: [],
    name: "",
    description: "",
    types: null,
    strategyPlainText: "",
    lookalikes: ["", "", ""],
    keywords: [],
    keywordsExcluded: [],
    exclusions: "",
    countries: [],
    status: "",
    ageThreshold: null,
    client: 0,
    creator: "",
    lastEditedBy: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    publishedAt: null,
    companiesDelivered: 0,
    isDemo: false,
    iterations: 0,
    matches: 0,
    slug: "",
    statusPending: false,
    requests: { exportRequested: false, iterationRequested: false },
  },
} as NewCampaignState;

export const newCampaignSlice = createSlice({
  name: "new_campaign",
  initialState: initialState,
  reducers: {
    saveDraftCampaign(state, action) {
      state.saveDraftCampaign = {
        ...initialState.saveDraftCampaign,
        loading: true,
      };
    },
    saveDraftCampaignSuccess(state, action) {
      state.saveDraftCampaign.loading = false;
      state.saveDraftCampaign.message = action.payload.message;
      state.saveDraftCampaign.success = true;
      if (state.draftCampaign) {
        state.draftCampaign.updatedAt = action.payload.data.updatedAt;
        state.draftCampaign.lastEditedBy = action.payload.data.lastEditedBy;
        // state.draftCampaign.keywordsExcluded = action.payload.data.keywordsExcluded;
      } else {
        state.draftCampaign = action.payload.data;
      }
    },
    saveDraftCampaignFailure(state, action) {
      state.saveDraftCampaign.loading = false;
      state.saveDraftCampaign.message = action.payload?.data?.message;
      state.saveDraftCampaign.success = false;
    },
    fetchDraftCampaign(state, action) {
      state.fetchDraftCampaign = {
        ...initialState.fetchDraftCampaign,
        loading: true,
      };
    },
    fetchDraftCampaignSuccess(state, action) {
      state.fetchDraftCampaign.loading = false;
      state.fetchDraftCampaign.message = action.payload.message;
      state.fetchDraftCampaign.success = true;
      state.draftCampaign = action.payload.data;
    },
    fetchDraftCampaignFailure(state, action) {
      state.fetchDraftCampaign.loading = false;
      state.fetchDraftCampaign.message = action.payload.data.message;
      state.fetchDraftCampaign.success = false;
    },
    deleteDraftCampaign(state, action) {
      state.deleteDraftCampaign = {
        ...initialState.deleteDraftCampaign,
        loading: true,
      };
    },
    deleteDraftCampaignSuccess(state, action) {
      state.deleteDraftCampaign.loading = false;
      state.deleteDraftCampaign.message = action.payload.message;
      state.deleteDraftCampaign.success = true;
    },
    deleteDraftCampaignFailure(state, action) {
      state.deleteDraftCampaign.loading = false;
      state.deleteDraftCampaign.message = "";
      state.deleteDraftCampaign.success = false;
    },
    createNewCampaign(state, action) {
      state.createNewCampaign = {
        ...initialState.createNewCampaign,
        loading: true,
      };
    },
    createNewCampaignSuccess(state, action) {
      state.createNewCampaign.loading = false;
      state.createNewCampaign.message = action.payload.message;
      state.createNewCampaign.success = true;
      state.createNewCampaign.newCampaignSlug = action.payload.data.slug;
    },
    createNewCampaignFailure(state, action) {
      state.createNewCampaign.loading = false;
      state.createNewCampaign.message = action.payload.data.message;
      state.createNewCampaign.success = false;
    },
    fetchSuggestedKeywords(
      state,
      action: PayloadAction<SuggestedKeywordsData>
    ) {
      state.fetchSuggestedKeywords.loading = true;
      state.fetchSuggestedKeywords.message = "";
    },
    fetchSuggestedKeywordsSuccess(state, action) {
      state.fetchSuggestedKeywords.loading = false;
      state.fetchSuggestedKeywords.success = true;
      state.fetchSuggestedKeywords.message = "";
      state.suggestedKeywords = action.payload.data;
    },
    fetchSuggestedKeywordsFailure(state, action) {
      state.fetchSuggestedKeywords.loading = false;
      state.fetchSuggestedKeywords.success = false;
      state.fetchSuggestedKeywords.message =
        action.payload.response.data.message;
    },
    fetchClusters(state, action: PayloadAction<SuggestedKeywordsData>) {
      state.fetchClusters.loading = true;
    },
    fetchClustersSuccess(state, action) {
      state.fetchClusters.loading = false;
      state.fetchClusters.success = true;
      state.clusters = action.payload;
    },
    fetchClustersFailure(state, action) {
      state.fetchClusters.loading = false;
      state.fetchClusters.success = false;
      state.fetchClusters.message = action.payload.data.message;
    },
    setSelectedKeywords(state, action) {
      state.selectedKeywords = action.payload;
    },
    setClusters(state, action) {
      state.clusters = action.payload;
    },
    setInitialState(state) {
      state.saveDraftCampaign = initialState.saveDraftCampaign;
      state.fetchDraftCampaign = initialState.fetchDraftCampaign;
      state.deleteDraftCampaign = initialState.deleteDraftCampaign;
      state.createNewCampaign = initialState.createNewCampaign;
      state.fetchSuggestedKeywords = initialState.fetchSuggestedKeywords;
      state.fetchClusters = initialState.fetchClusters;
      state.fetchFirmographics = initialState.fetchFirmographics;
      state.clusters = initialState.clusters;
      state.suggestedKeywords = initialState.suggestedKeywords;
      state.selectedKeywords = initialState.selectedKeywords;
      state.firmographics = initialState.firmographics;
      state.draftCampaign = initialState.draftCampaign;
    },
    fetchFirmographics(state, action: PayloadAction<FirmographicsData>) {
      state.fetchFirmographics.loading = true;
      state.fetchFirmographics.message = "";
    },
    fetchFirmographicsSuccess(state, action) {
      state.fetchFirmographics.loading = false;
      state.fetchFirmographics.success = true;
      state.fetchFirmographics.message = "";
      state.firmographics = action.payload.data;
    },
    fetchFirmographicsFailure(state, action) {
      state.fetchFirmographics.loading = false;
      state.fetchFirmographics.success = false;
      state.fetchFirmographics.message = action.payload.data.message;
    },
    setDraftName(state, action) {
      state.draftCampaign.name = action.payload;
    },
  },
});

const draftCampaigns = (state: State) => state.campaigns.draftCampaigns;
const campaigns = (state: State) => state.campaigns.campaigns;

const BASE_DRAFT_NAME = "Campaign Title";
export const generateDraftName = () =>
  createSelector(
    [draftCampaigns, campaigns],
    (draftCampaigns: Campaign[], campaigns: any) => {
      let names = [...draftCampaigns, ...campaigns].map((x) => x.name);
      if (names.length !== 0) {
        let nameAlreadyExists = names.some((name) => name === BASE_DRAFT_NAME);
        if (!nameAlreadyExists) return BASE_DRAFT_NAME;

        for (let i = 0; i < names.length; i++) {
          let draftName = `${BASE_DRAFT_NAME} ${i + 1}`;

          let nameAlreadyExists = names.some((name) => name === draftName);
          if (!nameAlreadyExists) return draftName;
        }
      }

      return BASE_DRAFT_NAME;
    }
  );

export const campaignAndDraftNames = () =>
  createSelector(draftCampaigns, campaigns, (draftCampaigns, campaigns) => {
    const draftNames = draftCampaigns.map((x) => {
      return { uuid: x.uuid, name: x.name };
    });
    const campaignNames = campaigns.map((x) => {
      return { uuid: x.uuid, name: x.name };
    });
    const names = [...draftNames, ...campaignNames];
    return names;
  });

function* saveDraftCampaignSaga() {
  yield takeEvery(
    newCampaignSlice.actions.saveDraftCampaign,
    function* (action) {
      try {
        const readyLookalikes = filterEmptyStrings(action.payload.lookalikes);
        action.payload["lookalikes"] = readyLookalikes;
        const response: AxiosResponse<NewCampaignData> =
          yield backend.campaignBackend.saveDraftCampaign(action.payload);
        yield put(newCampaignSlice.actions.saveDraftCampaignSuccess(response));
        yield put(addDraftToStore(response.data));
      } catch (error: any) {
        yield put(newCampaignSlice.actions.saveDraftCampaignFailure(error));
      }
    }
  );
}

function* fetchDraftCampaignSaga() {
  yield takeEvery(
    newCampaignSlice.actions.fetchDraftCampaign,
    function* (action) {
      try {
        let response: AxiosResponse<Campaign> =
          yield backend.campaignBackend.getDraftCampaign(action.payload);
        let clusters = addIsActiveToClustersKeywords([
          ...response.data.clusters,
        ]);
        response.data.clusters = [...clusters];
        yield put(newCampaignSlice.actions.fetchDraftCampaignSuccess(response));
        yield put(addDraftToStore(response.data));
      } catch (error: any) {
        yield put(newCampaignSlice.actions.fetchDraftCampaignFailure(error));
      }
    }
  );
}

function* deleteDraftCampaignSaga() {
  yield takeEvery(
    newCampaignSlice.actions.deleteDraftCampaign,
    function* (action) {
      try {
        const response: AxiosResponse<DeleteDraftData> =
          yield backend.campaignBackend.deleteDraftCampaign(action.payload);

        yield put(
          newCampaignSlice.actions.deleteDraftCampaignSuccess(response)
        );
        // TODO: Delete, if existing, from list of drafts
        yield put(deleteDraftFromStore(response.data));
      } catch (error: any) {
        yield put(newCampaignSlice.actions.deleteDraftCampaignFailure(error));
      }
    }
  );
}

function* createNewCampaignSaga() {
  yield takeEvery(
    newCampaignSlice.actions.createNewCampaign,
    function* (action) {
      try {
        const readyLookalikes = filterEmptyStrings(action.payload.lookalikes);
        action.payload["lookalikes"] = readyLookalikes;
        const response: AxiosResponse<NewCampaignData> =
          yield backend.campaignBackend.createNewCampaign(action.payload);

        yield put(addCampaignToStore(response.data));
        yield put(deleteDraftFromStore(response.data));
        yield put(newCampaignSlice.actions.createNewCampaignSuccess(response));
      } catch (error: any) {
        yield put(newCampaignSlice.actions.createNewCampaignFailure(error));
      }
    }
  );
}

function* fetchSuggestedKeywordsSaga() {
  yield takeEvery(
    newCampaignSlice.actions.fetchSuggestedKeywords,
    function* (action) {
      try {
        if (action.payload.lookalikes) {
          const readyLookalikes = filterEmptyStrings(action.payload.lookalikes);
          action.payload["lookalikes"] = readyLookalikes;
        }
        const response: AxiosResponse<string[]> =
          yield backend.campaignBackend.fetchSuggestedKeywords(action.payload);
        yield put(
          newCampaignSlice.actions.fetchSuggestedKeywordsSuccess(response)
        );
      } catch (error: any) {
        yield put(
          newCampaignSlice.actions.fetchSuggestedKeywordsFailure(error)
        );
      }
    }
  );
}

function* fetchClustersSaga() {
  yield takeEvery(newCampaignSlice.actions.fetchClusters, function* (action) {
    try {
      if (action.payload.lookalikes) {
        const readyLookalikes = filterEmptyStrings(action.payload.lookalikes);
        action.payload["lookalikes"] = readyLookalikes;
      }
      const response: AxiosResponse<Cluster[]> =
        yield backend.campaignBackend.fetchClusters(action.payload);

      const normalizedClusters = normalizeClusterWeight(response.data);
      // To keep control of active/non-active keywords in a cluster
      const normalizedClustersWithActiveKeywords =
        addIsActiveToClustersKeywords(normalizedClusters);

      yield put(
        newCampaignSlice.actions.fetchClustersSuccess(
          normalizedClustersWithActiveKeywords
        )
      );
    } catch (error: any) {
      yield put(newCampaignSlice.actions.fetchClustersFailure(error));
    }
  });
}

function* fetchFirmographicsSaga() {
  yield takeEvery(
    newCampaignSlice.actions.fetchFirmographics,
    function* (action) {
      try {
        if (action.payload.lookalikes) {
          const readyLookalikes = filterEmptyStrings(action.payload.lookalikes);
          action.payload["lookalikes"] = readyLookalikes;
        }

        const response: AxiosResponse<any> =
          yield backend.campaignBackend.fetchFirmographics(action.payload);
        yield put(newCampaignSlice.actions.fetchFirmographicsSuccess(response));
      } catch (error: any) {
        yield put(newCampaignSlice.actions.fetchFirmographicsFailure(error));
      }
    }
  );
}

export function* newCampaignSaga() {
  yield fork(saveDraftCampaignSaga);
  yield fork(fetchDraftCampaignSaga);
  yield fork(deleteDraftCampaignSaga);
  yield fork(createNewCampaignSaga);
  yield fork(fetchSuggestedKeywordsSaga);
  yield fork(fetchClustersSaga);
  yield fork(fetchFirmographicsSaga);
}

export const suggestedKeywordsSelector = (state: State) =>
  state.newCampaign.suggestedKeywords;

export const selectedKeywordsSelector = (state: State) =>
  state.newCampaign.selectedKeywords;

export const {
  saveDraftCampaign,
  fetchDraftCampaign,
  deleteDraftCampaign,
  createNewCampaign,
  fetchSuggestedKeywords,
  setInitialState,
  setSelectedKeywords,
  fetchClusters,
  fetchClustersSuccess,
  setClusters,
  fetchFirmographics,
  setDraftName,
} = newCampaignSlice.actions;
