import styled from "@emotion/styled";
import { SectionContent } from "../../../ui-share";

export const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  width: 100%;
`;

export const SectionItem = styled(SectionContent)`
  width: 50%;
`;
